import React, { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../../../context/user";
import { Client } from "../../../../../types/userManagement";
import api from "../../../../../api/axiosConfig";
import { fetchClient, useGetClient } from "../../../../../lib/clients/useGetClient";



type ClientDashboardContextType = {
    setClient: (client: Client | undefined) => void
    clientID: string
    client:  Client | undefined
    clientName: string
    clear: () => void
    loadClientByID: (clientID: string) => void
    loaded: boolean
    getClientByID: (clientID: string) => Promise<Client | undefined>
}


const ClientDashboardContext = createContext<ClientDashboardContextType | undefined>(undefined);

type ClientDashboardProviderProps = {
    children: ReactNode;
}

const localStorageKey = "ClientDashboardProvider:clientID"
 
export const ClientDashboardProvider: React.FC<ClientDashboardProviderProps> = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialClientID, setInitialClientID] = useState<string>(searchParams.get("clientID") || localStorage.getItem(localStorageKey) || "");
    const {data: initialClient, isError, isSuccess, isLoading} = useGetClient(initialClientID);
    const {isSuperAdmin, clientName: authClientName, isAuthenticated} = useAuth();
    const [client, setClient] = useState<Client | undefined>();
    const navigate = useNavigate();
    const clientID = useMemo(() =>{
        if (client) {
            return client.id;
        } 
        return "";
    },[client])
    const clientName = useMemo(() =>{
        if (!isSuperAdmin) {
            return authClientName 
        }
        if (client) {
            return client.name;
        } 
        return "";
    },[client])



    const loaded = useMemo(() =>{
        if(!searchParams.get("clientID")){
            return true
        }

        if (!initialClientID) {
            return true
        }
        if (isLoading) {
            return false
        }
        if (clientID) {
            return true
        }
        return false
    },[clientID, initialClientID, isLoading, searchParams])
  
    useEffect(() => {
      if (!window.location.pathname.startsWith("/reporting") && !window.location.pathname.startsWith("/management")) {
        console.log("removing selected client");
        localStorage.removeItem(localStorageKey);
        setInitialClientID("");
        setClient(undefined);
      } else if (!isAuthenticated){
        navigate("/")
      }
    }, [window.location.pathname, isAuthenticated]);

    useEffect(() => {
        console.log(isSuccess, initialClient)
        if (isSuccess && initialClient) {
            setClient(initialClient)
        }
    }, [isSuccess, initialClient])


    useEffect(() => {
        if (!isSuperAdmin || !isAuthenticated) {
            return;
        }

        if(window.location.pathname.startsWith("/management")){
            return
        }

        const newSearchParams = new URLSearchParams(searchParams.toString());

        if (client) {
            newSearchParams.set("clientID", client.id)
            localStorage.setItem(localStorageKey, client.id)
        } else {
            localStorage.removeItem(localStorageKey)
            newSearchParams.delete("clientID")
        }
        setSearchParams(newSearchParams);

    }, [client, isSuperAdmin, isAuthenticated, searchParams]);

    const clear = useCallback(() => {
        setClient(undefined)
    }, [setClient])

    const loadClientByID = useCallback(async (clientID: string) => {
        const newClient = await fetchClient(clientID)
        if (newClient) {
            setClient(newClient)
        }
    }, [setClient, fetchClient])

    const getClientByID = useCallback(async (clientID: string): Promise<Client | undefined> => {
        const newClient = await fetchClient(clientID);
        return newClient ?? undefined; 
    }, [fetchClient]);


    return (
        <ClientDashboardContext.Provider value={{ clientID, setClient, clientName, clear, loaded, loadClientByID, getClientByID, client }}>
            {loaded && children}
        </ClientDashboardContext.Provider>
    );
};


export const useClientDashboard = (): ClientDashboardContextType => {
    const context = useContext(ClientDashboardContext);
    if (!context) {
        throw new Error("useClientDashboard must be used within a ClientDashboardProvider");
    }
    return context;
};