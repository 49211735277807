import React from "react";
import clsx from "clsx";

export const CompletePage: React.FC = () => {
    return <div className="flex flex-col gap-8 rounded-md w-full lg:w-1/3 justify-center">
        <Link href={"/"} text={"Login"} hideMobile/>
        <Link href={"https://apps.apple.com/gb/app/ngs-app-plus/id1489153694"} text={"Download iOS App"}/>
        <Link href={"https://play.google.com/store/apps/details?id=com.ngs.OneTapPlus&hl=en&gl=US"} text={"Download Android App"}/>
    </div>

}

type LinkProps = {
    href: string
    text: string
    hideMobile?: boolean
}
const Link: React.FC<LinkProps> = (props) => {

    const className = clsx(
        "w-full min-w-fit h-10 text-white text-sm rounded-md shadow-lg flex items-center gap-6 justify-center text-center hover:bg-opacity-80 transition-opacity bg-blue px-[10%]",
        {
            "hidden md:flex": props.hideMobile,
        }
    )

    return <a href={props.href} target="_blank" className={className} rel="noreferrer">
        {props.text}
    </a>
}