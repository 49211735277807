import {useImportContext} from "./ImportContextProvider";
import {useCallback, useMemo, useState} from "react";
import {AxiosResponse, isAxiosError} from "axios";
import {Import} from "./types";
import {apiV2} from "../../../api/axiosConfig";
import {ImportRow} from "./useImportParser";
import {useChunkedArray} from "./useChunkedArray";
import pLimit from "p-limit";
import {useIncrement} from "./useIncrement";
import {useNavigate} from "react-router-dom";

export const useSubmitImport = (sendInvitationEmails: boolean, name: string) => {

    const [chunkSize] = useState(200)
    const {headers, rows, clientID, gotoStage, clear, matchOnEmail, archiveMissing} = useImportContext();

    const rowChunks = useChunkedArray(rows, chunkSize)

    const [submitting, setSubmitting] = useState<boolean>(false);

    const [error, setError] = useState<string | null>(null)

    const [progress, incrementProgress, resetProgress] = useIncrement()

    const rowCount = useMemo(() => {
        return rows.length;
    }, [rows])

    const chunkCount = useMemo(() => {
        return Math.ceil(rowCount / chunkSize)
    }, [rowCount, chunkSize])

    const importBaseRequest = useMemo(() => {

        const flags : string[] = []

        if(matchOnEmail) {
            flags.push("match_on_email")
        }

        if(sendInvitationEmails) {
            flags.push("send_invitation_emails")
        }

        if(archiveMissing) {
            flags.push("archive_missing")
        }

        return {
            clientID,
            headers,
            rowCount,
            chunkCount,
            flags,
            name
        }
    }, [clientID, rowCount, chunkCount, headers, sendInvitationEmails, matchOnEmail, name])


    const submitRow = useCallback(async (uploadID: string, rows: ImportRow[], index: number) => {
        await apiV2.post(`import/${uploadID}`, {
            index: index + 1,
            data: rows,
        })

        incrementProgress(rows.length)
    }, [])

    const handleSubmitSuccess = useCallback(async (res: AxiosResponse<Import>) => {
        for (const [index, chunk] of rowChunks.entries()) {
            await submitRow(res.data.id, chunk, index)
        }
        clear()
        gotoStage("select_file")


    }, [rowChunks, clear])

    const handleSubmitError = useCallback((error: Error) => {
        if (isAxiosError(error)) {
            if (error?.response?.data?.error) {
                setError(error?.response?.data?.error)
                return
            }
        }
        setError("an error occurred")
    }, [])

    const submit = useCallback(() => {
        setSubmitting(true)
        resetProgress()

        apiV2.post<Import>("import", importBaseRequest)
            .then(handleSubmitSuccess)
            .catch(handleSubmitError)
            .finally(() => setSubmitting(false))

    }, [importBaseRequest, handleSubmitSuccess, handleSubmitError])

    return {error, submit, submitting, progress}

}