// Import necessary libraries
import React, {createContext, useContext, useState, ReactNode, useEffect, useCallback} from "react";
import {useQuery} from "react-query";
import {apiV2} from "../../../api/axiosConfig";
import {Client} from "../../../types/userManagement";
import {PaginatedResponse} from "../../../types/PaginatedResponse";
import {useSearchParams} from "react-router-dom";
import {useDebounce} from "use-debounce";

// Define the shape of the context value
interface ClientsManagementContextType {
    clients?: PaginatedResponse<Client>;
    isLoading: boolean;
    error: any;
    selected: Set<string>;
    addSelected: (id: string) => void;
    removeSelected: (id: string) => void;
    selectAll: () => void;
    deselectAll: () => void;
    page: number;
    setPage: (page: number) => void;
    limit: number;
    refetch: () => void;
    actionError: string;
    setActionError: (error: string) => void;
    archived: boolean;
    setArchived: (archived: boolean) => void;
    search: string;
    setSearch: (search: string) => void;
}

// Create the context with a default value
const ClientsManagementContext = createContext<ClientsManagementContextType | undefined>(undefined);

// Fetch clients based on clientID
const fetchClients = async (page: number, limit: number, archived: boolean, search: string): Promise<PaginatedResponse<Client> | undefined> => {

    let url = `clients?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`
    if (archived) {
        url = `${url}&archived=true&search=${encodeURIComponent(search)}`;
    }
    const response = await apiV2.get(url);
    return response.data
};

// Create a provider component
interface ClientsManagementProviderProps {
    children: ReactNode;
}

export const ClientsManagementProvider: React.FC<ClientsManagementProviderProps> = ({children}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selected, setSelected] = useState(new Set<string>)
    const [page, setPage] = useState<number>(Number(searchParams.get("page")) || 1);
    const [limit, setLimit] = useState<number>(10);
    const [archived, setArchived] = useState(false)
    const [search, setSearch] = useState("")
    const [debouncedSearch] = useDebounce(search, 300)

    useEffect(() => {
        setPage(1)
    }, [debouncedSearch]);


    const [actionError, setActionError] = useState("");

    useEffect(() => {
        if (actionError != "") {
            setTimeout(() => {
                setActionError("");
            }, 10 * 1000)
        }


    }, [actionError]);


    const addSelected = (id: string) => {
        const newSet = new Set(selected);
        newSet.add(id);
        setSelected(newSet);
    }

    const removeSelected = (id: string) => {
        const newSet = new Set(selected);
        newSet.delete(id);
        setSelected(newSet);

    }

    const selectAll = () => {
        if (clients) {
            const newSet = new Set(selected);
            clients.items.forEach(d => d.id ? newSet.add(d.id) : null)
            setSelected(newSet)
        }
    }
    const deselectAll = () => {
        setSelected(new Set())
    }


    const {data: clients, isLoading, error, refetch} = useQuery(
        ["clients", archived, page, limit, debouncedSearch],
        () => fetchClients(page, limit, archived, debouncedSearch),
    );

    useEffect(() => {

        const newParams = new URLSearchParams(searchParams.toString());

        newParams.set("page", `${page}`); // Update or add the parameter
        newParams.set("search", `${debouncedSearch}`); // Update or add the parameter
        setSearchParams(newParams); // Update the URL

    }, [page, debouncedSearch]);

    const initialClientID = searchParams.get("clientID") ?? undefined;

    return (
        <ClientsManagementContext.Provider value={{
            clients,
            isLoading,
            error,
            addSelected,
            removeSelected,
            selectAll,
            deselectAll,
            selected,
            page,
            limit,
            setPage,
            refetch,
            actionError,
            setActionError,
            archived,
            setArchived,
            search,
            setSearch
        }}>
            {children}
        </ClientsManagementContext.Provider>
    );
};

// Create a custom hook for consuming the context
export const useClientsManagement = (): ClientsManagementContextType => {
    const context = useContext(ClientsManagementContext);
    if (!context) {
        throw new Error("useClientsManagement must be used within a ClientsManagementProvider");
    }
    return context;
};
