import React, {useCallback, useEffect, useMemo, useState} from "react";
import Layout from "../layout/Layout";
import {ClientSelectSingle} from "../../../components/form-v2/searchable-select/ClientSelect";
import {Action, ActionSelect} from "../../../components/ui/ActionSelect";
import PaginationNoneFloating from "../../../components/ui/Content/PaginationNoneFloating";
import {Link, useSearchParams} from "react-router-dom";
import {BlueButton} from "../../../components/ui/Buttons";
import BasicText from "../../../components/form-v2/basic-text";
import {useAuth} from "../../../context/user";
import {useInvitationsManagement, UserActivationToken} from "./InvitationsManagementContext";
import {useTimeZoneSelectContext} from "../../../providers/TimeZoneSelectProvider";
import moment from "moment";
import {apiV2} from "../../../api/axiosConfig";
import {useQuery} from "react-query";
import {UserDataTypes} from "../../../types/user-data-types";
import Modal from "../../../components/portal/Modal";

const InvitationFilterArchiveStatus: React.FC = () => {

    const ctx = useInvitationsManagement()
    const auth = useAuth();

    const count = useCallback((status: string) => {
        if(status == ctx.statusFilter && ctx.invitations) return `(${ctx.invitations.totalResults})`
        return ""
    }, [ctx.statusFilter, ctx.invitations?.totalResults])

    return <div className="bg-white shadow-md p-4 flex flex-row gap-8 items-center">


        <a className={`cursor-pointer ${ctx.statusFilter == "" ? "opacity-50" : ""}`} onClick={() => {
            ctx.setPage(1);
            ctx.setStatusFilter("")
        }}>All{count("")}</a>

        <a className={`cursor-pointer ${ctx.statusFilter == "expired" ? "opacity-50" : ""}`} onClick={() => {
            ctx.setPage(1);
            ctx.setStatusFilter("expired")
        }}>Expired{count("expired")}</a>


        <a className={`cursor-pointer ${ctx.statusFilter == "not_sent" ? "opacity-50" : ""}`} onClick={() => {
            ctx.setPage(1);
            ctx.setStatusFilter("not_sent")
        }}>Not Sent{count("not_sent")}</a>


        <a className={`cursor-pointer ${ctx.statusFilter == "sent" ? "opacity-50" : ""}`} onClick={() => {
            ctx.setPage(1);
            ctx.setStatusFilter("sent")
        }}>Sent{count("sent")}</a>

        <a className={`cursor-pointer ${ctx.statusFilter == "complete" ? "opacity-50" : ""}`} onClick={() => {
            ctx.setPage(1);
            ctx.setStatusFilter("completed")
        }}>Completed{count("completed")}</a>

        <a className={`cursor-pointer ${ctx.statusFilter == "complete" ? "opacity-50" : ""}`} onClick={() => {
            ctx.setPage(1);
            ctx.setStatusFilter("cancelled")
        }}>Cancelled{count("cancelled")}</a>


        <div className={"flex-1"}/>
        <div>

        </div>
    </div>
}

const InvitationFilterSearch: React.FC = () => {

    const ctx = useInvitationsManagement()
    const auth = useAuth()


    return <div className="bg-white shadow-md p-4 flex flex-row gap-8 items-center">
    {auth.isSuperAdmin && <div className="w-64">
            <ClientSelectSingle initialClientID={ctx.initialClientID} client={ctx.client} onChange={(c) => {
                ctx.setClient(c)
            }}/>
        </div>
        }

        <BasicText value={ctx.search} onChange={ctx.setSearch} placeholder={"Search"}/>

    </div>
}


const InvitationTable: React.FC = () => {

    const ctx = useInvitationsManagement()

    return <div className="bg-white shadow-md p-4">
        <InvitationTableHeader/>
        <hr className={"my-4"}/>

        <div className={"flex flex-col gap-4"}>
            {ctx.invitations?.results && ctx.invitations.results.map((d) => (
                <InvitationRow invitation={d} key={d.id}/>
            ))}
            <div className={"flex justify-center"}>
                <PaginationNoneFloating
                    currentPage={ctx.page}
                    itemsPerPage={ctx.limit}
                    dataLength={ctx.invitations?.totalResults || 0}
                    togglePageSelect={(page) => ctx.setPage(page)}
                />
            </div>


        </div>


    </div>
}


type InvitationRowProps = {
    invitation: UserActivationToken
}


const fetchUser = async (userID: string) => {
    const response = await apiV2.get<UserDataTypes>(`users/${userID}`);
    return {
        name: `${response.data.profile.firstName} ${response.data.profile.lastName}`,
        email: response.data.email,
    }
}

const InvitationRow: React.FC<InvitationRowProps> = (props) => {

    const ctx = useInvitationsManagement()
    const tzContext = useTimeZoneSelectContext()

    const [showDelete, setShowDelete] = useState(false)
    const [showResend, setShowResend] = useState(false)

    const expiresAt = useMemo(() => {
        return moment.tz(props.invitation.expiresAt, tzContext.timeZone)
    }, [props.invitation.expiresAt])

    const expired = useMemo(() => {
        const now = moment()

        return expiresAt.isBefore(now)


    }, [expiresAt])

    const userName = useMemo(() => {
        return props.invitation.userName
    }, [props.invitation])

    const userEmail = useMemo(() => {
        return props.invitation.userEmail
    }, [props.invitation])



    return <>

        <Modal open={showDelete} setOpen={setShowDelete}>

                <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                    <p className={"font-semibold"}>Cancel Invitation</p>
                    <p>Please click to confirm cancellation of invitation sent to {props.invitation.userName}</p>
                    <BlueButton text={"Cancel Invitation"} onClick={() => {
                        ctx.deleteInvitation(props.invitation.id)
                        setShowDelete(false)
                    }}/>
                </div>

        </Modal>

        <Modal open={showResend} setOpen={setShowResend}>

            <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                {props.invitation.status == "not_sent" ?
                    <>
                        <p className={"font-semibold"}>Send Invitation</p>
                        <p>Please click to confirm sending invitation to {userName} ({userEmail})</p>
                    </> :
                    <>
                        <p className={"font-semibold"}>Resend Invitation</p>
                        <p>Please click to confirm resending invitation to {userName} ({userEmail})</p>
                    </>
                }

                <BlueButton text={props.invitation.status == "not_sent" ? "Send Invitation" : "Resend Invitation"} onClick={() => {
                    ctx.resendInvitation(props.invitation.id)
                    setShowResend(false)
                }}/>
            </div>

        </Modal>

        <div className="grid grid-cols-[5%_35%_36%_24%] h-10">
            <div className={"flex justify-center"}>



            </div>
            <div className={"flex items-center"}>
                <Link to={`/management/users/edit/${props.invitation.userID}`}>
                    {userName}
                </Link>
            </div>
            {props.invitation.status == "completed" && <div
                className={"flex items-center text-green"}>Invitation Complete</div>}
            {props.invitation.status == "sent" && !expired && <div
                className={"flex items-center text-dark-orange"}>Expires At {moment.tz(props.invitation.expiresAt, tzContext.timeZone).format("DD/MM/YYYY HH:mm Z z")}</div>
            }

            {props.invitation.status == "sent" && expired && <div
                className={"flex items-center text-red"}>Expired</div>
            }


            {props.invitation.status == "not_sent" && <div
                className={"flex items-center text-grey"}>Not Sent</div>
            }

            {props.invitation.status == "cancelled" && <div
                className={"flex items-center text-grey"}>Cancelled</div>
            }


            <div className={""}>

                <ActionSelect label={"Actions"}>
                    {!["completed", "cancelled", "sent"].includes(props.invitation.status)  &&
                        <Action label={"Cancel"} onSelect={() => {
                            setShowDelete(true)
                        }}/>
                    }

                      {props.invitation.status == "not_sent" &&
                          <>
                            <Action label={"Send"} onSelect={() => {setShowResend(true)}}/>
                          </>
                      }

                    {props.invitation.status == "sent" &&
                        <>
                            <Action label={"Resend"} onSelect={() => {setShowResend(true)}}/>
                            <Action label={"Cancel"} onSelect={() => {setShowDelete(true)}}/>
                        </>
                    }

                    {props.invitation.status == "cancelled" &&
                        <>
                            <Action label={"Resend"} onSelect={() => {setShowResend(true)}}/>
                        </>
                    }





                </ActionSelect>

            </div>
        </div>
    </>
}

const InvitationTableHeader: React.FC = () => {
    const ctx = useInvitationsManagement()


    return <div className="grid grid-cols-[5%_35%_36%_24%]">
        <div className={"flex justify-center"}>

        </div>
        <div>Name</div>
        <div>Status</div>
        <div>Actions</div>
    </div>
}



const InvitationList: React.FC = () => {
    const ctx = useInvitationsManagement()

    const [searchParams, setSearchParams] = useSearchParams()

    const [message, setMessage] = useState(searchParams.get("message"));

    useEffect(() => {
        if (message) {
            setTimeout(() => {
                setMessage(null)
                const newParams = new URLSearchParams(searchParams.toString());
                newParams.delete("message")
                setSearchParams(newParams);
            }, 5000)
        }
    }, [message]);


    return <Layout
        active={"invitations"}
    >
        <div className="flex flex-col gap-8">
            {ctx.actionError &&
                <div className="text-white bg-red rounded-md shadow-md p-4 flex flex-row gap-8 items-center">
                    {ctx.actionError}
                </div>}

            {message && <div
                className="text-white bg-green shadow-md p-4 flex flex-row gap-8 items-center justify-center rounded-md">
                {message}
            </div>}

            <InvitationFilterArchiveStatus/>
            <InvitationFilterSearch/>
            <InvitationTable/>
        </div>


    </Layout>

}

InvitationList.displayName = "InvitationList";

export default InvitationList;