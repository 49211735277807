import React, { useEffect } from "react";
import { Sidebar, Main } from "../../features/operational-map";
import FilterMapContextProvider from "../../features/operational-map/context/filter-map";
import {OperationalMapProvider} from "../../providers/OperationalMapProvider";
import {OperationalMapV2} from "../../features/operational-map-v2/map";
import {APIProvider} from "@vis.gl/react-google-maps";

const googleMapsApiKey: string = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;


function OperationalMap() {
  const userLoggedIn = JSON.parse(localStorage.getItem("user") as string);
  const handleResetFilters = () => {
    const defaultFilters = {
      clientID: userLoggedIn?.metadata?.clientID,
      userTypes: ["app", "trip", "facility", "satellite"],
      siteTypes: [],
      trackingUserID: null,
      incidents: true,
    };
    localStorage.setItem(
      "operational-map-filter-config",
      JSON.stringify(defaultFilters)
    );
  };



  useEffect(() => {
    const isOperationalMapFiltersSet = JSON.parse(
      localStorage.getItem("operational-map-filter-config") as string
    );
    if (isOperationalMapFiltersSet) return;
    handleResetFilters();
  }, []);
  return (
      <APIProvider apiKey={googleMapsApiKey}>

        <OperationalMapProvider>
        <div className="h-screen pt-28 flex">
          <Sidebar sidebarType="non-emergency" key={"non-emergency"} />
            <OperationalMapV2/>
          {/* <Main />*/ }
          <Sidebar sidebarType="emergency" key={"emergency"} />
        </div>
      </OperationalMapProvider>
      </APIProvider>
  );
}

export default OperationalMap;
