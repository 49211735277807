import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Layout from "../layout/Layout";

import api, {apiV2} from "../../../api/axiosConfig";
import BasicText from "../../../components/form-v2/basic-text";
import {BlueButton} from "../../../components/ui/Buttons";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {isAxiosError} from "axios";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import {AlternativeIDs, Client} from "../../../types/userManagement";
import {MultiInputMultiField} from "../../../components/form-v2/multi-input/MultiInputMultiField";


const ClientEdit: React.FC = () => {


    const {id} = useParams();


    const [loading, setLoading] = useState(true);

    const [name, setName] = useState("");
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("")
    const [alternativeIDs, setAlternativeIDs] = useState<AlternativeIDs>([]);


    const navigate = useNavigate();

    const loadClient = async() => {
        try {
            const res = await apiV2.get<Client>(`clients/${id}`);

            setName(res.data?.name);
            setAlternativeIDs(res.data.alternativeIDs ?? [])

        } catch (e){
            setError("error loading client")
        }


        setLoading(false);
    }

    useEffect(() => {
        if(!id){
            return
        }

        loadClient()
    }, [id]);


    const save = useCallback(async () => {
        setSaving(true);

        try {
            await apiV2.patch(`clients/${id}`, {
                name,
                alternativeIDs,
            })

        } catch (e) {
            if (isAxiosError(e) && e?.response?.data?.error) {
                setError(e.response.data.error);
            } else {
                setError("An error has occurred")
            }
            setSaving(false);
            return


        }

        setSaving(false);

        const params = new URLSearchParams();
        params.set("message", "client has been updated")
        navigate(`/management/clients?${params.toString()}`);
        return
    }, [name, alternativeIDs])

    return <Layout
        active={"clients"}
    >

        <div className="flex flex-row gap-8">
            {loading && <div className="bg-white shadow-md p-4 w-1/2 flex flex-col gap-8 items-center">
                <LoadingSpinner/>
            </div>}

            {!loading && id && <ClientImageForm id={id}/>}


            {!loading && <div className="bg-white shadow-md p-4 w-2/3 flex flex-col gap-8">
                <p className="text-md text-left pb-2 font-semibold">Edit Client</p>
                <BasicText value={name} onChange={setName} label="Name"/>

                    <MultiInputMultiField label={"External IDs"}
                                          initialItems={alternativeIDs}
                                          columns={3}
                                          unboldLabel
                                          onChange={setAlternativeIDs}
                                          placeholders={{
                                              type: "Type",
                                              value: "Value",
                                          }}
                                          required={[
                                              "type",
                                              "value"
                                          ]}
                    />


                <BlueButton text={"Update Client"} disabled={name == ""} onClick={save} loading={saving}/>
                {error && <p className={"text-center text-red"}>{error}</p>}
            </div>}
        </div>

    </Layout>

}


type ClientImageFormProps = {
    id: string
}

const ClientImageForm: React.FC<ClientImageFormProps> = (props)=> {



    const [saving, setSaving] = useState(false);
    const [preview, setPreview] = useState<string | null>(null);
    const [imageBlob, setImageBlob] = useState<Blob | null>(null);
    const [noImage, setNoImage] = useState(false)
    const [loading, setLoading] = useState(false)

    const inputRef = useRef<HTMLInputElement|null>(null)

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    };


    const fetchImage = async () => {
        setLoading(true)
        return await fetch(
            `${process.env.REACT_APP_API_URL}files/platform-logo/${props.id}`
        )
            .then(async (response) => {
                if(!response.ok){
                    setLoading(false);
                    return
                }

                const blob = await response.blob();
                setImageBlob(blob);
                setLoading(false)

            })
            .catch((err) => {
                setLoading(false)

            });

    };

    useEffect(() => {
        fetchImage();
    }, []);



    const save = useCallback(async () => {
        if(!inputRef?.current?.files){
            return
        }

        const file = inputRef?.current?.files[0]


        const formData = new FormData();
        formData.append("file", file);

        api
            .post(`/files/platform-logo/${props.id}`, formData, {
                headers: { "content-type": "multipart/form-data" },
            })
            .then((res) => {
                console.log("image uploaded");
                if(inputRef.current){
                    inputRef.current.value = "";
                    setPreview(null)
                }
                fetchImage();
            })
            .catch((err) => {
                console.error(err);
            });
    }, [])


    return <div className="bg-white shadow-md p-4 w-1/3 flex flex-col gap-8">
        <p className="text-md text-left pb-2 font-semibold">Upload Image</p>

        <div>
            <p>Current Image:</p>
            {!imageBlob  &&  !loading && <p>No image available</p>}
            {imageBlob &&  !loading && (
                <img
                    src={URL.createObjectURL(imageBlob)}
                    alt="Fetched Image"
                    className="rounded-full"
                />
            )}
        </div>

        <div>
            <p>Preview:</p>
            {preview ? <img src={preview} alt="Selected"/>
                : <p>No image selected</p>
            }
        </div>

        <input ref={inputRef} type="file" accept="image/*" onChange={handleImageChange} className={"hidden"}/>

        <BlueButton text={"Select Image"} onClick={() => {
            if (inputRef.current) {
                inputRef.current?.click()
            }
        }} loading={saving}/>
        <BlueButton text={"Update Image"} disabled={!preview} onClick={save} loading={saving}/>
    </div>

}


ClientEdit.displayName = "ClientEdit";

export default ClientEdit;