import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ClientSelectSingle} from "../../../components/form-v2/searchable-select/ClientSelect";
import {useUserManagementBrowsing} from "../../../lib/user-management/UserManagementBrowsingContext";
import {Client} from "../../../types/userManagement";
import {BlueButton} from "../../../components/ui/Buttons";
import {useImportContext} from "./ImportContextProvider";
import {Check, FileUploadRounded, SvgIconComponent} from "@mui/icons-material";
import {usePrettyFileSize} from "../../../lib/files/usePrettyFileSize";
import {noop} from "../../../lib/misc/noop";
import {FileSelect, IconButton, ToggleButton} from "./inputs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {Panel} from "./Panel";
import {useLoadImports} from "./useLoadImports";
import {Import} from "./types";
import {useTimeZoneSelectContext} from "../../../providers/TimeZoneSelectProvider";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import {useDownloadXLSX} from "./useDownloadXLSX";
import {useDownloadSummary} from "./useDownloadSummary";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import BasicText from "../../../components/form-v2/basic-text";
import {apiV2} from "../../../api/axiosConfig";
import { useQuery } from "react-query";


const useClient = () => {
    const [client, setClient] = useState<Client>()
    const {clientID: initialClientID, setClientID} = useUserManagementBrowsing()
    useEffect(() => {
        setClientID(client?.id || "")
    }, [client])

    return {initialClientID, client, setClient}
}


export const ImportSelectFilePage: React.FC = () => {

    const {initialClientID, client, setClient} = useClient();
    const {
        selectedFile,
        gotoStage,
        selectedFileRows,
        submitForVerification,
        currentStage,
        setMatchOnEmail,
        invalidFile,
        name,
        setName,
        setArchiveMissing,
    } = useImportContext()

    const gotoVerifyStage = useCallback(() => {
        submitForVerification()
        gotoStage("verify")

    }, [submitForVerification])

    const {generateAndSave: downloadSample} = useDownloadXLSX(
        "import-sample.xlsx",
        [
            "ID",
            "First name*",
            "Middle names",
            "Last Name*",
            "Email*",
            "Phone Number",
            "Gender",
            "Gender At Birth",
            "Date Of Birth",
            "Country",
            "Secondary Phone Number.1",
            "Secondary Email.1",
            "Meta.Employee ID",
            "Role",
            "Department",
            "Home Facility",
            "Stationed Facility",
            "Archive",
        ],
        [[
            "",
            "Trav",
            "",
            "Eller",
            "traveller@ngs.com",
            "+447735928372",
            "Male",
            "",
            "3/25/1990",
            "United Kingdom",
            "",
            "",
            "",
            "Traveller",
            "Ministry of Travel",
            "Heathrow",
            "Palma de Mallorca",
            "No",
        ]]
    )

    const {loading, imports} = useLoadImports(client?.id, currentStage == "select_file")

    return (
        <div className={"flex flex-col gap-8"}>
            <Panel>
                <h2 className="text-lg font-semibold">Select Client</h2>

                <div className="w-72 flex flex-col gap-8">
                    <ClientSelectSingle initialClientID={initialClientID} client={client} onChange={setClient}/>

                </div>


            </Panel>


            {client && (
                <>
                    <Panel>
                        <h2 className="text-lg font-semibold">Import CSV or XLSX File</h2>

                        <div className="w-72 flex flex-col gap-8">
                            <FileSelect/>

                            {selectedFile && !invalidFile && (
                                <>
                                    <ToggleButton text={"Match Users On Email"} initialValue={false} onChange={setMatchOnEmail}/>
                                    <ToggleButton text={"Archive Missing"} initialValue={false} onChange={setArchiveMissing}/>
                                    <BasicText value={name} onChange={setName} placeholder={"Import Name"}/>
                                    <IconButton onClick={gotoVerifyStage}
                                                color="green"
                                                disabled={name.length == 0}
                                                text={`Verify File (${selectedFileRows} rows)`}
                                                icon={<NavigateNextIcon/>}
                                    />
                                </>
                            )}

                            <IconButton onClick={downloadSample} text={"Download Sample"} icon={<DownloadIcon/>}/>

                        </div>

                    </Panel>

                    <Panel>
                        <h2 className="text-lg font-semibold">Previous Imports</h2>

                        <div className="w-72 flex flex-col gap-8">
                            {!loading && imports && imports.length == 0 && <p className={"text-center"}>No previous imports</p>}


                        </div>

                        {!loading && imports && imports.map((imp) => (
                            <ImportRow data={imp} key={imp.id}/>
                        ))}

                    </Panel>
                </>
            )}

        </div>


    )

}

const ImportRow: React.FC<{ data: Import }> = ({data}) => {

    const tzCtx = useTimeZoneSelectContext()

    const createdAt = useMemo(() => {
        return moment.tz(data.createdAt, tzCtx.timeZone).format("DD MMMM YYYY HH:mm")
    }, [data])

    const {download, downloading} = useDownloadSummary(data)


    const getProgress = useCallback(async () => {
        if(data.status != "processing") return ""

        if(data.rowCount == 0) return ""


        const res = await apiV2.get<{progress: number}>(`import/${data.id}/progress`)

        const percentage = (res.data.progress / data.rowCount) * 100
        return percentage.toFixed(1);


    }, [data.id, data.rowCount])

    const { data: progress } = useQuery(
        ["import-progress", data.id], // Query key
        getProgress, // Fetch function
        {
            refetchInterval: 5000, // Refetch every 5 seconds
        }
    );


    return (
        <div className={"grid grid-cols-5 w-full"} data-import-id={data.id}>
            <div className={"flex flex-col justify-center items-center gap-2"}>
                <span className={"font-semibold"}>
                    Name
                </span>
                <span>
                    {data.name || "N/A"}
                </span>
            </div>
            <div className={"flex flex-col justify-center items-center gap-2"}>
                <span className={"font-semibold"}>
                    Created On
                </span>
                <span>
                    {createdAt}
                </span>
            </div>
            <div className={"capitalize flex flex-col justify-center items-center gap-2"}>
                <span className={"font-semibold"}>
                    Status
                </span>
                <span>
                    {data.status} {progress && data.status == "processing" ? `${progress}%` : ""}
                </span>
            </div>
            <div className={"flex flex-row items-center"}>

                <div
                    className={"bg-darker-red text-white min-w-16 text-center rounded-l-md flex justify-center items-center  h-10"}>
                    {data.failCount}

                </div>

                <div
                    className={"bg-dark-orange text-white min-w-16 text-center flex justify-center items-center  h-10"}>
                    {data.warnCount}

                </div>
                <div
                    className={"bg-green text-white min-w-16 text-center rounded-r-md flex justify-center items-center h-10"}>
                    {data.successCount}
                </div>

            </div>
            <div className={"flex items-center"}>
                <IconButton
                    icon={!downloading ? <DownloadIcon/> : <LoadingSpinner color="#fff" width={20} height={20}/>}
                    text={"Download Summary"}
                    onClick={download}
                />
            </div>

        </div>
    )
}
