import {useCallback, useState} from "react";
import {apiV2} from "../../../api/axiosConfig";
import {PaginatedResponse} from "../../../types/PaginatedResponse";
import {UserDataTypes} from "../../../types/user-data-types";
import moment from "moment";
import {countries} from "../../../lib/countries";
import {generateAndSaveXLSX} from "../import/useDownloadXLSX";
import {useAuth} from "../../../context/user";
import {useTimeZoneSelectContext} from "../../../providers/TimeZoneSelectProvider";


export const fetchUsers = async (
    clientID: string,
    page: number,
    limit: number,
    archived: boolean,
    departmentIDs: string[],
    search: string
): Promise<PaginatedResponse<UserDataTypes> | undefined> => {
    let url = `users?clientID=${clientID}&page=${page}&limit=${limit}`
    if (archived) {
        url = `${url}&archived=true`;
    }

    if(departmentIDs) {
        url = `${url}&departmentID=${departmentIDs.join(",")}`;
    }

    if(search) {
        url = `${url}&search=${encodeURIComponent(search)}`;
    }

    const response = await apiV2.get(url);
    return response.data
};



export const useUserExport = (clientID: string, departmentIDs: string[], search: string) => {

    const auth = useAuth();

    const tzCtx = useTimeZoneSelectContext()

    const [downloading, setDownloading] = useState(false);
    const download = useCallback(async () => {

        if(!clientID && auth.isSuperAdmin) return

        setDownloading(true)
        const users : UserDataTypes[] = []
        let found = false
        let page = 1;
        const rpp = 200

        try {
            do {
                const res = await fetchUsers(clientID, page, rpp, false, departmentIDs, search);
                if (res && res.items.length > 0) {
                    found = true;
                    page = page + 1

                    users.push(...res.items)
                } else {
                    found = false
                }

            } while (found)
        } catch(e){
            console.error(e);
        }
        const headers =  [
            "ID",
            "First name",
            "Middle names",
            "Last Name",
            "Email",
            "Phone Number",
            "Gender",
            "Gender At Birth",
            "Date Of Birth",
            "Country",
            "Timezone",
            "Role",
            "Department",
            "Home Facility",
            "Stationed Facility",
            "Last Known Location [Latitude]",
            "Last Known Location [Longitude]",
            "Last Login At",
            "Last App Session",
        ]

        const maxPhoneCount = users.reduce((max, item) =>
            Math.max(max, item.phoneNumbers.length ), 0);

        for(let i = 0; i < maxPhoneCount - 1; i++){
            headers.push(`Secondary Phone Number.${i+1}`);
        }

        const maxEmailCount = users.reduce((max, item) =>
            Math.max(max, item.additionalEmails.length), 0);

        for(let i = 0; i < maxEmailCount; i++){
            headers.push(`Secondary Email.${i+1}`);
        }

        const metadataFieldsSet = new Set<string>()

        users.forEach(user => {
            const labels = user.metadata.map(m => m.label)
            labels.forEach(label => metadataFieldsSet.add(label))
        })

        const metadataColumns = Array.from(metadataFieldsSet)

        metadataColumns.forEach(meta => {
            headers.push(`Meta.${meta}`);
        })



        const rows = users.map((u): string[] => {
            const secondaryPhones: string[] = []
            for(let i = 1; i < maxPhoneCount; i++){
                secondaryPhones.push(u.phoneNumbers[i] ? u.phoneNumbers[i].number : "");
            }

            const secondaryEmails: string[] = []
            for(let i = 0; i < maxEmailCount; i++){
                secondaryEmails.push(u.additionalEmails[i] ? u.additionalEmails[i] : "");
            }

            const metadata: string[] = []

            for(const c of metadataColumns){
                const field = u.metadata.find(m => m.label == c)
                metadata.push(field ? field.value : "")
            }

            const [lng, lat] = (() => {
               if(!u.lastKnownLocation){
                   return ["", ""]
               }

               return u.lastKnownLocation.position.coordinates
            })();

            const lastActivityAt = (() => {
                if(!u.pushNotificationTokenReceivedAt) return ""
                if(u.pushNotificationTokenReceivedAt == "0001-01-01T00:00:00Z") return ""

                return moment.tz(u.pushNotificationTokenReceivedAt).tz(tzCtx.timeZone).format("DD/MM/YYYY HH:mm:ss z")
            })()

            const lastLoginAt = (() => {
                if(!u.status) return ""
                if(!u.status.lastLoginAt) return ""
                return moment(u.status.lastLoginAt).tz(tzCtx.timeZone).format("DD/MM/YYYY HH:mm:ss z")
            })()

            return [
                u.id,
                u.profile.firstName,
                u.profile.middleNames ?? "",
                u.profile.lastName,
                u.email,
                u.phoneNumbers[0] ? u.phoneNumbers[0].number : "",
                u.profile.gender ?? "",
                u.profile.genderAssignedAtBirth ?? "",
                u.profile.dateOfBirth ? moment.tz(u.profile.dateOfBirth, "UTC").format("DD/MM/YYYY") : "",
                countries.find(c => c.countryCode == u.profile.countryOfResidence)?.countryNameEn ?? "",
                u.profile.timezone,
                u.roleName,
                u.departmentName,
                u.homeFacilityName ?? "",
                u.stationedFacilityName ?? "",
                `${lat || ""}`,
                `${lng || ""}`,
                lastLoginAt,
                lastActivityAt,

                ...secondaryPhones,
                ...secondaryEmails,
                ...metadata,
            ]

        })

        generateAndSaveXLSX("user-export.xlsx", headers, rows)

        setDownloading(false)

    }, [clientID, departmentIDs, search]);

    return {download, downloading}

}