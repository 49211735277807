import React from "react";
import SearchableSelect, {SearchableSelectOption} from "./index";
import Icon from "../../../assets/icons/exclamation-warning.svg";
import {languages} from "../../../constants/languages";
import {nationalities} from "../../../constants/nationalities";


type NationalitySelectProps = {
  value: string | undefined;
  setValue: (value: string | undefined) => void;
}

export const NationalitySelect: React.FC<NationalitySelectProps> = (props) => {

  const componentOptions = nationalities.map((l) => {
    return {
      value: l.nationality,
      key: l.alpha_2_code,
      label: l.nationality,
      search: l.nationality,

    }
  })

  const selected: SearchableSelectOption<string> | undefined  = componentOptions.find(l => l.value === props.value)

  function handleChange(values: SearchableSelectOption<string>[]) {

    if (values.length == 0) {
      props.setValue(undefined)
      return
    }

    props.setValue(values[0].value)
  }

  return <SearchableSelect
      label={"Nationality"}
      multiple={false} options={componentOptions} selected={selected ? [selected] : []} search={true}
      localSearch={true}
      onChange={handleChange}/>
}
