import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { kebabCaseToGeneralCase } from "../../util/helpers";

import SidebarLinks from "../../components/ui/SidebarLinks";
import { ContentWrapperWithHeader } from "../../components/ui/Content";

import navLinks from "./nav-links.json";
import customReportsLinks from "./custom-reports-links.json";
import Breadcrumbs from "../../components/ui/Content/Breadcrumbs";
import ReportsSummaryProvider from "./pages/Summary/context/ReportsSummaryProvider";
import Sidebar from "./pages/Summary/components/SummaryPage";

function Reports() {
  return (
    <ReportsSummaryProvider>
      <div className="pt-32 pb-8 px-8 flex h-screen">
        <Sidebar />
        <main className="flex-1 h-full">
          <Outlet /> 
        </main>
      </div>
    </ReportsSummaryProvider>
  );
}

export default Reports;
