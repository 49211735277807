import {useQuery} from "react-query";
import {useCallback, useEffect} from "react";
import {apiV2} from "../../../api/axiosConfig";
import {Import} from "./types";

export const useLoadImports = (clientID: string | undefined, run = true) => {

    const fetchImports = useCallback(async () => {
        const res = await apiV2.get<Import[]>(`import?clientID=${clientID}`)
        return res.data
    }, [clientID])

    useEffect(() => {
        console.log({run})
    },[run])

    const query = useQuery(
        ["imports", clientID],
        fetchImports,
        {
            enabled: !!clientID && run,
            refetchInterval: 1000 * 5
        }

    )

    return {
        loading: query.isLoading,
        imports: query.data,

    }



}