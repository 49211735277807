import React, {useEffect, useMemo, useState} from "react"
import SearchableSelect, { SearchableSelectOption } from "."
import api, {apiV2} from "../../../api/axiosConfig"
import { PaginatedResponse } from "../../../types/PaginatedResponse"
import {Client, Department} from "../../../types/userManagement"

import PeopleIcon from "../../../assets/icons/icon-person.svg";
import DepartmentIcon from "../../../assets/icons/department-icon.svg";
import ClientSelect from "./ClientSelect";
import {isNilObjectID} from "../../../lib/objectid/objectid";
type DepartmentSelectParams = {
  departments: Department[]
  clientID?: string | null;
  multiple?: boolean;
  onSelectionChange?: (departments: Department[]) => void;
  disabled?: boolean;
  allowSelectAll?: boolean;
  noClientOnChange?: boolean;
}

function DepartmentSelect({
  clientID,
  multiple,
  onSelectionChange,
  departments = [],
  disabled,
  allowSelectAll = false,
  noClientOnChange,
}: DepartmentSelectParams){

  const [loading, setLoading] = useState(false)
  const [options, setOptions]  = useState<SearchableSelectOption<Department>[]>([])
  const [searchStr, setSearchStr] = useState("")

  const fetchDepartments = async () => {
    setLoading(true)
    try{
      const res = await apiV2.get<PaginatedResponse<Department>>("/departments", { params: { clientID, search: searchStr, rpp: 200 } })
      setOptions(res.data.items.map((d) => ({
        label: d.name,
        value: d,
        key: d.id
      })))

    } catch(e){
      console.error(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchDepartments()
  }, [clientID, searchStr])

  useEffect(() => {
    if (!noClientOnChange) {
      onChange([])
    }
  }, [clientID, noClientOnChange])

  const onChange = (selected: SearchableSelectOption<Department>[]) => {
    if(onSelectionChange){
      onSelectionChange(selected.map(s => s.value))
    }
  }

  const onSearch = (value: string) => {
    setSearchStr(value);
  }

  return (<SearchableSelect
    icon={DepartmentIcon}
    label={multiple ? "Departments" : "Department"}
    selected={departments.map(d => ({
      label: d.name,
      value: d,
      key: d.id
    }))}
    search={true}
    multiple={multiple ? true : false}
    options={options}
    localSearch={false}
    onChange={onChange}
    onSearch={onSearch}
    loading={loading}
    disabled={disabled}
    enableSelectAll={allowSelectAll}    
  />)
}

export default DepartmentSelect

type DepartmentSelectSingleProps = {
  department?: Department,
  onChange?: (selected: Department|undefined) => void,
  disabled?: boolean,
  initialDepartmentID?: string
  clientID?: string
}

export const DepartmentSelectSingle: React.FC<DepartmentSelectSingleProps> = (props) =>
{

  const loadInitialDepartment = async () => {
    if(isNilObjectID(props.initialDepartmentID)) return;
    const res = await api.get<Department>(`departments/${props.initialDepartmentID}`)
    if(props.onChange){
      props.onChange(res.data)
    }


  }

  useEffect(() => {
    loadInitialDepartment()
  }, []);


  const onChange = (departments: Department[]) => {
    if (!props.onChange) return;

    if(departments.length == 0){
      props.onChange(undefined)
      return
    }

    props.onChange(departments[0])
  }

  return <DepartmentSelect clientID={props.clientID} disabled={props.disabled} multiple={false} onSelectionChange={onChange} departments={props.department ? [props.department] : []}/>
}



type DepartmentSelect1WayParams = {
  initialDepartments: Department[]
  clientID?: string | null;
  multiple?: boolean;
  onSelectionChange?: (departments: Department[]) => void;
  disabled?: boolean;
  allowSelectAll?: boolean;
  noClientOnChange?: boolean;
}

export function DepartmentSelect1Way({
                            clientID,
                            multiple,
                            onSelectionChange,
                            initialDepartments = [],
                            disabled,
                            allowSelectAll = false,
                            noClientOnChange,
                          }: DepartmentSelect1WayParams){


  const [departments, setDepartments] = useState<Department[]>(initialDepartments)
  const [loading, setLoading] = useState(false)
  const [options, setOptions]  = useState<SearchableSelectOption<Department>[]>([])
  const [searchStr, setSearchStr] = useState("")

  const fetchDepartments = async () => {
    setLoading(true)
    try{
      const res = await apiV2.get<PaginatedResponse<Department>>("/departments", { params: { clientID, search: searchStr, rpp: 200 } })
      setOptions(res.data.items.map((d) => ({
        label: d.name,
        value: d,
        key: d.id
      })))

    } catch(e){
      console.error(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchDepartments()
  }, [clientID, searchStr])

  useEffect(() => {
    if (!noClientOnChange) {
      onChange([])
    }
  }, [clientID, noClientOnChange])

  const onChange = (selected: SearchableSelectOption<Department>[]) => {
    setDepartments(selected.map(s => s.value))
  }

  useEffect(() => {
    if(onSelectionChange){
      onSelectionChange(departments)
    }
  }, [departments])

  const selected = useMemo(() => {
    return departments.map(d => ({
      label: d.name,
      value: d,
      key: d.id
    }))
  }, [departments])

  const onSearch = (value: string) => {
    setSearchStr(value);
  }

  return (<SearchableSelect
      icon={DepartmentIcon}
      label={multiple ? "Departments" : "Department"}
      selected={selected}
      search={true}
      multiple={multiple ? true : false}
      options={options}
      localSearch={false}
      onChange={onChange}
      onSearch={onSearch}
      loading={loading}
      disabled={disabled}
      enableSelectAll={allowSelectAll}
  />)
}
