// Import necessary libraries
import React, {createContext, useContext, useState, ReactNode, useEffect, useCallback} from "react";
import {useQuery} from "react-query";
import {apiV2} from "../../../api/axiosConfig";
import {Client, Departments} from "../../../types/userManagement";
import {PaginatedResponse, PaginatedResponseV2} from "../../../types/PaginatedResponse";
import {useSearchParams} from "react-router-dom";
import {useDebounce} from "use-debounce";
import {useAuth} from "../../../context/user";
import {useUserManagementBrowsing} from "../../../lib/user-management/UserManagementBrowsingContext";

export type UserActivationToken = {
    id: string
    userID: string
    keyID: string
    createdAt: string
    expiresAt: string
    version: number
    status: "not_sent" | "cancelled" | "sent" | "completed"
    userName: string
    userEmail: string
}


// Define the shape of the context value
interface InvitationsManagementContextType {
    initialClientID?: string;
    client?: Client;
    setClient: (client?: Client) => void;
    invitations?: PaginatedResponseV2<UserActivationToken>;
    isLoading: boolean;
    error: any;
    selected: Set<string>;
    addSelected: (id: string) => void;
    removeSelected: (id: string) => void;
    selectAll: () => void;
    deselectAll: () => void;
    page: number;
    setPage: (page: number) => void;
    limit: number;
    refetch: () => void;
    actionError: string;
    setActionError: (error: string) => void;
    statusFilter: string;
    setStatusFilter: (s: string) => void;
    search: string;
    setSearch: (search: string) => void;
    deleteInvitation: (id: string) => void;
    resendInvitation: (id: string) => void;
}

// Create the context with a default value
const InvitationsManagementContext = createContext<InvitationsManagementContextType | undefined>(undefined);

// Fetch invitations based on clientID
const fetchInvitations = async (clientID: string, page: number, limit: number, statusFilter: string, search: string, isSuperAdmin: boolean): Promise<PaginatedResponseV2<UserActivationToken> | undefined> => {

    const params = new URLSearchParams

    if (clientID && isSuperAdmin) {
        params.set("clientID", clientID);
    }

    params.set("page", `${page}`);
    params.set("limit", `${limit}`)

    if (statusFilter) {
        params.set("status", statusFilter)
    }

    if(search){
        params.set("search", search);
    }


    const url = `user-activation?${params.toString()}`
    const response = await apiV2.get(url);
    return response.data
};

// Create a provider component
interface InvitationsManagementProviderProps {
    children: ReactNode;
}

export const InvitationsManagementProvider: React.FC<InvitationsManagementProviderProps> = ({children}) => {
    const auth = useAuth()


    const [searchParams, setSearchParams] = useSearchParams();
    const [client, setClient] = useState<Client>();
    const [selected, setSelected] = useState(new Set<string>)
    const [page, setPage] = useState<number>(Number(searchParams.get("page")) || 1);
    const [limit, setLimit] = useState<number>(10);
    const [statusFilter, setStatusFilter] = useState("")
    const [search, setSearch] = useState(searchParams.get("search") || "")

    const [actionError, setActionError] = useState("");

    const [debouncedSearch] = useDebounce(search, 300)

    const browsing = useUserManagementBrowsing()
    const [initialClientID] = useState<string>(browsing.clientID || "");

    useEffect(() => {
        browsing.setClientID(client?.id || "");
    }, [client]);

    useEffect(() => {
        setPage(1)
    }, [debouncedSearch]);


    useEffect(() => {
        if (actionError != "") {
            setTimeout(() => {
                setActionError("");
            }, 10 * 1000)
        }


    }, [actionError]);

    const deleteInvitation = async (id: string) => {
        await apiV2.delete(`user-activation/${id}`);
        await refetch()
    }

    const resendInvitation = async (id: string) => {
        await apiV2.post(`resend-user-activation/${id}`);
        await refetch()
    }


    const addSelected = (id: string) => {
        const newSet = new Set(selected);
        newSet.add(id);
        setSelected(newSet);
    }

    const removeSelected = (id: string) => {
        const newSet = new Set(selected);
        newSet.delete(id);
        setSelected(newSet);

    }

    const selectAll = () => {
        if (invitations) {
            const newSet = new Set(selected);
            invitations.results.forEach(d => d.id ? newSet.add(d.id) : null)
            setSelected(newSet)
        }
    }
    const deselectAll = () => {
        setSelected(new Set())
    }


    const {data: invitations, isLoading, error, refetch} = useQuery(
        ["invitations", client, statusFilter, page, limit, debouncedSearch],
        () => fetchInvitations(client?.id || "", page, limit, statusFilter, debouncedSearch, auth.isSuperAdmin),
        {
            enabled: !!client || !auth.isSuperAdmin, // Only fetch when clientID is set
        }
    );



    useEffect(() => {
        if (client && client.id) {
            const newParams = new URLSearchParams(searchParams.toString());
            newParams.set("clientID", client.id); // Update or add the parameter
            newParams.set("page", `${page}`); // Update or add the parameter
            newParams.set("search", `${debouncedSearch}`);
            setSearchParams(newParams); // Update the URL
        }
    }, [client, page, debouncedSearch]);


    return (
        <InvitationsManagementContext.Provider value={{
            client,
            setClient,
            invitations,
            isLoading,
            error,
            initialClientID,
            addSelected,
            removeSelected,
            selectAll,
            deselectAll,
            selected,
            page,
            limit,
            setPage,
            refetch,
            actionError,
            setActionError,
            statusFilter,
            setStatusFilter,
            search,
            setSearch,
            deleteInvitation,
            resendInvitation,
        }}>
            {children}
        </InvitationsManagementContext.Provider>
    );
};

// Create a custom hook for consuming the context
export const useInvitationsManagement = (): InvitationsManagementContextType => {
    const context = useContext(InvitationsManagementContext);
    if (!context) {
        throw new Error("useInvitationsManagement must be used within a InvitationsManagementProvider");
    }
    return context;
};
