import { useQuery } from "react-query";
import { apiV2 } from "../../api/axiosConfig";

export interface DashboardLayoutResponse {
    accessLevel: string
    dashboard: Dashboard
    dashboardOwner: string
  }
  
  export interface Dashboard {
    id: string
    dashboardName: string
    clientID: string
    clientName: string
    createdByUserID: string
    departmentIDs: string[]
    departmentNames: string[]
    layout: Layout[]
    createdAt: string
    updatedAt: string
    collision: boolean
  }
  


  export interface Layout {
    i: string
    reportID: string
    reportName: string
    departments: string[]
    owner: boolean
    tableView: boolean
    x: number
    y: number
    w: number
    h: number
  }

const fetchDashboardLayout = async (dashboardId: string): Promise<DashboardLayoutResponse> => {
  const response = await apiV2.get(`/reporting/get-dashboard/${dashboardId}`);
  return response.data;
};

export const useGetDashboardLayout = (dashboardId: string | undefined) => {
  return useQuery(
    ["dashboardLayout", dashboardId],
    () => fetchDashboardLayout(dashboardId!),
    {
      enabled: !!dashboardId, 
    }
  );
};
