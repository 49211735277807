import React, {createContext, useContext, useState, ReactNode, useMemo} from "react";
import {useRoles} from "./useRoles";
import {Roles} from "../../types/userManagement";

type DataContextType = {
    roles: Roles[]
    loadedRoles: boolean
    canUseRole: (roleID: string) => boolean
    loaded: boolean
};

const DataContext = createContext<DataContextType | undefined>(undefined);

export const DataProvider = ({ children }: { children: ReactNode }) => {

    const {roles, loaded: loadedRoles, canUseRole } = useRoles();

    const loaded = useMemo(() => {
        return loadedRoles
    }, [loadedRoles]);


    return (
        <DataContext.Provider value={{roles, loadedRoles, loaded, canUseRole}}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error("useData must be used within a DataProvider");
    }
    return context;
};
