// @ts-nocheck

import {countries} from "../../../lib/countries"
import Select, {ClassNamesConfig, StylesConfig, FormatOptionLabelMeta, CommonProps, GroupBase} from "react-select";
import React, {
    forwardRef,
    KeyboardEventHandler,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react";
import {CountryCode, parsePhoneNumberWithError} from "libphonenumber-js";
import {useDebugCounter} from "../../../lib/debug-counter/useDebugCounter";

type PhoneInputProps = {
    initialValue?: string;
    label?: string;
    required?: boolean;
    onChange: (value: string) => void;
    placeholder?: string;
    onEnter?: () => void;
}

export type  PhoneInputHandle = {
    reset: () => void;
}




export const PhoneInput = forwardRef<PhoneInputHandle, PhoneInputProps>((props, ref) => {

    const countrySelectHandle = useRef<PhoneCountrySelectHandle>(null)

    const [initialised, setInitialised] = useState(false)
    const [code, setCode] = useState<string>("")
    const [country, setCountry] = useState<string>()
    const [number, setNumber] = useState<string>("")
    const [valid, setValid] = useState(false)
    const [value, setValue] = useState<string>(props.initialValue || "")

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (event.key != "Enter") {
            return
        }

        if (props.onEnter) {
            props.onEnter()
        }
    }

    useImperativeHandle(ref, () => ({
        reset: () => {
            setNumber("")
            console.log(countrySelectHandle.current)
            if(countrySelectHandle.current){
                countrySelectHandle.current.reset()
            }
        }
    }));

    const [debug, incrementDebug] = useDebugCounter()

    useEffect(() => {
        if (!props.initialValue) {
            setInitialised(true)
            return
        }
        if (initialised) return

        try {
            const parsedNumber = parsePhoneNumberWithError(props.initialValue);
            console.log(parsedNumber)

            if (parsedNumber) {
                setCode(parsedNumber.countryCallingCode)
                setCountry(parsedNumber.country)
                setNumber(parsedNumber.nationalNumber)
                setValid(parsedNumber.isValid())
            }
        } catch (e) {

        }

        setInitialised(true)
    }, [props.initialValue]);


    const updateCountry = (value?: string) => {
        if (!value) {
            setCode("")
            setCountry("")
            return
        }


        const option = countries.find(c => c.countryCode === value)

        if (option) {
            setCode(option.countryCallingCode)
            setCountry(option.countryCode)
        }
    }


    useEffect(() => {
        if (!props.onChange) return;

        if (number.trim() == "") {
            setValid(true)
            setValue("")
            props.onChange("")
            return
        }

        const combinations = [
            `+${code}${number}`,
            `${number}`,
        ]

        for (const p of combinations) {
            try {
                const parsedNumber = parsePhoneNumberWithError(p, country as CountryCode);
                if (parsedNumber.isValid()) {
                    setValue(parsedNumber.format("E.164"))
                    setValid(true)
                    props.onChange(parsedNumber.format("E.164"))
                    return
                }
            } catch (e) {

            }


        }
        setValid(false)
        setValue(`+${code}${number}`)
        props.onChange(`+${code}${number}`)

    }, [code, number]);

    const parsedCountry = useMemo(() => {
        try {
            const parsedNumber = parsePhoneNumberWithError(value);
            return parsedNumber.country

        } catch (e) {

        }

        return "?"
    }, [value])


    return (
        <div>
            {props.label != undefined && <label className={"mb-1"} onClick={incrementDebug}>
                {props.label}
                {props.required && <span className="text-dark-red"> *</span>}
                {debug && <span
                    className={`${valid ? "bg-green" : "bg-darker-red"} text-white rounded-md px-2 ml-2`}>{parsedCountry} {value}</span>}
            </label>}
            <div
                className={"grid grid-cols-[130px_1fr] shadow-md rounded-l-md max-h-20 outline-none bg-white border-none"}>
                {initialised && <PhoneCountrySelect ref={countrySelectHandle} initialValue={country} onChange={updateCountry}/>}
                <input type={"text"} className={"px-2"} placeholder={props.placeholder} value={number} onKeyDown={handleKeyDown} onChange={(e) => setNumber(e.target.value)}/>
            </div>
        </div>
    )

})

PhoneInput.displayName = "PhoneInput"

type CountrySelectProps = {
    initialValue?: string
    onChange?: (value: string) => void
}


export type PhoneCountrySelectHandle = {
    reset: () => void;
}

type CountryOption =  {
    value: string;
    countryISO: string;
    label: string;
    shortLabel: string;
}


const PhoneCountrySelect = forwardRef<PhoneCountrySelectHandle, CountrySelectProps>((props, ref ) => {



    const selectInputRef = useRef<CommonProps<CountryOption, false, GroupBase<CountryOption>>>();

    useImperativeHandle(ref, () => ({
        reset: () => {

            console.log("SELECT", selectInputRef)
            if(selectInputRef.current) {
                selectInputRef.current.clearValue()
            }
        }
    }));

    const options = useMemo(() => {
        return countries.map((c) => ({
            value: c.countryCode,
            countryISO: c.countryCode,
            label: `<div class="grid grid-cols-[40px_60px_1fr]">
                <div>${c.flag}</div>
                <div>+${c.countryCallingCode}</div>
                <div>${c.countryNameEn}</div>
            </div>`,
            shortLabel: `${c.flag} +${c.countryCallingCode}`,
        }))
    }, [])

    const defaultValue = useMemo(() => {
        return options.find((o => o.countryISO === props.initialValue))
    }, [props.initialValue])

    const classNames: ClassNamesConfig<CountryOption> = {
        control: (state) => "h-10 w-full",
    }

    const styles: StylesConfig = {
        control: (def) => ({
            ...def,
            "border": "none",
        }),
        menu: (def) => ({
            ...def,
            "position": "absolute",
            "width": "500px",
            "max-width": "100vw"
        })
    }

    const formatOptionsLabel = (option: CountryOption, meta: FormatOptionLabelMeta<CountryOption>) => {
        if (meta.context == "menu") {
            return <div dangerouslySetInnerHTML={{__html: option.label}}/>;
        }

        return option.shortLabel
    }

    const onChange = (option: CountryOption|null) => {
        if (!props.onChange) return
        props.onChange(option?.value || "")
    }

    return <div>
        {defaultValue ?
            <Select ref={selectInputRef} onChange={onChange} options={options} classNames={classNames} styles={styles}
                    formatOptionLabel={formatOptionsLabel} defaultValue={defaultValue}/> :
            <Select ref={selectInputRef} onChange={onChange} options={options} classNames={classNames} styles={styles}
                    formatOptionLabel={formatOptionsLabel}/>
        }
    </div>
})

PhoneCountrySelect.displayName = "PhoneCountrySelect"