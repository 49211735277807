/**
 * @description checks if the accessed link is restricted for the user. If its a restricted link then navigates the user to the first allowed link for the user. If it is an allowed link, then the page is rendered.
 */
import React from "react";
import { OidcSecure } from "@axa-fr/react-oidc";
import { useRoleBasedAppRestriction } from "../restrictions";
import {AuroraAccessLevel, useAuth} from "../context/user";

type ProtectedRouteTypes = {
  children: React.ReactNode;
  accessLevel?: AuroraAccessLevel
};

function ProtectedRoute({ children, accessLevel }: ProtectedRouteTypes) {
  const auth = useAuth();

  if(accessLevel && auth.hasAccessLevel(accessLevel)) {
    return <OidcSecure>{children}</OidcSecure>;
  }


  useRoleBasedAppRestriction();
  return <OidcSecure>{children}</OidcSecure>;
}

export default ProtectedRoute;
