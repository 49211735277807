import {useCallback, useState} from "react";
import {Import, ImportChunk} from "./types";
import {apiV2} from "../../../api/axiosConfig";
import {generateAndSaveXLSX, generateAndSaveXLSXMultiSheet, Sheet} from "./useDownloadXLSX";


function* range(x:number, y:number) {
    for (let i = x; i <= y; i++) {
        yield i;
    }
}






export const useDownloadSummary = (importJob: Import) => {

    const [downloading, setDownloading] = useState(false);

    const download = useCallback(async () => {
        if(downloading) return
        try {
            setDownloading(true);
            const sheets: Sheet[] = []

            const id = importJob.id;

            const headers = [
                "action",
                "errors",
                "warnings",
                ...importJob.headers
            ]

            const rows: string[][] = []

            for (const index of range(1, importJob.chunkCount)) {
                const url = `import/${id}/chunk/${index}`;

                const {data: chunk} = await apiV2.get<ImportChunk>(url)

                for (let i = 0; i < chunk.data.length; i++) {
                    rows.push([
                        chunk.actions[i],
                        chunk.errors[i].join(" "),
                        chunk.warnings[i].join(" "),
                        ...chunk.data[i],
                    ])
                }
            }

            sheets.push({headers, rows, name: "User Import"})

            const archiveMissingUsers: string[][] = []
            for(const userID of Object.keys(importJob.archiveMissingUsers)) {
                archiveMissingUsers.push([userID, importJob.archiveMissingUsers[userID]])
            }

            if (archiveMissingUsers.length != 0){
                sheets.push({
                    headers: ["ID", "Email"],
                    rows: archiveMissingUsers,
                    name: "Archived Missing From Import"
                })
            }

            const departments = Object.keys(importJob.createdDepartments).map(d => [d])

            if(departments.length != 0){
                sheets.push({headers: ["Departments Created"], rows: departments, name: "Departments Created"})
            }

            generateAndSaveXLSXMultiSheet(
                sheets,
                `import-${importJob.id}.xlsx`
            )

        } catch (error) {
            console.error(error)
        }

        setDownloading(false);



    }, [importJob, downloading]);


    return {download, downloading}


}