import React, {useMemo, useState} from "react";
import BasicText from "../../components/form-v2/basic-text";
import {BlueButton} from "../../components/ui/Buttons";
import {usePasswordReset} from "./PasswordResetContext";

export const PasswordForm: React.FC = () => {

    const ctx = usePasswordReset()

    const passwordStatus = useMemo(() => {
        return <PasswordStatus/>
    }, [ctx.passwordIsValid, ctx.passwordErrorMessages])

    return <>
        <div className="bg-white shadow-md p-4 flex flex-col gap-8 rounded-md w-full md:w-1/3">
            <h2 className="text-md text-left font-semibold">{"Set Password"}</h2>
            <BasicText label="Password" type="password" value={ctx.password} onChange={ctx.setPassword}/>
            <BasicText label="Verify Password" type="password" value={ctx.passwordVerification}
                       onChange={ctx.setPasswordVerification}/>
            <div className="flex flex-col gap-4">
                {passwordStatus}
                {ctx.savingError &&
                    <div className={"bg-red text-white text-center rounded-md py-2 px-4 first-letter:capitalize"}>{ctx.savingError}</div>}
            </div>
        </div>
        <div className="w-full md:w-1/3">
            <BlueButton text={"Reset Password"} disabled={!ctx.passwordIsValid} onClick={ctx.submit}
                        loading={ctx.loading}/>


        </div>

    </>
}

const PasswordStatus: React.FC = () => {
    const ctx = usePasswordReset()

    if (ctx.passwordIsValid) {
        return <div className={"bg-green text-white text-center rounded-md py-2 px-4"}>Password is valid</div>

    }


    return (
        <>
            {ctx.passwordErrorMessages.map((m) => (
                <div className={"bg-red text-white text-center rounded-md py-2 px-4"}>{m}</div>
            ))}
        </>
    )

}