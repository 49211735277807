import React, { useEffect, useState } from "react";
import { Popover } from "@mui/material";
import SearchableSelect, { SearchableSelectOption } from ".";
import api from "../../../api/axiosConfig";
import { PaginatedResponse } from "../../../types/PaginatedResponse";
import { Client } from "../../../types/userManagement";
import SuitcaseIcon from "../../../assets/icons/suitcase.svg";

type ClientSelectSinglePopupProps = {
  client?: Client;
  onChange?: (selected: Client | undefined) => void;
  disabled?: boolean;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

function ClientSelectSinglePopup({
  client,
  onChange,
  disabled,
  open,
  anchorEl,
  onClose,
}: ClientSelectSinglePopupProps) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<SearchableSelectOption<Client>[]>([]);
  const [searchStr, setSearchStr] = useState("");

  const fetchClients = async () => {
    setLoading(true);
    try {
      const res = await api.get<PaginatedResponse<Client>>("/clients", {
        params: { search: searchStr, rpp: 20 },
      });
      setOptions(
        res.data.items.map((c) => ({
          label: c.name,
          value: c,
          key: c.id!,
        }))
      );
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchClients();
  }, [searchStr]);

  const handleChange = (selected: SearchableSelectOption<Client>[]) => {
    if (!onChange) return;
    onChange(selected.length > 0 ? selected[0].value : undefined);
    onClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      PaperProps={{
        sx: { width: 350, height: 250, p: 2 },
        className: "shadow-lg rounded-lg bg-transparent",
      }}
    >
      <SearchableSelect
        icon={SuitcaseIcon}
        label="Client"
        selected={client ? [{ label: client.name, value: client, key: client.id! }] : []}
        search={true}
        multiple={false}
        options={options}
        localSearch={false}
        onChange={handleChange}
        onSearch={setSearchStr}
        loading={loading}
        disabled={disabled}
      />
    </Popover>
  );
}

export default ClientSelectSinglePopup;
