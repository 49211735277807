import { useQuery } from "react-query";
import api from "../../api/axiosConfig";
import { Client } from "../../types/userManagement";

export const fetchClient = async (id: string): Promise<Client | null> => {
  if (!id) {
    return new Promise(() => null)
  }
  const response = await api.get(`/clients/${id}`);
  return response.data;
};

export const useGetClient = (id: string) => {
  return useQuery(
    ["client", id],
    () => fetchClient(id!),
    {
      enabled: !!id, 
    }
  );
};
