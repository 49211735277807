import React, { useCallback, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import moment from "moment/moment";
import { countries } from "../../../lib/countries";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import { ButtonV2 } from "../../ui/ButtonsV2/ButtonV2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { InputType } from "./InputType";
import { MultiInputMultiFieldInput, MultiInputMultiFieldInputHandle } from "./MultiInputMultiFieldInput";

type MultiInputMultiFieldRowProps<T> = {
    item: T;
    index: number;
    columns: number;
    placeholders: Partial<Record<keyof T, string>>;
    types: Partial<Record<keyof T, InputType>>;
    showLabels: boolean;
    onRemove: (index: number) => void;
    onChange: (item: T, index: number) => void;
    requireAll: boolean;
    required: Array<keyof T>;
};

export function MultiInputMultiFieldRow<T>(props: MultiInputMultiFieldRowProps<T>): JSX.Element {
    // Destructure props for clarity and maintainability
    const {
        item,
        index,
        columns,
        placeholders,
        types,
        showLabels,
        onRemove,
        onChange,
        requireAll,
        required,
    } = props;

    // State and refs
    const [editMode, setEditMode] = useState(false);
    const [editItem, setEditItem] = useState<T>(item);
    const inputRefs = useRef<MultiInputMultiFieldInputHandle[]>([]);

    // Grid classes based on columns
    const gridClass = clsx(`grid grid-cols-1 md:grid-cols-${columns} gap-8`);

    // Add input ref to manage reset functionality
    const addInputRef = (element: MultiInputMultiFieldInputHandle | null) => {
        if (element && !inputRefs.current.includes(element)) {
            inputRefs.current.push(element);
        }
    };

    // Memoized keys for placeholders
    const keys = useMemo(() => {
        return Object.keys(placeholders || {}) as Array<keyof T>;
    }, [placeholders]);

    // Update input value in edit state
    const updateInput = useCallback(
        (key: keyof T) => (value: string) => {
            setEditItem((prev) => ({
                ...prev,
                [key]: value as T[keyof T],
            }));
        },
        []
    );

    // Disable save if required conditions aren't met
    const isDisabled = useMemo(() => {
        if (requireAll) {
            return keys.some((key) => !item[key]);
        }
        return keys.some((key) => {
            const value = ((item[key] || "") as string).trim();
            return required.includes(key) && !value;
        });
    }, [item, keys, requireAll, required]);

    // Update and exit edit mode
    const update = useCallback(() => {
        if (!isDisabled) {
            onChange(editItem, index);
            setEditMode(false);
        }
    }, [editItem, isDisabled, onChange, index]);

    // Format display values for different input types
    const displayValue = useCallback(
        (key: keyof T) => {
            const type = types[key] || "text";
            const value = item[key] as string;

            if (!value) return "";

            switch (type) {
                case "date":
                    return moment.tz(value, "UTC").format("DD/MM/YYYY");
                case "country": {
                    const country = countries.find((c) => c.countryCode === value);
                    return country ? `${country.flag} ${country.countryNameEn}` : "";
                }
                case "phone":
                    try {
                        const parsed = parsePhoneNumberWithError(value);
                        if (parsed) {
                            const country = countries.find((c) => c.countryCode === parsed.country);
                            return country
                                ? `${country.flag} ${parsed.format("INTERNATIONAL")}`
                                : parsed.format("INTERNATIONAL");
                        }
                    } catch {
                        return "";
                    }
            }
            return value;
        },
        [types, item]
    );

    // Display labels and values
    const display = useCallback(
        (key: keyof T) => {
            if (showLabels) {
                return (
                    <div className="grid grid-cols-3 gap-8 w-full">
                        <div className="flex items-center font-semibold">{placeholders[key]}</div>
                        <div className="flex items-center col-span-2">
                            <div className={"truncate max-w-full"}>{displayValue(key)}</div>
                        </div>
                    </div>
                );
            }
            return <div className="flex items-center">{displayValue(key)}</div>;
        },
        [placeholders, displayValue, showLabels]
    );

    // Action handlers
    const enableEdit = useCallback(() => setEditMode(true), []);
    const remove = useCallback(() => onRemove(index), [onRemove, index]);

    // Render the component
    return !editMode ? (
        <div className={gridClass}>
            {keys.map((key) => (
                <div className={"flex items-center"} key={String(key)}>{display(key)}</div>
            ))}
            <div className="grid grid-cols-2 gap-8 col-span-1">
                <ButtonV2 label={<EditIcon />} onClick={enableEdit} />
                <ButtonV2 label={<DeleteIcon />} onClick={remove} color="orange" />
            </div>
        </div>
    ) : (
        <div className={gridClass}>
            {keys.map((key) => (
                <MultiInputMultiFieldInput
                    key={String(key)}
                    ref={addInputRef}
                    value={editItem[key] as string}
                    onChange={updateInput(key)}
                    placeholder={placeholders[key] || ""}
                    type={types[key] || "text"}
                    onEnter={update}
                />
            ))}
            <div className="grid grid-cols-2 gap-8 col-span-1">
                <ButtonV2 label={<SaveIcon />} onClick={update} color="green" disabled={isDisabled} />
            </div>
        </div>
    );
}
