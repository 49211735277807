import React, {ComponentType, useCallback, useEffect, useMemo, useRef, useState} from "react";
import DepartmentIcon from "../../../assets/icons/department-icon.svg";
import SearchableSelect from "./index";
import {capitalizeFirstLetter} from "../../../util/helpers";
import BasicSelect from "../basic-select";
import BasicText, {BasicTextHandle} from "../basic-text";

type GenderSelectProps = {
    gender?: string
    onChange: (gender?: string) => void
    required?: boolean
}

const genders = [
    "male",
    "female",
    "other"
] as const






const genderOptions = genders.map(g => ({
    label: capitalizeFirstLetter(g),
    value: g,
}))


export const GenderSelect: React.FC<GenderSelectProps> = (props) => {

    const inputRef = useRef<BasicTextHandle>(null);
    const [selectValue, setSelectValue] = useState<string | undefined>(() => {
        switch(props.gender){
            case "male":
            case "female":
                return props.gender
            case "":
                return "unspecified"


        }
        return "other"


    });

   const [otherValue, setOtherValue] = useState<string>(() => {
       switch(props.gender){
           case "male":
           case "female":
               return ""

       }
       return props.gender || ""
   })





    const showOther = useMemo(() => {
        return selectValue == "other"
    }, [selectValue])

    const options = useMemo(() => {
        if(props.gender == "" && !showOther){
            return [
                {label: "Unspecified", value: "unspecified"},
                ...genderOptions,
            ]
        }

        return genderOptions

    }, [props.gender, showOther])

    useEffect(() => {
        switch(selectValue){
            case "male":
            case "female":
                props.onChange(selectValue)
                return

            case "other":
                props.onChange(otherValue)
                return


        }

        props.onChange("")

    }, [selectValue, props.onChange, inputRef.current]);

   const handleSelectOnChange = useCallback((value: string|undefined) => {
       setSelectValue(value)
       if(value == "other"){
           inputRef.current?.focus()
       }
   }, [setSelectValue])


    useEffect(() => {
        if(!showOther) return
        props.onChange(otherValue)
    }, [showOther, otherValue]);


    return <>
        <BasicSelect  required={props.required} label={"Gender"} options={options} value={selectValue} onChange={handleSelectOnChange}/>
        {showOther && <BasicText ref={inputRef} value={otherValue} onChange={setOtherValue} label="Enter Gender" />}

    </>
}


export const GenderSelect2: React.FC<GenderSelectProps> = (props) => {

    const [showOther, setShowOther] = React.useState(false);
    const inputRef = useRef<BasicTextHandle>(null);

    useEffect(() => {
        switch(props.gender){
            case "female": setShowOther(false); return;
            case "male": setShowOther(false); return;
            case "": setShowOther(false); return;
        }

        setShowOther(true);


    }, [props.gender, inputRef.current]);



    useEffect(() => {
        if(inputRef.current && showOther){
            inputRef.current.focus();
        }
    }, [inputRef.current, showOther]);

    const options = useMemo(() => {
        if(props.gender == "" && !showOther){
            return [
                {label: "Unspecified", value: ""},
                ...genderOptions,
            ]
        }

        return genderOptions

    }, [props.gender])

    const update = (value?: string) => {
        if(value == "male" || value == "female") {
            setShowOther(false);
            props.onChange(value)
            return
        }

        props.onChange("")
        setShowOther(true)
    }


    const value = useMemo(() => {
        if(props.gender == "" && !showOther) return undefined;
        if(props.gender == "male" || props.gender == "female") return props.gender;
        return "other"
    }, [props.gender])


    return <>

        <BasicSelect  required={props.required} label={"Gender"} options={options} value={value} onChange={update}/>
        {showOther && <BasicText ref={inputRef} value={props.gender || ""} onChange={props.onChange} label="Enter Gender" />}

    </>
}

const realGenders = [
    "male",
    "female",
]


const realOptions = realGenders.map(g => ({
    label: capitalizeFirstLetter(g),
    value: g,
}))

export const RealGenderSelect: React.FC<GenderSelectProps> = (props) => {

    const options = useMemo(() => {
        if(props.gender == ""){
            return [
                {label: "Unspecified", value: ""},
                ...realOptions,
            ]
        }

        return realOptions

    }, [props.gender])

    return <>
        <BasicSelect label={"Gender at Birth"} options={options} value={props.gender} onChange={props.onChange}/>
    </>
}