import React, {useCallback, useEffect, useMemo, useState} from "react";
import Layout from "../layout/Layout";
import {ClientSelectSingle} from "../../../components/form-v2/searchable-select/ClientSelect";
import {DepartmentsManagementProvider} from "./DepartmentsManagementContext";
import {Client, Department, Departments} from "../../../types/userManagement";
import {apiV2} from "../../../api/axiosConfig";
import BasicText from "../../../components/form-v2/basic-text";
import {BlueButton} from "../../../components/ui/Buttons";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {isAxiosError} from "axios";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import {ToggleButton} from "../import/inputs";
import {PhoneInput} from "../../../components/form-v2/phone-input/PhoneInput";


const DepartmentEdit: React.FC = () => {


    const {id} = useParams();


    const [loading, setLoading] = useState(true);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [receiveEmailNotifications, setReceiveEmailNotifications] = useState(false);
    const [department, setDepartment] = useState<Department>();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("")

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const loadDepartment = async() => {
        try {
            const res = await apiV2.get<Department>(`departments/${id}`);

            setName(res.data?.name);
            setEmail(res.data.alertConfig.emailAddress || "")
            setPhone(res.data.alertConfig.emergencyPhoneNumber || "")
            setDepartment(res.data)



        } catch (e){
            setError("error loading department")
        }


        setLoading(false);
    }

    useEffect(() => {
        if(!id){
            return
        }

        loadDepartment()
    }, [id]);


    const save = useCallback(async () => {
        setSaving(true);

        try {
            await apiV2.patch(`departments/${id}`, {
                name,
                email,
                phone,
                receiveEmailNotifications,
            })

        } catch (e) {
            if (isAxiosError(e) && e?.response?.data?.error) {
                setError(e.response.data.error);
            } else {
                setError("An error has occurred")
            }
            setSaving(false);
            return


        }


        setSaving(false);



        const params = new URLSearchParams();
        params.set("message", "department has been updated")
        navigate(`/management/departments?${params.toString()}`);
        return

    }, [name, email, phone, receiveEmailNotifications])

    return <Layout
        active={"departments"}
    >

        <div className="flex flex-col gap-8">
            {loading && <div className="bg-white shadow-md p-4 w-1/2 flex flex-col gap-8 items-center">
                <LoadingSpinner/>
            </div>}
            {!loading && department && <div className="bg-white shadow-md p-4 w-1/2 flex flex-col gap-8">
                <p className="text-md text-left pb-2 font-semibold">Edit Department</p>
                <BasicText value={name} onChange={setName} label="Name"/>
                <BasicText value={email} onChange={setEmail} label="Email"/>
                <ToggleButton text={"Receive Email Notifications"} initialValue={department.receiveEmailNotifications} onChange={setReceiveEmailNotifications} />
                <PhoneInput onChange={setPhone} label="Phone" initialValue={department.alertConfig.emergencyPhoneNumber || ""} />
                <BlueButton text={"Update Department"} disabled={name == ""} onClick={save} loading={saving}/>
                {error && <p className={"text-center text-red"}>{error}</p>}
            </div>}
        </div>

    </Layout>

}

DepartmentEdit.displayName = "DepartmentEdit";

export default DepartmentEdit;