import api, {apiV2} from "../../api/axiosConfig";
import {Roles} from "../../types/userManagement";
import {useQuery, useQueryClient} from "react-query";
import {useData} from "../data/DataContext";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import BasicSelect from "../../components/form-v2/basic-select";
import {noop} from "../misc/noop";
import {update} from "lodash";

type RoleSelectProps = {
    initialRoleID?: string
    onChange?: (role?: Roles) => void
}

export const RoleSelect: React.FC<RoleSelectProps> = ({
    initialRoleID = "",
    onChange = noop,
                           }) => {
    const {roles, loadedRoles} = useData()
    const [roleID, setRoleID] = useState<string|undefined>(initialRoleID || roles.find(r => r.default)?.id)

    const options = useMemo(() => {
        return roles.map((r) => ({
            value: r.id || "",
            label: r.name,
        }))
    }, [roles, loadedRoles])


    useEffect(() => {
        if(!roleID){
            onChange(undefined)
            return
        }

        onChange(roles.find(r => r.id == roleID))

    }, [roleID, roles]);

    return <div>
        <BasicSelect label={"Role"} options={options} value={roleID} onChange={setRoleID}/>
    </div>

}

