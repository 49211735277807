import React, { useCallback } from "react";
import { useDebounce } from "use-debounce";
import { useOperationalMapContext } from "../../providers/OperationalMapProvider";
import { dbscanGeo, zoomLevelToEpsilon } from "./math";
import { useQuery } from "react-query";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import {FacilityMarker} from "./facilitymarker";

export const FacilitiesClustered: React.FC = () => {
  const ctx = useOperationalMapContext();
  const [debouncedZoom] = useDebounce(ctx.zoom, 300); // Debounce delay

  const getItemsClustered = useCallback(() => {
    if (!debouncedZoom || !ctx.facilityQuery.data) return null;

    // Memoize the result of dbscanGeo
    const allFacilities = ctx.facilityQuery.data.pages.flatMap(p => p.items);
    return dbscanGeo(
      allFacilities,
      zoomLevelToEpsilon[debouncedZoom],
      1,
      (item) => ({
        lat: item.position.coordinates[1],
        lng: item.position.coordinates[0],
      })
    );
  }, [debouncedZoom, ctx.facilityQuery.data]);

  const itemsQuery = useQuery(
    ["facilities-clustered", debouncedZoom, ctx.showFacilities, ctx.facilityQuery.data],
    getItemsClustered,
    {
      staleTime: 10000,
      cacheTime: 300000,
    },
  );

  if (itemsQuery.isLoading) return null;
  if (itemsQuery.isError) return null;
  if (!itemsQuery.data || itemsQuery.data.clusters.length === 0) return null;

  return (
    <>
      {itemsQuery.data.clusters.map((c) => {
        if (c.items.length > 1) {
          return (
            <AdvancedMarker
              key={`${c.center.lat}_${c.center.lng}`}
              position={{ lat: c.center.lat, lng: c.center.lng }}
            >
              <div className="w-[24px] h-[24px] flex rounded-full justify-center items-center bg-[#280E70] text-white font-semibold shadow">
                {c.items.length}
              </div>
            </AdvancedMarker>
          );
        }

        return (
          <FacilityMarker
            key={c.items[0].id}
            facility={c.items[0]}
            infoKey={c.items[0].id}
            showInfoWindow={ctx.visibleInfoWindow === c.items[0].id}
          />
        );
      })}
    </>
  );
};