import {useCallback, useMemo, useState} from "react";

type useToggleHook = [value: boolean, toggle: () => void, set: (value: boolean) => void];

export const useToggle = (defaultVal = false):useToggleHook => {
    const [value, setValue] = useState<boolean>(defaultVal)

    const toggle = useCallback(() => {
        setValue(prev => !prev)
    },[])

    return [value, toggle, setValue]


}

