import {ImportRow} from "./useImportParser";
import {useCallback, useMemo, useState} from "react";
import pLimit from "p-limit";
import {apiV2} from "../../../api/axiosConfig";
import {useChunkedArray} from "./useChunkedArray";
import {useIncrement} from "./useIncrement";


export type VerificationResponseItem = {
    index: number;
    valid: boolean;
    errors: string[];
    warnings: string[];
    action: "update" | "create" | "no_action" | "archive" | "unarchive"
}

export type VerifyResponse = {
    items: VerificationResponseItem[],
    departmentsCreated: string[]
}


export const useVerifyImport = (headers: ImportRow, rows: ImportRow[], clientID: string | undefined, matchOnEmail: boolean) => {

    const [chunkSize] = useState(100)

    const [verificationProgress, incrementVerificationProgress, resetVerificationProgress] = useIncrement()
    const [verifying, setVerifying] = useState<boolean>(false)
    const [verificationCompleted, setVerificationCompleted] = useState<boolean>(false)
    const [verificationResults, setVerificationResults] = useState<VerificationResponseItem[]>([])
    const [departmentsCreatedSet, setDepartmentsCreatedSet] = useState<Set<string>>(new Set())

    const totalRows = useMemo(() => {
        return rows.length
    }, [rows])

    const chunkedRows = useChunkedArray(rows, chunkSize)

    const verifyRows = useCallback(async (index: number, rows: ImportRow[]) => {

        if(!clientID){
            return
        }

        try {
            const flags: string[] = []
            if(matchOnEmail){
                flags.push("match_on_email")
            }


            const res = await apiV2.post<VerifyResponse>(`import/verify?clientID=${clientID}`, {
                headers: headers,
                rows: rows,
                flags,
            })


            setVerificationResults((prev) => {
                const newResults = [...prev]

                for(let i = 0; i < res.data.items.length; i++) {
                    const idx = res.data.items[i].index
                    const rowIndex = (chunkSize * index) + idx
                    newResults[rowIndex] = res.data.items[i]
                }

                return newResults
            })

            setDepartmentsCreatedSet(prev => {
                const newItems = new Set(prev)
                res.data.departmentsCreated.forEach(d => newItems.add(d))
                return newItems
            })




        } catch (e) {
            setVerificationResults((prev) => {
                const newResults = [...prev]

                for(let i = 0; i < chunkSize; i++) {
                    const rowIndex = (chunkSize * index) + i

                    if(rowIndex >= totalRows) {
                        break;
                    }

                    newResults[rowIndex] = {
                        index: index,
                        valid: false,
                        errors: ["server_error"],
                        warnings: [],
                        action: "no_action"
                    }

                }

                return newResults
            })
        }

       incrementVerificationProgress(rows.length)


    },[headers, clientID, chunkSize, matchOnEmail])



    const submitForVerification = useCallback(async () => {

        if(verifying) return

        setDepartmentsCreatedSet(new Set)
        setVerifying(true)
        setVerificationResults([])

        resetVerificationProgress()
        const limit = pLimit(5);

        const promises = chunkedRows.map((rows, index) =>
            limit(() => verifyRows(index, rows)) // wrap verifyRow in a function here
        );

        await Promise.all(promises);


        setVerifying(false)
        setVerificationCompleted(true)
    }, [headers, chunkedRows, verifying, verifyRows]);

    const clear = useCallback(() => {
        setVerificationResults([])
        setVerifying(false)
        setVerificationCompleted(false)
    }, [])


    const departmentsCreated = useMemo(() => {
        console.log("DEPS", departmentsCreatedSet)
        return Array.from(departmentsCreatedSet)
    }, [departmentsCreatedSet])

    return {
        verificationProgress,
        totalRows,
        submitForVerification,
        verifying,
        verificationCompleted,
        verificationResults,
        clear,
        departmentsCreated
    }


}