import React, {useCallback, useEffect, useMemo, useState} from "react";
import Layout from "../layout/Layout";
import {apiV2} from "../../../api/axiosConfig";
import BasicText, {BasicTextArea} from "../../../components/form-v2/basic-text";
import {BlueButton} from "../../../components/ui/Buttons";
import {useNavigate, useSearchParams} from "react-router-dom";
import {isAxiosError} from "axios";
import {UserEmergencyContact, UserPassport} from "../../../types/user-data-types";
import {ClientSelectSingle} from "../../../components/form-v2/searchable-select/ClientSelect";
import {DepartmentSelectSingle} from "../../../components/form-v2/searchable-select/DepartmentSelect";
import {Client, Department, Roles} from "../../../types/userManagement";
import {GenderSelect, RealGenderSelect} from "../../../components/form-v2/searchable-select/GenderSelect";
import BasicSelect from "../../../components/form-v2/basic-select";
import {emailTemplate} from "../../user-management/pages/add-client/constants/email-template";
import {FacilitySelectSingle} from "../../../components/form-v2/searchable-select/FacilitySelect";
import {FacilityDataTypes} from "../../../types/facilitiy-data-types";
import {LanguageSelect, LanguageSelectMultiple} from "../../../components/form-v2/searchable-select/LanguageSelect";
import {NationalitySelect} from "../../../components/form-v2/searchable-select/NationalitySelect";
import {Metadata} from "./components/Metadata";
import {CountrySelectSingle} from "../../../components/form-v2/searchable-select/CountrySelectSingle";
import {useAuth} from "../../../context/user";
import {Medication} from "./types/UserModel";
import {PhoneInput} from "../../../components/form-v2/phone-input/PhoneInput";
import MultiInputSingleField from "../../../components/form-v2/multi-input/MultiInputSingleField";
import {MultiInputMultiField} from "../../../components/form-v2/multi-input/MultiInputMultiField";
import {SelectTimezone} from "../../../lib/timezones/SelectTimezone";
import {useUserManagementBrowsing} from "../../../lib/user-management/UserManagementBrowsingContext";
import moment from "moment";
import {RoleSelect} from "../../../lib/roles/RoleSelect";


const UserCreate: React.FC = () => {

    const auth = useAuth();

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [gender, setGender] = useState<string | undefined>("");
    const [genderAtBirth, setGenderAtBirth] = useState<string | undefined>("");
    const [phone, setPhone] = useState("")

    const [additionalEmails, setAdditionalEmails] = useState<string[]>([])
    const [additionalPhones, setAdditionalPhones] = useState<string[]>([])

    const [passports, setPassports] = useState<UserPassport[]>([]);

    const [emergencyContacts, setEmergencyContacts] = useState<UserEmergencyContact[]>([]);

    const [metadata, setMetadata] = useState<Metadata[]>([])


    const [client, setClient] = useState<Client>();

    useEffect(() => {
        setDepartment(undefined);
        setHomeFacility(undefined);
        setStationedFacility(undefined);
    }, [client]);


    const [department, setDepartment] = useState<Department>();
    const [role, setRole] = useState<Roles>()

    const [sendInvitationEmail, setSendInvitationEmail] = useState<string>("yes");

    const [homeFacility, setHomeFacility] = useState<FacilityDataTypes>()
    const [stationedFacility, setStationedFacility] = useState<FacilityDataTypes>()

    const [mainLanguage, setMainLanguage] = useState<string>()
    const [secondaryLanguages, setSecondaryLanguages] = useState<string[]>([])
    const [nationality, setNationality] = useState<string>()
    const [country, setCountry] = useState<string>()
    const [timezone, setTimezone] = useState<string>()

    const [conditions, setConditions] = useState<string[]>([])
    const [allergies, setAllergies] = useState<string[]>([])
    const [medications, setMedications] = useState<Medication[]>([])
    const [bloodGroup, setBloodGroup] = useState("")
    const [additionalNotes, setAdditionalNotes] = useState("")

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState<string[]>([])

    const [searchParams] = useSearchParams();

    const browsing = useUserManagementBrowsing()

    const initialClientID = useMemo(() => {
        return browsing.clientID
    }, [browsing.clientID]);

    const navigate = useNavigate();

    const save = useCallback(async () => {
        setSaving(true);



        try {
            await apiV2.post("users", {
                email,
                firstName,
                middleName,
                lastName,
                dateOfBirth,
                gender,
                genderAtBirth,
                mainLanguage,
                secondaryLanguages,
                nationality,
                countryISO: country,
                homeFacilityID: homeFacility?.id,
                stationedFacilityID: stationedFacility?.id,
                passports,
                emergencyContacts,
                metadata,
                medicalInformationV2: {
                    conditions,
                    allergies,
                    medications,
                    bloodGroup,
                    additionalNotes
                },
                clientID: client?.id,
                departmentID: department?.id,
                roleID: role?.id,
                sendInvitationEmail: sendInvitationEmail == "yes",
                phone,
                additionalPhones,
                additionalEmails,
                timezone
            })

        } catch (e) {
            if (isAxiosError(e) && e?.response?.data?.error) {
                setErrors([e.response.data.error]);
            } else if (isAxiosError(e) && e?.response?.data?.errors){
                setErrors(e.response.data.errors);

            } else {
                setErrors(["An error has occurred"])
            }
            setSaving(false);
            return


        }


        setSaving(false);


        if(auth.hasAccessLevel("department")){
            const params = new URLSearchParams();
            params.set("message", "user has been updated")
            navigate(`/management/users?${params.toString()}`);
            return
        }

        navigate("/")



    }, [
        email,
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        gender,
        genderAtBirth,
        mainLanguage,
        secondaryLanguages,
        nationality,
        country,
        homeFacility,
        stationedFacility,
        passports,
        emergencyContacts,
        client,
        department,
        role,
        sendInvitationEmail,
        metadata,
        conditions,
        allergies,
        medications,
        bloodGroup,
        additionalNotes,
        additionalEmails,
        additionalPhones,
        phone,
        timezone
    ])

    return <Layout
        active={"users"}
    >
        <>
            <div className="flex flex-row gap-8 mb-8">
                <div className="flex flex-col gap-8 flex-1">
                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <p className="text-md text-left pb-2 font-semibold">Create User</p>
                        <BasicText type={"email"} value={email} onChange={(e) => setEmail(e.toLowerCase())}
                                   label="Email Address" required/>
                        <BasicText value={firstName} onChange={setFirstName} label="First Name" required/>
                        <BasicText value={middleName} onChange={setMiddleName} label="Middle Name/s"/>
                        <BasicText value={lastName} onChange={setLastName} label="Last Name" required/>
                        <GenderSelect gender={gender} onChange={setGender}/>
                        <RealGenderSelect gender={genderAtBirth} onChange={setGenderAtBirth}/>
                        <PhoneInput label={"Phone"} onChange={setPhone} initialValue={phone}/>

                        <BasicText type="date" value={dateOfBirth} onChange={setDateOfBirth} label="Date of Birth"/>
                        {/* <RealGenderSelect gender={genderAtBirth} onChange={setGenderAtBirth}/>*/}



                    </div>

                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <p className="text-md text-left pb-2 font-semibold">Demographic</p>
                        <div>
                            <label className="mb-1">Main Language</label>
                            <LanguageSelect value={mainLanguage} setValue={setMainLanguage}/>
                        </div>

                        <div>
                            <label className="mb-1">Secondary Languages</label>
                            <LanguageSelectMultiple value={secondaryLanguages} setValue={setSecondaryLanguages}/>
                        </div>

                        <div>
                            <label className="mb-1">Nationality</label>
                            <NationalitySelect value={nationality} setValue={setNationality}/>
                        </div>

                        <CountrySelectSingle value={country} setValue={setCountry} label={"Country of Residence"}/>

                        <div>
                            <label className="mb-1">Timezone</label>
                            <SelectTimezone countryISO={country} value={timezone} onChange={setTimezone}/>
                        </div>

                    </div>

                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <p className="text-md text-left pb-2 font-semibold">Facilities</p>
                        <div>
                        <label className="mb-1">Home Facility</label>
                            <FacilitySelectSingle clientID={auth.isSuperAdmin ? client?.id : undefined} facility={homeFacility}
                                                  onChange={setHomeFacility}/>
                        </div>

                        <div>
                            <label className="mb-1">Stationed Facility</label>
                            <FacilitySelectSingle clientID={auth.isSuperAdmin ? client?.id : undefined} facility={stationedFacility}
                                                  onChange={setStationedFacility}/>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-8 flex-1">
                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <p className="text-md text-left pb-2 font-semibold">Access</p>
                        {auth.isSuperAdmin && <div>
                            <label className="mb-1">Client</label>
                            <ClientSelectSingle client={client} onChange={setClient} initialClientID={initialClientID}/>
                        </div>}
                        {(client || !auth.isSuperAdmin) && <div>
                            <label className="mb-1">Department</label>
                            <DepartmentSelectSingle department={department} onChange={setDepartment}
                                                    clientID={client?.id}/>
                        </div>}

                        <RoleSelect onChange={setRole}/>
                    </div>


                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <MultiInputSingleField placeholder={"Enter Email Address"} label={"Additional Email Addresses"}
                                               initialValues={additionalEmails}
                                               type={"email"}
                                               onChange={setAdditionalEmails}/>
                    </div>

                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <MultiInputSingleField placeholder={"Enter Phone Number"} label={"Additional Phone Numbers"}
                                               initialValues={additionalPhones}
                                               onChange={setAdditionalPhones} type={"phone"}/>
                    </div>


                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <p className="text-md text-left pb-2 font-semibold">Medical Information</p>

                        <MultiInputSingleField label={"Conditions"} placeholder="Enter Condition"
                                               initialValues={conditions} onChange={setConditions}/>
                        <MultiInputSingleField label={"Allergies"} placeholder="Enter Allergy" initialValues={allergies}
                                               onChange={setAllergies}/>
                        <MultiInputMultiField
                            label={"Medications"}
                            initialItems={medications}
                            onChange={setMedications}
                            showLabels={true}
                            columns={2}
                            placeholders={{
                                name: "Name",
                                dose: "Dosage",
                                frequency: "Frequency"
                            }}
                            required={["name"]}
                        />

                        <BasicText value={bloodGroup} onChange={setBloodGroup} label="Blood Type"/>
                        <BasicTextArea value={additionalNotes} onChange={setAdditionalNotes}
                                       label="Additional Notes"/>
                    </div>


                </div>
            </div>

            <div className="flex flex-col gap-8 mb-8">
                <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                    <MultiInputMultiField label={"Passport Details"}
                                          initialItems={passports}
                                          columns={2}
                                          onChange={setPassports}
                                          showLabels
                                          placeholders={{
                                              number: "Passport Number",
                                              expiresAt: "Expires On",
                                              countryISO: "Country",
                                          }}
                                          types={{
                                              number: "text",
                                              expiresAt: "date",
                                              countryISO: "country",
                                          }}
                                          required={[
                                              "number",
                                              "countryISO"
                                          ]}
                    />
                </div>

                <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                    <MultiInputMultiField label={"Emergency Contacts"} initialItems={emergencyContacts} columns={3}
                                          showLabels
                                          onChange={setEmergencyContacts}
                                          placeholders={{
                                              firstName: "First Name",
                                              lastName: "Last Name",
                                              relationship: "Relationship",
                                              email: "Email",
                                              phoneNumber: "Phone Number"
                                          }}
                                          types={{
                                              phoneNumber: "phone",
                                          }}
                                          required={[
                                              "firstName",
                                              "lastName"
                                          ]}
                    />
                </div>


                <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                    <MultiInputMultiField label={"Metadata"} initialItems={metadata} columns={3} onChange={setMetadata}
                                          requireAll
                                          placeholders={{label: "Label", value: "Value"}}/>
                </div>

                <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                    <p className="text-md text-left pb-2 font-semibold">Invitation Email</p>
                    <BasicSelect label={"Send Invitation Email"} value={sendInvitationEmail}
                                 options={[{value: "yes", label: "Yes"}, {value: "no", label: "No"}]}
                                 onChange={(v) => setSendInvitationEmail(v || "no")}/>

                    <BlueButton text={"Create User"} disabled={false} onClick={save} loading={saving}/>
                    <div className="flex flex-col gap-4">
                        {errors && errors.map((e, i) => (
                            <p key={i} className={"text-center text-red"}>{e}</p>
                        ))}
                    </div>
                </div>
            </div>


        </>

    </Layout>

}

UserCreate.displayName = "UserCreate";

export default UserCreate;