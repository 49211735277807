type checkPasswordResult = [valid: boolean, messages: string[]]


export function checkPassword(password: string, passwordVerification: string): checkPasswordResult {
    const minLength = 8;

    const messages = []

    if (password.length < minLength) {
        messages.push("Password must be at least 8 characters long.");
    }

    if(password != passwordVerification) {
        messages.push("Passwords do not match.")
    }

    // Regex patterns for checks
    const hasUppercase = /[A-Z]/;
    const hasLowercase = /[a-z]/;
    const hasNumber = /[0-9]/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasSpaces = /\s/; // Check for spaces


    if (!hasUppercase.test(password)) {
        messages.push("Password must contain at least one uppercase letter.")
    }
    if (!hasLowercase.test(password)) {
        messages.push("Password must contain at least one lowercase letter.")
    }
    if (!hasNumber.test(password)) {
        messages.push("Password must contain at least one number.")
    }
    if (!hasSpecialChar.test(password)) {
        messages.push("Password must contain at least one special character.")
    }
    if (hasSpaces.test(password)) {
        messages.push("Password cannot contain spaces.")
    }

    return [messages.length == 0, messages]


}