// Import necessary libraries
import React, {createContext, useContext, useState, ReactNode, useEffect, useCallback} from "react";
import {useQuery} from "react-query";
import {apiV2} from "../../../api/axiosConfig";
import {Client, Departments} from "../../../types/userManagement";
import {PaginatedResponse} from "../../../types/PaginatedResponse";
import {useSearchParams} from "react-router-dom";
import { useDebounce } from "use-debounce";
import {useAuth} from "../../../context/user";
import {useUserManagementBrowsing} from "../../../lib/user-management/UserManagementBrowsingContext";


// Define the shape of the context value
interface DepartmentsManagementContextType {
    initialClientID?: string;
    client?: Client;
    setClient: (client?: Client) => void;
    departments?: PaginatedResponse<Departments>;
    isLoading: boolean;
    error: any;
    selected: Set<string>;
    addSelected: (id: string) => void;
    removeSelected: (id: string) => void;
    selectAll: () => void;
    deselectAll: () => void;
    page: number;
    setPage: (page: number) => void;
    limit: number;
    refetch: () => void;
    actionError: string;
    setActionError: (error: string) => void;
    archived: boolean;
    setArchived: (archived: boolean) => void;
    search: string;
    setSearch: (search: string) => void;
}

// Create the context with a default value
const DepartmentsManagementContext = createContext<DepartmentsManagementContextType | undefined>(undefined);

// Fetch departments based on clientID
const fetchDepartments = async (clientID: string, page: number, limit: number, archived: boolean, search: string, isSuperAdmin: boolean): Promise<PaginatedResponse<Departments> | undefined> => {

    if (!clientID && isSuperAdmin) {
        return undefined
    }
    let url = `departments?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`;
    if (archived) {
        url = `${url}&archived=true&search=${encodeURIComponent(search)}`;
    }

    if(isSuperAdmin) {
        url = `${url}&clientID=${clientID}`
    }
    const response = await apiV2.get(url);
    return response.data
};

// Create a provider component
interface DepartmentsManagementProviderProps {
    children: ReactNode;
}

export const DepartmentsManagementProvider: React.FC<DepartmentsManagementProviderProps> = ({children}) => {
    const auth = useAuth()


    const browsing = useUserManagementBrowsing()


    const [searchParams, setSearchParams] = useSearchParams();
    const [client, setClient] = useState<Client>();
    const [selected, setSelected] = useState(new Set<string>)
    const [page, setPage] = useState<number>(Number(searchParams.get("page")) || 1);
    const [limit, setLimit] = useState<number>(10);
    const [archived, setArchived] = useState(false)
    const [search, setSearch] = useState("")

    const [actionError, setActionError] = useState("");

    const [debouncedSearch] = useDebounce(search, 300)

    useEffect(() => {
        setPage(1)
    }, [debouncedSearch]);


    useEffect(() => {
        if (actionError != "") {
            setTimeout(() => {
                setActionError("");
            }, 10 * 1000)
        }


    }, [actionError]);


    const addSelected = (id: string) => {
        const newSet = new Set(selected);
        newSet.add(id);
        setSelected(newSet);
    }

    const removeSelected = (id: string) => {
        const newSet = new Set(selected);
        newSet.delete(id);
        setSelected(newSet);

    }

    const selectAll = () => {
        if (departments) {
            const newSet = new Set(selected);
            departments.items.forEach(d => d.id ? newSet.add(d.id) : null)
            setSelected(newSet)
        }
    }
    const deselectAll = () => {
        setSelected(new Set())
    }


    const {data: departments, isLoading, error, refetch} = useQuery(
        ["departments", client, archived, page, limit, debouncedSearch],
        () => fetchDepartments(client?.id || "", page, limit, archived, debouncedSearch, auth.isSuperAdmin),
        {
            enabled: !!client || !auth.isSuperAdmin, // Only fetch when clientID is set
        }
    );

    useEffect(() => {
        browsing.setClientID(client?.id || "");
    }, [client]);

    useEffect(() => {
        if (client && client.id) {
            const newParams = new URLSearchParams(searchParams.toString());
            newParams.set("clientID", client.id); // Update or add the parameter
            newParams.set("page", `${page}`); // Update or add the parameter
            newParams.set("search", `${debouncedSearch}`);
            setSearchParams(newParams); // Update the URL
        }
    }, [client, page, debouncedSearch]);

    const initialClientID = searchParams.get("clientID") ?? undefined;

    return (
        <DepartmentsManagementContext.Provider value={{
            client,
            setClient,
            departments,
            isLoading,
            error,
            initialClientID,
            addSelected,
            removeSelected,
            selectAll,
            deselectAll,
            selected,
            page,
            limit,
            setPage,
            refetch,
            actionError,
            setActionError,
            archived,
            setArchived,
            search,
            setSearch
        }}>
            {children}
        </DepartmentsManagementContext.Provider>
    );
};

// Create a custom hook for consuming the context
export const useDepartmentsManagement = (): DepartmentsManagementContextType => {
    const context = useContext(DepartmentsManagementContext);
    if (!context) {
        throw new Error("useDepartmentsManagement must be used within a DepartmentsManagementProvider");
    }
    return context;
};
