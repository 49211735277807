import { apiV2 } from "../../api/axiosConfig";
import { useQuery } from "react-query";
import { ReportData, ReportErrorResponse } from "../../pages/Reports/pages/Summary/components/ReportsEditOverlay";
import { AxiosResponse } from "axios";

type ApiResponse = ReportData | ReportErrorResponse


async function fetchReportDataSingle(
    customReportID: string,
    timeframe: string,
    timezone: string,
    startDate?: string,
    endDate?: string,
    dataSourceDepartmentIDs?: string,
  ): Promise<ApiResponse> {
    const response = await apiV2.get<ApiResponse>("/reporting/report-data-single", {
      params: { customReportID, timeframe, timezone, startDate, endDate, dataSourceDepartmentIDs},
    });
  
    return response.data;
  }


export function useGetReportDataSingle(customReportID: string, timeframe: string, timezone: string, startDate?: string, endDate?: string, dataSourceDepartmentIDs?: string
    ) {
    return useQuery<ApiResponse>(
        ["useGetReportDataQuery", customReportID, timeframe, timezone, startDate, endDate, dataSourceDepartmentIDs],
        () => fetchReportDataSingle(customReportID, timeframe, timezone, startDate, endDate, dataSourceDepartmentIDs),
        { enabled: !!(customReportID && timezone && timeframe) }
      );
}