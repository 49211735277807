import React, { useEffect, useState, useRef } from "react";
import { apiV2 } from "../../../../../api/axiosConfig";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiCheckCircleOutline } from "@mdi/js";
import { CircularProgress } from "@mui/material";
import { useClientDashboard } from "./ClientDashboardProvider";

interface Dashboard {
  id: string;
  dashboardName: string;
  clientID: string;
  collision: boolean;
}

interface AnimatedDashboardSelectorSidebarProps {
  isVisible: boolean;
  onClose: () => void;
  reportName: string;
  reportID: string;
  onAddToDashboard: (dashboardId: string) => void;
}

const AnimatedDashboardSelectorSidebar: React.FC<AnimatedDashboardSelectorSidebarProps> = ({
  isVisible,
  onClose,
  reportName,
  reportID,
  onAddToDashboard,
}) => {
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [success, setSuccess] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const {setClient, loadClientByID} = useClientDashboard()


  useEffect(() => {
    if (isVisible) {
      const fetchDashboards = async () => {
        try {
          const response = await apiV2.get<Dashboard[]>(
            `/reporting/get-dashboard-list-reports?reportID=${reportID}`
          );
          setDashboards(response.data);
        } catch (error) {
          console.error("Error fetching dashboards:", error);
        }
      };
      fetchDashboards();
    }
  }, [isVisible, reportID]);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      setSearchTerm("");
      setSelectedDashboard(null);
      setIsDropdownOpen(false);
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleAddToDashboard = async () => {
    if (!selectedDashboard) {
      alert("Please select a dashboard to add the report to.");
      return;
    }

    setIsAdding(true);
    setSuccess(false);

    try {
      await apiV2.post("/reporting/add-existing-to-dashboard", {
        customReportID: reportID,
        dashboardID: selectedDashboard.id,
        customReportName: reportName,
        copy: selectedDashboard.collision,
      });

      setSuccess(true);

        setIsAdding(false);
        setSuccess(false);
        onAddToDashboard(selectedDashboard.id);
        onClose();
        console.log(selectedDashboard.id, selectedDashboard.clientID)
        
        loadClientByID(selectedDashboard.clientID);
        navigate(`/reporting/summary/${selectedDashboard.id}?clientID=${selectedDashboard.clientID}`);

    } catch (error) {
      console.error("Error adding report to dashboard:", error);
      alert("Failed to add report to the dashboard. Please try again.");
      setIsAdding(false);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredDashboards = dashboards.filter((dashboard) =>
    dashboard.dashboardName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectDashboard = (dashboard: Dashboard) => {
    setSelectedDashboard(dashboard);
    setIsDropdownOpen(false);
  };

  return (
    <>
      <style>
        {`
          .animated-dashboard-sidebar {
            position: fixed;
            top: 0;
            right: 0;
            height: 100%;
            width: 380px;
            max-width: 80%;
            background-color: #ffffff;
            box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
            z-index: 50;
            transform: translateX(100%);
            transition: transform 0.3s ease-in-out;
            display: flex;
            flex-direction: column;
          }

          .animated-dashboard-sidebar.visible {
            transform: translateX(0);
          }

          .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 40;
            opacity: 1;
            transition: opacity 0.3s ease;
          }

          .overlay-hidden {
            opacity: 0;
            pointer-events: none;
          }

          .dashboard-sidebar-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            border-bottom: 1px solid #e5e7eb;
          }

          .dashboard-sidebar-content {
            padding: 16px;
            overflow-y: auto;
            flex-grow: 1;
          }

          .close-button {
            background: none;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
            color: #ef4444;
          }

          @media (max-width: 768px) {
            .animated-dashboard-sidebar {
              width: 100%;
            }
          }
        `}
      </style>

      {isVisible && (
        <div
          className={`overlay ${isVisible ? "" : "overlay-hidden"}`}
          onClick={onClose}
          aria-hidden="true"
        />
      )}

      <div
        className={`animated-dashboard-sidebar ${isVisible ? "visible" : ""}`}
        aria-hidden={!isVisible}
      >
        <div className="dashboard-sidebar-header">
          <h2 className="text-lg mt-28 font-bold text-dark-dark-grey">{reportName}</h2>
          <button onClick={onClose} className="close-button" aria-label="Close Sidebar">
            &times;
          </button>
        </div>
        <div className="dashboard-sidebar-content flex flex-col h-full p-6 bg-white shadow-md">
          <div className="mb-4 relative" ref={dropdownRef}>
            <label className="block text-gray-700 mb-2">Add to Dashboard</label>
            <div
              className="border border-gray-300 rounded p-2 w-full flex justify-between items-center cursor-pointer"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {selectedDashboard ? selectedDashboard.dashboardName : "Select a dashboard"}
              <svg
                className={`w-4 h-4 transition-transform ${
                  isDropdownOpen ? "transform rotate-180" : ""
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
            {isDropdownOpen && (
              <div className="absolute mt-1 w-full bg-white border border-grey rounded shadow-lg z-10">
                <div className="p-2">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border border-gray-300 rounded p-2 w-full"
                    placeholder="Search dashboards"
                  />
                </div>
                <div className="max-h-60 overflow-y-auto">
                  {filteredDashboards.length > 0 ? (
                    <ul className="py-1">
                      {filteredDashboards.map((dashboard) => (
                        <li
                          key={dashboard.id}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleSelectDashboard(dashboard)}
                        >
                          {dashboard.dashboardName}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="px-4 py-2 text-gray-500">No dashboards found.</p>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="mt-4">
            <button
              className={`w-full bg-blue text-white py-2 px-4 rounded flex items-center justify-center gap-2 hover:bg-blue-600 disabled:bg-gray-400 ${
                isAdding ? "cursor-not-allowed" : ""
              }`}
              onClick={handleAddToDashboard}
              disabled={!selectedDashboard || isAdding}
              style={{ minHeight: "2.5rem" }}
            >
              {isAdding ? (
                success ? (
                  <Icon path={mdiCheckCircleOutline} size={1} color="white" />
                ) : (
                  <CircularProgress size={20} color="inherit" />
                )
              ) : (
                <span>Add to Dashboard</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimatedDashboardSelectorSidebar;
