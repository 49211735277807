import {useState} from "react";
import {UserEmergencyContact, UserPassport} from "../../../types/user-data-types";
import {UserActivationGetType} from "../types";
import moment from "moment";
import {PhoneNumbers} from "../../../types/userManagement";

export type UserFields = {
    email: string;
    setEmail: (email: string) => void;
    phone: string;
    setPhone: (phone: string) => void;
    firstName: string;
    setFirstName: (firstName: string) => void;
    middleName: string;
    setMiddleName: (middleName: string) => void;
    lastName: string;
    setLastName: (lastName: string) => void;
    dateOfBirth: string;
    setDateOfBirth: (dateOfBirth: string) => void;
    gender: string | undefined;
    setGender: (gender: string | undefined) => void;
    genderAtBirth: string | undefined;
    setGenderAtBirth: (genderAtBirth: string | undefined) => void;
    mainLanguage: string | undefined;
    setMainLanguage: (language: string  | undefined) => void;
    secondaryLanguages: string[]
    setSecondaryLanguages: (languages: string[]) => void;
    nationality: string | undefined;
    setNationality: (nationality: string  | undefined) => void;
    countryISO: string | undefined;
    setCountryISO: (countryISO: string | undefined) => void;
    passports: UserPassport[];
    setPassports: (passports: UserPassport[]) => void;
    emergencyContacts: UserEmergencyContact[];
    setEmergencyContacts: (emergencyContacts: UserEmergencyContact[]) => void;

    secondaryEmails: string[]
    setSecondaryEmails: (emails: string[]) => void;
    secondaryPhones: string[]
    setSecondaryPhones: (phones: string[]) => void;
};

export const useUserState = (): UserFields => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [gender, setGender] = useState<string | undefined>("");
    const [genderAtBirth, setGenderAtBirth] = useState<string | undefined>("");

    const [mainLanguage, setMainLanguage] = useState<string>()
    const [secondaryLanguages, setSecondaryLanguages] = useState<string[]>([])
    const [nationality, setNationality] = useState<string>()
    const [countryISO, setCountryISO] = useState<string>()

    const [passports, setPassports] = useState<UserPassport[]>([]);
    const [emergencyContacts, setEmergencyContacts] = useState<UserEmergencyContact[]>([]);

    const [secondaryEmails, setSecondaryEmails] = useState<string[]>([])
    const [secondaryPhones, setSecondaryPhones] = useState<string[]>([])



    return {
        email, setEmail,
        phone, setPhone,
        firstName, setFirstName,
        middleName, setMiddleName,
        lastName, setLastName,
        dateOfBirth, setDateOfBirth,
        gender, setGender,
        genderAtBirth, setGenderAtBirth,
        mainLanguage, setMainLanguage,
        secondaryLanguages, setSecondaryLanguages,
        nationality, setNationality,
        countryISO, setCountryISO,
        passports, setPassports,
        emergencyContacts, setEmergencyContacts,
        secondaryEmails, setSecondaryEmails,
        secondaryPhones, setSecondaryPhones
    };
};

export const setUserFields = (data: UserActivationGetType, userState: UserFields) => {
    userState.setEmail(data.user.email)
    userState.setFirstName(data.user.profile.firstName);
    userState.setMiddleName(data.user.profile.middleNames);
    userState.setLastName(data.user.profile.lastName);
    userState.setDateOfBirth(moment.tz(data.user.profile.dateOfBirth, "UTC").format("YYYY-MM-DD"));
    userState.setGender(data.user.profile.gender);
    userState.setGenderAtBirth(data.user.profile.genderAssignedAtBirth);

    userState.setMainLanguage(data.user.mainLanguage)
    userState.setSecondaryLanguages(data.user.secondaryLanguages)
    userState.setNationality(data.user.profile.nationality)
    userState.setCountryISO(data.user.profile.countryOfResidence)

    userState.setEmergencyContacts(data.user.emergencyContacts)
    userState.setPassports(data.user.passports)

    if(data.user.phoneNumbers.length != 0){
        userState.setPhone(data.user.phoneNumbers[0].number)
    }

    if(data.user.phoneNumbers.length > 1){
        const phones = data.user.phoneNumbers.slice(1)
        userState.setSecondaryPhones(phones.map(p => p.number))
    }

    userState.setSecondaryEmails(data.user.additionalEmails)
}