import React, {useMemo} from "react";
import {useUserManagementBrowsing} from "../../../lib/user-management/UserManagementBrowsingContext";
import {Link} from "react-router-dom";


type ManagementNavButtonProps = {
    active?: boolean;
    text?: string;
    href?: string;
}

const ManagementNavButton: React.FC<ManagementNavButtonProps> = (props) => {

    const ctx = useUserManagementBrowsing()

    const link = useMemo(() => {
        if(ctx.clientID){
            return `${props.href}?clientID=${ctx.clientID}`
        }

        return props.href


    }, [ctx.clientID, props.href])

    if(!props.active) {
        return <Link className="rounded-md shadow-md bg-white justify-center flex w-full py-1 cursor-pointer" to={link ?? ""}>
            {props.text}
        </Link>
    }


    return <Link className="rounded-md shadow-md bg-blue text-white justify-center flex w-full py-1 font-semibold cursor-pointer" to={link ?? ""}>
        {props.text}
    </Link>

}

export default ManagementNavButton