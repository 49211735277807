import React, {useCallback, useEffect, useState} from "react";
import Layout from "./layout/Layout";
import BasicText from "../../components/form-v2/basic-text";
import {BlueButton} from "../../components/ui/Buttons";
import {Client} from "../../types/userManagement";
import {ClientSelectSingle} from "../../components/form-v2/searchable-select/ClientSelect";
import {useSearchParams} from "react-router-dom";
import BasicSelect from "../../components/form-v2/basic-select";
import {apiV2} from "../../api/axiosConfig";
import {isAxiosError} from "axios";
import {useUserManagementBrowsing} from "../../lib/user-management/UserManagementBrowsingContext";
import {ToggleButton} from "./import/inputs";
import {PhoneInput} from "../../components/form-v2/phone-input/PhoneInput";
import MultiInputSingleField from "../../components/form-v2/multi-input/MultiInputSingleField";

const boolOptions = [
    {value: "no", label: "No"},
    {value: "yes", label: "Yes"},
]


export const ClientCustomisation: React.FC = () => {


    const ctx = useUserManagementBrowsing()

    const [client, setClient] = useState<Client>()



    return <Layout active={"customisation"}>
         <div className="flex flex-col gap-8">
            <div className="bg-white shadow-md p-4 w-1/2 flex flex-col gap-8">
                <p className="text-md text-left pb-2 font-semibold">Customise Client</p>
                <ClientSelectSingle client={client}  onChange={setClient} initialClientID={ctx.clientID|| undefined}/>
                {client  && <FormMemo key={client.id} client={client}/>}

            </div>
        </div>

    </Layout>
}

const Form = ({client}:{client: Client | undefined}) => {
    const [loaded, setLoaded] = useState(false)

    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [sendAlertNGS,setSendAlertNGs] = useState<string>()
    const [airlineRiskMatrix, setAirlineRiskMatrix] = useState<string>()
    const [incidentVicinityAlerts, setIncidentVicinityAlerts] = useState<string>();
    const [insurancePhone, setInsurancePhone] = useState("")
    const [insuranceEmailLink, setInsuranceEmailLink] = useState("")
    const [importNotificationEmails, setImportNotificationEmails] = useState<string[]>([])

    const [searchParams, setSearchParams] = useSearchParams();

    const [saving, setSaving] = useState<boolean>(false)
    const [saved, setSaved] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    const [errorTimeout, setErrorTimeout] = useState<NodeJS.Timeout>()
    const [savedTimeout, setSavedTimeout] = useState<NodeJS.Timeout>()
    const [receiveEmailNotifications, setReceiveEmailNotifications] = useState(false)

    const save = useCallback(async () => {

        if(!client){
            return
        }
        setSaved(false)
        setSaving(true)

        try {

            const modules = []

            if(airlineRiskMatrix == "yes"){
                modules.push("airlineRiskMatrix")
            }

            if(incidentVicinityAlerts == "yes"){
                modules.push("incident_vicinity_alerts")
            }


            await apiV2.patch(`clients/${client.id}/customisation`, {
                phone,
                email,
                sendAlertToNGS: sendAlertNGS == "yes",
                insurancePhone,
                insuranceEmailLink,
                modules,
                receiveEmailNotifications,
                importNotificationEmails,
            })

            setSaved(true)
        } catch(e){
            if(isAxiosError(e) && e?.response?.data?.error){
                setErrorMessage(e.response.data.error)
            } else {
                setErrorMessage("an error occurred")
            }
        }
        setSaving(false)
    }, [client, phone, email, sendAlertNGS, airlineRiskMatrix, incidentVicinityAlerts, insurancePhone, insuranceEmailLink, receiveEmailNotifications, importNotificationEmails])

    useEffect(() => {
        if(errorMessage && errorTimeout){
            clearTimeout(errorTimeout)
        }

        const timeout = setTimeout(() => {
            setErrorMessage("")
        }, 5000)

        setErrorTimeout(timeout)
    }, [errorMessage]);

    useEffect(() => {
        if(savedTimeout){
            clearTimeout(savedTimeout)
        }

        const timeout = setTimeout(() => {
            setSaved(false)
        }, 5000)

        setSavedTimeout(timeout)
    }, [saved]);

    useEffect(() => {
        if(!client) return
        setLoaded(false)

        searchParams.set("clientID", client.id || "")
        setSearchParams(searchParams)



        setReceiveEmailNotifications(client.receiveEmailNotifications)
        setEmail(client.alertEmailAddresses)
        setPhone(client.clientAlarmConfig.emergencyPhoneNumber)
        setInsurancePhone(client.insuranceNumber)
        setInsuranceEmailLink(client.insuranceLink)
        setImportNotificationEmails(client.importNotificationEmails)

        const modules = client.modules || []

        setSendAlertNGs(client.sendAlertsToNGS ? "yes" : "no")
        setAirlineRiskMatrix(modules.includes("airlineRiskMatrix") ? "yes" : "no")
        setIncidentVicinityAlerts(modules.includes("incident_vicinity_alerts") ? "yes" : "no")

        setLoaded(true)
    }, [client]);

    if(!client){
        return null
    }

    if(!loaded){
        return (
            <p>Loading...</p>
        )
    }

    return <>
        <PhoneInput onChange={setPhone} initialValue={client.clientAlarmConfig.emergencyPhoneNumber} label="Emergency Phone Number" />
        {/* <BasicText value={phone} onChange={setPhone} label="Emergency Phone Number" />*/}
        <BasicText value={email} onChange={setEmail} label="Emergency Email Address" />
        <ToggleButton text={"Receive Email Notifications"} initialValue={client.receiveEmailNotifications} onChange={setReceiveEmailNotifications} />

        {/* <BasicSelect label={"Send Alerts to NGS"} options={boolOptions} value={sendAlertNGS} onChange={setSendAlertNGs} />*/}
        <BasicSelect label={"Enable Airline Risk Matrix"} options={boolOptions} value={airlineRiskMatrix} onChange={setAirlineRiskMatrix} />
        <BasicSelect label={"Enable Incident Vicinity Alerts"} options={boolOptions} value={incidentVicinityAlerts} onChange={setIncidentVicinityAlerts} />


        <BasicText value={insurancePhone} onChange={setInsurancePhone} label="Insurance Phone Number" />
        <BasicText value={insuranceEmailLink} onChange={setInsuranceEmailLink} label="Insurance Email Link" />

        <MultiInputSingleField placeholder={"Enter Email Address"} label={"Import Notification Email Addresses"}
                               initialValues={importNotificationEmails}
                               type={"email"}
                               onChange={setImportNotificationEmails}/>

        {errorMessage && <div className="bg-red text-white text-center py-2 px-4 rounded-md">{errorMessage}</div>}
        {saved && <div className="bg-green text-white text-center py-2 px-4 rounded-md">client customisation saved</div>}
        <BlueButton text={"Save"} onClick={save} loading={saving}/>

    </>

}

const FormMemo = React.memo(Form, (prev, next) => {

    return prev.client?.id == next.client?.id
})