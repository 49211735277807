import React, {useCallback, useEffect, useMemo} from "react";
import {IconButton} from "./inputs";
import {useImportContext} from "./ImportContextProvider";
import {FileUpload} from "@mui/icons-material";
import {ProgressBar} from "./ProgressBar";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {Panel} from "./Panel";

export const ImportVerifyPage: React.FC = () => {

    const {submitForVerification, totalRows, verificationProgress, verifying, verificationCompleted, gotoStage} = useImportContext()

    const gotoVerifyResultsStage = useCallback(() => {
        gotoStage("verify_results")
    }, [])

    useEffect(() => {
        if(verificationCompleted){
            gotoVerifyResultsStage()
        }
    }, [verificationCompleted, gotoVerifyResultsStage]);


    return (
        <Panel>
            <h2 className="text-lg font-semibold">Verify File</h2>
            <div className="w-72 flex flex-col gap-8">
                {!verifying && !verificationCompleted && (
                    <IconButton onClick={submitForVerification} text={"Submit For Verification"} icon={<FileUpload/>}/>
                )}

                {verifying && !verificationCompleted && (
                    <ProgressBar current={verificationProgress} total={totalRows}/>
                )}

                {verificationCompleted && (
                    <IconButton onClick={gotoVerifyResultsStage} text={"View Verification Results"}
                                icon={<NavigateNextIcon/>}/>

                )}
            </div>
        </Panel>
    )
}