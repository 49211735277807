import React, {useMemo} from "react";
import Layout from "../layout/Layout";
import {useImportContext} from "./ImportContextProvider";
import {ImportSelectFilePage} from "./ImportSelectFilePage";
import {ImportVerifyPage} from "./ImportVerifyPage";
import {ImportVerifyResultsPage} from "./ImportVerifyResultsPage";

export const ImportPage: React.FC = () => {

    const {
        currentStage,
    } = useImportContext()


    const content = useMemo(() => {
        switch (currentStage){
            case "select_file": return <ImportSelectFilePage/>
            case "verify": return <ImportVerifyPage/>
            case "verify_results": return <ImportVerifyResultsPage/>
        }

        return null;

    }, [currentStage])



    return (
        <Layout active="import">

                {content}
        </Layout>
)
}