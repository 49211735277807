import React, { useState, useEffect, useContext, useRef, useCallback } from "react"
import {
  Close,
  ArrowBack,
  ArrowForward,
  MoreVert,
  Edit,
  FileDownload,
  Schedule as ScheduleIcon,
  Dashboard as DashboardIcon,
  Delete,
  ContentCopy,
} from "@mui/icons-material"
import { Menu, MenuItem, IconButton, Tooltip, CircularProgress, ToggleButtonGroup, ToggleButton } from "@mui/material"
import { apiV2 } from "../../../../../api/axiosConfig"
import AnimatedDashboardSelectorSidebar from "./ReportsSidebar"
import AnimatedExportSidebar from "./ReportsSidebarExport"
import { UserContext } from "../../../../../context/user"
import EditOverlay from "./ReportsEditOverlay"
import { ReportsTableAll, ReportsTableScheduled } from "./ReportsTable"
import { Report, ScheduledReport } from "./reportTypes"
import { UserSelectSingle } from "../../../../../components/form-v2/searchable-select/UserSelect"
import { UserDataTypes } from "../../../../../types/user-data-types"
import { TimeZoneSelectContextType, useTimeZoneSelectContext } from "../../../../../providers/TimeZoneSelectProvider"
import { CountrySelectSingle } from "../../../../../components/form-v2/searchable-select/CountrySelectSingle"
import BasicSelect from "../../../../../components/form-v2/basic-select"
import useOnClickOutside from "../../../../../hooks/useOnClickOutside"
import { getTimezonesForCountry } from "countries-and-timezones"
import RecurrencePicker from "../../../../../lib/reports/RecurringSelect"
import { useLocation, useNavigate } from "react-router-dom"
import NewReportEditOverlay from "./NewReportEditOverlay"
import { useClientDashboard } from "./ClientDashboardProvider"
import {Department} from "../../../../../types/userManagement";

const REPORTS_PER_PAGE = 7
const FILTER_TYPES = ["value", "static", "table"]

export default function ReportsPage() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [selectedScheduledReport, setSelectedScheduledReport] = useState<ScheduledReport | null>(null);
  const [isScheduleOverlayOpen, setIsScheduleOverlayOpen] = useState(false)
  const [isEditOverlayOpen, setIsEditOverlayOpen] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null)
  const [activeTab, setActiveTab] = useState("all")
  const [reports, setReports] = useState<Report[]>([])
  const [scheduledReports, setScheduledReports] = useState<ScheduledReport[]>([])
  const [loading, setLoading] = useState(true)
  const [scheduledLoading, setScheduledLoading] = useState(false)
  const [error, setError] = useState("")
  const [scheduledError, setScheduledError] = useState("")
  const [activeSidebar, setActiveSidebar] = useState<"dashboard" | "export" | null>(null)
  const [selectedReportName, setSelectedReportName] = useState("")
  const [selectedReportID, setSelectedReportID] = useState("")
  const [frequency, setFrequency] = useState("")
  const [time, setTime] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>([])
  const [currentPageAll, setCurrentPageAll] = useState(1)
  const [currentPageScheduled, setCurrentPageScheduled] = useState(1)
  const [selectedFilterTypes, setSelectedFilterTypes] = useState<string[]>([])
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null)
  const [customRangeStart, setCustomRangeStart] = useState("")
  const [customRangeEnd, setCustomRangeEnd] = useState("")
  const [scheduleType, setScheduleType] = useState("");
  const [selectedUser, setSelectedUser] = useState<UserDataTypes | undefined>();
  const [validationError, setValidationError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState<{ id: string; name: string } | null>(null);
  const [isClonePopupOpen, setIsClonePopupOpen] = useState(false);
  const [isCloneOverlayOpen, setIsCloneOverlayOpen] = useState(false);
  const [cloneReportName, setCloneReportName] = useState("");
  const [cloneCustomRangeStart, setCloneCustomRangeStart] = useState("");
  const [cloneCustomRangeEnd, setCloneCustomRangeEnd] = useState("");
  const [selectedReportTimeframe, setSelectedReportTimeframe] = useState<string>("");
  const [selectedReportClientName, setSelectedReportClientName] = useState<string>("");
  const [selectedReportClientID, setSelectedReportClientID] = useState<string>("");
  const [selectedReportDepartments, setSelectedReportDepartments] = useState<string[]>([]);
  const [selectedReportStartDate, setSelectedReportStartDate] = useState<string>("");
  const [selectedReportEndDate, setSelectedReportEndDate] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [isEditSchedule, setIsEditSchedule] = useState(false);
  const [departments, setDepartments] = useState<{ id: string; name: string }[]>([]);
  const [dataSourceDepartments, setDataSourceDepartments] = useState<{ id: string; name: string }[]>([]);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedClientFilter, setSelectedClientFilter] = useState<string[]>([]);
  const location = useLocation()
  const { clientID, loaded: loadedClient } = useClientDashboard();

  const handleDaySelection = (event: React.MouseEvent<HTMLElement>, newDays: string[]) => {
    setSelectedDays(newDays);
  };
  
  type Recurrence = {
    startDate: string;
    selectedDays: string[];
    frequency: string;
    repeatEvery: number;
    repeatUnit: "day" | "week" | "month" | "year";
    endDate?: string;
    pattern?: string;
    monthlyYearlyDay: string;
  };

  const [authData, setAuthData] = useState<{
    userID: string;
    accessLevel: string;
    clientName: string;
    clientID: string;
    departmentID: string;
  } | null>(null);

  const tzContext = useTimeZoneSelectContext()
  const timeZoneSelect = useTimeZoneSelectContext()
  const tzDropdownRef = useRef(null);
  const navigate = useNavigate()

  interface TimeZoneConfig {
    countryISO: string;
    timezone: string;
  }

  const [newReportID, setNewReportID] = useState<string | null>(null);
  const [newReportName, setNewReportName] = useState<string>(""); 
  const [newClientName, setNewClientName] = useState<string>(""); 
  const [newClientID, setNewClientID] = useState<string>(""); 
  const [initialTimeframe, setInitialTimeframe] = useState<string>("PAST DAY");
  const [newDataSourceDepartments, setNewDataSourceDepartments] = useState<Department[]>([]);

  const [stateLoaded, setStateLoaded] = useState<boolean>(false);

  const [newAllUsers, setNewAllUsers] = useState(false)

  useEffect(() => {
    if(location.state){
      setNewAllUsers(location.state.allUsers);
    }
    setStateLoaded(true)
  }, [location.state]);

  useEffect(() => {
    if (location.state?.openEditOverlay) {
      console.log("Opening EditOverlay for report:", {
        reportID: location.state.newReportID,
        reportName: location.state.newReportName,
        clientName: location.state.new
      });
  
      setIsEditOverlayOpen(true);
      setNewReportID(location.state.newReportID);
      setNewReportName(location.state.newReportName);
      setNewClientName(location.state.newClientName)
      setNewClientID(location.state.newClientID)
      setCustomRangeStart(location.state.newStartDate || ""); 
      setInitialTimeframe(location.state.newTimeframe)
      setCustomRangeEnd(location.state.newEndDate || "");
      setIsEditOverlayOpen(true);
      setNewDataSourceDepartments(location.state.newDataSourceDepartments);

      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);


  const [scheduleTimezone, setScheduleTimezone] = useState<TimeZoneConfig>({
    countryISO: tzContext.countryISO,
    timezone: tzContext.timeZone || "", // Ensure this is populated
  });
  console.log("Initial Schedule Timezone:", scheduleTimezone);

  const [dataSourceTimezone, setDataSourceTimezone] = useState<TimeZoneConfig>({
    countryISO: tzContext.countryISO,
    timezone: tzContext.timeZone || "", // Ensure this is populated
  });
  console.log("Initial Data Source Timezone:", dataSourceTimezone);


  const user = useContext(UserContext)
  const accessLevel = user?.role.auroraAccessLevel

  console.log(user?.sub)
  console.log("timezone context", tzContext.timeZone)


  const fetchReports= useCallback(async() => {
    if (!loadedClient) {
      return false
    }
    try {
      setLoading(true);
      
      console.log("client STUFF", clientID, accessLevel)

      const url = clientID && accessLevel == "all" ? `/reporting/get-reports?clientID=${clientID}` : "/reporting/get-reports";
      const allRes = await apiV2.get(url);

      const reportsWithFilterType: Report[] = allRes.data.map((item: any) => ({
        ...item.report,
        filterType: item.filterType,
        clientName: item.clientName,
        ownedByUserName: item.ownedByUserName,
        ownedByUserID: item.report.ownedByUserID,
        departmentNames: item.departmentNames || [],
        dataSourceDepartmentNames: item.dataSourceDepartmentNames || [],
      }));

      setReports(reportsWithFilterType);
    } catch {
      setError("No reports found.");
    } finally {
      setLoading(false);
    }
  },[clientID, accessLevel, loadedClient])

  const getTimezoneOptions = (countryISO: string) => {
    return getTimezonesForCountry(countryISO)?.map((tz) => ({
      label: tz.name,
      value: tz.name
    })) || []
  }

  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        const res = await apiV2.get<{
          userID: string;
          accessLevel: string;
          clientName: string;
          clientID: string;
          departmentID: string;
        }>("/reporting/get-user-auth-data");

        setAuthData({
          userID: res.data.userID,
          accessLevel: res.data.accessLevel,
          clientName: res.data.clientName,
          clientID: res.data.clientID,
          departmentID: res.data.departmentID,
        });
      } catch (err) {
        console.error("Failed to fetch auth data:", err);
      }
    };

    fetchAuthData();
  }, []);


  useEffect(() => {
    const timezoneOptions = getTimezoneOptions(scheduleTimezone.countryISO);
    if (timezoneOptions.length === 1) {
      setScheduleTimezone((prev) => ({
        ...prev,
        timezone: timezoneOptions[0].value,
      }));
    }
  }, [scheduleTimezone.countryISO]);


  useEffect(() => {
    const timezoneOptions = getTimezoneOptions(dataSourceTimezone.countryISO);
    if (timezoneOptions.length === 1) {
      setDataSourceTimezone((prev) => ({
        ...prev,
        timezone: timezoneOptions[0].value,
      }));
    }
  }, [dataSourceTimezone.countryISO]);


  function validateStartDateAndTime(date: any, time: any) {
    const currentDate = new Date();
    const selectedStartDate = new Date(`${date}T${time}`);

    if (selectedStartDate < currentDate) {
      setValidationError("Start Time must not be in the past");
    } else {
      setValidationError("");
    }
  }


  const [recurrence, setRecurrence] = useState<Recurrence>({
    startDate: "",
    selectedDays: [], 
    frequency: "custom",
    repeatEvery: 1,
    repeatUnit: "week",
    pattern: "",
    monthlyYearlyDay: "",
  })

  type RecurrenceUpdate = Partial<Recurrence>;

  function handleRecurrenceChange(updatedValues: RecurrenceUpdate) {
    setRecurrence(prev => ({ ...prev, ...updatedValues }));
  }




  async function loadScheduledReports() {
    try {
      setScheduledLoading(true);
      const scheduledRes = await apiV2.get("/reporting/get-scheduled-reports");
      const scheduledData: ScheduledReport[] = scheduledRes.data.map((item: any) => ({
        id: item.id,
        customReportID: item.customReportID,
        reportName: item.reportName,
        createdByUserID: item.createdByUserID,
        recipientID: item.recipientID,
        recipientName: item.recipientName,
        clientID: item.clientID,
        userID: item.userID,
        schedule: {
          frequency: item.schedule.frequency,
          repeatEvery: item.schedule.repeatEvery,
          monthlyYearlyDay: item.schedule.monthlyYearlyDay,
          pattern: item.schedule.pattern,
          time: item.schedule.time,
          startDate: item.schedule.startDate,
          endDate: item.schedule.endDate,
          daysOfWeek: item.schedule.daysOfWeek || [],
        },
        scheduleType: item.scheduleType,
      }));
      setScheduledReports(scheduledData);
    } catch {
      setScheduledError("Failed to fetch scheduled reports.");
    } finally {
      setScheduledLoading(false);
    }
  }


  useEffect(() => {
    fetchReports()
    console.log("useEffect")
  }, [clientID, fetchReports, loadedClient])

  function refreshReports() {
    console.log("refreshReports")
    if (activeTab === "all") fetchReports()
    else if (activeTab === "scheduled") loadScheduledReports()
  }


  // const handleCloneSave = (data?: Report) => {
  //   if (data) {
  //     setNewReportID(data.id || "");
  //     setNewReportName(data.reportName || "");
  //     setNewClientName(data.clientName || "");
  //     setNewClientID(data.clientID || "");
  //     setCustomRangeStart(data.startDate || "");
  //     setCustomRangeEnd(data.endDate || "");
  //     setInitialTimeframe(data.timeframe || "");
  //     setIsCloneOverlayOpen(false);
  //     setIsEditOverlayOpen(true);
  //   } else {
  //     refreshReports();
  //   }
  // };

  function handleTabChange(tab: string) {
    setActiveTab(tab)
    if (tab === "scheduled" && scheduledReports.length === 0) loadScheduledReports()
  }

  function isReport(r: Report | ScheduledReport): r is Report {
    return (r as Report).id !== undefined
  }

  function isScheduledReport(r: Report | ScheduledReport): r is ScheduledReport {
    return (r as ScheduledReport).id !== undefined
  }

 
  function handleMenuOpen(
    e: React.MouseEvent<HTMLElement>,
    report: Report | ScheduledReport,
    action?: string
  ) {
    setAnchorEl(e.currentTarget);

    if ("id" in report && "customReportID" in report) {
      // For Scheduled Reports

      setSelectedScheduledReport(report as ScheduledReport);
      setSelectedReport(null);
      setSelectedReportName(report.reportName || "");
      setSelectedReportID(report.customReportID || "");
      setSelectedReportClientName(report.clientName || "");
      setSelectedReportClientID(report.clientID || "");
  
      if (action === "delete") {
        setReportToDelete({ id: report.id, name: report.reportName });
        setIsDeleteDialogOpen(true);
      }
  
      if (action === "editSchedule") {
        console.log(report.schedule)


        setIsEditSchedule(true);
        console.log(report, "REPORT HERE")
        setScheduleType(report.scheduleType);
        setFrequency(report.schedule.frequency || "");
        setTime(report.schedule.time || "");
        setStartDate(report.schedule.startDate || "");
        setEndDate(report.schedule.endDate || "");
        setDaysOfWeek(report.schedule.daysOfWeek || []);
        setSelectedReportID(report.customReportID || "");
        setSelectedReportTimeframe(report.timeframe || "");
        setSelectedReportClientName(report.clientName || "");
        setSelectedReportClientID(report.clientID || "");

        setRecurrence({
          startDate: report.schedule.startDate || "",
          selectedDays: report.schedule.daysOfWeek || [],
          frequency: report.schedule.frequency || "custom",
          repeatEvery: report.schedule.repeatEvery || 1,
          repeatUnit: report.schedule.frequency || "week",
          endDate: report.schedule.endDate ? report.schedule.endDate : undefined,          
          pattern: report.schedule.pattern || "byDate",
          monthlyYearlyDay: report.schedule.monthlyYearlyDay || "",
        });

  
        apiV2
          .get(`/v2/users/${report.recipientID}`)
          .then((response) => {
            const user = response.data;
            const fullName = `${user.profile.firstName || ""} ${user.profile.lastName || ""}`;
            setUserName(fullName.trim());
          })
          .catch((err) => {
            console.error("Error fetching user:", err);
            setUserName("Unknown User");
          });
  
        setIsScheduleOverlayOpen(true);
      }
    } else {
      // For Regular Reports
      setSelectedReport(report as Report);
      setSelectedScheduledReport(null);
      setSelectedReportName(report.reportName || "");
      setSelectedReportID(report.id || "");
      setSelectedReportTimeframe(report.timeframe || "");
      setSelectedReportClientName(report.clientName || "");
      setSelectedReportDepartments(report.departmentIDs || []);
      setSelectedReportStartDate(report.startDate || "");
      setSelectedReportEndDate(report.endDate || "");
      setSelectedReportClientID(report.clientID || "");
  
      // Set Departments
      setDepartments(
        (report.departmentIDs || []).map((id, index) => ({
          id,
          name: report.departmentNames?.[index] || "Unknown",
        }))
      );
  
      // Set Data Source Departments
      setDataSourceDepartments(
        (report.dataSourceDepartmentIDs || []).map((id, index) => ({
          id,
          name: report.dataSourceDepartmentNames?.[index] || "Unknown",
        }))
      );
  
      if (action === "schedule") {
        setIsEditSchedule(false);
        setScheduleType("file");
        setFrequency("");
        setTime("");
        setStartDate("");
        setEndDate("");
        setDaysOfWeek([]);
        setUserName("");
        setSelectedReportID(report.id || ""); // Ensure this is always set
        setIsScheduleOverlayOpen(true); // Open the overlay
      }
    }
  }


  function handleNameClick(e: React.MouseEvent<HTMLElement>, report: Report | ScheduledReport) {
    e.stopPropagation();
    if (activeTab === "all") {
      setSelectedReport(report as Report);
      setSelectedScheduledReport(null);
      setSelectedReportName(report.reportName || "");
      setSelectedReportID((report as Report).id || "");
      setSelectedReportTimeframe((report as Report).timeframe || "");
      setSelectedReportClientName(report.clientName || "");
      setSelectedReportDepartments((report as Report).departmentIDs || []);
      setSelectedReportStartDate((report as Report).startDate || "");
      setSelectedReportEndDate((report as Report).endDate || "");
      setSelectedReportClientID((report as Report).clientID || "");
      setSelectedTemplate({ title: report.reportName });
      if (report.ownedByUserID == user?.sub) {
        setIsEditOverlayOpen(true);
        return
      }
      setCloneCustomRangeStart("");
      setCloneCustomRangeEnd("");
      setCloneReportName("Cloned Report - " + report.reportName || "");
      setIsCloneOverlayOpen(true);
    } else {
      setSelectedScheduledReport(report as ScheduledReport);
      setSelectedReport(null);
      setSelectedReportName(report.reportName || "");
      setSelectedReportID((report as ScheduledReport).customReportID || "");
      setSelectedReportClientName(report.clientName || "");
      setSelectedReportClientID(report.clientID || "");
      setIsEditSchedule(true);
      setFrequency((report as ScheduledReport).schedule.frequency || "");
      setTime((report as ScheduledReport).schedule.time || "");
      setStartDate((report as ScheduledReport).schedule.startDate || "");
      setEndDate((report as ScheduledReport).schedule.endDate || "");
      setDaysOfWeek((report as ScheduledReport).schedule.daysOfWeek || []);
      setIsScheduleOverlayOpen(true);
    }
  }
  
  


  function handleMenuClose() {
    setAnchorEl(null)
    setSelectedReport(null)
  }

  async function handleScheduleClick() {
    try {
      setIsScheduleOverlayOpen(true)
        setScheduleType("file"); 
        setFrequency("");
        setTime("");
        setStartDate("");
        setEndDate("");
        setDaysOfWeek([]);
        setRecurrence({
          startDate: "",
          selectedDays: [],
          frequency: "custom",
          repeatEvery: 1,
          repeatUnit: "week",
          endDate: undefined,
          pattern: "",
          monthlyYearlyDay: "",
        });
        setSelectedUser(undefined);
    } finally {
      handleMenuClose()
    }
  }

  function closeScheduleOverlay() {
    setIsScheduleOverlayOpen(false)
  }

  function handleEditClick() {
    if (selectedReport) {
      setSelectedTemplate({ title: selectedReportName })
      setIsEditOverlayOpen(true)
    }
    handleMenuClose()
  }




  function handleCloneClick() {
    if (selectedReport) {
      setCloneReportName("Cloned Report - " + selectedReport.reportName + " Copy");
      setCloneCustomRangeStart("");
      setCloneCustomRangeEnd("");
      setIsCloneOverlayOpen(true);
    }
    handleMenuClose();
  }


  function closeEditOverlay() {
    setIsEditOverlayOpen(false)
    setNewReportID(null)
    setNewReportName("")
  }



  function handleAddToDashboardClick() {
    setActiveSidebar("dashboard")
    handleMenuClose()
  }

  function handleExportClick() {
    setActiveSidebar("export")
    handleMenuClose()
  }


  function handleDeleteClick() {
    if (selectedReport) {
      setReportToDelete({ id: selectedReportID, name: selectedReportName });
      setIsDeleteDialogOpen(true);
    }
    handleMenuClose();
  }
  async function confirmDeleteReport() {
    if (!reportToDelete) return;

    try {
      if (selectedScheduledReport) {
        await apiV2.post(`/reporting/delete-scheduled-report?scheduledReportID=${reportToDelete.id}`);
      } else {
        await apiV2.post(`/reporting/delete-custom-report?customReportID=${reportToDelete.id}`);
      }

      setIsDeleteDialogOpen(false);
      setReportToDelete(null);
      refreshReports();
    } catch (error) {
      console.error("Failed to delete report:", error);
    }
  }


  function cancelDeleteReport() {
    setIsDeleteDialogOpen(false);
    setReportToDelete(null);
  }


  function handleFilterOpen(e: React.MouseEvent<HTMLElement>) {
    setFilterAnchorEl(e.currentTarget)
  }

  function handleFilterClose() {
    setFilterAnchorEl(null)
  }

  function handleFilterChange(type: string) {
    setSelectedFilterTypes((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
    )
  }

  const currentData = activeTab === "all" ? reports : scheduledReports
  const currentError = activeTab === "all" ? error : scheduledError
  const currentLoading = activeTab === "all" ? loading : scheduledLoading
  const currentPage = activeTab === "all" ? currentPageAll : currentPageScheduled
  const setCurrentPageFn = activeTab === "all" ? setCurrentPageAll : setCurrentPageScheduled



  async function scheduleReport(
    api: any,
    reportID: string,
    reportName: string,
    schedule: {
      frequency: string;
      repeatEvery: number;
      time: string;
      scheduleTimezone: string;
      startDate: string;
      endDate?: string;
      daysOfWeek: string[];
      pattern?: string;
      monthlyYearlyDay?: string,
    },
    scheduleType: string,
    dataSourceTimezone: string,
    userID: string | undefined,
    isEdit: boolean,
    scheduledReportID?: string
  ) {
    setValidationError("");
    setEndDateError("");
  
    if (!schedule.startDate || !schedule.time) {
      setValidationError("Start Date and Time are required");
      return false;
    }
  
    const selectedStartDate = new Date(`${schedule.startDate}T${schedule.time}`);
    if (isNaN(selectedStartDate.getTime())) {
      setValidationError("Invalid Start Date or Time");
      return false;
    }
  
    const currentDate = new Date();
    if (selectedStartDate < currentDate) {
      setValidationError("Start Time must not be in the past");
      return false;
    }
  
    let endDateFormatted = "";
    if (schedule.endDate) {
      const selectedEndDate = new Date(schedule.endDate);
      if (isNaN(selectedEndDate.getTime())) {
        setEndDateError("Invalid End Date");
        return false;
      }
      if (selectedEndDate <= selectedStartDate) {
        setEndDateError("End Date must be greater than Start Date");
        return false;
      }
      endDateFormatted = selectedEndDate.toISOString().split("T")[0];
    }
  
    const [hours, minutes] = schedule.time.split(":").map(Number);
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    const startDateFormatted = selectedStartDate.toISOString().split("T")[0];
  
    const payload = {
      customReportID: reportID,
      reportName,
      schedule: {
        frequency: schedule.frequency,
        repeatEvery: schedule.repeatEvery,
        time: formattedTime,
        scheduleTimezone: schedule.scheduleTimezone,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
        daysOfWeek: schedule.daysOfWeek,
        pattern: schedule.pattern,
        monthlyYearlyDay: schedule.monthlyYearlyDay,
      },
      scheduleType,
      dataSourceTimezone,
      recipientID: userID,
      ...(isEdit && scheduledReportID ? { id: scheduledReportID } : {}),
    };
  
    const endpoint = isEdit ? "/reporting/update-scheduled-report" : "/reporting/schedule-report-new";
  
    try {
      const method = isEdit ? "patch" : "post";
      await api[method](endpoint, payload);
      if (activeTab === "scheduled") loadScheduledReports();
      return true;
    } catch (error) {
      console.error("Error scheduling report:", error);
      return false;
    }
  }
  

  
  const menuItemsAll = (
    <>
      {selectedReport && selectedReport.ownedByUserID === authData?.userID && (
        <MenuItem onClick={handleEditClick}>
          <Edit fontSize="small" className="mr-1" /> Edit
        </MenuItem>
      )}
      <MenuItem onClick={() => handleScheduleClick()}>
        <ScheduleIcon fontSize="small" className="mr-1" /> Schedule
      </MenuItem>
      <MenuItem onClick={handleAddToDashboardClick}>
        <DashboardIcon fontSize="small" className="mr-1" /> Add to Dashboard
      </MenuItem>
      <MenuItem onClick={handleCloneClick}>
        <ContentCopy fontSize="small" className="mr-1" /> Clone
      </MenuItem>
      <MenuItem onClick={handleExportClick}>
        <FileDownload fontSize="small" className="mr-1" /> Export
      </MenuItem>
      {selectedReport && selectedReport.ownedByUserID === authData?.userID && (
        <MenuItem onClick={handleDeleteClick}>
          <Delete fontSize="small" className="mr-1" /> Delete
        </MenuItem>
      )}
    </>
  );

  const menuItemsScheduled = (
    <>
      {selectedScheduledReport &&
        selectedScheduledReport.createdByUserID === authData?.userID && (
          <>
            <MenuItem
              onClick={(e) => {
                handleMenuOpen(e, selectedScheduledReport, "editSchedule");
                handleMenuClose();
              }}
            >
              <Edit fontSize="small" className="mr-1" /> Edit
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleMenuOpen(e, selectedScheduledReport, "delete");
                handleMenuClose();
              }}
            >
              <Delete fontSize="small" className="mr-1" /> Delete
            </MenuItem>
          </>
        )}
    </>
  );





  return (
    <div className="bg-gray-50 relative h-full pb-1">
      <div className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-5 px-4 sm:px-6 lg:px-8">
          <h1 className="text-xl font-bold text-gray-900">Reports</h1>
        </div>
      </div>

      <div className="overflow-y-auto h-full">
        <div className="max-w-7xl mx-auto py-6   sm:px-6 lg:px-8">
          <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center">
            <div className="flex gap-2">
              <button
                className={`px-4 py-2 rounded-lg font-medium ${activeTab === "all"
                  ? "bg-blue text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                onClick={() => handleTabChange("all")}
              >
                All Reports
              </button>
              <button
                className={`px-4 py-2 rounded-lg font-medium ${activeTab === "scheduled"
                  ? "bg-blue text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                onClick={() => handleTabChange("scheduled")}
              >
                Scheduled Reports
              </button>
            </div>
            <button
              className="px-2 text-blue border border-2 border-blue py-1 rounded-sm font-medium bg-gray-200 text-xs hover:bg-gray-300 ml-auto"
             onClick={() => navigate("/reporting/create-new-report", { state: { from: location.pathname } })}
            >
              Create new Report

            </button>
            
          </div>

          {currentLoading ? (
            <div className="flex justify-center items-center h-64">
              <CircularProgress color="primary" />
            </div>
          ) : currentError ? (
            <div className="text-red-500 text-center">{currentError}</div>
          ) : activeTab === "all" ? (
            <ReportsTableAll
              data={reports.filter((r) => !selectedFilterTypes.length || selectedFilterTypes.includes(r.filterType))}
              handleMenuOpen={handleMenuOpen}
              handleNameClick={handleNameClick}
              filterAnchorEl={filterAnchorEl}
              handleFilterOpen={handleFilterOpen}
              handleFilterClose={handleFilterClose}
              FILTER_TYPES={FILTER_TYPES}
              selectedFilterTypes={selectedFilterTypes}
              handleFilterChange={handleFilterChange}
            />
          ) : (
            <ReportsTableScheduled
              data={scheduledReports}
              handleMenuOpen={handleMenuOpen}
              handleNameClick={handleNameClick}
            />
          )}


          </div>
        </div>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {activeTab === "all" ? menuItemsAll : menuItemsScheduled}
        </Menu>

        {isScheduleOverlayOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black bg-opacity-50" />
            <div className="relative bg-white overflow-y-auto h-4/5 mt-20 w-11/12 max-w-5xl p-6 md:p-8 rounded-lg shadow-xl z-10">
              <button
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                onClick={closeScheduleOverlay}
              >
                <Close className="w-6 h-6" />
              </button>

              {/* Header Section */}
              <div className="text-center justify-center mb-4 flex">
                <h2 className="text-medium font-semibold px-2 text-gray-800">
                  {selectedScheduledReport && isEditSchedule
                    ? "Edit Scheduled Report: "
                    : "Schedule Report Email: "}
                </h2>
                <h3 className="text-medium text-gray-600">
                  <span className="text-blue-600">{selectedReportName}</span>
                </h3>
              </div>

              <div>
                <label className="block text-sm flex justify-center font-medium text-gray-700">
                  Attachment Type
                </label>
                <div className="flex items-center justify-between bg-light-light-grey p-3 mx-60 px-4 rounded-md">
                  <label className="flex items-center space-x-3 cursor-pointer">
                    <input
                      type="radio"
                      name="attachmentType"
                      value="file"
                      checked={scheduleType === "file"}
                      onChange={() => setScheduleType("file")}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <span className="text-gray-700 text-sm">File Attachment</span>
                  </label>
                  <label className="flex items-center space-x-3 cursor-pointer">
                    <input
                      type="radio"
                      name="attachmentType"
                      value="link"
                      checked={scheduleType === "link"}
                      onChange={() => setScheduleType("link")}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <span className="text-gray-700 text-sm">Email Download Link</span>
                  </label>
                </div>
              </div>

              {/* Two-Column Layout */}
              <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-8">
                {/* Left Column */}
                <div className="space-y-2">
                  {/* Frequency */}

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Scheduled Email Timezone
                    </label>
                    <div className="bg-white p-2 mb-2 text-xs rounded shadow text-grey flex flex-col gap-4">
                      {/* Country Select */}
                      <CountrySelectSingle
                        value={scheduleTimezone.countryISO} // Current countryISO
                        setValue={(newCountryISO) => {
                          if (newCountryISO) {
                            console.log("Country ISO updated for Schedule:", newCountryISO);

                            const timezoneOptions = getTimezoneOptions(newCountryISO);

                            setScheduleTimezone((prev) => ({
                              ...prev,
                              countryISO: newCountryISO, // Update the country
                              timezone: timezoneOptions.length > 0 ? timezoneOptions[0].value : "", // Default to the first timezone if available
                            }));
                          }
                        }}
                        label={"Country"}
                      />


                      <BasicSelect
                        label={"Timezone"}
                        options={getTimezoneOptions(scheduleTimezone.countryISO)} // Timezone options for the selected country
                        value={scheduleTimezone.timezone} // Current timezone
                        onChange={(newTimezone) => {
                          if (newTimezone) {
                            console.log("Timezone selected for Schedule:", newTimezone);
                            setScheduleTimezone((prev) => ({
                              ...prev,
                              timezone: newTimezone, // Update timezone when user selects it
                            }));
                          }
                        }}
                        useEmptyOption={false}
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Data Source Timezone
                    </label>
                    <div className="bg-white p-2 mb-2 text-xs rounded shadow text-grey flex flex-col gap-4">
                      {/* Country Select */}
                      <CountrySelectSingle
                        value={dataSourceTimezone.countryISO} // Use the initial value from dataSourceTimezone.countryISO
                        setValue={(newCountryISO) => {
                          if (newCountryISO) {
                            setDataSourceTimezone((prev) => ({
                              ...prev,
                              countryISO: newCountryISO, // Update the countryISO in the dataSourceTimezone state
                              timezone: "", // Reset timezone since the country changed
                            }));
                          }
                        }}
                        label={"Country"}
                      />

                      {/* Timezone Select */}
                      <BasicSelect
                        label={"Timezone"}
                        options={getTimezoneOptions(dataSourceTimezone.countryISO)} // Fetch timezone options based on countryISO
                        value={dataSourceTimezone.timezone} // Use the initial value from dataSourceTimezone.timezone
                        onChange={(newTimezone) => {
                          if (newTimezone) {

                            setDataSourceTimezone((prev) => ({
                              ...prev,
                              timezone: newTimezone, 
                            }));
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="pt-2 text-sm">
                    {selectedScheduledReport && isEditSchedule ? (
                      <div></div>
                    ) : (
                      <>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                      Recipient
                    </label>
                      <UserSelectSingle
                        user={selectedUser}
                        onChange={(user) => setSelectedUser(user)}
                        clientID={
                          authData?.accessLevel === "client" ? authData?.clientID : null
                        }
                        departmentID={
                          authData?.accessLevel === "department"
                            ? authData?.departmentID
                            : null
                        }
                        disabled={false}
                      />
                      </>
                    )}
                  </div>
                </div>

                <div className="space-y-4">
                <div>
                    <label className="block text-sm text-sm text-gray-700 mb-2">
                      Time <span className="text-xs">({scheduleTimezone.timezone})</span>
                    </label>
                    <input
                      type="time"
                      className="w-full border border-gray-300 p-1.5 text-xs rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                    />
                  </div>
                <RecurrencePicker recurrence={recurrence} onChange={handleRecurrenceChange} />
                </div>
              </div>

              <div className="absolute bottom-4 right-4 flex justify-end mt-4 space-x-3">
                <button
                  onClick={closeScheduleOverlay}
                  className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  onClick={async () => {
                    console.log("Schedule Timezone before API call:", scheduleTimezone);
                    console.log("Data Source Timezone before API call:", dataSourceTimezone);

                    if (!scheduleTimezone.timezone || !dataSourceTimezone.timezone) {
                      console.error("Timezone is missing in the state");
                      return;
                    }

                    const success = await scheduleReport(
                      apiV2,
                      selectedReportID,
                      selectedReportName,
                      {
                        frequency: recurrence.repeatUnit,
                        repeatEvery: recurrence.repeatEvery,
                        time,
                        startDate: recurrence.startDate,
                        endDate: recurrence.endDate || "",
                        daysOfWeek: recurrence.selectedDays,
                        scheduleTimezone: scheduleTimezone.timezone,
                        pattern: recurrence.pattern,
                        monthlyYearlyDay: recurrence.monthlyYearlyDay,
                      },
                      scheduleType,
                      dataSourceTimezone.timezone,
                      selectedUser?.id,
                      !!selectedScheduledReport && isEditSchedule,
                      selectedScheduledReport?.id
                    );
                
                    if (success) {
                      closeScheduleOverlay();
                    }
                  }}
                  className="px-4 py-2 bg-blue text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none"
                >
                  {selectedScheduledReport && isEditSchedule
                    ? "Update Schedule"
                    : "Create Schedule"}
                </button>
              </div>
              <div className="mt-6 text-center space-y-2">
                {validationError && (
                  <span className="text-sm text-red">{validationError}</span>
                )}
                {endDateError && (
                  <span className="text-sm text-red">{endDateError}</span>
                )}
              </div>
            </div>
          </div>
        )}


        {isDeleteDialogOpen && reportToDelete && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50" />
            <div className="bg-white rounded-lg shadow-lg p-6 z-10">
              <h2 className="text-lg font-semibold text-gray-800 mb-4">
                Are you sure you want to delete{" "}
                {selectedScheduledReport
                  ? `Scheduled Report for ${reportToDelete.name}`
                  : reportToDelete.name}?
              </h2>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={cancelDeleteReport}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDeleteReport}
                  className="px-4 py-2 bg-red text-white rounded-lg hover:bg-red-700"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}


        {isCloneOverlayOpen && (
          <EditOverlay
            closeEditOverlay={() => setIsCloneOverlayOpen(false)}
            reportName={cloneReportName}
            setReportName={setCloneReportName}
            customRangeStart={cloneCustomRangeStart}
            setCustomRangeStart={setCloneCustomRangeStart}
            customRangeEnd={cloneCustomRangeEnd}
            setCustomRangeEnd={setCloneCustomRangeEnd}
            initialTimeframe={selectedReportTimeframe}
            clientID={selectedReportClientID}
            clientName={selectedReportClientName}
            customReportID={selectedReportID}
            departments={departments}
            dataSourceDepartments={dataSourceDepartments}
            onSave={refreshReports}
            isClone={true}
          />
        )}

        {isEditOverlayOpen && newReportID && newReportName && stateLoaded && (
        <NewReportEditOverlay
          closeEditOverlay={closeEditOverlay}
          reportName={newReportName}
          setReportName={setNewReportName}
          customRangeStart={customRangeStart}
          setCustomRangeStart={setCustomRangeStart}
          customRangeEnd={customRangeEnd}
          setCustomRangeEnd={setCustomRangeEnd}
          initialTimeframe={initialTimeframe}
          clientName={newClientName}
          clientID={newClientID}
          customReportID={newReportID}
          dataSourceDepartments={newDataSourceDepartments}
          onSave={refreshReports}
          allUsers={newAllUsers}
        />
      )}





        {isEditOverlayOpen && selectedTemplate && (
          <EditOverlay
            closeEditOverlay={closeEditOverlay}
            reportName={selectedReportName}
            setReportName={setSelectedReportName}
            customRangeStart={customRangeStart}
            setCustomRangeStart={setCustomRangeStart}
            customRangeEnd={customRangeEnd}
            clientID={selectedReportClientID}
            setCustomRangeEnd={setCustomRangeEnd}
            customReportID={selectedReportID}
            initialTimeframe={selectedReportTimeframe}
            clientName={selectedReportClientName}
            departments={departments}
            dataSourceDepartments={dataSourceDepartments}
            onSave={refreshReports}
            isClone={false}
          />
        )}


        <AnimatedDashboardSelectorSidebar
          isVisible={activeSidebar === "dashboard"}
          onClose={() => setActiveSidebar(null)}
          reportName={selectedReportName}
          reportID={selectedReportID}
          onAddToDashboard={() => setActiveSidebar(null)}
        />

        <AnimatedExportSidebar
          isVisible={activeSidebar === "export"}
          onClose={() => setActiveSidebar(null)}
          reportName={selectedReportName}
          clientName={selectedReportClientName}
          departments={selectedReportDepartments}
          timeframe={selectedReportTimeframe}
          startDate={selectedReportStartDate}
          endDate={selectedReportEndDate}
          reportQueryID={selectedReportID}
        />


        <style>
          {`
          @keyframes zoom-in {
            0% {
              opacity: 0;
              transform: scale3d(0.7, 0.7, 0.7);
            }
            100% {
              opacity: 1;
              transform: scale3d(1, 1, 1);
            }
          }
        `}
        </style>
      </div>
    </div>
  )
}
