import React, { useEffect, useState } from "react";
import { apiV2 } from "../../../../../api/axiosConfig"; // Adjust the import path as needed
import Icon from "@mdi/react";
import { mdiCheckCircleOutline, mdiMicrosoftExcel } from "@mdi/js";
import { CircularProgress } from "@mui/material";
import { useTimeZoneSelectContext } from "../../../../../providers/TimeZoneSelectProvider";

interface AnimatedExportSidebarProps {
  isVisible: boolean;
  onClose: () => void;
  reportName: string;
  clientName: string;
  reportQueryID: string;
  timeframe: string;
  startDate?: string;
  endDate?: string;
  departments: string[];
}

const AnimatedExportSidebar: React.FC<AnimatedExportSidebarProps> = ({
  isVisible,
  onClose,
  reportName,
  clientName,
  timeframe,
  startDate,
  endDate,
  departments,
  reportQueryID,
}) => {
  const [isExporting, setIsExporting] = useState(false);
  const [success, setSuccess] = useState(false);
  const tzCtx = useTimeZoneSelectContext()
  const timezone = tzCtx.timeZone
   
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  const formattedTimeframe = (() => {
    const formatDate = (dateString: string | undefined) => {
      if (!dateString) return "Invalid Date"; 
      const [year, month, day] = dateString.split("-"); 
      return `${day}/${month}/${year}`; 
    };
    switch (timeframe) {
      case "ytd":
        return "Year To Date"
        case "mtd":
        return "Month To Date"
      case "past30":
        return "Past 30 Days";
      case "past7":
        return "Past 7 Days";
      case "past1":
        return "Past Day";
      default:
        return `${formatDate(startDate)} - ${formatDate(endDate)}`;

    }
  })();

  const handleExport = async () => {
    setIsExporting(true);
    setSuccess(false);
  
    try {

      const currentDate = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
      const formattedReportName = reportName.toLowerCase().replace(/\s+/g, "_"); 
      const fileName = `${formattedReportName}_${currentDate}.xlsx`;


      const response = await apiV2.get(
        `/reports/download/${reportQueryID}`, 
        {
          params: {
            timezone, 
          },
          responseType: "blob",
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to export report");
      }
      const contentDisposition = response.headers["content-disposition"];
    let downloadFileName = fileName; 

    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?(.+)"?/);
      if (match && match[1]) {
        downloadFileName = match[1];
      }
    }

      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", downloadFileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
  
      setSuccess(true);
  
      setTimeout(() => {
        setIsExporting(false);
        setSuccess(false);
        onClose();
      }, 500);
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "Failed to export report. Please try again.";
      alert(errorMessage);
      console.error("Export Error:", error);
      setIsExporting(false);
    }
  };
  

  return (
    <>
      <style>
        {`
          .animated-export-sidebar {
            position: fixed;
            top: 0;
            right: 0;
            height: 100%;
            width: 380px;
            max-width: 80%;
            background-color: #ffffff;
            box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
            z-index: 50;
            transform: translateX(100%);
            transition: transform 0.3s ease-in-out;
            display: flex;
            flex-direction: column;
          }

          .animated-export-sidebar.visible {
            transform: translateX(0);
          }

          .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 40;
            opacity: 1;
            transition: opacity 0.3s ease;
          }

          .overlay-hidden {
            opacity: 0;
            pointer-events: none;
          }

          .sidebar-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            border-bottom: 1px solid #e5e7eb;
          }

          .sidebar-content {
            padding: 16px;
            overflow-y: auto;
            flex-grow: 1;
          }

          .close-button {
            background: none;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
            color: #ef4444;
          }

          @media (max-width: 768px) {
            .animated-export-sidebar {
              width: 100%;
            }
          }
        `}
      </style>

      {isVisible && (
        <div
          className={`overlay ${isVisible ? "" : "overlay-hidden"}`}
          onClick={onClose}
          aria-hidden="true"
        />
      )}

      <div
        className={`animated-export-sidebar ${isVisible ? "visible" : ""}`}
        aria-hidden={!isVisible}
      >
        <div className="sidebar-header">
          <h2 className="text-lg mt-32 font-bold text-gray-800">
            Export Report: {reportName}
          </h2>
        </div>
        <div className="sidebar-content p-6 bg-white shadow-md flex flex-col">
          <ul className="list-disc pl-5 pb-8 space-y-2 text-sm text-gray-600">
            <li>
              <strong>Client:</strong> {clientName}
            </li>
            <li>
              <strong>Departments:</strong> {departments.length}
            </li>
            <li>
              <strong>Timeframe:</strong> {formattedTimeframe}
            </li>
          </ul>
          <div>
            <button
              className={`w-full bg-blue text-white py-2 px-4 rounded flex items-center justify-center gap-2 hover:bg-blue-600 disabled:bg-gray-400 ${
                isExporting ? "cursor-not-allowed" : ""
              }`}
              onClick={handleExport}
              disabled={isExporting}
              style={{ minHeight: "2.5rem" }}
            >
              {isExporting ? (
                success ? (
                  <Icon path={mdiCheckCircleOutline} size={1} color="white" />
                ) : (
                  <CircularProgress size={20} color="inherit" />
                )
              ) : (
                <>
                  <span>Export Spreadsheet</span>
                  <Icon path={mdiMicrosoftExcel} size={1} color="white" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimatedExportSidebar;
