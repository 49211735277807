import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";
import { apiV2 } from "../../../../../api/axiosConfig";
import WhiteLogoImage from "../../../../../assets/icons/ngs-white-blue.svg";

const ReportFileDownload: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [key, setKey] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileID, setFileID] = useState("");
  const [reportID, setReportID] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const urlKey = queryParams.get("key");

    if (urlKey) {
      setKey(urlKey);
      handleTest2(urlKey);
    } else {
      const defaultKey = "default-report-id";
      navigate(`/?key=${defaultKey}`, { replace: true });
    }
  }, [location.search, navigate]);

  const handleDownload = async () => {
    if (!fileID) {
      console.error("File ID is required for download");
      return;
    }

    setIsLoading(true);
    try {
      const response = await apiV2.get(`/reports/download-external/${reportID}`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log("Download successful");
    } catch (error) {
      console.error("Error during download:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTest2 = async (testKey: string) => {
    if (!testKey) {
      console.error("Key is required");
      return;
    }

    setTestLoading(true);
    try {
      const response = await apiV2.get(`/reports/verify/${testKey}`);
      setFileName(response.data.fileName);
      setFileID(response.data.fileID);
      setReportID(response.data.scheduledReportID);
      console.log("Test 2 response:", response.data);
    } catch (error) {
      console.error("Test 2 error:", error);
    } finally {
      setTestLoading(false);
    }
  };

  return (
    <div className="relative w-screen h-screen bg-gradient-to-bl from-[#2D166F_50%] to-[#007584] flex flex-col justify-center items-center">
      <div>
        <img src={WhiteLogoImage} alt="logo of the company" className="h-54 mb-2" />
      </div>
      <div className="text-white text-xl font-semibold mb-8 text-center">
          Your Report &quot;{fileName}&quot; is ready:
        </div>
      <button
          className="bg-[#6BE8FA] focus:bg-[#67D1E0] hover:bg-[#66C4D1] disabled:bg-[#75C6D1] px-6 py-3 rounded-full shadow-md transition flex items-center justify-center gap-2 text-[#2D166F] text-lg font-bold"
          onClick={handleDownload}
          disabled={isLoading || !fileID}
        >
          {isLoading ? (
            <CircularProgress size={24} style={{ color: "#2D166F" }} />
          ) : (
            <>
              <span>Download</span>
              <DownloadIcon />
            </>
          )}
        </button>
    </div>
  );
};

export default ReportFileDownload;
