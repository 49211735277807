import {ClassNamesConfig, StylesConfig} from "react-select";
import clsx from "clsx";

export const reactSelectClassNames: ClassNamesConfig = {
    control: (state) => {
        return clsx(
            "min-h-10 w-full px-4 shadow-md rounded-md outline-none bg-white flex", {
                "outline outline-blue": state.isFocused,
                "max-h-20": !state.isMulti
            }
        )

    },
    valueContainer: (state) => {
        return ""
    }
}

export const reactSelectStyles: StylesConfig = {
    control: () => ({
        "border": "none",
    }),
    menu: (def) => ({
        ...def,
        "position": "absolute",
        "width": "500px",
        "max-width": "100vw"
    })
}
