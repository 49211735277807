import React, { useRef } from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import AudioAlert from "../../../../assets/audio/alert.mp3";
import useAlarm from "../../hooks/useAlarm";
import {useNavigate} from "react-router-dom";

function Header({
  text,
  sidebarType,
}: {
  text: string;
  sidebarType: "non-emergency" | "emergency";
}) {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { setAlarm, alarm, alwaysMuteAlarm } = useAlarm(audioRef);
  const navigate = useNavigate();

  return (
    <header className="uppercase text-sm font-semibold text-grey border-b-4 border-grey border-opacity-50 pb-6 flex items-center justify-between px-6">
      {sidebarType === "emergency" ? (
        <audio loop ref={audioRef}>
          <source src={AudioAlert} type="audio/mp3" />
        </audio>
      ) : null}
      {text}
      {sidebarType === "emergency" && !alwaysMuteAlarm && (
        <button
          onClick={() => {
            setAlarm(false);
          }}
        >
          {alarm ? <VolumeUpIcon /> : <VolumeOffIcon />}
        </button>
      )}
      {sidebarType === "emergency" && alwaysMuteAlarm && (
          <button onClick={() => navigate("?mute=0")}>Disable Mute</button>
      )}
    </header>
  );
}

export default Header;
