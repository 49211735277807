import React, {useCallback, useEffect, useMemo, useState} from "react";
import Layout from "../layout/Layout";
import {apiV2} from "../../../api/axiosConfig";
import BasicText, {BasicTextArea} from "../../../components/form-v2/basic-text";
import {BlueButton} from "../../../components/ui/Buttons";
import {useNavigate, useParams} from "react-router-dom";
import {isAxiosError} from "axios";
import {UserEmergencyContact, UserPassport} from "../../../types/user-data-types";
import DepartmentSelect, {DepartmentSelectSingle} from "../../../components/form-v2/searchable-select/DepartmentSelect";
import {AlternativeIDs, Client, Department, Roles} from "../../../types/userManagement";
import {GenderSelect, RealGenderSelect} from "../../../components/form-v2/searchable-select/GenderSelect";
import {FacilitySelectSingle} from "../../../components/form-v2/searchable-select/FacilitySelect";
import {FacilityDataTypes} from "../../../types/facilitiy-data-types";
import {LanguageSelect, LanguageSelectMultiple} from "../../../components/form-v2/searchable-select/LanguageSelect";
import {NationalitySelect} from "../../../components/form-v2/searchable-select/NationalitySelect";
import {Metadata} from "./components/Metadata";
import {CountrySelectSingle} from "../../../components/form-v2/searchable-select/CountrySelectSingle";
import {Medication, UserModel} from "./types/UserModel";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import moment from "moment";
import {useAuth} from "../../../context/user";
import {UserImageForm} from "./components/UserImageForm";
import {PhoneInput} from "../../../components/form-v2/phone-input/PhoneInput";
import MultiInputSingleField from "../../../components/form-v2/multi-input/MultiInputSingleField";
import {MultiInputMultiField} from "../../../components/form-v2/multi-input/MultiInputMultiField";
import {DepartmentSelectV2} from "../../../lib/departments/DepartmentSelectV2";
import {DepartmentSelectV2Multi} from "../../../lib/departments/DepartmentSelectV2Multi";
import {TimeZoneSelectProvider, useTimeZoneSelectContext} from "../../../providers/TimeZoneSelectProvider";
import {SelectTimezone} from "../../../lib/timezones/SelectTimezone";
import {RoleSelect} from "../../../lib/roles/RoleSelect";
import {useQuery} from "react-query";
import {useData} from "../../../lib/data/DataContext";


const UserEdit: React.FC = () => {


    const {id} = useParams();
    const {canUseRole} = useData()
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState<UserModel>();
    const auth = useAuth()
    const tzContext = useTimeZoneSelectContext()



    const canEditRole = useMemo(() => {
        if(!userData) return false
        if(userData.id == auth.userID) return false

        return canUseRole(userData.roleID);
    }, [canUseRole, userData]);




    const loadUser = async () => {
        try {
            const res = await apiV2.get<UserModel>(`users/${id}`);
            console.log(res.data)
            const data = res.data;

            setUserData(data);

            setEmail(data.email)
            setFirstName(data.profile.firstName)
            setMiddleName(data.profile.middleNames)
            setLastName(data.profile.lastName)

            if (data.profile.dateOfBirth) {
                const dob = moment.tz(data.profile.dateOfBirth, "UTC")
                if (dob.isValid()) {
                    setDateOfBirth(dob.format("YYYY-MM-DD"))
                } else {
                    setDateOfBirth("")
                }
            }

            setGender(data.profile.gender)
            setGenderAtBirth(data.profile.genderAssignedAtBirth)

            setMainLanguage(data.mainLanguage)
            setSecondaryLanguages(data.secondaryLanguages || [])
            setNationality(data.profile.nationality)
            setCountry(data.profile.countryOfResidence)
            setTimezone(data.profile.timezone)

            setConditions(data.medicalInformationV2.conditions)
            setAllergies(data.medicalInformationV2.allergies)
            setMedications(data.medicalInformationV2.medications)
            setBloodGroup(data.medicalInformationV2.bloodGroup)
            setAdditionalNotes(data.medicalInformationV2.additionalNotes)

            setEmergencyContacts(data.emergencyContacts || [])
            setPassports(data.passports || [])
            setMetadata(data.metadata || [])

            if (data.phoneNumbers.length != 0) {
                setPhone(data.phoneNumbers[0].number)
            }

            if (data.phoneNumbers.length > 1) {
                const numbers = data.phoneNumbers.slice(1)
                setAdditionalPhones(numbers.map(p => p.number))
            }

            setAdditionalEmails(data.additionalEmails)


            setRoleID(data.roleID)


        } catch (e) {
            setErrors(["error loading user"])
        }


        setLoading(false);
    }

    // const [loadedGuardianDepartments, setLoadedGuardianDepartments] = useState(false)

    const loadGuardianDepartments = useCallback(async () => {
        const res = await apiV2.get<Department[]>(`users/${id}/guardian-departments`);
        return res.data

    }, [id])

    const {data: initialGuardianDepartments} = useQuery(
        ["guardian-departments", id],
        loadGuardianDepartments
    )

    useEffect(() => {
        if (!id) {
            return
        }
        loadUser()
    }, [id]);


    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [gender, setGender] = useState<string | undefined>("male");
    const [genderAtBirth, setGenderAtBirth] = useState<string | undefined>("male");
    const [phone, setPhone] = useState("")

    const [additionalEmails, setAdditionalEmails] = useState<string[]>([])
    const [additionalPhones, setAdditionalPhones] = useState<string[]>([])


    const [passports, setPassports] = useState<UserPassport[]>([]);
    const [emergencyContacts, setEmergencyContacts] = useState<UserEmergencyContact[]>([]);
    const [metadata, setMetadata] = useState<Metadata[]>([])


    const [department, setDepartment] = useState<Department>();
    const [guardianDepartments, setGuardianDepartments] = useState<Department[] | null>(null);
    const [roleID, setRoleID] = useState<string>();


    const [homeFacility, setHomeFacility] = useState<FacilityDataTypes>()
    const [stationedFacility, setStationedFacility] = useState<FacilityDataTypes>()

    const [mainLanguage, setMainLanguage] = useState<string>()
    const [secondaryLanguages, setSecondaryLanguages] = useState<string[]>([])
    const [nationality, setNationality] = useState<string>()
    const [country, setCountry] = useState<string>()
    const [timezone, setTimezone] = useState<string>()

    const [conditions, setConditions] = useState<string[]>([])
    const [allergies, setAllergies] = useState<string[]>([])
    const [medications, setMedications] = useState<Medication[]>([])
    const [bloodGroup, setBloodGroup] = useState("")
    const [additionalNotes, setAdditionalNotes] = useState("")

    const [alternativeIDs, setAlternativeIDs] = useState<AlternativeIDs>([])

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState<string[]>([])

    const navigate = useNavigate();

    const [role, setRole] = useState<Roles>()

    const save = useCallback(async () => {
        if (!role && canEditRole) {
            setErrors(["no role selected"])
            return
        }
        setSaving(true);


        const roleID = (() => {
            if (id == auth.userID) {
                return null
            }

            return role?.id || null
        })()


        try {
            await apiV2.patch(`users/${id}`, {
                email,
                firstName,
                middleName,
                lastName,
                dateOfBirth,
                gender,
                genderAtBirth,
                mainLanguage,
                secondaryLanguages,
                nationality,
                countryISO: country,
                homeFacilityID: homeFacility?.id,
                stationedFacilityID: stationedFacility?.id,
                passports,
                emergencyContacts,
                metadata,
                medicalInformationV2: {
                    conditions,
                    allergies,
                    medications,
                    bloodGroup,
                    additionalNotes
                },
                departmentID: department?.id,
                roleID,
                phone,
                additionalPhones,
                additionalEmails,
                guardianDepartmentIDs: guardianDepartments?.map(d => d.id) || [],
                timezone,
                alternativeIDs
            })

        } catch (e) {
            if (isAxiosError(e) && e?.response?.data?.error) {
                setErrors([e.response.data.error]);
            } else if (isAxiosError(e) && e?.response?.data?.errors) {
                setErrors(e.response.data.errors);
            } else {
                setErrors(["An error has occurred"])
            }
            setSaving(false);
            return


        }


        setSaving(false);


        if (auth.isUser) {
            navigate("/")
            return
        }


        if (auth.hasAccessLevel("department")) {
            const params = new URLSearchParams();
            params.set("message", "user has been updated")
            navigate(`/management/users?${params.toString()}`);
            return
        }

        navigate("/")
    }, [
        email,
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        gender,
        genderAtBirth,
        mainLanguage,
        secondaryLanguages,
        nationality,
        country,
        homeFacility,
        stationedFacility,
        passports,
        emergencyContacts,
        department,
        roleID,
        metadata,
        conditions,
        allergies,
        medications,
        bloodGroup,
        additionalNotes,
        phone,
        additionalPhones,
        additionalEmails,
        guardianDepartments,
        timezone,
        role,
        canEditRole,
        alternativeIDs
    ])




    const initialGuardianDepartmentIDs = useMemo(() => {
        return initialGuardianDepartments?.map(d => d.id)
    }, [initialGuardianDepartments])

    const showAccess = useMemo(() => {
        if(auth.hasAccessLevelClient) return true
        if(auth.hasAccessLevelDepartment) return id != auth.userID
        return false


    }, [id, auth.userID, auth.hasAccessLevelClient, auth.hasAccessLevelDepartment])

    const GuardianSelect = useCallback(() => {
        if (!auth.hasAccessLevelClient) return null;
        if (role?.auroraAccessLevel != "department") return null;
        if (!userData?.clientID) return null
        if (!initialGuardianDepartments) return null

        return (
            <div>
                <label className="mb-1">Guardian of Departments</label>

                <DepartmentSelectV2Multi
                    clientID={userData.clientID}
                    initialIDs={initialGuardianDepartmentIDs}
                    onChange={setGuardianDepartments}
                />
            </div>
        )

    }, [auth, role, initialGuardianDepartments, userData?.clientID, auth.hasAccessLevelClient])


    if (loading) {
        return <Layout active={"users"}>
            <div className="flex flex-row gap-8">
                {loading && <div className="bg-white shadow-md p-4 w-1/2 flex flex-col gap-8 items-center">
                    <LoadingSpinner/>
                </div>}
            </div>
        </Layout>
    }

    if (!userData) {
        return <Layout active={"users"}>
            <div className="flex flex-row gap-8">
                {loading && <div className="bg-white shadow-md p-4 w-1/2 flex flex-col gap-8 items-center">
                    <p>Error loading user</p>
                </div>}
            </div>
        </Layout>
    }


    return <Layout active={"users"}>
        <>
            <div className="flex flex-row gap-8 mb-8">
                <div className="flex flex-col gap-8 flex-1">
                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <p className="text-md text-left pb-2 font-semibold">Edit User</p>
                        <BasicText type={"email"} value={email} onChange={(e) => setEmail(e.toLowerCase())}
                                   label="Email Address" disabled={!auth.isSuperAdmin}/>
                        <BasicText value={firstName} onChange={setFirstName} label="First Name"/>
                        <BasicText value={middleName} onChange={setMiddleName} label="Middle Name/s"/>
                        <BasicText value={lastName} onChange={setLastName} label="Last Name"/>
                        <BasicText type="date" value={dateOfBirth} onChange={setDateOfBirth} label="Date of Birth"/>
                        <GenderSelect gender={gender} onChange={setGender}/>
                        <RealGenderSelect gender={genderAtBirth} onChange={setGenderAtBirth}/>
                        <PhoneInput label={"Phone"} onChange={setPhone} required initialValue={phone}/>

                    </div>

                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <p className="text-md text-left pb-2 font-semibold">Demographic</p>
                        <div>
                            <label className="mb-1">Main Language</label>
                            <LanguageSelect value={mainLanguage} setValue={setMainLanguage}/>
                        </div>

                        <div>
                            <label className="mb-1">Secondary Languages</label>
                            <LanguageSelectMultiple value={secondaryLanguages} setValue={setSecondaryLanguages}/>
                        </div>

                        <div>
                            <label className="mb-1">Nationality</label>
                            <NationalitySelect value={nationality} setValue={setNationality}/>
                        </div>

                        <CountrySelectSingle value={country} setValue={setCountry} label={"Country of Residence"}/>


                        <div>
                            <label className="mb-1">Timezone</label>
                            <SelectTimezone countryISO={country} value={timezone} onChange={setTimezone}/>
                        </div>


                    </div>

                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <p className="text-md text-left pb-2 font-semibold">Facilities</p>
                        <div>
                            <label className="mb-1">Home Facility</label>
                            <FacilitySelectSingle clientID={userData.clientID} facility={homeFacility}
                                                  initialFacilityID={userData.homeFacilityID}
                                                  onChange={setHomeFacility}/>
                        </div>

                        <div>
                            <label className="mb-1">Stationed Facility</label>
                            <FacilitySelectSingle clientID={userData.clientID} facility={stationedFacility}
                                                  initialFacilityID={userData.stationedFacilityID}

                                                  onChange={setStationedFacility}/>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-8 flex-1">





                    {showAccess && <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <p className="text-md text-left pb-2 font-semibold">Access</p>

                        <div>
                            <label className="mb-1">Department</label>
                            {(auth.hasAccessLevelClient || (auth.hasAccessLevelDepartment && id != auth.userID)) && (
                                <DepartmentSelectSingle disabled={!canEditRole} department={department} onChange={setDepartment}
                                                        initialDepartmentID={userData.departmentID}
                                                        clientID={userData.clientID}/>
                            )}

                        </div>


                        {canEditRole && <RoleSelect initialRoleID={roleID} onChange={setRole}/>}

                        <GuardianSelect/>


                    </div>}


                    <UserImageForm id={userData.id}/>

                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <MultiInputSingleField placeholder={"Enter Email Address"} label={"Additional Email Addresses"}
                                               initialValues={additionalEmails}
                                               type={"email"}
                                               onChange={setAdditionalEmails}/>
                    </div>


                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <MultiInputSingleField placeholder={"Enter Phone Number"} label={"Additional Phone Numbers"}
                                               initialValues={additionalPhones}
                                               onChange={setAdditionalPhones} type={"phone"}/>
                    </div>


                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <p className="text-md text-left pb-2 font-semibold">Medical Information</p>

                        <MultiInputSingleField label={"Conditions"} placeholder="Enter Condition"
                                               initialValues={conditions} onChange={setConditions}/>
                        <MultiInputSingleField label={"Allergies"} placeholder="Enter Allergy" initialValues={allergies}
                                               onChange={setAllergies}/>
                        <MultiInputMultiField
                            label={"Medications"}
                            initialItems={medications}
                            onChange={setMedications}
                            showLabels={true}
                            columns={2}
                            placeholders={{
                                name: "Name",
                                dose: "Dosage",
                                frequency: "Frequency"
                            }}
                            required={["name"]}
                        />

                        <BasicText value={bloodGroup} onChange={setBloodGroup} label="Blood Type"/>
                        <BasicTextArea value={additionalNotes} onChange={setAdditionalNotes}
                                       label="Additional Notes"/>
                    </div>


                </div>

            </div>

            <div className="flex flex-col gap-8 mb-8">

                <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                    <MultiInputMultiField label={"Passport Details"}
                                          initialItems={passports}
                                          columns={2}
                                          onChange={setPassports}
                                          showLabels
                                          placeholders={{
                                              number: "Passport Number",
                                              expiresAt: "Expires On",
                                              countryISO: "Country",
                                          }}
                                          types={{
                                              number: "text",
                                              expiresAt: "date",
                                              countryISO: "country",
                                          }}
                                          required={[
                                              "number",
                                              "countryISO"
                                          ]}
                    />
                </div>

                <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                    <MultiInputMultiField label={"Emergency Contacts"} initialItems={emergencyContacts} columns={3}
                                          showLabels
                                          onChange={setEmergencyContacts}
                                          placeholders={{
                                              firstName: "First Name",
                                              lastName: "Last Name",
                                              relationship: "Relationship",
                                              email: "Email",
                                              phoneNumber: "Phone Number"
                                          }}
                                          types={{
                                              phoneNumber: "phone",
                                          }}
                                          required={[
                                              "firstName",
                                              "lastName"
                                          ]}
                    />
                </div>


                <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                    <MultiInputMultiField label={"Metadata"} initialItems={metadata} columns={3} onChange={setMetadata}
                                          requireAll
                                          placeholders={{label: "Label", value: "Value"}}/>
                </div>

                {auth.isSuperAdmin && (
                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <MultiInputMultiField label={"External IDs"}
                                              initialItems={alternativeIDs}
                                              columns={3}
                                              onChange={setAlternativeIDs}
                                              placeholders={{
                                                  type: "Type",
                                                  value: "Value",
                                              }}
                                              required={[
                                                  "type",
                                                  "value"
                                              ]}
                        />
                    </div>
                )}



                {auth.isSuperAdmin && (
                    <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                        <p className="text-md text-left pb-2 font-semibold">Activity</p>

                        <div className="grid grid-cols-1 md:grid-cols-2">
                            <div className="grid grid-cols-2">
                                <div className="font-semibold">
                                    Last Login At
                                </div>
                                <div>
                                    {
                                        userData?.status?.lastLoginAt ?
                                            moment.tz(userData.status.lastLoginAt, tzContext.timeZone).format("DD/MM/YYYY HH:mm:ss") :
                                            "N/A"
                                    }
                                </div>

                            </div>

                            <div className="grid grid-cols-2">
                                <div className="font-semibold">
                                    Last App Session
                                </div>
                                <div>
                                    {
                                        (userData.pushNotificationTokenReceivedAt && userData.pushNotificationToken) ? moment.tz(userData.pushNotificationTokenReceivedAt, tzContext.timeZone).format("DD/MM/YYYY HH:mm:ss") :
                                            "N/A"
                                    }
                                </div>

                            </div>

                            {userData.lastKnownLocation && (
                                <>
                            <div className="grid grid-cols-2">
                                <div className="font-semibold">
                                    Last Known Location
                                </div>
                                <div className="grid grid-cols-[auto_1fr] gap-x-4">
                                    <div>
                                        Latitude:
                                    </div>
                                    <div>
                                        {userData.lastKnownLocation.position.coordinates[1]}
                                    </div>
                                     <div>
                                         Longitude:
                                     </div>
                                    <div>
                                        {userData.lastKnownLocation.position.coordinates[0]}
                                    </div>
                                    <div>
                                        Received At:
                                    </div>
                                    <div>
                                        {moment.tz(userData.lastKnownLocation.receivedAt, tzContext.timeZone).format("DD/MM/YYYY HH:mm:ss")}
                                    </div>
                                </div>

                            </div>
                                </>
                            )}

                        </div>

                    </div>
                )}


                <div className="bg-white shadow-md p-4 flex flex-col gap-8">
                    <p className="text-md text-left pb-2 font-semibold">Confirm</p>

                    <BlueButton text={"Update User"} disabled={false} onClick={save} loading={saving}/>
                    <div className="flex flex-col gap-4">
                        {errors && errors.map((e, i) => (
                            <p key={i} className={"text-center text-red"}>{e}</p>
                        ))}
                    </div>
                </div>
            </div>


        </>

    </Layout>

}

UserEdit.displayName = "UserEdit";

export default UserEdit;