import {CountryData} from "./types";

export const countries: CountryData[] = [
    {
        "countryNameEn": "Andorra",
        "countryNameLocal": "Andorra",
        "countryCode": "AD",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ca",
        "officialLanguageNameEn": "Catalan, Valencian",
        "officialLanguageNameLocal": "Català, Valencià",
        "countryCallingCode": "376",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇦🇩"
    },
    {
        "countryNameEn": "Afghanistan",
        "countryNameLocal": "د افغانستان اسلامي دولتدولت اسلامی افغانستان, جمهوری اسلامی افغانستان",
        "countryCode": "AF",
        "currencyCode": "AFN",
        "currencyNameEn": "Afghan afghani",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fa",
        "officialLanguageNameEn": "Persian",
        "officialLanguageNameLocal": "فارسی",
        "countryCallingCode": "93",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇦🇫"
    },
    {
        "countryNameEn": "Antigua and Barbuda",
        "countryNameLocal": "Antigua and Barbuda",
        "countryCode": "AG",
        "currencyCode": "XCD",
        "currencyNameEn": "East Caribbean dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1268",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇦🇬"
    },
    {
        "countryNameEn": "Anguilla",
        "countryNameLocal": "Anguilla",
        "countryCode": "AI",
        "currencyCode": "XCD",
        "currencyNameEn": "East Caribbean dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1264",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇦🇮"
    },
    {
        "countryNameEn": "Albania",
        "countryNameLocal": "Shqipëria",
        "countryCode": "AL",
        "currencyCode": "ALL",
        "currencyNameEn": "Albanian lek",
        "tinType": "NIPT",
        "tinName": "Numri i Identifikimit për Personin e Tatueshëm",
        "officialLanguageCode": "sq",
        "officialLanguageNameEn": "Albanian",
        "officialLanguageNameLocal": "Shqip",
        "countryCallingCode": "355",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇦🇱"
    },
    {
        "countryNameEn": "Armenia",
        "countryNameLocal": "Հայաստան",
        "countryCode": "AM",
        "currencyCode": "AMD",
        "currencyNameEn": "Armenian dram",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "hy",
        "officialLanguageNameEn": "Armenian",
        "officialLanguageNameLocal": "Հայերեն",
        "countryCallingCode": "374",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇦🇲"
    },
    {
        "countryNameEn": "Angola",
        "countryNameLocal": "Angola",
        "countryCode": "AO",
        "currencyCode": "AOA",
        "currencyNameEn": "Angolan kwanza",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "pt",
        "officialLanguageNameEn": "Portuguese",
        "officialLanguageNameLocal": "Português",
        "countryCallingCode": "244",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇦🇴"
    },
    {
        "countryNameEn": "Antarctica",
        "countryNameLocal": "Antarctica, Antártico, Antarctique, Антарктике",
        "countryCode": "AQ",
        "currencyCode": "",
        "currencyNameEn": "",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "672",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇦🇶"
    },
    {
        "countryNameEn": "Argentina",
        "countryNameLocal": "Argentina",
        "countryCode": "AR",
        "currencyCode": "ARS",
        "currencyNameEn": "Argentine peso",
        "tinType": "CUIT",
        "tinName": "Código Único de Identificación Tributaria",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "54",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇦🇷"
    },
    {
        "countryNameEn": "American Samoa",
        "countryNameLocal": "American Samoa",
        "countryCode": "AS",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1684",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇦🇸"
    },
    {
        "countryNameEn": "Austria",
        "countryNameLocal": "Österreich",
        "countryCode": "AT",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "UID",
        "tinName": "Umsatzsteuer-Identifikationsnummer",
        "officialLanguageCode": "de",
        "officialLanguageNameEn": "German",
        "officialLanguageNameLocal": "Deutsch",
        "countryCallingCode": "43",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇦🇹"
    },
    {
        "countryNameEn": "Australia",
        "countryNameLocal": "Australia",
        "countryCode": "AU",
        "currencyCode": "AUD",
        "currencyNameEn": "Australian dollar",
        "tinType": "ABN",
        "tinName": "Australian Business Number",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "61",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇦🇺"
    },
    {
        "countryNameEn": "Aruba",
        "countryNameLocal": "Aruba",
        "countryCode": "AW",
        "currencyCode": "AWG",
        "currencyNameEn": "Aruban florin",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "nl",
        "officialLanguageNameEn": "Dutch, Flemish",
        "officialLanguageNameLocal": "Nederlands, Vlaams",
        "countryCallingCode": "297",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇦🇼"
    },
    {
        "countryNameEn": "Åland Islands",
        "countryNameLocal": "Åland",
        "countryCode": "AX",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "sv",
        "officialLanguageNameEn": "Swedish",
        "officialLanguageNameLocal": "Svenska",
        "countryCallingCode": "358",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇦🇽"
    },
    {
        "countryNameEn": "Azerbaijan",
        "countryNameLocal": "Azərbaycan",
        "countryCode": "AZ",
        "currencyCode": "AZN",
        "currencyNameEn": "Azerbaijani manat",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "az",
        "officialLanguageNameEn": "Azerbaijani",
        "officialLanguageNameLocal": "azərbaycan dili",
        "countryCallingCode": "994",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇦🇿"
    },
    {
        "countryNameEn": "Bosnia and Herzegovina",
        "countryNameLocal": "Bosna i Hercegovina",
        "countryCode": "BA",
        "currencyCode": "BAM",
        "currencyNameEn": "Bosnia and Herzegovina convertible mark",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "bs",
        "officialLanguageNameEn": "Bosnian",
        "officialLanguageNameLocal": "bosanski jezik",
        "countryCallingCode": "387",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇧🇦"
    },
    {
        "countryNameEn": "Barbados",
        "countryNameLocal": "Barbados",
        "countryCode": "BB",
        "currencyCode": "BBD",
        "currencyNameEn": "Barbados dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1246",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇧🇧"
    },
    {
        "countryNameEn": "Bangladesh",
        "countryNameLocal": "গণপ্রজাতন্ত্রী বাংলাদেশ",
        "countryCode": "BD",
        "currencyCode": "BDT",
        "currencyNameEn": "Bangladeshi taka",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "bn",
        "officialLanguageNameEn": "Bengali",
        "officialLanguageNameLocal": "বাংলা",
        "countryCallingCode": "880",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇧🇩"
    },
    {
        "countryNameEn": "Belgium",
        "countryNameLocal": "België, Belgique, Belgien",
        "countryCode": "BE",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "n° TVABTW-nr Mwst-nr",
        "tinName": "BTW identificatienummer / Numéro de TVA",
        "officialLanguageCode": "nl",
        "officialLanguageNameEn": "Dutch, Flemish",
        "officialLanguageNameLocal": "Nederlands, Vlaams",
        "countryCallingCode": "32",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇧🇪"
    },
    {
        "countryNameEn": "Burkina Faso",
        "countryNameLocal": "Burkina Faso",
        "countryCode": "BF",
        "currencyCode": "XOF",
        "currencyNameEn": "CFA franc BCEAO",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "226",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇧🇫"
    },
    {
        "countryNameEn": "Bulgaria",
        "countryNameLocal": "България",
        "countryCode": "BG",
        "currencyCode": "BGN",
        "currencyNameEn": "Bulgarian lev",
        "tinType": "ДДС номер",
        "tinName": "Идентификационен номер по ДДС",
        "officialLanguageCode": "bg",
        "officialLanguageNameEn": "Bulgarian",
        "officialLanguageNameLocal": "български език",
        "countryCallingCode": "359",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇧🇬"
    },
    {
        "countryNameEn": "Bahrain",
        "countryNameLocal": "البحرين",
        "countryCode": "BH",
        "currencyCode": "BHD",
        "currencyNameEn": "Bahraini dinar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "973",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇧🇭"
    },
    {
        "countryNameEn": "Burundi",
        "countryNameLocal": "Burundi",
        "countryCode": "BI",
        "currencyCode": "BIF",
        "currencyNameEn": "Burundian franc",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "257",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇧🇮"
    },
    {
        "countryNameEn": "Benin",
        "countryNameLocal": "Bénin",
        "countryCode": "BJ",
        "currencyCode": "XOF",
        "currencyNameEn": "CFA franc BCEAO",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "229",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇧🇯"
    },
    {
        "countryNameEn": "Saint Barthélemy",
        "countryNameLocal": "Saint-Barthélemy",
        "countryCode": "BL",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "590",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇧🇱"
    },
    {
        "countryNameEn": "Bermuda",
        "countryNameLocal": "Bermuda",
        "countryCode": "BM",
        "currencyCode": "BMD",
        "currencyNameEn": "Bermudian dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1441",
        "areaCodes": [],
        "region": "North America",
        "flag": "🇧🇲"
    },
    {
        "countryNameEn": "Brunei Darussalam",
        "countryNameLocal": "Brunei Darussalam",
        "countryCode": "BN",
        "currencyCode": "BND",
        "currencyNameEn": "Brunei dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ms",
        "officialLanguageNameEn": "Malay",
        "officialLanguageNameLocal": "Bahasa Melayu, بهاس ملايو‎",
        "countryCallingCode": "673",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇧🇳"
    },
    {
        "countryNameEn": "Bolivia (Plurinational State of)",
        "countryNameLocal": "Bolivia, Bulibiya, Volívia, Wuliwya",
        "countryCode": "BO",
        "currencyCode": "BOP",
        "currencyNameEn": "",
        "tinType": "NIT",
        "tinName": "Número de Identificación Tributaria",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "591",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇧🇴"
    },
    {
        "countryNameEn": "Bonaire, Sint Eustatius and Saba",
        "countryNameLocal": "Caribisch Nederland",
        "countryCode": "BQ",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "nl",
        "officialLanguageNameEn": "Dutch, Flemish",
        "officialLanguageNameLocal": "Nederlands, Vlaams",
        "countryCallingCode": "5997",
        "areaCodes": [],
        "region": "Unknown",
        "flag": "🇧🇶"
    },
    {
        "countryNameEn": "Brazil",
        "countryNameLocal": "Brasil",
        "countryCode": "BR",
        "currencyCode": "BRL",
        "currencyNameEn": "Brazilian real",
        "tinType": "CNPJ",
        "tinName": "Cadastro Nacional de Pessoa Jurídica",
        "officialLanguageCode": "pt",
        "officialLanguageNameEn": "Portuguese",
        "officialLanguageNameLocal": "Português",
        "countryCallingCode": "55",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇧🇷"
    },
    {
        "countryNameEn": "Bhutan",
        "countryNameLocal": "འབྲུག་ཡུལ",
        "countryCode": "BT",
        "currencyCode": "BTN",
        "currencyNameEn": "Bhutanese ngultrum",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "dz",
        "officialLanguageNameEn": "Dzongkha",
        "officialLanguageNameLocal": "རྫོང་ཁ",
        "countryCallingCode": "975",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇧🇹"
    },
    {
        "countryNameEn": "Bouvet Island",
        "countryNameLocal": "Bouvetøya",
        "countryCode": "BV",
        "currencyCode": "NOK",
        "currencyNameEn": "Norwegian krone",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "no",
        "officialLanguageNameEn": "Norwegian",
        "officialLanguageNameLocal": "Norsk",
        "countryCallingCode": "47",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇧🇻"
    },
    {
        "countryNameEn": "Botswana",
        "countryNameLocal": "Botswana",
        "countryCode": "BW",
        "currencyCode": "BWP",
        "currencyNameEn": "Botswana pula",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "267",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇧🇼"
    },
    {
        "countryNameEn": "Belarus",
        "countryNameLocal": "Беларусь",
        "countryCode": "BY",
        "currencyCode": "BYR",
        "currencyNameEn": "",
        "tinType": "УНП (UNP)",
        "tinName": "Учетный номер плательщика",
        "officialLanguageCode": "be",
        "officialLanguageNameEn": "Belarusian",
        "officialLanguageNameLocal": "беларуская мова",
        "countryCallingCode": "375",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇧🇾"
    },
    {
        "countryNameEn": "Belize",
        "countryNameLocal": "Belize",
        "countryCode": "BZ",
        "currencyCode": "BZD",
        "currencyNameEn": "Belize dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "501",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇧🇿"
    },
    {
        "countryNameEn": "Canada",
        "countryNameLocal": "Canada",
        "countryCode": "CA",
        "currencyCode": "CAD",
        "currencyNameEn": "Canadian dollar",
        "tinType": "BN / NE",
        "tinName": "Business Number",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1",
        "areaCodes": ["403", "587", "780", "825", "236", "250", "604", "672", "778", "204", "431", "506", "709", "782", "902", "226", "249", "289", "343", "365", "416", "437", "519", "548", "613", "647", "705", "807", "905", "367", "418", "438", "450", "514", "579", "581", "819", "873", "306", "639", "867"],
        "region": "North America",
        "flag": "🇨🇦"
    },
    {
        "countryNameEn": "Switzerland",
        "countryNameLocal": "Schweiz, Suisse, Svizzera, Svizra",
        "countryCode": "CH",
        "currencyCode": "CHE",
        "currencyNameEn": "WIR Euro (complementary currency)",
        "tinType": "MWST/TVA/IVA",
        "tinName": "Mehrwertsteuernummer",
        "officialLanguageCode": "de",
        "officialLanguageNameEn": "German",
        "officialLanguageNameLocal": "Deutsch",
        "countryCallingCode": "41",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇨🇭"
    },
    {
        "countryNameEn": "Côte d'Ivoire",
        "countryNameLocal": "Côte d'Ivoire",
        "countryCode": "CI",
        "currencyCode": "XOF",
        "currencyNameEn": "CFA franc BCEAO",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "225",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇨🇮"
    },
    {
        "countryNameEn": "Chile",
        "countryNameLocal": "Chile",
        "countryCode": "CL",
        "currencyCode": "CLP",
        "currencyNameEn": "Chilean peso",
        "tinType": "RUT",
        "tinName": "Rol Único Tributario",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "56",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇨🇱"
    },
    {
        "countryNameEn": "Cameroon",
        "countryNameLocal": "Cameroun, Cameroon",
        "countryCode": "CM",
        "currencyCode": "XAF",
        "currencyNameEn": "CFA franc BEAC",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "237",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇨🇲"
    },
    {
        "countryNameEn": "China",
        "countryNameLocal": "中国",
        "countryCode": "CN",
        "currencyCode": "CNY",
        "currencyNameEn": "Renminbi (Chinese) yuan",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "zh-hans",
        "officialLanguageNameEn": "",
        "officialLanguageNameLocal": "",
        "countryCallingCode": "86",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇨🇳"
    },
    {
        "countryNameEn": "Colombia",
        "countryNameLocal": "Colombia",
        "countryCode": "CO",
        "currencyCode": "COP",
        "currencyNameEn": "Colombian peso",
        "tinType": "NIT",
        "tinName": "Número De Identificación Tributaria",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "57",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇨🇴"
    },
    {
        "countryNameEn": "Costa Rica",
        "countryNameLocal": "Costa Rica",
        "countryCode": "CR",
        "currencyCode": "CRC",
        "currencyNameEn": "Costa Rican colon",
        "tinType": "",
        "tinName": "Cédula Jurídica",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "506",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇨🇷"
    },
    {
        "countryNameEn": "Cuba",
        "countryNameLocal": "Cuba",
        "countryCode": "CU",
        "currencyCode": "CUC",
        "currencyNameEn": "Cuban convertible peso",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "53",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇨🇺"
    },
    {
        "countryNameEn": "Cabo Verde",
        "countryNameLocal": "Cabo Verde",
        "countryCode": "CV",
        "currencyCode": "CVE",
        "currencyNameEn": "Cape Verdean escudo",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "pt",
        "officialLanguageNameEn": "Portuguese",
        "officialLanguageNameLocal": "Português",
        "countryCallingCode": "238",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇨🇻"
    },
    {
        "countryNameEn": "Curaçao",
        "countryNameLocal": "Curaçao",
        "countryCode": "CW",
        "currencyCode": "ANG",
        "currencyNameEn": "Netherlands Antillean guilder",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "nl",
        "officialLanguageNameEn": "Dutch, Flemish",
        "officialLanguageNameLocal": "Nederlands, Vlaams",
        "countryCallingCode": "599",
        "areaCodes": [],
        "region": "Unknown",
        "flag": "🇨🇼"
    },
    {
        "countryNameEn": "Christmas Island",
        "countryNameLocal": "Christmas Island",
        "countryCode": "CX",
        "currencyCode": "AUD",
        "currencyNameEn": "Australian dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "61",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇨🇽"
    },
    {
        "countryNameEn": "Cyprus",
        "countryNameLocal": "Κύπρος, Kibris",
        "countryCode": "CY",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "ΦΠΑ",
        "tinName": "Αριθμός Εγγραφής Φ.Π.Α.",
        "officialLanguageCode": "el",
        "officialLanguageNameEn": "Greek, Modern (1453-)",
        "officialLanguageNameLocal": "ελληνικά",
        "countryCallingCode": "357",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇨🇾"
    },
    {
        "countryNameEn": "Germany",
        "countryNameLocal": "Deutschland",
        "countryCode": "DE",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "USt-IdNr.",
        "tinName": "Umsatzsteuer-Identifikationsnummer",
        "officialLanguageCode": "de",
        "officialLanguageNameEn": "German",
        "officialLanguageNameLocal": "Deutsch",
        "countryCallingCode": "49",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇩🇪"
    },
    {
        "countryNameEn": "Djibouti",
        "countryNameLocal": "Djibouti, جيبوتي, Jabuuti, Gabuutih",
        "countryCode": "DJ",
        "currencyCode": "DJF",
        "currencyNameEn": "Djiboutian franc",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "253",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇩🇯"
    },
    {
        "countryNameEn": "Denmark",
        "countryNameLocal": "Danmark",
        "countryCode": "DK",
        "currencyCode": "DKK",
        "currencyNameEn": "Danish krone",
        "tinType": "CVR",
        "tinName": "Momsregistreringsnummer",
        "officialLanguageCode": "da",
        "officialLanguageNameEn": "Danish",
        "officialLanguageNameLocal": "dansk",
        "countryCallingCode": "45",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇩🇰"
    },
    {
        "countryNameEn": "Dominica",
        "countryNameLocal": "Dominica",
        "countryCode": "DM",
        "currencyCode": "XCD",
        "currencyNameEn": "East Caribbean dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "767",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇩🇲"
    },
    {
        "countryNameEn": "Algeria",
        "countryNameLocal": "الجزائر",
        "countryCode": "DZ",
        "currencyCode": "DZD",
        "currencyNameEn": "Algerian dinar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "213",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇩🇿"
    },
    {
        "countryNameEn": "Ecuador",
        "countryNameLocal": "Ecuador",
        "countryCode": "EC",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "RUC",
        "tinName": "Número de Registro Unico de Contribuyentes",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "593",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇪🇨"
    },
    {
        "countryNameEn": "Estonia",
        "countryNameLocal": "Eesti",
        "countryCode": "EE",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "KMKR",
        "tinName": "Käibemaksukohustuslase number",
        "officialLanguageCode": "et",
        "officialLanguageNameEn": "Estonian",
        "officialLanguageNameLocal": "eesti, eesti keel",
        "countryCallingCode": "372",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇪🇪"
    },
    {
        "countryNameEn": "Egypt",
        "countryNameLocal": "مصر",
        "countryCode": "EG",
        "currencyCode": "EGP",
        "currencyNameEn": "Egyptian pound",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "20",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇪🇬"
    },
    {
        "countryNameEn": "Western Sahara",
        "countryNameLocal": "Sahara Occidental",
        "countryCode": "EH",
        "currencyCode": "MAD",
        "currencyNameEn": "Moroccan dirham",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "212",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇪🇭"
    },
    {
        "countryNameEn": "Eritrea",
        "countryNameLocal": "ኤርትራ, إرتريا, Eritrea",
        "countryCode": "ER",
        "currencyCode": "ERN",
        "currencyNameEn": "Eritrean nakfa",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ti",
        "officialLanguageNameEn": "Tigrinya",
        "officialLanguageNameLocal": "ትግርኛ",
        "countryCallingCode": "291",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇪🇷"
    },
    {
        "countryNameEn": "Spain",
        "countryNameLocal": "España",
        "countryCode": "ES",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "NIF (CIF)",
        "tinName": "Número de Identificación Fiscal (formerly named Código de Identificación Fiscal)",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "34",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇪🇸"
    },
    {
        "countryNameEn": "Ethiopia",
        "countryNameLocal": "ኢትዮጵያ, Itoophiyaa",
        "countryCode": "ET",
        "currencyCode": "ETB",
        "currencyNameEn": "Ethiopian birr",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "am",
        "officialLanguageNameEn": "Amharic",
        "officialLanguageNameLocal": "አማርኛ",
        "countryCallingCode": "251",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇪🇹"
    },
    {
        "countryNameEn": "Finland",
        "countryNameLocal": "Suomi",
        "countryCode": "FI",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "ALV nro",
        "tinName": "Arvonlisäveronumero",
        "officialLanguageCode": "fi",
        "officialLanguageNameEn": "Finnish",
        "officialLanguageNameLocal": "suomi, suomen kieli",
        "countryCallingCode": "358",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇫🇮"
    },
    {
        "countryNameEn": "Fiji",
        "countryNameLocal": "Fiji",
        "countryCode": "FJ",
        "currencyCode": "FJD",
        "currencyNameEn": "Fiji dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "679",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇫🇯"
    },
    {
        "countryNameEn": "Micronesia (Federated States of)",
        "countryNameLocal": "Micronesia",
        "countryCode": "FM",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "691",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇫🇲"
    },
    {
        "countryNameEn": "France",
        "countryNameLocal": "France",
        "countryCode": "FR",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "n° TVA",
        "tinName": "Numéro d'identification à la taxe sur la valeur ajoutée / Numéro de TVA intracommunautaire",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "33",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇫🇷"
    },
    {
        "countryNameEn": "Gabon",
        "countryNameLocal": "Gabon",
        "countryCode": "GA",
        "currencyCode": "XAF",
        "currencyNameEn": "CFA franc BEAC",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "241",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇬🇦"
    },
    {
        "countryNameEn": "Grenada",
        "countryNameLocal": "Grenada",
        "countryCode": "GD",
        "currencyCode": "XCD",
        "currencyNameEn": "East Caribbean dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1473",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇬🇩"
    },
    {
        "countryNameEn": "Georgia",
        "countryNameLocal": "საქართველო",
        "countryCode": "GE",
        "currencyCode": "GEL",
        "currencyNameEn": "Georgian lari",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ka",
        "officialLanguageNameEn": "Georgian",
        "officialLanguageNameLocal": "ქართული",
        "countryCallingCode": "995",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇬🇪"
    },
    {
        "countryNameEn": "French Guiana",
        "countryNameLocal": "Guyane française",
        "countryCode": "GF",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "594",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇬🇫"
    },
    {
        "countryNameEn": "Guernsey",
        "countryNameLocal": "Guernsey",
        "countryCode": "GG",
        "currencyCode": "GBP",
        "currencyNameEn": "Pound sterling",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "44",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇬🇬"
    },
    {
        "countryNameEn": "Ghana",
        "countryNameLocal": "Ghana",
        "countryCode": "GH",
        "currencyCode": "GHS",
        "currencyNameEn": "Ghanaian cedi",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "233",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇬🇭"
    },
    {
        "countryNameEn": "Gibraltar",
        "countryNameLocal": "Gibraltar",
        "countryCode": "GI",
        "currencyCode": "GIP",
        "currencyNameEn": "Gibraltar pound",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "350",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇬🇮"
    },
    {
        "countryNameEn": "Greenland",
        "countryNameLocal": "Kalaallit Nunaat, Grønland",
        "countryCode": "GL",
        "currencyCode": "DKK",
        "currencyNameEn": "Danish krone",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "kl",
        "officialLanguageNameEn": "Kalaallisut, Greenlandic",
        "officialLanguageNameLocal": "kalaallisut, kalaallit oqaasii",
        "countryCallingCode": "299",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇬🇱"
    },
    {
        "countryNameEn": "Guinea",
        "countryNameLocal": "Guinée",
        "countryCode": "GN",
        "currencyCode": "GNF",
        "currencyNameEn": "Guinean franc",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "224",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇬🇳"
    },
    {
        "countryNameEn": "Guadeloupe",
        "countryNameLocal": "Guadeloupe",
        "countryCode": "GP",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "590",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇬🇵"
    },
    {
        "countryNameEn": "Equatorial Guinea",
        "countryNameLocal": "Guiena ecuatorial, Guinée équatoriale, Guiné Equatorial",
        "countryCode": "GQ",
        "currencyCode": "XAF",
        "currencyNameEn": "CFA franc BEAC",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "240",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇬🇶"
    },
    {
        "countryNameEn": "Greece",
        "countryNameLocal": "Ελλάδα",
        "countryCode": "GR",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "el",
        "officialLanguageNameEn": "Greek, Modern (1453-)",
        "officialLanguageNameLocal": "ελληνικά",
        "countryCallingCode": "30",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇬🇷"
    },
    {
        "countryNameEn": "South Georgia and the South Sandwich Islands",
        "countryNameLocal": "South Georgia and the South Sandwich Islands",
        "countryCode": "GS",
        "currencyCode": "",
        "currencyNameEn": "",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "500",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇬🇸"
    },
    {
        "countryNameEn": "Guatemala",
        "countryNameLocal": "Guatemala",
        "countryCode": "GT",
        "currencyCode": "GTQ",
        "currencyNameEn": "Guatemalan quetzal",
        "tinType": "NIT",
        "tinName": "Número de Identificación Tributaria",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "502",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇬🇹"
    },
    {
        "countryNameEn": "Guam",
        "countryNameLocal": "Guam, Guåhån",
        "countryCode": "GU",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇬🇺"
    },
    {
        "countryNameEn": "Guinea-Bissau",
        "countryNameLocal": "Guiné-Bissau",
        "countryCode": "GW",
        "currencyCode": "XOF",
        "currencyNameEn": "CFA franc BCEAO",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "pt",
        "officialLanguageNameEn": "Portuguese",
        "officialLanguageNameLocal": "Português",
        "countryCallingCode": "245",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇬🇼"
    },
    {
        "countryNameEn": "Guyana",
        "countryNameLocal": "Guyana",
        "countryCode": "GY",
        "currencyCode": "GYD",
        "currencyNameEn": "Guyanese dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "592",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇬🇾"
    },
    {
        "countryNameEn": "Hong Kong",
        "countryNameLocal": "香港, Hong Kong",
        "countryCode": "HK",
        "currencyCode": "HKD",
        "currencyNameEn": "Hong Kong dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "zh-hant",
        "officialLanguageNameEn": "",
        "officialLanguageNameLocal": "",
        "countryCallingCode": "852",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇭🇰"
    },
    {
        "countryNameEn": "Honduras",
        "countryNameLocal": "Honduras",
        "countryCode": "HN",
        "currencyCode": "HNL",
        "currencyNameEn": "Honduran lempira",
        "tinType": "RTN",
        "tinName": "Registro Tributario Nacional",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "504",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇭🇳"
    },
    {
        "countryNameEn": "Croatia",
        "countryNameLocal": "Hrvatska",
        "countryCode": "HR",
        "currencyCode": "HRK",
        "currencyNameEn": "Croatian kuna",
        "tinType": "PDV-ID; OIB",
        "tinName": "PDV Id. Broj OIB",
        "officialLanguageCode": "hr",
        "officialLanguageNameEn": "Croatian",
        "officialLanguageNameLocal": "hrvatski jezik",
        "countryCallingCode": "385",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇭🇷"
    },
    {
        "countryNameEn": "Haiti",
        "countryNameLocal": "Haïti, Ayiti",
        "countryCode": "HT",
        "currencyCode": "HTG",
        "currencyNameEn": "Haitian gourde",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "509",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇭🇹"
    },
    {
        "countryNameEn": "Hungary",
        "countryNameLocal": "Magyarország",
        "countryCode": "HU",
        "currencyCode": "HUF",
        "currencyNameEn": "Hungarian forint",
        "tinType": "ANUM",
        "tinName": "Közösségi adószám",
        "officialLanguageCode": "hu",
        "officialLanguageNameEn": "Hungarian",
        "officialLanguageNameLocal": "magyar",
        "countryCallingCode": "36",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇭🇺"
    },
    {
        "countryNameEn": "Indonesia",
        "countryNameLocal": "Indonesia",
        "countryCode": "ID",
        "currencyCode": "IDR",
        "currencyNameEn": "Indonesian rupiah",
        "tinType": "NPWP",
        "tinName": "Nomor Pokok Wajib Pajak",
        "officialLanguageCode": "id",
        "officialLanguageNameEn": "Indonesian",
        "officialLanguageNameLocal": "Bahasa Indonesia",
        "countryCallingCode": "62",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇮🇩"
    },
    {
        "countryNameEn": "Ireland",
        "countryNameLocal": "Ireland, Éire",
        "countryCode": "IE",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "VAT or CBL",
        "tinName": "Value added tax identification no.",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "353",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇮🇪"
    },
    {
        "countryNameEn": "Israel",
        "countryNameLocal": "ישראל",
        "countryCode": "IL",
        "currencyCode": "ILS",
        "currencyNameEn": "Israeli new shekel",
        "tinType": "",
        "tinName": "מס' עוסק מורשה / ח\"פ",
        "officialLanguageCode": "he",
        "officialLanguageNameEn": "Hebrew",
        "officialLanguageNameLocal": "עברית",
        "countryCallingCode": "972",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇮🇱"
    },
    {
        "countryNameEn": "Isle of Man",
        "countryNameLocal": "Isle of Man",
        "countryCode": "IM",
        "currencyCode": "GBP",
        "currencyNameEn": "Pound sterling",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "44",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇮🇲"
    },
    {
        "countryNameEn": "India",
        "countryNameLocal": "भारत, India",
        "countryCode": "IN",
        "currencyCode": "INR",
        "currencyNameEn": "Indian rupee",
        "tinType": "VAT TIN / CST TIN",
        "tinName": "Value Added Tax - Taxpayer Identification Number / Central Sales Tax - Taxpayer Identification Number (In most states)Not applicable",
        "officialLanguageCode": "hi",
        "officialLanguageNameEn": "Hindi",
        "officialLanguageNameLocal": "हिन्दी, हिंदी",
        "countryCallingCode": "91",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇮🇳"
    },
    {
        "countryNameEn": "British Indian Ocean Territories",
        "countryNameLocal": "British Indian Ocean Territories",
        "countryCode": "IO",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "246",
        "areaCodes": [],
        "region": "Indian Ocean",
        "flag": "🇮🇴",
    },
    {
        "countryNameEn": "Iraq",
        "countryNameLocal": "العراق, Iraq",
        "countryCode": "IQ",
        "currencyCode": "IQD",
        "currencyNameEn": "Iraqi dinar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "964",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇮🇶"
    },
    {
        "countryNameEn": "Iran (Islamic Republic of)",
        "countryNameLocal": "ایران",
        "countryCode": "IR",
        "currencyCode": "IRR",
        "currencyNameEn": "Iranian rial",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fa",
        "officialLanguageNameEn": "Persian",
        "officialLanguageNameLocal": "فارسی",
        "countryCallingCode": "98",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇮🇷"
    },
    {
        "countryNameEn": "Iceland",
        "countryNameLocal": "Ísland",
        "countryCode": "IS",
        "currencyCode": "ISK",
        "currencyNameEn": "Icelandic króna",
        "tinType": "VSK / VASK",
        "tinName": "Virðisaukaskattsnúmer",
        "officialLanguageCode": "is",
        "officialLanguageNameEn": "Icelandic",
        "officialLanguageNameLocal": "Íslenska",
        "countryCallingCode": "354",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇮🇸"
    },
    {
        "countryNameEn": "Italy",
        "countryNameLocal": "Italia",
        "countryCode": "IT",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "P.IVA",
        "tinName": "Partita IVA(IVA = Imposta sul Valore Aggiunto)",
        "officialLanguageCode": "it",
        "officialLanguageNameEn": "Italian",
        "officialLanguageNameLocal": "Italiano",
        "countryCallingCode": "39",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇮🇹"
    },
    {
        "countryNameEn": "Jersey",
        "countryNameLocal": "Jersey",
        "countryCode": "JE",
        "currencyCode": "GBP",
        "currencyNameEn": "Pound sterling",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "44",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇯🇪"
    },
    {
        "countryNameEn": "Jamaica",
        "countryNameLocal": "Jamaica",
        "countryCode": "JM",
        "currencyCode": "JMD",
        "currencyNameEn": "Jamaican dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "876",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇯🇲"
    },
    {
        "countryNameEn": "Jordan",
        "countryNameLocal": "الأُرْدُن",
        "countryCode": "JO",
        "currencyCode": "JOD",
        "currencyNameEn": "Jordanian dinar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "962",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇯🇴"
    },
    {
        "countryNameEn": "Japan",
        "countryNameLocal": "日本",
        "countryCode": "JP",
        "currencyCode": "JPY",
        "currencyNameEn": "Japanese yen",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ja",
        "officialLanguageNameEn": "Japanese",
        "officialLanguageNameLocal": "日本語 (にほんご)",
        "countryCallingCode": "81",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇯🇵"
    },
    {
        "countryNameEn": "Kenya",
        "countryNameLocal": "Kenya",
        "countryCode": "KE",
        "currencyCode": "KES",
        "currencyNameEn": "Kenyan shilling",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "sw",
        "officialLanguageNameEn": "Swahili",
        "officialLanguageNameLocal": "Kiswahili",
        "countryCallingCode": "254",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇰🇪"
    },
    {
        "countryNameEn": "Kyrgyzstan",
        "countryNameLocal": "Кыргызстан, Киргизия",
        "countryCode": "KG",
        "currencyCode": "KGS",
        "currencyNameEn": "Kyrgyzstani som",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ky",
        "officialLanguageNameEn": "Kirghiz, Kyrgyz",
        "officialLanguageNameLocal": "Кыргызча, Кыргыз тили",
        "countryCallingCode": "996",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇰🇬"
    },
    {
        "countryNameEn": "Cambodia",
        "countryNameLocal": "កម្ពុជា",
        "countryCode": "KH",
        "currencyCode": "KHR",
        "currencyNameEn": "Cambodian riel",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "km",
        "officialLanguageNameEn": "Central Khmer",
        "officialLanguageNameLocal": "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ",
        "countryCallingCode": "855",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇰🇭"
    },
    {
        "countryNameEn": "North Korea",
        "countryNameLocal": "조선민주주의인민공화국",
        "countryCode": "KP",
        "currencyCode": "KPW",
        "currencyNameEn": "North Korean won",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ko",
        "officialLanguageNameEn": "Korean",
        "officialLanguageNameLocal": "조선어",
        "countryCallingCode": "850",
        "areaCodes": [],
        "region": "Asia",
        "flag": "🇰🇵"
    },
    {
        "countryNameEn": "South Korea",
        "countryNameLocal": "대한민국",
        "countryCode": "KR",
        "currencyCode": "KRW",
        "currencyNameEn": "South Korean won",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ko",
        "officialLanguageNameEn": "Korean",
        "officialLanguageNameLocal": "한국어",
        "countryCallingCode": "82",
        "areaCodes": [],
        "region": "Asia",
        "flag": "🇰🇷"
    },
    {
        "countryNameEn": "Kiribati",
        "countryNameLocal": "Kiribati",
        "countryCode": "KI",
        "currencyCode": "AUD",
        "currencyNameEn": "Australian dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "686",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇰🇮"
    },
    {
        "countryNameEn": "Saint Kitts and Nevis",
        "countryNameLocal": "Saint Kitts and Nevis",
        "countryCode": "KN",
        "currencyCode": "XCD",
        "currencyNameEn": "East Caribbean dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1869",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇰🇳"
    },
    {
        "countryNameEn": "Kuwait",
        "countryNameLocal": "الكويت",
        "countryCode": "KW",
        "currencyCode": "KWD",
        "currencyNameEn": "Kuwaiti dinar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "965",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇰🇼"
    },
    {
        "countryNameEn": "Kazakhstan",
        "countryNameLocal": "Қазақстан, Казахстан",
        "countryCode": "KZ",
        "currencyCode": "KZT",
        "currencyNameEn": "Kazakhstani tenge",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "kk",
        "officialLanguageNameEn": "Kazakh",
        "officialLanguageNameLocal": "қазақ тілі",
        "countryCallingCode": "7",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇰🇿"
    },
    {
        "countryNameEn": "Lebanon",
        "countryNameLocal": "لبنان, Liban",
        "countryCode": "LB",
        "currencyCode": "LBP",
        "currencyNameEn": "Lebanese pound",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "961",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇱🇧"
    },
    {
        "countryNameEn": "Saint Lucia",
        "countryNameLocal": "Saint Lucia",
        "countryCode": "LC",
        "currencyCode": "XCD",
        "currencyNameEn": "East Caribbean dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1758",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇱🇨"
    },
    {
        "countryNameEn": "Liechtenstein",
        "countryNameLocal": "Liechtenstein",
        "countryCode": "LI",
        "currencyCode": "CHF",
        "currencyNameEn": "Swiss franc",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "de",
        "officialLanguageNameEn": "German",
        "officialLanguageNameLocal": "Deutsch",
        "countryCallingCode": "423",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇱🇮"
    },
    {
        "countryNameEn": "Sri Lanka",
        "countryNameLocal": "ශ්‍රී ලංකා, இலங்கை",
        "countryCode": "LK",
        "currencyCode": "LKR",
        "currencyNameEn": "Sri Lankan rupee",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "si",
        "officialLanguageNameEn": "Sinhala, Sinhalese",
        "officialLanguageNameLocal": "සිංහල",
        "countryCallingCode": "94",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇱🇰"
    },
    {
        "countryNameEn": "Liberia",
        "countryNameLocal": "Liberia",
        "countryCode": "LR",
        "currencyCode": "LRD",
        "currencyNameEn": "Liberian dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "231",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇱🇷"
    },
    {
        "countryNameEn": "Lesotho",
        "countryNameLocal": "Lesotho",
        "countryCode": "LS",
        "currencyCode": "LSL",
        "currencyNameEn": "Lesotho loti",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "266",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇱🇸"
    },
    {
        "countryNameEn": "Lithuania",
        "countryNameLocal": "Lietuva",
        "countryCode": "LT",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "PVM kodas",
        "tinName": "PVM (abbrev. Pridėtinės vertės mokestis) mokėtojo kodas",
        "officialLanguageCode": "lt",
        "officialLanguageNameEn": "Lithuanian",
        "officialLanguageNameLocal": "lietuvių kalba",
        "countryCallingCode": "370",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇱🇹"
    },
    {
        "countryNameEn": "Luxembourg",
        "countryNameLocal": "Lëtzebuerg, Luxembourg, Luxemburg",
        "countryCode": "LU",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "No. TVA",
        "tinName": "Numéro d'identification à la taxe sur la valeur ajoutée",
        "officialLanguageCode": "lb",
        "officialLanguageNameEn": "Luxembourgish, Letzeburgesch",
        "officialLanguageNameLocal": "Lëtzebuergesch",
        "countryCallingCode": "352",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇱🇺"
    },
    {
        "countryNameEn": "Latvia",
        "countryNameLocal": "Latvija",
        "countryCode": "LV",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "PVN",
        "tinName": "Pievienotās vērtības nodokļa (PVN) reģistrācijas numurs",
        "officialLanguageCode": "lv",
        "officialLanguageNameEn": "Latvian",
        "officialLanguageNameLocal": "latviešu valoda",
        "countryCallingCode": "371",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇱🇻"
    },
    {
        "countryNameEn": "Libya",
        "countryNameLocal": "ليبيا",
        "countryCode": "LY",
        "currencyCode": "LYD",
        "currencyNameEn": "Libyan dinar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "218",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇱🇾"
    },
    {
        "countryNameEn": "Morocco",
        "countryNameLocal": "Maroc, ⵍⵎⵖⵔⵉⴱ, المغرب",
        "countryCode": "MA",
        "currencyCode": "MAD",
        "currencyNameEn": "Moroccan dirham",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "212",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇲🇦"
    },
    {
        "countryNameEn": "Monaco",
        "countryNameLocal": "Monaco",
        "countryCode": "MC",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "377",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇲🇨"
    },
    {
        "countryNameEn": "Montenegro",
        "countryNameLocal": "Crna Gora, Црна Гора",
        "countryCode": "ME",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "srp",
        "officialLanguageNameEn": "српски језик",
        "officialLanguageNameLocal": "",
        "countryCallingCode": "382",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇲🇪"
    },
    {
        "countryNameEn": "Saint Martin (French part)",
        "countryNameLocal": "Saint-Martin",
        "countryCode": "MF",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "590",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇲🇫"
    },
    {
        "countryNameEn": "Madagascar",
        "countryNameLocal": "Madagasikara, Madagascar",
        "countryCode": "MG",
        "currencyCode": "MGA",
        "currencyNameEn": "Malagasy ariary",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "mg",
        "officialLanguageNameEn": "Malagasy",
        "officialLanguageNameLocal": "fiteny malagasy",
        "countryCallingCode": "261",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇲🇬"
    },
    {
        "countryNameEn": "Mali",
        "countryNameLocal": "Mali",
        "countryCode": "ML",
        "currencyCode": "XOF",
        "currencyNameEn": "CFA franc BCEAO",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "223",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇲🇱"
    },
    {
        "countryNameEn": "Myanmar",
        "countryNameLocal": "မြန်မာ",
        "countryCode": "MM",
        "currencyCode": "MMK",
        "currencyNameEn": "Myanmar kyat",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "my",
        "officialLanguageNameEn": "Burmese",
        "officialLanguageNameLocal": "ဗမာစာ",
        "countryCallingCode": "95",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇲🇲"
    },
    {
        "countryNameEn": "Mongolia",
        "countryNameLocal": "Монгол Улс",
        "countryCode": "MN",
        "currencyCode": "MNT",
        "currencyNameEn": "Mongolian tögrög",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "mn",
        "officialLanguageNameEn": "Mongolian",
        "officialLanguageNameLocal": "Монгол хэл",
        "countryCallingCode": "976",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇲🇳"
    },
    {
        "countryNameEn": "Macao",
        "countryNameLocal": "澳門, Macau",
        "countryCode": "MO",
        "currencyCode": "MOP",
        "currencyNameEn": "Macanese pataca",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "zh-hant",
        "officialLanguageNameEn": "",
        "officialLanguageNameLocal": "",
        "countryCallingCode": "853",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇲🇴"
    },
    {
        "countryNameEn": "Martinique",
        "countryNameLocal": "Martinique",
        "countryCode": "MQ",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "596",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇲🇶"
    },
    {
        "countryNameEn": "Mauritania",
        "countryNameLocal": "موريتانيا, Mauritanie",
        "countryCode": "MR",
        "currencyCode": "MRU",
        "currencyNameEn": "",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "222",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇲🇷"
    },
    {
        "countryNameEn": "Montserrat",
        "countryNameLocal": "Montserrat",
        "countryCode": "MS",
        "currencyCode": "XCD",
        "currencyNameEn": "East Caribbean dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1664",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇲🇸"
    },
    {
        "countryNameEn": "Malta",
        "countryNameLocal": "Malta",
        "countryCode": "MT",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "Vat No.",
        "tinName": "Vat reg. no.",
        "officialLanguageCode": "mt",
        "officialLanguageNameEn": "Maltese",
        "officialLanguageNameLocal": "Malti",
        "countryCallingCode": "356",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇲🇹"
    },
    {
        "countryNameEn": "Mauritius",
        "countryNameLocal": "Maurice, Mauritius",
        "countryCode": "MU",
        "currencyCode": "MUR",
        "currencyNameEn": "Mauritian rupee",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "mfe",
        "officialLanguageNameEn": "",
        "officialLanguageNameLocal": "",
        "countryCallingCode": "230",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇲🇺"
    },
    {
        "countryNameEn": "Maldives",
        "countryNameLocal": "",
        "countryCode": "MV",
        "currencyCode": "MVR",
        "currencyNameEn": "Maldivian rufiyaa",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "dv",
        "officialLanguageNameEn": "Divehi, Dhivehi, Maldivian",
        "officialLanguageNameLocal": "ދިވެހި",
        "countryCallingCode": "960",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇲🇻"
    },
    {
        "countryNameEn": "Malawi",
        "countryNameLocal": "Malawi",
        "countryCode": "MW",
        "currencyCode": "MWK",
        "currencyNameEn": "Malawian kwacha",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "265",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇲🇼"
    },
    {
        "countryNameEn": "Mexico",
        "countryNameLocal": "México",
        "countryCode": "MX",
        "currencyCode": "MXN",
        "currencyNameEn": "Mexican peso",
        "tinType": "RFC",
        "tinName": "Registro Federal de Contribuyentes",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "52",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇲🇽"
    },
    {
        "countryNameEn": "Malaysia",
        "countryNameLocal": "",
        "countryCode": "MY",
        "currencyCode": "MYR",
        "currencyNameEn": "Malaysian ringgit",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ms",
        "officialLanguageNameEn": "Malay",
        "officialLanguageNameLocal": "Bahasa Melayu, بهاس ملايو‎",
        "countryCallingCode": "60",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇲🇾"
    },
    {
        "countryNameEn": "Mozambique",
        "countryNameLocal": "Mozambique",
        "countryCode": "MZ",
        "currencyCode": "MZN",
        "currencyNameEn": "Mozambican metical",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "pt",
        "officialLanguageNameEn": "Portuguese",
        "officialLanguageNameLocal": "Português",
        "countryCallingCode": "258",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇲🇿"
    },
    {
        "countryNameEn": "Namibia",
        "countryNameLocal": "Namibia",
        "countryCode": "NA",
        "currencyCode": "NAD",
        "currencyNameEn": "Namibian dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "264",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇳🇦"
    },
    {
        "countryNameEn": "New Caledonia",
        "countryNameLocal": "Nouvelle-Calédonie",
        "countryCode": "NC",
        "currencyCode": "XPF",
        "currencyNameEn": "CFP franc (franc Pacifique)",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "687",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇳🇨"
    },
    {
        "countryNameEn": "Norfolk Island",
        "countryNameLocal": "Norfolk Island",
        "countryCode": "NF",
        "currencyCode": "AUD",
        "currencyNameEn": "Australian dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "672",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇳🇫"
    },
    {
        "countryNameEn": "Nigeria",
        "countryNameLocal": "Nigeria",
        "countryCode": "NG",
        "currencyCode": "NGN",
        "currencyNameEn": "Nigerian naira",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "234",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇳🇬"
    },
    {
        "countryNameEn": "Nicaragua",
        "countryNameLocal": "Nicaragua",
        "countryCode": "NI",
        "currencyCode": "NIO",
        "currencyNameEn": "Nicaraguan córdoba",
        "tinType": "RUC",
        "tinName": "Registro Unico de Contribuyentes",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "505",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇳🇮"
    },
    {
        "countryNameEn": "Norway",
        "countryNameLocal": "Norge, Noreg",
        "countryCode": "NO",
        "currencyCode": "NOK",
        "currencyNameEn": "Norwegian krone",
        "tinType": "Orgnr",
        "tinName": "Organisasjonsnummer",
        "officialLanguageCode": "nb",
        "officialLanguageNameEn": "Norwegian Bokmål",
        "officialLanguageNameLocal": "Norsk Bokmål",
        "countryCallingCode": "47",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇳🇴"
    },
    {
        "countryNameEn": "Nepal",
        "countryNameLocal": "",
        "countryCode": "NP",
        "currencyCode": "NPR",
        "currencyNameEn": "Nepalese rupee",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ne",
        "officialLanguageNameEn": "Nepali",
        "officialLanguageNameLocal": "नेपाली",
        "countryCallingCode": "977",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇳🇵"
    },
    {
        "countryNameEn": "Nauru",
        "countryNameLocal": "Nauru",
        "countryCode": "NR",
        "currencyCode": "AUD",
        "currencyNameEn": "Australian dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "na",
        "officialLanguageNameEn": "Nauru",
        "officialLanguageNameLocal": "Dorerin Naoero",
        "countryCallingCode": "674",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇳🇷"
    },
    {
        "countryNameEn": "Niue",
        "countryNameLocal": "Niue",
        "countryCode": "NU",
        "currencyCode": "NZD",
        "currencyNameEn": "New Zealand dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "niu",
        "officialLanguageNameEn": "",
        "officialLanguageNameLocal": "",
        "countryCallingCode": "683",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇳🇺"
    },
    {
        "countryNameEn": "New Zealand",
        "countryNameLocal": "New Zealand",
        "countryCode": "NZ",
        "currencyCode": "NZD",
        "currencyNameEn": "New Zealand dollar",
        "tinType": "NZBN",
        "tinName": "NZ Business Number",
        "officialLanguageCode": "mi",
        "officialLanguageNameEn": "Maori",
        "officialLanguageNameLocal": "te reo Māori",
        "countryCallingCode": "64",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇳🇿"
    },
    {
        "countryNameEn": "Oman",
        "countryNameLocal": "سلطنة عُمان",
        "countryCode": "OM",
        "currencyCode": "OMR",
        "currencyNameEn": "Omani rial",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "968",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇴🇲"
    },
    {
        "countryNameEn": "Panama",
        "countryNameLocal": "Panama",
        "countryCode": "PA",
        "currencyCode": "PAB",
        "currencyNameEn": "Panamanian balboa",
        "tinType": "RUC",
        "tinName": "Registro Unico de Contribuyentes",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "507",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇵🇦"
    },
    {
        "countryNameEn": "Peru",
        "countryNameLocal": "Perú",
        "countryCode": "PE",
        "currencyCode": "PEN",
        "currencyNameEn": "Peruvian sol",
        "tinType": "RUC",
        "tinName": "Registro Unico de Contribuyentes",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "51",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇵🇪"
    },
    {
        "countryNameEn": "French Polynesia",
        "countryNameLocal": "Polynésie française",
        "countryCode": "PF",
        "currencyCode": "XPF",
        "currencyNameEn": "CFP franc (franc Pacifique)",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "689",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇵🇫"
    },
    {
        "countryNameEn": "Papua New Guinea",
        "countryNameLocal": "Papua New Guinea",
        "countryCode": "PG",
        "currencyCode": "PGK",
        "currencyNameEn": "Papua New Guinean kina",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "675",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇵🇬"
    },
    {
        "countryNameEn": "Pakistan",
        "countryNameLocal": "پاکستان",
        "countryCode": "PK",
        "currencyCode": "PKR",
        "currencyNameEn": "Pakistani rupee",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "92",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇵🇰"
    },
    {
        "countryNameEn": "Poland",
        "countryNameLocal": "Polska",
        "countryCode": "PL",
        "currencyCode": "PLN",
        "currencyNameEn": "Polish złoty",
        "tinType": "NIP",
        "tinName": "numer identyfikacji podatkowej",
        "officialLanguageCode": "pl",
        "officialLanguageNameEn": "Polish",
        "officialLanguageNameLocal": "język polski, polszczyzna",
        "countryCallingCode": "48",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇵🇱"
    },
    {
        "countryNameEn": "Saint Pierre and Miquelon",
        "countryNameLocal": "Saint-Pierre-et-Miquelon",
        "countryCode": "PM",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "508",
        "areaCodes": [],
        "region": "North America",
        "flag": "🇵🇲"
    },
    {
        "countryNameEn": "Pitcairn",
        "countryNameLocal": "Pitcairn",
        "countryCode": "PN",
        "currencyCode": "NZD",
        "currencyNameEn": "New Zealand dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "64",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇵🇳"
    },
    {
        "countryNameEn": "Puerto Rico",
        "countryNameLocal": "Puerto Rico",
        "countryCode": "PR",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "1",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇵🇷"
    },
    {
        "countryNameEn": "Palestine, State of",
        "countryNameLocal": "Palestinian Territory",
        "countryCode": "PS",
        "currencyCode": "",
        "currencyNameEn": "",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "970",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇵🇸"
    },
    {
        "countryNameEn": "Portugal",
        "countryNameLocal": "Portugal",
        "countryCode": "PT",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "NIPC",
        "tinName": "Número de Identificação de Pessoa Coletiva (NIPC)",
        "officialLanguageCode": "pt",
        "officialLanguageNameEn": "Portuguese",
        "officialLanguageNameLocal": "Português",
        "countryCallingCode": "351",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇵🇹"
    },
    {
        "countryNameEn": "Palau",
        "countryNameLocal": "Palau",
        "countryCode": "PW",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "680",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇵🇼"
    },
    {
        "countryNameEn": "Paraguay",
        "countryNameLocal": "Paraguay",
        "countryCode": "PY",
        "currencyCode": "PYG",
        "currencyNameEn": "Paraguayan guaraní",
        "tinType": "RUC",
        "tinName": "Registro Unico de Contribuyentes",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "595",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇵🇾"
    },
    {
        "countryNameEn": "Qatar",
        "countryNameLocal": "قطر",
        "countryCode": "QA",
        "currencyCode": "QAR",
        "currencyNameEn": "Qatari riyal",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "974",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇶🇦"
    },
    {
        "countryNameEn": "Réunion",
        "countryNameLocal": "La Réunion",
        "countryCode": "RE",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "262",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇷🇪"
    },
    {
        "countryNameEn": "Romania",
        "countryNameLocal": "România",
        "countryCode": "RO",
        "currencyCode": "RON",
        "currencyNameEn": "Romanian leu",
        "tinType": "CIF",
        "tinName": "Codul de identificare fiscală",
        "officialLanguageCode": "ro",
        "officialLanguageNameEn": "Romanian, Moldavian, Moldovan",
        "officialLanguageNameLocal": "Română",
        "countryCallingCode": "40",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇷🇴"
    },
    {
        "countryNameEn": "Serbia",
        "countryNameLocal": "Србија",
        "countryCode": "RS",
        "currencyCode": "RSD",
        "currencyNameEn": "Serbian dinar",
        "tinType": "PIB",
        "tinName": "Poreski identifikacioni broj",
        "officialLanguageCode": "sr",
        "officialLanguageNameEn": "Serbian",
        "officialLanguageNameLocal": "српски језик",
        "countryCallingCode": "381",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇷🇸"
    },
    {
        "countryNameEn": "Russia",
        "countryNameLocal": "Россия",
        "countryCode": "RU",
        "currencyCode": "RUB",
        "currencyNameEn": "Russian ruble",
        "tinType": "ИНН",
        "tinName": "Идентификационный номер налогоплательщика",
        "officialLanguageCode": "ru",
        "officialLanguageNameEn": "Russian",
        "officialLanguageNameLocal": "русский",
        "countryCallingCode": "7",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇷🇺"
    },
    {
        "countryNameEn": "Rwanda",
        "countryNameLocal": "Rwanda",
        "countryCode": "RW",
        "currencyCode": "RWF",
        "currencyNameEn": "Rwandan franc",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "rw",
        "officialLanguageNameEn": "Kinyarwanda",
        "officialLanguageNameLocal": "Ikinyarwanda",
        "countryCallingCode": "250",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇷🇼"
    },
    {
        "countryNameEn": "Saudi Arabia",
        "countryNameLocal": "السعودية",
        "countryCode": "SA",
        "currencyCode": "SAR",
        "currencyNameEn": "Saudi riyal",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "966",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇸🇦"
    },
    {
        "countryNameEn": "Solomon Islands",
        "countryNameLocal": "Solomon Islands",
        "countryCode": "SB",
        "currencyCode": "SBD",
        "currencyNameEn": "Solomon Islands dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "677",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇸🇧"
    },
    {
        "countryNameEn": "Seychelles",
        "countryNameLocal": "Seychelles",
        "countryCode": "SC",
        "currencyCode": "SCR",
        "currencyNameEn": "Seychelles rupee",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "248",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇸🇨"
    },
    {
        "countryNameEn": "Sweden",
        "countryNameLocal": "Sverige",
        "countryCode": "SE",
        "currencyCode": "SEK",
        "currencyNameEn": "Swedish krona/kronor",
        "tinType": "Momsnr.",
        "tinName": "VAT-nummer",
        "officialLanguageCode": "sv",
        "officialLanguageNameEn": "Swedish",
        "officialLanguageNameLocal": "Svenska",
        "countryCallingCode": "46",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇸🇪"
    },
    {
        "countryNameEn": "Singapore",
        "countryNameLocal": "Singapore",
        "countryCode": "SG",
        "currencyCode": "SGD",
        "currencyNameEn": "Singapore dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "zh-hans",
        "officialLanguageNameEn": "",
        "officialLanguageNameLocal": "",
        "countryCallingCode": "65",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇸🇬"
    },
    {
        "countryNameEn": "Saint Helena, Ascension and Tristan da Cunha",
        "countryNameLocal": "Saint Helena",
        "countryCode": "SH",
        "currencyCode": "SHP",
        "currencyNameEn": "Saint Helena pound",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "290",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇸🇭"
    },
    {
        "countryNameEn": "Slovenia",
        "countryNameLocal": "Slovenija",
        "countryCode": "SI",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "ID za DDV",
        "tinName": "Davčna številka",
        "officialLanguageCode": "sl",
        "officialLanguageNameEn": "Slovenian",
        "officialLanguageNameLocal": "Slovenski Jezik, Slovenščina",
        "countryCallingCode": "386",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇸🇮"
    },
    {
        "countryNameEn": "Svalbard and Jan Mayen",
        "countryNameLocal": "Svalbard and Jan Mayen",
        "countryCode": "SJ",
        "currencyCode": "NOK",
        "currencyNameEn": "Norwegian krone",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "no",
        "officialLanguageNameEn": "Norwegian",
        "officialLanguageNameLocal": "Norsk",
        "countryCallingCode": "4779",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇸🇯"
    },
    {
        "countryNameEn": "Slovakia",
        "countryNameLocal": "Slovensko",
        "countryCode": "SK",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "IČ DPH",
        "tinName": "Identifikačné číslo pre daň z pridanej hodnoty",
        "officialLanguageCode": "sk",
        "officialLanguageNameEn": "Slovak",
        "officialLanguageNameLocal": "Slovenčina, Slovenský Jazyk",
        "countryCallingCode": "421",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇸🇰"
    },
    {
        "countryNameEn": "Sierra Leone",
        "countryNameLocal": "Sierra Leone",
        "countryCode": "SL",
        "currencyCode": "SLL",
        "currencyNameEn": "Sierra Leonean leone",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "232",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇸🇱"
    },
    {
        "countryNameEn": "Republic of San Marino",
        "countryNameLocal": "Repubblica di San Marino",
        "countryCode": "SM",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "C.O.E.",
        "tinName": "Codice operatore economico",
        "officialLanguageCode": "it",
        "officialLanguageNameEn": "Italian",
        "officialLanguageNameLocal": "Italiano",
        "countryCallingCode": "378",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇸🇲"
    },
    {
        "countryNameEn": "Senegal",
        "countryNameLocal": "Sénégal",
        "countryCode": "SN",
        "currencyCode": "XOF",
        "currencyNameEn": "CFA franc BCEAO",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "221",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇸🇳"
    },
    {
        "countryNameEn": "Somalia",
        "countryNameLocal": "Somalia, الصومال",
        "countryCode": "SO",
        "currencyCode": "SOS",
        "currencyNameEn": "Somali shilling",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "so",
        "officialLanguageNameEn": "Somali",
        "officialLanguageNameLocal": "Soomaaliga, af Soomaali",
        "countryCallingCode": "252",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇸🇴"
    },
    {
        "countryNameEn": "Suriname",
        "countryNameLocal": "Suriname",
        "countryCode": "SR",
        "currencyCode": "SRD",
        "currencyNameEn": "Surinamese dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "nl",
        "officialLanguageNameEn": "Dutch, Flemish",
        "officialLanguageNameLocal": "Nederlands, Vlaams",
        "countryCallingCode": "597",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇸🇷"
    },
    {
        "countryNameEn": "South Sudan",
        "countryNameLocal": "South Sudan",
        "countryCode": "SS",
        "currencyCode": "SSP",
        "currencyNameEn": "South Sudanese pound",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "211",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇸🇸"
    },
    {
        "countryNameEn": "Sao Tome and Principe",
        "countryNameLocal": "São Tomé e Príncipe",
        "countryCode": "ST",
        "currencyCode": "STN",
        "currencyNameEn": "",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "pt",
        "officialLanguageNameEn": "Portuguese",
        "officialLanguageNameLocal": "Português",
        "countryCallingCode": "239",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇸🇹"
    },
    {
        "countryNameEn": "El Salvador",
        "countryNameLocal": "El Salvador",
        "countryCode": "SV",
        "currencyCode": "SVC",
        "currencyNameEn": "Salvadoran colón",
        "tinType": "NIT",
        "tinName": "Número de Identificación Tributaria",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "503",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇸🇻"
    },
    {
        "countryNameEn": "Sint Maarten (Dutch part)",
        "countryNameLocal": "Sint Maarten",
        "countryCode": "SX",
        "currencyCode": "ANG",
        "currencyNameEn": "Netherlands Antillean guilder",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "nl",
        "officialLanguageNameEn": "Dutch, Flemish",
        "officialLanguageNameLocal": "Nederlands, Vlaams",
        "countryCallingCode": "1721",
        "areaCodes": [],
        "region": "Unknown",
        "flag": "🇸🇽"
    },
    {
        "countryNameEn": "Syrian Arab Republic",
        "countryNameLocal": "سوريا, Sūriyya",
        "countryCode": "SY",
        "currencyCode": "SYP",
        "currencyNameEn": "Syrian pound",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "963",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇸🇾"
    },
    {
        "countryNameEn": "Chad",
        "countryNameLocal": "Tchad, تشاد",
        "countryCode": "TD",
        "currencyCode": "XAF",
        "currencyNameEn": "CFA franc BEAC",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "235",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇹🇩"
    },
    {
        "countryNameEn": "Togo",
        "countryNameLocal": "Togo",
        "countryCode": "TG",
        "currencyCode": "XOF",
        "currencyNameEn": "CFA franc BCEAO",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "228",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇹🇬"
    },
    {
        "countryNameEn": "Thailand",
        "countryNameLocal": "ประเทศไทย",
        "countryCode": "TH",
        "currencyCode": "THB",
        "currencyNameEn": "Thai baht",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "th",
        "officialLanguageNameEn": "Thai",
        "officialLanguageNameLocal": "ไทย",
        "countryCallingCode": "66",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇹🇭"
    },
    {
        "countryNameEn": "Tajikistan",
        "countryNameLocal": ",",
        "countryCode": "TJ",
        "currencyCode": "TJS",
        "currencyNameEn": "Tajikistani somoni",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "tg",
        "officialLanguageNameEn": "Tajik",
        "officialLanguageNameLocal": "тоҷикӣ, toçikī, تاجیکی‎",
        "countryCallingCode": "992",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇹🇯"
    },
    {
        "countryNameEn": "Tokelau",
        "countryNameLocal": "Tokelau",
        "countryCode": "TK",
        "currencyCode": "NZD",
        "currencyNameEn": "New Zealand dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "tkl",
        "officialLanguageNameEn": "",
        "officialLanguageNameLocal": "",
        "countryCallingCode": "690",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇹🇰"
    },
    {
        "countryNameEn": "Timor-Leste",
        "countryNameLocal": "Timor-Leste, Timor Lorosa'e",
        "countryCode": "TL",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "pt",
        "officialLanguageNameEn": "Portuguese",
        "officialLanguageNameLocal": "Português",
        "countryCallingCode": "670",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇹🇱"
    },
    {
        "countryNameEn": "Turkmenistan",
        "countryNameLocal": "Türkmenistan",
        "countryCode": "TM",
        "currencyCode": "TMT",
        "currencyNameEn": "Turkmenistan manat",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "tk",
        "officialLanguageNameEn": "Turkmen",
        "officialLanguageNameLocal": "Türkmen, Түркмен",
        "countryCallingCode": "993",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇹🇲"
    },
    {
        "countryNameEn": "Tunisia",
        "countryNameLocal": "تونس, Tunisie",
        "countryCode": "TN",
        "currencyCode": "TND",
        "currencyNameEn": "Tunisian dinar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "216",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇹🇳"
    },
    {
        "countryNameEn": "Tonga",
        "countryNameLocal": "Tonga",
        "countryCode": "TO",
        "currencyCode": "TOP",
        "currencyNameEn": "Tongan paʻanga",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "676",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇹🇴"
    },
    {
        "countryNameEn": "Turkey",
        "countryNameLocal": "Türkiye",
        "countryCode": "TR",
        "currencyCode": "TRY",
        "currencyNameEn": "Turkish lira",
        "tinType": "KDV",
        "tinName": "Vergi Kimlik Numarası",
        "officialLanguageCode": "tr",
        "officialLanguageNameEn": "Turkish",
        "officialLanguageNameLocal": "Türkçe",
        "countryCallingCode": "90",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇹🇷"
    },
    {
        "countryNameEn": "Trinidad and Tobago",
        "countryNameLocal": "Trinidad and Tobago",
        "countryCode": "TT",
        "currencyCode": "TTD",
        "currencyNameEn": "Trinidad and Tobago dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "868",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇹🇹"
    },
    {
        "countryNameEn": "Tuvalu",
        "countryNameLocal": "Tuvalu",
        "countryCode": "TV",
        "currencyCode": "AUD",
        "currencyNameEn": "Australian dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "688",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇹🇻"
    },
    {
        "countryNameEn": "United Republic of Tanzania",
        "countryNameLocal": "Tanzania",
        "countryCode": "TZ",
        "currencyCode": "TZS",
        "currencyNameEn": "Tanzanian shilling",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "sw",
        "officialLanguageNameEn": "Swahili",
        "officialLanguageNameLocal": "Kiswahili",
        "countryCallingCode": "255",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇹🇿"
    },
    {
        "countryNameEn": "Ukraine",
        "countryNameLocal": "Україна",
        "countryCode": "UA",
        "currencyCode": "UAH",
        "currencyNameEn": "Ukrainian hryvnia",
        "tinType": "ІНПП",
        "tinName": "Ідентифікаційний номер платника податків",
        "officialLanguageCode": "uk",
        "officialLanguageNameEn": "Ukrainian",
        "officialLanguageNameLocal": "Українська",
        "countryCallingCode": "380",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇺🇦"
    },
    {
        "countryNameEn": "Uganda",
        "countryNameLocal": "Uganda",
        "countryCode": "UG",
        "currencyCode": "UGX",
        "currencyNameEn": "Ugandan shilling",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "256",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇺🇬"
    },
    {
        "countryNameEn": "United States of America",
        "countryNameLocal": "United States of America",
        "countryCode": "US",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "EIN",
        "tinName": "Tax Identification Number",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1",
        "areaCodes": [],
        "region": "North America",
        "flag": "🇺🇸"
    },
    {
        "countryNameEn": "Uruguay",
        "countryNameLocal": "Uruguay",
        "countryCode": "UY",
        "currencyCode": "UYU",
        "currencyNameEn": "Uruguayan peso",
        "tinType": "RUT",
        "tinName": "Registro Único Tributario",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "598",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇺🇾"
    },
    {
        "countryNameEn": "Uzbekistan",
        "countryNameLocal": "",
        "countryCode": "UZ",
        "currencyCode": "UZS",
        "currencyNameEn": "Uzbekistan som",
        "tinType": "СТИР",
        "tinName": "Солиқ тўловчиларнинг идентификация рақами",
        "officialLanguageCode": "uz",
        "officialLanguageNameEn": "Uzbek",
        "officialLanguageNameLocal": "Oʻzbek, Ўзбек, أۇزبېك‎",
        "countryCallingCode": "998",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇺🇿"
    },
    {
        "countryNameEn": "Saint Vincent and the Grenadines",
        "countryNameLocal": "Saint Vincent and the Grenadines",
        "countryCode": "VC",
        "currencyCode": "XCD",
        "currencyNameEn": "East Caribbean dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1784",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇻🇨"
    },
    {
        "countryNameEn": "Venezuela (Bolivarian Republic of)",
        "countryNameLocal": "Venezuela",
        "countryCode": "VE",
        "currencyCode": "VES",
        "currencyNameEn": "",
        "tinType": "RIF",
        "tinName": "Registro de Informacion Fiscal",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "58",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇻🇪"
    },
    {
        "countryNameEn": "Virgin Islands (British)",
        "countryNameLocal": "British Virgin Islands",
        "countryCode": "VG",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1284",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇻🇬"
    },
    {
        "countryNameEn": "Virgin Islands (U.S.)",
        "countryNameLocal": "United States Virgin Islands",
        "countryCode": "VI",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1340",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇻🇮"
    },
    {
        "countryNameEn": "Vietnam",
        "countryNameLocal": "Việt Nam",
        "countryCode": "VN",
        "currencyCode": "VND",
        "currencyNameEn": "Vietnamese đồng",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "vi",
        "officialLanguageNameEn": "Vietnamese",
        "officialLanguageNameLocal": "Tiếng Việt",
        "countryCallingCode": "84",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇻🇳"
    },
    {
        "countryNameEn": "Vanuatu",
        "countryNameLocal": "Vanuatu",
        "countryCode": "VU",
        "currencyCode": "VUV",
        "currencyNameEn": "Vanuatu vatu",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "bi",
        "officialLanguageNameEn": "Bislama",
        "officialLanguageNameLocal": "Bislama",
        "countryCallingCode": "678",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇻🇺"
    },
    {
        "countryNameEn": "Wallis and Futuna",
        "countryNameLocal": "Wallis-et-Futuna",
        "countryCode": "WF",
        "currencyCode": "XPF",
        "currencyNameEn": "CFP franc (franc Pacifique)",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "681",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇼🇫"
    },
    {
        "countryNameEn": "Samoa",
        "countryNameLocal": "Samoa",
        "countryCode": "WS",
        "currencyCode": "WST",
        "currencyNameEn": "Samoan tala",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "sm",
        "officialLanguageNameEn": "Samoan",
        "officialLanguageNameLocal": "gagana fa'a Samoa",
        "countryCallingCode": "685",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇼🇸"
    },
    {
        "countryNameEn": "Yemen",
        "countryNameLocal": "اليَمَن",
        "countryCode": "YE",
        "currencyCode": "YER",
        "currencyNameEn": "Yemeni rial",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "967",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇾🇪"
    },
    {
        "countryNameEn": "Mayotte",
        "countryNameLocal": "Mayotte",
        "countryCode": "YT",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "262",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇾🇹"
    },
    {
        "countryNameEn": "South Africa",
        "countryNameLocal": "South Africa",
        "countryCode": "ZA",
        "currencyCode": "ZAR",
        "currencyNameEn": "South African rand",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "27",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇿🇦"
    },
    {
        "countryNameEn": "Zambia",
        "countryNameLocal": "Zambia",
        "countryCode": "ZM",
        "currencyCode": "ZMW",
        "currencyNameEn": "Zambian kwacha",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "260",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇿🇲"
    },
    {
        "countryNameEn": "Zimbabwe",
        "countryNameLocal": "Zimbabwe",
        "countryCode": "ZW",
        "currencyCode": "ZWL",
        "currencyNameEn": "Zimbabwean dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "263",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇿🇼"
    },
    {
        "countryNameEn": "Eswatini",
        "countryNameLocal": "Swaziland",
        "countryCode": "SZ",
        "currencyCode": "SZL",
        "currencyNameEn": "Swazi lilangeni",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "268",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇸🇿"
    },
    {
        "countryNameEn": "North Macedonia",
        "countryNameLocal": "Македонија",
        "countryCode": "MK",
        "currencyCode": "MKD",
        "currencyNameEn": "Macedonian denar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "mk",
        "officialLanguageNameEn": "Macedonian",
        "officialLanguageNameLocal": "македонски јазик",
        "countryCallingCode": "389",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇲🇰"
    },
    {
        "countryNameEn": "Philippines",
        "countryNameLocal": "Philippines",
        "countryCode": "PH",
        "currencyCode": "PHP",
        "currencyNameEn": "Philippine peso",
        "tinType": "TIN",
        "tinName": "Tax Identification Number",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "63",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇵🇭"
    },
    {
        "countryNameEn": "Netherlands",
        "countryNameLocal": "Nederland",
        "countryCode": "NL",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "Btw-nr.",
        "tinName": "Btw-nummer",
        "officialLanguageCode": "nl",
        "officialLanguageNameEn": "Dutch, Flemish",
        "officialLanguageNameLocal": "Nederlands, Vlaams",
        "countryCallingCode": "31",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇳🇱"
    },
    {
        "countryNameEn": "United Arab Emirates",
        "countryNameLocal": "دولة الإمارات العربيّة المتّحدة",
        "countryCode": "AE",
        "currencyCode": "AED",
        "currencyNameEn": "United Arab Emirates dirham",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "971",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇦🇪"
    },
    {
        "countryNameEn": "Republic of Moldova",
        "countryNameLocal": "Moldova, Молдавия",
        "countryCode": "MD",
        "currencyCode": "MDL",
        "currencyNameEn": "Moldovan leu",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ro",
        "officialLanguageNameEn": "Romanian, Moldavian, Moldovan",
        "officialLanguageNameLocal": "Română",
        "countryCallingCode": "373",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇲🇩"
    },
    {
        "countryNameEn": "Gambia",
        "countryNameLocal": "The Gambia",
        "countryCode": "GM",
        "currencyCode": "GMD",
        "currencyNameEn": "Gambian dalasi",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "220",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇬🇲"
    },
    {
        "countryNameEn": "Dominican Republic",
        "countryNameLocal": "República Dominicana",
        "countryCode": "DO",
        "currencyCode": "DOP",
        "currencyNameEn": "Dominican peso",
        "tinType": "RNC",
        "tinName": "Registro Nacional del Contribuyente",
        "officialLanguageCode": "es",
        "officialLanguageNameEn": "Spanish, Castilian",
        "officialLanguageNameLocal": "Español",
        "countryCallingCode": "1",
        "areaCodes": [],
        "region": "South/Latin America",
        "flag": "🇩🇴"
    },
    {
        "countryNameEn": "Sudan",
        "countryNameLocal": "السودان",
        "countryCode": "SD",
        "currencyCode": "SDG",
        "currencyNameEn": "Sudanese pound",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "ar",
        "officialLanguageNameEn": "Arabic",
        "officialLanguageNameLocal": "العربية",
        "countryCallingCode": "249",
        "areaCodes": [],
        "region": "Arab States",
        "flag": "🇸🇩"
    },
    {
        "countryNameEn": "Lao People's Democratic Republic",
        "countryNameLocal": "ປະຊາຊົນລາວ",
        "countryCode": "LA",
        "currencyCode": "LAK",
        "currencyNameEn": "Lao kip",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "lo",
        "officialLanguageNameEn": "Lao",
        "officialLanguageNameLocal": "ພາສາລາວ",
        "countryCallingCode": "856",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇱🇦"
    },
    {
        "countryNameEn": "Taiwan, Province of China",
        "countryNameLocal": "Taiwan",
        "countryCode": "TW",
        "currencyCode": "TWD",
        "currencyNameEn": "New Taiwan dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "zh-hant",
        "officialLanguageNameEn": "",
        "officialLanguageNameLocal": "",
        "countryCallingCode": "886",
        "areaCodes": [],
        "region": "Asia & Pacific",
        "flag": "🇹🇼"
    },
    {
        "countryNameEn": "Republic of the Congo",
        "countryNameLocal": "République du Congo",
        "countryCode": "CG",
        "currencyCode": "XAF",
        "currencyNameEn": "CFA franc BEAC",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "242",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇨🇬"
    },
    {
        "countryNameEn": "Czechia",
        "countryNameLocal": "Česká republika",
        "countryCode": "CZ",
        "currencyCode": "CZK",
        "currencyNameEn": "Czech koruna",
        "tinType": "DIČ",
        "tinName": "Daňové identifikační číslo",
        "officialLanguageCode": "cs",
        "officialLanguageNameEn": "Czech",
        "officialLanguageNameLocal": "Čeština",
        "countryCallingCode": "420",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇨🇿"
    },
    {
        "countryNameEn": "United Kingdom",
        "countryNameLocal": "Great Britain",
        "countryCode": "GB",
        "currencyCode": "GBP",
        "currencyNameEn": "Pound sterling",
        "tinType": "VAT Reg No",
        "tinName": "Value added tax registration number",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "44",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇬🇧"
    },
    {
        "countryNameEn": "Niger",
        "countryNameLocal": "Niger",
        "countryCode": "NE",
        "currencyCode": "XOF",
        "currencyNameEn": "CFA franc BCEAO",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "227",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇳🇪"
    },
    {
        "countryNameEn": "Democratic Republic of the Congo",
        "countryNameLocal": "Democratic Republic of the Congo",
        "countryCode": "CD",
        "currencyCode": "CDF",
        "currencyNameEn": "Congolese franc",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "243",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇨🇩",
    },
    {
        "countryNameEn": "Commonwealth of The Bahamas",
        "countryNameLocal": "Commonwealth of The Bahamas",
        "countryCode": "BS",
        "currencyCode": "BSD",
        "currencyNameEn": "Bahamian dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1 242",
        "areaCodes": [],
        "region": "Caribbean",
        "flag": "🇧🇸",
    },
    {
        "countryNameEn": "Cocos (Keeling) Islands",
        "countryNameLocal": "Pulu Kokos (Keeling)",
        "countryCode": "CC",
        "currencyCode": "AUD",
        "currencyNameEn": "Australian dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "61 891",
        "areaCodes": [],
        "region": "Australia",
        "flag": "🇨🇨",
    },
    {
        "countryNameEn": "Central African Republic",
        "countryNameLocal": "République centrafricaine",
        "countryCode": "CF",
        "currencyCode": "XAF",
        "currencyNameEn": "Central African CFA",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "236",
        "areaCodes": [],
        "region": "Africa",
        "flag": "🇨🇫",
    },
    {
        "countryNameEn": "Cook Islands",
        "countryNameLocal": "Kūki 'Āirani",
        "countryCode": "CK",
        "currencyCode": "NZD",
        "currencyNameEn": "New Zealand dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "682",
        "areaCodes": [],
        "region": "South Pacific Ocean",
        "flag": "🇨🇰",
    },
    {
        "countryNameEn": "Falkland Islands",
        "countryNameLocal": "Falkland Islands",
        "countryCode": "FK",
        "currencyCode": "FKP",
        "currencyNameEn": "Falklands pound",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "500",
        "areaCodes": [],
        "region": "South Atlantic Ocean",
        "flag": "🇫🇰",
    },
    {
        "countryNameEn": "Faroe Islands",
        "countryNameLocal": "Færøerne",
        "countryCode": "FO",
        "currencyCode": "DKK",
        "currencyNameEn": "Faroese króna",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "da",
        "officialLanguageNameEn": "Danish",
        "officialLanguageNameLocal": "dansk",
        "countryCallingCode": "298",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇫🇴",
    },
    {
        "countryNameEn": "Territory of Heard Island and McDonald Islands",
        "countryNameLocal": "Territory of Heard Island and McDonald Islands",
        "countryCode": "HM",
        "currencyCode": "AUD",
        "currencyNameEn": "Australian dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "672",
        "areaCodes": [],
        "region": "Indian Ocean",
        "flag": "🇭🇲",
    },
    {
        "countryNameEn": "British Indian Ocean Territory",
        "countryNameLocal": "British Indian Ocean Territory",
        "countryCode": "IO",
        "currencyCode": "USD",
        "currencyNameEn": "United States Dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "246",
        "areaCodes": [],
        "region": "Indian Ocean",
        "flag": "🇮🇴",
    },
    {
        "countryNameEn": "Comoros",
        "countryNameLocal": "Umoja wa Komori",
        "countryCode": "KM",
        "currencyCode": "KMF",
        "currencyNameEn": "Comorian franc",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "269",
        "areaCodes": [],
        "region": "Indian Ocean",
        "flag": "🇰🇲",
    },
    {
        "countryNameEn": "Cayman Islands",
        "countryNameLocal": "Cayman Islands",
        "countryCode": "KY",
        "currencyCode": "KYD",
        "currencyNameEn": "Cayman Islands dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1 345",
        "areaCodes": [],
        "region": "Caribbean Sea",
        "flag": "🇰🇾",
    },
    {
        "countryNameEn": "Republic of the Marshall Islands",
        "countryNameLocal": "Aolepān Aorōkin Ṃajeḷ",
        "countryCode": "MH",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "692",
        "areaCodes": [],
        "region": "Pacific Ocean",
        "flag": "🇲🇭",
    },
    {
        "countryNameEn": "Commonwealth of the Northern Mariana Islands",
        "countryNameLocal": "Sankattan Siha Na Islas Mariånas",
        "countryCode": "MP",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1 670",
        "areaCodes": [],
        "region": "Pacific Ocean",
        "flag": "🇲🇵",
    },
    {
        "countryNameEn": "Turks and Caicos Islands",
        "countryNameLocal": "Turks and Caicos Islands",
        "countryCode": "TC",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1 649",
        "areaCodes": [],
        "region": "Atlantic Ocean",
        "flag": "🇹🇨",
    },
    {
        "countryNameEn": "French Southern and Antarctic Lands",
        "countryNameLocal": "Terres australes et antarctiques françaises",
        "countryCode": "TF",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "fr",
        "officialLanguageNameEn": "French",
        "officialLanguageNameLocal": "Français",
        "countryCallingCode": "672",
        "areaCodes": [],
        "region": "Indian Ocean",
        "flag": "🇹🇫",
    },
    {
        "countryNameEn": "United States Minor Outlying Islands",
        "countryNameLocal": "United States Minor Outlying Islands",
        "countryCode": "UM",
        "currencyCode": "USD",
        "currencyNameEn": "United States dollar",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "en",
        "officialLanguageNameEn": "English",
        "officialLanguageNameLocal": "English",
        "countryCallingCode": "1",
        "areaCodes": [],
        "region": "Pacific Ocean",
        "flag": "🇺🇲",
    },
    {
        "countryNameEn": "Holy See",
        "countryNameLocal": "Sancta Sedes",
        "countryCode": "VA",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "la",
        "officialLanguageNameEn": "Latin",
        "officialLanguageNameLocal": "lingua latīna",
        "countryCallingCode": "39",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇻🇦",
    },
    {
        "countryNameEn": "Republic of Kosovo",
        "countryNameLocal": "Republika e Kosovës",
        "countryCode": "XK",
        "currencyCode": "EUR",
        "currencyNameEn": "Euro",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "sq",
        "officialLanguageNameEn": "Albanian",
        "officialLanguageNameLocal": "Shqip",
        "countryCallingCode": "383",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇽🇰",

    },
    {
        "countryNameEn": "Netherlands Antilles",
        "countryNameLocal": "Nederlandse Antillen",
        "countryCode": "AN",
        "currencyCode": "ANG",
        "currencyNameEn": "Netherlands Antillean guilder",
        "tinType": "",
        "tinName": "",
        "officialLanguageCode": "nl",
        "officialLanguageNameEn": "Dutch, Flemish",
        "officialLanguageNameLocal": "Nederlands, Vlaams",
        "countryCallingCode": "599",
        "areaCodes": [],
        "region": "Europe",
        "flag": "🇧🇶",
    },
]

