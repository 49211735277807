import React, {useCallback, useMemo, useState} from "react";
import {useImportContext} from "./ImportContextProvider";
import {useVerificationResults} from "./useVerificationResults";
import clsx from "clsx";
import error from "../../../auth/error";
import {Panel} from "./Panel";
import {ImportRow} from "./useImportParser";
import {VerificationResponseItem, VerifyResponse} from "./useVerifyImport";
import {useToggle} from "./useToggle";
import {useSubmitImport} from "./useSubmitImport";
import {ErrorToggle, IconButton, NeutralToggleButton, ToggleButton, ValidToggleButton, WarningToggle} from "./inputs";
import {NavigateBefore, UploadFile} from "@mui/icons-material";
import {ProgressBar} from "./ProgressBar";
import PaginationNoneFloating from "../../../components/ui/Content/PaginationNoneFloating";
import {useChunkedArray} from "./useChunkedArray";
import Modal from "../../../components/portal/Modal";
import CloseIcon from "@mui/icons-material/Close";



type DisplayRow = {
    row: ImportRow
    index: number;
    validation: VerificationResponseItem
}

type ImportElProps = {
    headers: ImportRow
    row: ImportRow
    index: number
    warnings: string[]
    errors: string[]
    action: string
}


const ImportRowEl: React.FC<ImportElProps> = ({row, index, headers, warnings, errors, action}) => {
    const actionLabel = useMemo(() => {
        switch (action) {
            case "create":
                return "Create"
            case "update":
                return "Update"
            case "archive":
                return "Archive"
            case "unarchive":
                return "Unarchive"
            case "no_action":
                return "No Action"
        }

        return "N/A"

    }, [action])

    const style = useMemo(() => {

        const bg = (() => {
            if(errors.length > 0) return "bg-red"
            if(warnings.length > 0) return "bg-dark-orange"

            if(action == "archive") return "bg-blue"
            if(action == "unarchive") return "bg-blue"


            return "bg-green"
        })()


        return clsx("w-full px-8 py-4 rounded-md text-white shadow-md", bg)



    }, [warnings, errors, action])

    return (
        <div className={style}>
            <div className="font-semibold mb-2">Row {index + 1} ({actionLabel})</div>
            <div className="grid grid-cols-5 gap-4 w-full text-sm">
                {row.map((field, j) => (
                    <div key={j}>
                        <div className="font-semibold">{headers[j]}</div>
                        <div className={"truncate"}>{field || "\u00A0"}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}


export const ImportVerifyResultsPage: React.FC = () => {
    const {
        verificationResults,
        headers,
        rows,
        sendInvitationEmails,
        departmentsCreated,
        setSendInvitationEmails,
        gotoStage,
        clear,
        name,
    } = useImportContext();

    const {
        createCount,
        updateCount,
        archiveCount,
        unarchiveCount,
        verificationErrors,
        verificationWarnings,
        validCount,
    } = useVerificationResults(verificationResults);

    const [showCreate, toggleShowCreate] = useToggle()
    const [showUpdate, toggleShowUpdate] = useToggle()
    const [showArchive, toggleShowArchive] = useToggle()
    const [showUnarchive, toggleShowUnarchive] = useToggle()

    const [page, setPage] = useState(1);
    const [rpp] = useState(10)


    const {submit, submitting, error, progress} = useSubmitImport(sendInvitationEmails, name)

    const back = useCallback(() => {
        clear()
        gotoStage("select_file")
    }, [])

    const [showBack, toggleBack, setShowBack] = useToggle(false)

    const [hideErrors, setHideErrors] = useState<string[]>([]);

    const toggleHideError = useCallback((error: string) => {
        setHideErrors((prevErrors) => prevErrors.includes(error) ? prevErrors.filter((e) => e !== error) : [...prevErrors, error]);
    }, []);


    const displayRows = useMemo<DisplayRow[]>(() => {


        let data = rows.map((row, i) => ({index: i, row, validation: verificationResults[i]}))
        data = data.filter((d) => {
            let errors = [...d.validation.errors, ...d.validation.warnings];
            hideErrors.forEach(err => errors = errors.filter((e) => e !== err))

            return errors.length > 0 ||
                (showCreate && d.validation.action == "create") ||
                (showUpdate && d.validation.action == "update") ||
                (showArchive && d.validation.action == "archive") ||
                (showUnarchive && d.validation.action == "unarchive")
        })

        return data
    }, [rows, showCreate, showUpdate, showArchive, showUnarchive, hideErrors, verificationResults]);

    const displayRowsPages = useChunkedArray(displayRows, 10)
    const displayRowsPage = useMemo(() => displayRowsPages[page - 1], [displayRowsPages, page])

    return (
        <>
            <Modal open={showBack} setOpen={setShowBack}>
                <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                    <p className={"font-semibold"}>Are you sure you want to cancel this import?</p>
                    <IconButton text={"Cancel Import"} color={"orange"} icon={<CloseIcon/>} onClick={back}/>

                </div>
            </Modal>

            <Panel>
                <h2 className="text-lg font-semibold">Import Verification Results</h2>

                <h3 className="font-semibold">New Departments</h3>

                {
                    departmentsCreated.length > 0 ? (
                        <div className={"grid grid-cols-4 gap-4 w-full"}>
                            {departmentsCreated.map(d => (
                                <div
                                    className={"text-center bg-grey shadow-md rounded-md text-white px-4 py-2 text-sm"}>{d}</div>
                            ))}
                        </div>
                    ) : (
                        <p>No new departments will be created.</p>
                    )

                }

                <h3 className="font-semibold">Filter Results</h3>

                <div className="grid grid-cols-4 gap-8 w-full">
                    {verificationErrors.map((e) => (
                        <ErrorToggle key={e.key} error={e} isHidden={hideErrors.includes(e.key)}
                                     toggle={toggleHideError}/>
                    ))}

                    {verificationWarnings.map((e) => (
                        <WarningToggle key={e.key} warning={e} isHidden={hideErrors.includes(e.key)}
                                       toggle={toggleHideError}/>
                    ))}
                    <ValidToggleButton label={"New Users"} enabled={showCreate} toggle={toggleShowCreate}
                                       count={createCount}/>
                    <ValidToggleButton label={"Updated Users"} enabled={showUpdate} toggle={toggleShowUpdate}
                                       count={updateCount}/>

                    <NeutralToggleButton label={"Archive Users"} enabled={showArchive} toggle={toggleShowArchive}
                                       count={archiveCount}/>

                    <NeutralToggleButton label={"Unarchive Users"} enabled={showUnarchive} toggle={toggleShowUnarchive}
                                       count={unarchiveCount}/>
                </div>


                {!submitting && validCount > 0 && (
                    <>
                        <h3 className="font-semibold">Submit Import</h3>
                        <div className="flex flex-col gap-8 w-72">

                            <ToggleButton text={"Send Invitation Emails"} initialValue={false}
                                          onChange={setSendInvitationEmails}/>

                            <IconButton text={"Submit Import"} icon={<UploadFile/>} onClick={submit}/>

                        </div>
                    </>
                )}

                {!submitting && (<div className="flex flex-col gap-8 w-72">
                    <IconButton text={"Cancel Import"} color={"orange"} icon={<NavigateBefore/>} onClick={toggleBack}/>
                </div>)}

                {submitting && rows && (
                    <div className={"w-full"}>
                        <ProgressBar current={progress} total={rows.length}/>
                    </div>
                )}

                <h3 className="font-semibold">Verification Results</h3>

                {displayRowsPage && displayRowsPage.map(({row, index, validation}) => (
                    <ImportRowEl key={index} row={row} headers={headers} index={index} errors={validation.errors}
                                 warnings={validation.warnings}
                                 action={validation.action}/>
                ))}

                <PaginationNoneFloating
                    currentPage={page}
                    itemsPerPage={rpp}
                    dataLength={displayRows.length}
                    togglePageSelect={setPage}
                />

            </Panel>
        </>
    );
};