import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { Close, Edit } from "@mui/icons-material"
import { apiV2 } from "../../../../../api/axiosConfig"
import { CircularProgress } from "@mui/material"
import { Client, Department } from "../../../../../types/userManagement"
import DepartmentSelect from "../../../../../components/form-v2/searchable-select/DepartmentSelect"
import ClientSelect from "../../../../../components/form-v2/searchable-select/ClientSelect"
import {useAuth, UserContext } from "../../../../../context/user"
import { useTimeZoneSelectContext } from "../../../../../providers/TimeZoneSelectProvider"
import { useQuery, useQueryClient } from "react-query"
import { useGetReportDataSingle } from "../../../../../lib/reports/useGetReportDataSingle"
import {ToggleButton} from "../../../../../features/management-v2/import/inputs";

interface ReportCount {
  total: number
}

interface Document {
  _id: string
  clientName: string
  source: string
  timestamp: string
  type: string
  userName: string
}

export interface ReportResult {
  count: ReportCount[]
  documents: Document[]
}

export interface ReportData {
  type: string;
  timeframe: string;
  title: string;
  clientID: string;
  clientName?: string;
  departmentIDs: string[];
  departments?: string[];
  dataSourceDepartmentIDs: string[];
  dataSourceDepartments: string[];
  results: ReportResult[];
  startDate?: string;
  endDate?: string;
  allUsers: boolean;
}


export interface ReportErrorResponse {
  message: string
}

export type ApiResponse = ReportData | ReportErrorResponse
type ReportError = string | null

interface EditOverlayProps {
  closeEditOverlay: () => void
  reportName: string
  setReportName: React.Dispatch<React.SetStateAction<string>>
  customRangeStart: string
  setCustomRangeStart: React.Dispatch<React.SetStateAction<string>>
  customRangeEnd: string
  setCustomRangeEnd: React.Dispatch<React.SetStateAction<string>>
  customReportID: string
  clientName: string
  clientID: string
  initialTimeframe?: string
  onSave: () => void
  isClone?: boolean;
  departments?: { id: string; name: string }[];
  dataSourceDepartments?: { id: string; name: string }[];
}

const EditOverlay: React.FC<EditOverlayProps> = ({
  closeEditOverlay,
  reportName,
  setReportName,
  customRangeStart,
  setCustomRangeStart,
  customRangeEnd,
  setCustomRangeEnd,
  customReportID,
  clientName,
  clientID,
  initialTimeframe = "past7",
  onSave,
  isClone = false,
  departments: initialDepartments,
  dataSourceDepartments: initialDataSourceDepartments,
}) => {
  const [reportData, setReportData] = useState<ReportData | null>(null)
  const [error, setError] = useState<ReportError>(null)
  const [queryError, setQueryError] = useState<ReportError>(null)
  const [noData, setNoData] = useState(false)
  const [timeframe, setTimeframe] = useState(initialTimeframe)
  const [currentPage, setCurrentPage] = useState(1)
  const rowsPerPage = 10
  const [customDateLabel, setCustomDateLabel] = useState("Custom Date")
  const [isSaving, setIsSaving] = useState(false)
  const [saveError, setSaveError] = useState<ReportError>(null)
  const [singleDocData, setSingleDocData] = useState<ReportData | null>(null)
  const [selectedData, setSelectedData] = useState<ReportData | null>(null);
  const [tableLoading, setTableLoading] = useState(false)
  const [client, setClient] = useState<Client | undefined>(undefined);

  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [pendingTimeframe, setPendingTimeframe] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(customRangeStart || "");
  const [customEndDate, setCustomEndDate] = useState(customRangeEnd || "");

  const [allUsers, setAllUsers] = useState(false)
  const [initialAllUsers, setInitialAllUsers] = useState<boolean>()

  const userContext = useContext(UserContext)!
  const accessLevel = userContext?.role?.auroraAccessLevel
  const tzCtx = useTimeZoneSelectContext()
  const timezone = tzCtx.timeZone
  const [departments, setDepartments] = useState<Department[]>([]);
  const [dataSourceDepartments, setDataSourceDepartments] = useState<Department[]>([]);
  const auth = useAuth()
  useEffect(() => {
    setDepartments((initialDepartments || []) as Department[]);
    setDataSourceDepartments((initialDataSourceDepartments || []) as Department[]);
  }, [initialDepartments?.join(""), initialDataSourceDepartments?.join("")]);

  const dataSourceDepartmentIDs = useMemo(() => {
      return dataSourceDepartments.map((dept) => dept.id)
      .join(",");
  }, [dataSourceDepartments]);

  useEffect(() => {
    if(initialDataSourceDepartments){
      setDataSourceDepartments(initialDataSourceDepartments as Department[])
    }
  }, [initialDataSourceDepartments]);


  const { data, status, error: queryError2, refetch, isLoading: isLoadingQuery, isSuccess } = useGetReportDataSingle(
    customReportID,
    timeframe,
    timezone,
    timeframe === "custom" ? customRangeStart : undefined,
    timeframe === "custom" ? customRangeEnd : undefined,
    dataSourceDepartmentIDs  );


  const [loaded,setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if(!data) return
    if(loaded) return
    setLoaded(true)

    const deps: Department[] = []
    const rep = data as ReportData;

    rep.dataSourceDepartmentIDs.forEach((id, i) => {
      deps.push({
        id,
        name: rep.dataSourceDepartments[i],
      } as Department);
    })


    setDataSourceDepartments(deps)
    setInitialAllUsers(rep.allUsers)
    setAllUsers(rep.allUsers)


  }, [data]);

  const queryClient = useQueryClient();


  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    console.log("departments", departments)
    const fetchedData = data as ReportData;
    setReportData(fetchedData);
    setNoData(false);

    if (fetchedData.timeframe === "custom" || fetchedData.timeframe === "") {
      if (fetchedData.startDate && fetchedData.endDate) {
        setCustomRangeStart(fetchedData.startDate.split("T")[0]);
        setCustomRangeEnd(fetchedData.endDate.split("T")[0]);
        setCustomDateLabel(
          `${new Date(fetchedData.startDate).toLocaleDateString("en-GB")} - ${new Date(
            fetchedData.endDate
          ).toLocaleDateString("en-GB")}`
        );
        setTimeframe("custom");
      }
    } else {
      setTimeframe(fetchedData.timeframe);
    }
  }, [isSuccess, data]);


  const handleCellClick = async (tf: string) => {
    setTableLoading(true)
    try {
      const response = await apiV2.get<ReportData>("/reporting/report-data-single-documents", {
        params: { customReportID, timeframe: tf },
      })
      setSingleDocData(response.data)
    } catch (error) {
    } finally {
      setTableLoading(false)
    }
  }


  const renderAggregation = () => {
    if (noData) return <p className="text-center text-gray-500">No data found.</p>;
    if (!reportData) return null;
    if (selectedData) {
      return renderTableReport(selectedData);
    }

    const colTitle = reportData.title;
    const allValues: any[] = [];
    reportData.results.forEach((row) => {
      allValues.push(...Object.values(row));
    });

    return (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg">
          <thead>
            <tr>
              <th className="py-4 px-6 text-center pt-10 text-lg font-bold text-gray-700">{colTitle}</th>
            </tr>
          </thead>
          <tbody>
          {allValues.length ? (
            allValues.map((val, i) => {
              if (reportData.type === "value-percent") {
                val = `${parseFloat(val).toFixed(1)}%`;
              }
              return (
                <tr key={i} className="hover:bg-gray-50 transition-colors">
                  <td
                    className="py-4 px-6 text-3xl font-medium text-center text-dark-dark-grey"
                  >
                    {tableLoading ? (
                      <CircularProgress size={24} color="primary" sx={{ color: "#9e9e9e" }} />
                    ) : (
                      val
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
              <tr>
                <td className="py-4 px-6 text-center text-gray-500">0</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTableReport = (data: ReportData) => {
    if (!data || data.results.length === 0) {
      return (
        <div className="text-center text-gray-500">
          <p>No data available.</p>
          <p>0</p>
        </div>
      );
    }

    const result = data.results[0];
    const totalCount = result.count?.[0]?.total ?? 0;
    const documents = result.documents || [];

    if (!documents.length) {
      return (
        <div className="text-center text-gray-500">
          <p>No documents available.</p>
          <p>Total: {totalCount}</p>
        </div>
      );
    }

    const columns = Object.keys(documents[0]) as Array<keyof Document>;

    // Pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = documents.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(documents.length / rowsPerPage);

    const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
    const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

    return (
      <div className="overflow-x-auto">
        <div className="mb-4 flex justify-between items-center">
          <p className="text-gray-700">
            <strong>Total Records:</strong> {totalCount}
          </p>
          <div>
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="px-3 py-1 text-sm bg-gray-200 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Previous
            </button>
            <span className="mx-2 text-gray-700 text-sm">{currentPage} / {totalPages}</span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="px-3 py-1 text-sm bg-gray-200 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Next
            </button>
          </div>
        </div>
        <table className="min-w-full bg-white border border-gray-200 rounded-md shadow-sm">
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th
                  key={index}
                  className="py-2 px-4 text-left text-sm font-bold text-gray-700 border-b border-gray-200 bg-gray-50"
                >
                  {formatColumnName(col)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-gray-100 transition-colors">
                {columns.map((col, colIndex) => (
                  <td
                    key={colIndex}
                    onClick={() => console.log(`Clicked on ${col}: ${row[col]}`)}
                    className="py-2 px-4 text-sm text-gray-700 border-b border-gray-200 cursor-pointer"
                  >
                    {renderCellContent(row[col])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };


  const renderTableReport2 = (data: ReportData) => {
    if (!data || data.results.length === 0) {
      return (
        <div className="text-center text-gray-500">
          <p>No data available.</p>
          <p>0</p>
        </div>
      );
    }

    const documents = data.results; // Assuming `results` contains the document-like data
    const totalCount = documents.length;

    if (!documents.length) {
      return (
        <div className="text-center text-gray-500">
          <p>No documents available.</p>
          <p>Total: {totalCount}</p>
        </div>
      );
    }

    const columns = Object.keys(documents[0]) as Array<keyof typeof documents[0]>;

    // Pagination logic
    const startIndex = currentPage * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, documents.length);
    const currentRows = documents.slice(startIndex, endIndex);
    const totalPages = Math.ceil(documents.length / rowsPerPage);

    const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
    const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

    return (
      <div>
        <div className="overflow-auto rounded border border-gray-300 shadow-md">
          <table className="min-w-full text-sm">
            <thead>
              <tr className="bg-gray-100 text-left text-gray-600 font-semibold">
                {columns.map((header, index) => (
                  <th key={index} className="px-3 py-2.5 border-b border-gray-300">
                    {formatColumnName(header)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={`border-b last:border-none transition-colors ${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                    }`}
                >
                  {columns.map((col, colIndex) => (
                    <td
                      key={colIndex}
                      className="px-3 py-2 text-gray-700 whitespace-nowrap"
                    >
                      {renderCellContent(row[col])}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between mt-4">
          <button
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <p className="text-gray-700">
            Page {currentPage} of {totalPages}
          </p>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleEditClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };


  const renderCellContent = (value: any) => {
    if (typeof value === "object" && value !== null) {
      if ("timestamp" in value) return new Date(value.timestamp).toLocaleString()
      return <pre className="whitespace-pre-wrap text-xs">{JSON.stringify(value, null, 2)}</pre>
    }
    return value
  }

  const formatColumnName = (columnName: string) => {
    const withSpaces = columnName.replace(/_/g, " ")
    return withSpaces.replace(/\b\w/g, (c) => c.toUpperCase())
  }

  const handleSaveReport = async () => {
    setIsSaving(true);
    setSaveError(null);

    if (timeframe === "custom") {
      if (!customRangeStart || !customRangeEnd) {
        setSaveError("Please select both start and end dates.");
        setIsSaving(false);
        return;
      }
      if (new Date(customRangeStart) > new Date(customRangeEnd)) {
        setSaveError("Start date cannot be after end date.");
        setIsSaving(false);
        return;
      }
    }
    const payload: any = {
      customReportID,
      allUsers,
      reportName,
      timeframe: timeframe !== "custom" ? timeframe : "custom",
      departmentIDs: departments.map((dept) => dept.id), // Include departmentIDs
      dataSourceDepartmentIDs: dataSourceDepartments.map((dept) => dept.id), // Include dataSourceDepartmentIDs
    };

    if (timeframe === "custom") {
      payload.startDate = customRangeStart;
      payload.endDate = customRangeEnd;
    }

    try {
      const endpoint = isClone
        ? "/reporting/clone-custom-report"
        : "/reporting/update-custom-report";

      const method = isClone ? "post" : "patch";

      await apiV2[method](endpoint, payload);
      queryClient.invalidateQueries({ queryKey: ["useGetReportDataQuery"], exact: false });

      closeEditOverlay();
      onSave();
    } catch (err) {
      if (err instanceof Error) {
        setSaveError(err.message);
      } else {
        setSaveError("An unexpected error occurred while saving the report.");
      }
    } finally {
      setIsSaving(false);
    }
  };


  const handleTimeframeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTimeframe = e.target.value;
    if (selectedTimeframe === "custom") {
      setPendingTimeframe(true);
    } else {
      setTimeframe(selectedTimeframe);
      setPendingTimeframe(false);
    }
  };


  const handleApplyCustomDate = () => {
    if (customStartDate && customEndDate) {
      setCustomRangeStart(customStartDate);
      setCustomRangeEnd(customEndDate);
      setTimeframe("custom");
      const formattedLabel = `${new Date(customStartDate).toLocaleDateString()} - ${new Date(customEndDate).toLocaleDateString()}`;
      setCustomDateLabel(formattedLabel);
      setPendingTimeframe(false);
    } else {
      console.warn("Both start and end dates must be selected.");
    }
  };


  const handleDropdownClick = () => {
    if (timeframe === "custom" && !pendingTimeframe) {
      setPendingTimeframe(true); // Reopen the date picker UI
    }
  };


  const handleTableTimeframeChange = async (newTimeframe: string) => {
    setTableLoading(true)
    setTimeframe(newTimeframe)
    try {
      const response = await apiV2.get<ReportData>("/reporting/report-data-single-documents", {
        params: { customReportID, timeframe: newTimeframe },
      })
      setSingleDocData(response.data)
    } catch (error) {
      console.error("Error fetching document data:", error)
    } finally {
      setTableLoading(false)
    }
  }


  const handleAggregationClick = async () => {
  }


  
  // if (isClone) {
  //   const timeframeDisplay = getTimeframeDisplay(initialTimeframe)
  //   return (
  //     <div className="fixed inset-0 flex items-center justify-center z-50">
  //       <div className="absolute inset-0 backdrop-blur-sm backdrop-brightness-50" onClick={closeEditOverlay} />
  //       <div className="relative bg-white w-1/3 p-6 rounded-lg shadow-lg z-100">
  //       <div className="flex justify-between items-center mb-4">
  //         <div className="flex items-center w-full">
  //           <input
  //             ref={inputRef}
  //             type="text"
  //             value={reportName}
  //             onChange={(e) => setReportName(e.target.value)}
  //             onFocus={handleEditClick}
  //             className="text-xl font-semibold bg-blue border border-light-grey rounded-lg p-2 text-white focus:border-white outline-none w-full"
  //           />
  //           <Edit fontSize="small" className="ml-2 text-light-grey hover:text-white cursor-pointer" onClick={handleEditClick} />
  //         </div>
  //         <button className="text-white hover:text-white-blue" onClick={closeEditOverlay}>
  //           <Close />
  //         </button>
  //       </div>
  //         <div className="bg-gray-100 p-4 rounded mb-4">
  //           <p className="text-sm">
  //             <strong>Client:</strong> {clientName}
  //           </p>
  //           <p className="text-sm">
  //             <strong>Departments:</strong>{" "}
  //             {departments && departments.length
  //               ? departments.map((dept) => dept.name).join(", ")
  //               : "All"}
  //           </p>
  //           <p className="text-sm">
  //             <strong>Timeframe:</strong> {timeframeDisplay}
  //           </p>
  //         </div>
  //         {saveError && <p className="text-red-500 mb-3 text-xs">{saveError}</p>}
  //         <button
  //           type="button"
  //           onClick={handleSaveReport}
  //           disabled={isSaving}
  //           className={`w-full h-10 rounded shadow bg-blue text-white text-xs ${
  //             isSaving ? "opacity-50 cursor-not-allowed" : ""
  //           }`}
  //         >
  //           {isSaving ? "Cloning..." : "Clone Report"}
  //         </button>
  //       </div>
  //     </div>
  //   )
  // }


  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 backdrop-blur-sm backdrop-brightness-50" onClick={closeEditOverlay} />
      <div className="relative bg-white-blue w-3/4 h-4/5 p-0 pb-16 mt-20 rounded-lg shadow-lg z-100 overflow-hidden animate-[zoom-in_0.2s_ease-in-out_forwards]">
        <div className="bg-blue w-full flex items-center justify-between p-2">
        <div className="w-3/5 py-2">
        <input
          ref={inputRef}
          type="text"
          value={reportName}
          onChange={(e) => setReportName(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="h-1/5 w-4/5 text-xl font-semibold bg-blue border-2 border-light-grey rounded-lg p-2 text-white hover:border-light-grey focus:border-white focus:ring-0 outline-none"
        />
          <Edit
            fontSize="small" 
            className={`ml-2 mb-2 cursor-pointer ${
              isFocused ? "text-white" : "text-light-grey hover:text-white"
            }`}
            onClick={handleEditClick}
          />
          </div>
          <h2 className="text-sm text-dark-dark-grey font-medium">{clientName}</h2>
          <button className="text-white hover:text-white-blue" onClick={closeEditOverlay}>
            <Close />
          </button>
        </div>
        <div className="w-full h-full p-4 grid gap-4 grid-cols-1 md:grid-cols-3">
          <div className="md:col-span-2 shadow rounded bg-white p-3 overflow-auto">
            <h1 className="text-base font-medium text-gray-800 mb-2">Report Preview</h1>
            {isLoadingQuery && (
              <div className="flex flex-col justify-center mt-6 items-center">
                <CircularProgress size={30} sx={{ color: "#9e9e9e" }} />
                <p className="text-gray-500 mt-1 text-sm">Loading report data...</p>
              </div>
            )}
            {error && <p className="text-red-500 mb-2 text-sm">An error occurred: {error}</p>}
            {noData && <p className="text-gray-500 mb-2 text-sm">No data found.</p>}
                {
                  renderAggregation()
                }
          </div>

          <div className="flex flex-col h-full">
            <div className="bg-white p-4 flex-grow rounded shadow">
              <h2 className="text-sm font-medium mb-2">Filters</h2>
              <div className="mb-3">
                <select
                  value={timeframe === "custom" && !pendingTimeframe ? "custom" : timeframe}
                  onChange={handleTimeframeChange}
                  onClick={handleDropdownClick}
                  className="h-9 w-full shadow rounded px-3 outline-none bg-white text-xs"
                >
                  <option value="">Select Timeframe</option>
                  <option value="past1">Past Day</option>
                  <option value="past7">Past Week</option>
                  <option value="past30">Past 30 Days</option>
                  <option value="mtd">Month To Date</option>
                  <option value="ytd">Year To Date</option>
                  <option value="custom">{customDateLabel !== "Custom Date" ? customDateLabel : "Custom Date"}</option>
                </select>
              </div>
              {pendingTimeframe && (
                <div className="flex flex-col space-y-2 mt-2">
                  <label className="block text-gray-700 font-medium text-xs">Start Date:</label>
                  <input
                    type="date"
                    className="w-full border border-gray-300 p-1 rounded text-xs"
                    value={customStartDate}
                    onChange={(e) => setCustomStartDate(e.target.value)}
                  />
                  <label className="block text-gray-700 font-medium text-xs mt-2">End Date:</label>
                  <input
                    type="date"
                    className="w-full border border-gray-300 p-1 rounded text-xs"
                    value={customEndDate}
                    onChange={(e) => setCustomEndDate(e.target.value)}
                  />
                  <button
                    onClick={handleApplyCustomDate}
                    disabled={!customStartDate || !customEndDate}
                    className={`mt-2 px-4 py-1 text-xs rounded text-white ${customStartDate && customEndDate ? "bg-blue hover:bg-blue-700" : "bg-light-grey cursor-not-allowed"
                      }`}
                  >
                    Apply Custom Date
                  </button>
                </div>
              )}

              {(accessLevel === "all" || accessLevel === "client" || accessLevel === "department") && (
                <div className={"flex flex-col gap-2"}>
                  <h3 className="text-xs font-medium mt-3">Data Source:</h3>
                  {auth.hasAccessLevelClient && initialAllUsers != undefined && <ToggleButton text={"All Users"} onChange={setAllUsers} initialValue={initialAllUsers} />}
                  {!allUsers && <DepartmentSelect
                    departments={dataSourceDepartments}
                    multiple={true}
                    clientID={clientID}
                    onSelectionChange={(departments) => {
                      setDataSourceDepartments(departments || []);
                    }}
                    allowSelectAll={true}
                  />}
                </div>
              )}
            </div>


            {saveError && <p className="text-red-500 mb-3 text-xs">Error: {saveError}</p>}
            <button
              type="button"
              onClick={handleSaveReport}
              disabled={isSaving}
              className={`w-full h-12 text-white mt-24 rounded shadow bg-blue hover:0 transition-colors duration-200 text-xs ${isSaving ? "opacity-50 cursor-not-allowed" : ""
                }`}
            >
              {isSaving ? (isClone ? "Cloning..." : "Saving...") : isClone ? "Clone Report" : "Save Report"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

}

export default EditOverlay
