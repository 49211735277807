import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import useDebouncer from "../../../hooks/debouncer";
import { filter } from "rxjs";
import { Department } from "../../../types/userManagement";
import LoadingSpinner from "../../ui/LoadingSpinner";
import DoneIcon from "@mui/icons-material/Done";

export type SearchableSelectOption<T> = {
  value: T;
  key: string;
  label: string;
  search?: string;
  selected?: boolean;
};

type SearchableSelectParams<T> = {
  label?: string;
  multiple: boolean;
  icon?: string;
  options: SearchableSelectOption<T>[];
  selected: SearchableSelectOption<T>[];
  search: boolean;
  localSearch: boolean;
  key?: string;
  onChange: (selected: SearchableSelectOption<T>[]) => void;
  onSearch?: (searchStr: string) => void;
  debounceSearch?: number;
  sortBy?: string|string[];
  loading?: boolean;
  disabled?: boolean;
  enableSelectAll?: boolean;
};

function SearchableSelect<T>({
  label,
  multiple,
  icon,
  options,
  search,
  selected,
  localSearch,
  onSearch,
  debounceSearch,
  onChange,
  sortBy,
  loading,
  disabled,
  enableSelectAll,
}: SearchableSelectParams<T>) {

  const handleSelectAll = () => {
    if (isAllSelected()) {
      // Unselect all
      onChange([]);
    } else {
      // Select all
      onChange([...options]);
    }
  };

  const isAllSelected = () => {
    return selected.length === options.length;
  };
  const parentRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      parentRef.current &&
      !parentRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (open && searchRef && searchRef.current) {
      searchRef.current.focus();
    }
  }, [open]);

  const [searchStr, setSearchStr] = useState("");
  const searchStrDebounced = useDebouncer(searchStr, debounceSearch || 500);

  function searchArray(query: string, array: SearchableSelectOption<T>[]): SearchableSelectOption<T>[] {
    const normalizedQuery = query.toLowerCase().split(" ");
  
    return array.filter(item =>
      normalizedQuery.every(word =>
        item?.search?.toLowerCase().includes(word)
      )
    );
  }

  function orderByKey<T>(input: keyof T | keyof T[], array: SearchableSelectOption<T>[]): SearchableSelectOption<T>[] {
    if(!input){
      return array
    }

    const isArray = Array.isArray(input);
  
    return array.sort((a, b) => {
      if (isArray) {
        // If input is an array of keys, compare each key
        for (const i of input) {
          const key = i as keyof T
          if (a.value[key] < b.value[key]) return -1;
          if (a.value[key] > b.value[key]) return 1;
        }
        return 0;
      } else {
        // If input is a single key, compare that key
        const key = input as keyof T;
        if (a.value[key] < b.value[key]) return -1;
        if (a.value[key] > b.value[key]) return 1;
        return 0;
      }
    });
  }


  const availableOptions = (): SearchableSelectOption<T>[]   => {

    const filtered = (() => {
      if(!localSearch){
        return options
      }
  
      return searchArray(searchStrDebounced, options)
    })().filter(option =>
      !selected.some(selectedOption => selectedOption.key === option.key)
    );


    return [...orderByKey(sortBy as keyof T, selected), ...orderByKey(sortBy as keyof T, filtered)]
  }

  function isSelected(item: SearchableSelectOption<T>) {
    return selected.some(i => i.key === item.key);
  }

  useEffect(() => {
    if (onSearch && !localSearch) {
      onSearch(searchStrDebounced);
    }
  }, [searchStrDebounced]);


  const displayLabel = useMemo(() => {
    if(selected.length == 0) return label
    if(selected.length == 1) return selected[0].label
    return `${selected.length} Selected`
  }, [selected])

  const noneSelected = useMemo(() => {
    return selected.length == 0
  }, [selected])

  return (
    <div className={`h-fit w-full relative z-auto ${disabled ? "opacity-60" : ""}`} ref={parentRef}>
      <button
        className={`h-10 w-full shadow-md rounded-md flex items-center justify-between px-4 ${open ? "outline outline-blue" : ""}`}
        type="button"
        onClick={() => {
          if(disabled) return
          if(!loading){
            setOpen(!open);
          }
        }}
      >
        <div className="flex gap-4 overflow-x-hidden whitespace-nowrap">
          {icon ? <img src={icon} alt="" /> : null}

          <span className={noneSelected ? "opacity-70" : ""}>{displayLabel}</span>
          
        </div>
        <div
          className={`pointer-events-none transition-all ${
            open ? "-scale-y-100" : ""
          }`}
        >
          {loading ?  <LoadingSpinner height={16} width={16} color="#716F6F"/> :<KeyboardArrowDown /> }
        </div>
      </button>

      <div
        className={`bg-white absolute top-12 left-0 right-0 z-50 rounded-md shadow-md ${
          open ? "inline" : "hidden"
        }`}
      >
        <div className="w-full my-4 flex flex-col">
          {search && <input
            type="text"
            className="h-8 w-4/5 rounded-sm shadow-sm mx-auto px-4"
            placeholder="Search"
            ref={searchRef}
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
          />}

      <ul className="max-h-32 h-full overflow-y-auto my-4 ">
          {enableSelectAll && (
              <li
                onClick={handleSelectAll}
                className="cursor-pointer px-4 hover:bg-dark-blue hover:bg-opacity-50 hover:text-white bg-white py-2 transition-colors"
              >
                <div className="flex gap-4 text-sm mb-1">
                  <div className="relative w-4 min-w-4 h-4 flex items-center justify-center flex-none items-center content-center">
                    <input
                      type="checkbox"
                      className="h-full w-full z-10 relative opacity-0 cursor-pointer"
                      checked={isAllSelected()}
                      readOnly
                    />
                    <button className="w-full h-full absolute border top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 flex items-center justify-center">
                      {isAllSelected() ? (
                        <DoneIcon style={{ fontSize: "14px" }} />
                      ) : null}
                    </button>
                  </div>
                  <label className="leading-none">Select All</label>
                </div>
              </li>
            )}
          {availableOptions().map((option, index) => (
            <li
              key={option.key}
              onClick={() => {
                if(!multiple){
                  onChange(isSelected(option) ? [] : [option])
                  setOpen(false)
                  return
                }

                if(!isSelected(option)){
                  onChange([...selected, option])
                } else {
                  onChange(selected.filter(s => s.key != option.key))
                }
              }}
              className={"cursor-pointer px-4 hover:bg-dark-blue hover:bg-opacity-50 hover:text-white bg-white py-2 transition-colors"}
            >

          <div className="flex gap-4 text-sm mb-1">
                <div className="relative w-4 min-w-4 h-4 flex items-center justify-center flex-none items-center content-center">
                  <input
                    type="radio"
                    className="h-full w-full z-10 relative opacity-0 cursor-pointer"
                  />
                  <button className="w-full h-full absolute border top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 flex items-center justify-center">
                    {isSelected(option) ? (
                      <DoneIcon style={{ fontSize: "14px" }} />
                    ) : null}
                  </button>
                </div>
                <label className="leading-none">{option.label}</label>
              </div>
            </li>
          ))}
        </ul>
        </div>
      </div>
    </div>
  );
}

export default SearchableSelect;
