import React from "react";
import SearchableSelect, {SearchableSelectOption} from "./index";
import Icon from "../../../assets/icons/exclamation-warning.svg";
import {languages} from "../../../constants/languages";


type LanguageSelectProps = {
  value: string | undefined;
  setValue: (value: string | undefined) => void;
}

export const LanguageSelect: React.FC<LanguageSelectProps> = (props) => {

  const componentOptions = languages.map((l) => {
    return {
      value: l.code,
      key: l.code,
      label: l.name,
      search: l.name,

    }
  })

  const selected: SearchableSelectOption<string> | undefined  = componentOptions.find(l => l.key === props.value)

  function handleChange(values: SearchableSelectOption<string>[]) {

    if (values.length == 0) {
      props.setValue(undefined)
      return
    }

    props.setValue(values[0].value)
  }

  return <SearchableSelect
      label={"Language"}
      multiple={false} options={componentOptions} selected={selected ? [selected] : []} search={true}
      localSearch={true}
      onChange={handleChange}/>
}

type LanguageSelectMultipleProps = {
  value: string[];
  setValue: (value: string[]) => void;
}

export const LanguageSelectMultiple: React.FC<LanguageSelectMultipleProps> = (props) => {

  const componentOptions = languages.map((l) => {
    return {
      value: l.code,
      key: l.code,
      label: l.name,
      search: l.name,
    }
  })

  const selected = componentOptions.filter(o => props.value.includes(o.value))



  function handleChange(values: SearchableSelectOption<string>[]) {

    if (values.length == 0) {
      props.setValue([])
      return
    }

    props.setValue(values.map(v => v.value))

  }

  return <SearchableSelect
      label={"Language"}
      multiple={true} options={componentOptions} selected={selected} search={true}
      localSearch={true}
      onChange={handleChange}/>
}