import React, {useCallback, useContext, useEffect, useMemo} from "react";
import WhiteLogoImage from "../../../assets/icons/ngs-white-blue.svg";
import {useAuth, UserContext} from "../../../context/user";
import {useQuery} from "react-query";

function WhiteLogo() {
    const auth = useAuth();


    const loadImage = useCallback(async () => {




        const url = `${process.env.REACT_APP_API_URL}files/platform-logo/${auth.clientID}`
        return await fetch(url).then(async (res) => {
            if (!res.ok) return
            return await res.blob()
        })


    }, [auth.clientID])

    const {data, isLoading} = useQuery(
        ["platform-logo", auth.clientID],
        loadImage,
    )

    const image = useMemo(() => {
        if(data) return URL.createObjectURL(data)
        return WhiteLogoImage
    }, [data])

    const Content = useCallback(() => {
        if (isLoading) return null
        return (
            <img
                src={image}
                alt="logo of the company"
                className="h-fill w-auto max-h-30 max-w-[200px] object-contain"
            />
        )

    }, [isLoading, data, image])

    return (
        <div className="w-full h-full flex justify-center items-center">
            <Content/>
        </div>
    );
}

export default WhiteLogo;
