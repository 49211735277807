import { useOidcAccessToken } from "@axa-fr/react-oidc"
import { AccessTime, AddCircleOutline, ArrowBack, Close, ContentCopy, DashboardCustomizeRounded, Edit, FileDownload, Fullscreen, FullscreenExit, InfoOutlined, MoreVert, Schedule, Settings } from "@mui/icons-material"
import { CircularProgress, LinearProgress, Menu, MenuItem } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import GridLayout from "react-grid-layout"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { apiV2 } from "../../../../../api/axiosConfig"
import { apiPOST } from "../../../../../apiv2/request"
import DepartmentSelect from "../../../../../components/form-v2/searchable-select/DepartmentSelect"
import {useAuth, UserContext} from "../../../../../context/user"
import { useGetDashboardLayout } from "../../../../../lib/reports/useGetDashboardLayout"
import { useTimeZoneSelectContext } from "../../../../../providers/TimeZoneSelectProvider"
import { Department } from "../../../../../types/userManagement"
import { useClientDashboard } from "./ClientDashboardProvider"
import DashboardEditOverlay from "./DashboardEditOverlay"
import NewReportEditOverlay from "./NewReportEditOverlay"
import EditOverlay from "./ReportsEditOverlay"
import { mdiCheckCircleOutline } from "@mdi/js"
import Icon from "@mdi/react"
import ScheduleOverlay, { Recurrence, TimeZoneConfig } from "./ScheduleOverlay"
import { UserDataTypes } from "../../../../../types/user-data-types"
import Modal from "../../../../../components/portal/Modal";
import {ToggleButton} from "../../../../../features/management-v2/import/inputs";
import BasicText from "../../../../../components/form-v2/basic-text";

interface OutletContext {
    clientID?: string;
}


interface WebSocketReportDetails {
    title: string
    response: string | number | boolean | object | null
}

interface WebSocketReportData {
    type: string
    data: Array<Record<string, unknown>>
    title: string
    timeframe: string
    startDate?: string
    endDate?: string
}

interface WebSocketMessage {
    customReportID: string
    data: WebSocketReportData | null
    error?: string
}

interface ReportsDataState {
    [key: string]: {
        type: string
        title: string
        reportDetails: WebSocketReportDetails | null
    }
}

interface LayoutItem {
    i: string
    reportID: string
    reportName: string
    departments: string[]
    owner: boolean
    tableView: boolean
    x: number
    y: number
    w: number
    h: number
}

interface Dashboard {
    id: string
    clientID: string
    clientName: string
    departmentIDs: string[]
    createdByUserID: string;
    dashboardName: string
    layout: LayoutItem[]
}

interface Report {
    id: number
    title: string
}


interface CreateDashboardResponse {
    dashboard: {
        id: string;
        dashboardName: string;
        clientID: string;
        createdByUserID: string;
        departmentIDs: string[];
        layout: any | null;
        userRefreshRates: any | null;
        createdAt: string;
        updatedAt: string;
    };
    message: string;
}



function isValidResponse(response: any): response is string | number | boolean | object | null {
    return (
        typeof response === "string" ||
        typeof response === "number" ||
        typeof response === "boolean" ||
        typeof response === "object" ||
        response === null
    )
}

const timeframeMapping: Record<string, string> = {
    "PAST DAY": "past1",
    "PAST WEEK": "past7",
    "PAST 30 DAYS": "past30",
    "MONTH TO DATE": "mtd",
    "YEAR TO DATE": "ytd",
}

function useSymbol(name: string) {
    const [symbol, setSymbol] = useState(() => Symbol(name));

    const updateSymbol = useCallback(() => {
        setSymbol(Symbol(name));
    }, []);

    return [symbol, updateSymbol] as const;
}


export default function DashboardPageTwo() {
    const auth = useAuth()
    const [selectedDepartments, setSelectedDepartments] = useState<Department[]>([])
    const [dashboards, setDashboards] = useState<Dashboard[]>([])
    const [layout, setLayout] = useState<LayoutItem[]>([])
    const [overlayData, setOverlayData] = useState<any[] | null>(null)
    const [overlayDataTitle, setOverlayDataTitle] = useState<string>("")
    const [selectedTitle, setSelectedTitle] = useState<string>()
    const [loadingReports, setLoadingReports] = useState<string[]>([])
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null)
    const [isValueSelected, setIsValueSelected] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [reportDropdownOpen, setReportDropdownOpen] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [isOverlayOpen, setIsOverlayOpen] = useState(false)
    const [isCreateDashboardOpen, setIsCreateDashboardOpen] = useState(false)
    const [dashboardName, setDashboardName] = useState("")
    const [clientAdminsOnly, setClientAdminsOnly] = useState(false)
    const [isDragging, setDragging] = useState(false)
    const [selectedChart, setSelectedChart] = useState<string | null>(null)
    const navigate = useNavigate()
    const [selectedCharter, setSelectedCharter] = useState("App Activity")
    const { clientID, clientName, clear: clearClient } = useClientDashboard();
    const { dashboardID } = useParams<{ dashboardID?: string }>();
    const gridContainerRef = useRef<HTMLDivElement>(null)
    const previousLayoutLength = useRef<number>(layout.length)
    const [sseConnected, setSseConnected] = useState(false)
    const [category, setCategory] = useState<string | null>("summary")
    const location = useLocation()
    const [dateType, setDateType] = useState<string>("today")
    const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false)
    const [selectedInterval, setSelectedInterval] = useState<string>("Never")
    const [lastXDays, setLastXDays] = useState<number>(7)
    const [rollingRange, setRollingRange] = useState<string>("7")
    const [customRangeStart, setCustomRangeStart] = useState<string>("")
    const [customRangeEnd, setCustomRangeEnd] = useState<string>("")
    const [initialLayoutLoaded, setInitialLayoutLoaded] = useState(false)
    const [comparison, setComparison] = useState<string | null>(null)
    const [reportsData, setReportsData] = useState<ReportsDataState>({})
    const [unauthorized, setUnauthorized] = useState<Record<string, boolean>>({})
    const [socket, setSocket] = useState<WebSocket | null>(null)
    const [timeframeStates, setTimeframeStates] = useState<
        Record<
            string,
            {
                isOpen: boolean
                timeframe: string
                showCustomDates: boolean
                customStartDate?: string
                customEndDate?: string
            }
        >
    >({})

    const [isDashboardEditOpen, setIsDashboardEditOpen] = useState(false);
    const [isDashboardInfoOpen, setIsDashboardInfoOpen] = useState(false);
    const [dashboardData, setDashboardData] = useState<Dashboard | null>(null);
    const { data: dashboardQueryData, isLoading: isLoadingQuery, isError, refetch } = useGetDashboardLayout(dashboardID);

    const [newReportID, setNewReportID] = useState<string | null>(null);
    const [newReportName, setNewReportName] = useState<string>("");
    const [newClientName, setNewClientName] = useState<string>("");
    const [newClientID, setNewClientID] = useState<string>("");

    const [newDataSourceDepartments, setNewDataSourceDepartments] = useState<Department[]>([]);

    const [reloadWebsocketSymbol, reloadWebsocket] = useSymbol("reloadWebsocket");


    useEffect(() => {
        if (location.state?.openEditOverlay) {
            console.log("Opening EditOverlay for report:", {
                reportID: location.state.newReportID,
                reportName: location.state.newReportName,
                clientName: location.state.new
            });

            setIsNewEditOverlayOpen(true);
            setNewReportID(location.state.newReportID);
            setNewReportName(location.state.newReportName);
            setNewClientName(location.state.newClientName)
            setNewClientID(location.state.newClientID)
            setCustomRangeStart(location.state.newStartDate || "");
            setInitialTimeframe(location.state.newTimeframe)
            setCustomRangeEnd(location.state.newEndDate || "");
            setNewDataSourceDepartments(location.state.newDataSourceDepartments);

            setIsEditOverlayOpen(true);

            navigate(location.pathname, { replace: true });
        }
    }, [location, navigate]);



    useEffect(() => {
        console.log("clientID", clientID, localStorage.getItem(`selectedDashboardId_${clientID}`))
        setSelectedTitle(dashboardQueryData?.dashboard?.dashboardName || (dashboards.length === 0 ? "" : "Dashboard"));
        console.log("query data ", dashboardQueryData)
        setLayout(dashboardQueryData?.dashboard?.layout || [])
    }, [dashboardQueryData, dashboards])
    

    const toggleSettingsDropdown = () => {
        setSettingsDropdownOpen(!settingsDropdownOpen)
    }



    const handleDashboardEditClick = (dashboard: Dashboard) => {
        console.log("Data sent to DashboardEditOverlay:", dashboard);
        setDashboardData(dashboard);
        setIsDashboardEditOpen(true);
    };


    const handleDashboardInfoClick = (dashboard: Dashboard) => {
        console.log("Data sent to DashboardEditOverlay:", dashboard);
        setDashboardData(dashboard);
        setIsDashboardInfoOpen(true);
    };


    const [isEditOverlayOpen, setIsEditOverlayOpen] = useState(false);
    const [isNewEditOverlayOpen, setIsNewEditOverlayOpen] = useState(false);
    const [isCloneOverlayOpen, setIsCloneOverlayOpen] = useState(false);
    const [selectedReportID, setSelectedReportID] = useState<string>("");
    const [selectedReportName, setSelectedReportName] = useState<string>("");
    const [initialTimeframe, setInitialTimeframe] = useState<string>("PAST DAY");
    const [expandedDashboard, setExpandedDashboard] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [cardData, setCardData] = useState<Record<string, any[]>>({});

    const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
    const [reportToRemove, setReportToRemove] = useState<{ dashboardID: string; reportID: string } | null>(null);

    /*
    useEffect(() => {
      console.log("dashboardID", dashboardID)
      if (dashboardID) {
        setSelectedDashboard(dashboardID); 
        fetchDashboardLayout(dashboardID); 
      }
    }, [dashboardID]);
    */


    const userContext = useContext(UserContext)
    const departmentID = userContext?.metadata.departmentID
    const userID = userContext?.sub

    const tzCtx = useTimeZoneSelectContext()
    const timezone = tzCtx.timeZone


    const openConfirmPopup = (dashboardID: string, reportID: string) => {
        setMenuAnchorEl(null);
        setReportToRemove({ dashboardID, reportID });
        setIsConfirmPopupOpen(true);
    };

    const toggleDashboardDetails = (dashboardId: string) => {
        setExpandedDashboard((prev) => (prev === dashboardId ? null : dashboardId));
    };

    const closeConfirmPopup = () => {
        setIsConfirmPopupOpen(false);
        setReportToRemove(null);
    };
    const [customDateErrors, setCustomDateErrors] = useState<{
        [key: string]: string | null;
    }>({});

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [dashboardToDelete, setDashboardToDelete] = useState<string | null>(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [isWebSocketUpdating, setIsWebSocketUpdating] = useState(false);
    const [dashboardVersion, setDashboardVersion] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(0);

    const handleOpenDeleteConfirm = (dashboardID: string) => {
        setDashboardToDelete(dashboardID);
        setIsDeleteConfirmOpen(true);
    };

    const handleCloseDeleteConfirm = () => {
        setIsDeleteConfirmOpen(false);
        setDashboardToDelete(null);
    };

    const handleDeleteDashboard = async () => {
        if (!dashboardToDelete) return;
        try {
            await apiV2.post("/reporting/delete-dashboard", {
                dashboardID: dashboardToDelete,
            });
            setDashboards((prev) => prev.filter((d) => d.id !== dashboardToDelete));

            if(dashboardToDelete == dashboardID) {
                navigate("/reporting/summary")
            }
            handleCloseDeleteConfirm();
        } catch (error) {
            console.error("Error deleting dashboard:", error);
        }
    };




    const handleMoreVertClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, itemID: string) => {
        setMenuAnchorEl(event.currentTarget as unknown as HTMLElement);
        setSelectedItem(itemID);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setSelectedItem(null);
    };

    const handleEditClick = () => {
        console.log(`Edit clicked for item: ${selectedItem}`);
        handleMenuClose();
    };

    const handleRemoveClick = async () => {
        if (!reportToRemove) return;

        const { dashboardID, reportID } = reportToRemove;

        try {
            const payload = { dashboardID, reportID };
            const url = "/reporting/remove-report-from-dashboard";

            await apiV2.patch(url, payload);

            refetch();
            closeConfirmPopup();
        } catch (error) {
            console.error("Error removing report from dashboard:", error);
        }
    };


    const refreshRateMapping: Record<string, string | number> = {
        "5 minutes": 5,
        "15 minutes": 15,
        "1 hour": 60,
        "4 hours": 240,
        "Never": "never",
    };

    const handleIntervalSelect = async (interval: string) => {
        setSelectedInterval(interval);
        setSettingsDropdownOpen(false);

        if (!dashboardID) return;

        const refreshRate = refreshRateMapping[interval];

        try {
            await apiV2.patch("/reporting/update-dashboard-refresh", {
                dashboardID: dashboardID,
                refreshRate,
            });
            console.log(
                `Updated refresh rate to ${refreshRate} (${interval}) for dashboard ${dashboardID}`
            );
        } catch (error) {
            console.error("Error updating refresh rate:", error);
        }
    };



    useEffect(() => {
        if (!dashboardID) return;

        async function fetchDashboardRefreshRate() {
            try {
                const response = await apiV2.get("/reporting/get-user-dashboard-refresh", {
                    params: {
                        dashboardID: dashboardID, // Pass the dashboardID as a query parameter
                    },
                });
                const { refreshRate } = response.data;

                console.log(`Refresh rate for dashboard ${dashboardID}:`, refreshRate);

                const interval = Object.keys(refreshRateMapping).find(
                    (key) => refreshRateMapping[key] === refreshRate
                );
                if (interval) {
                    setSelectedInterval(interval);
                }
            } catch (error) {
                console.error(
                    `Error fetching refresh rate for dashboard ${dashboardID}:`,
                    error
                );
            }
        }

        fetchDashboardRefreshRate();
    }, [dashboardID]);


    async function fetchDashboards() {
        setIsLoading(true)
        try {
            let url = "/reporting/get-dashboard-list"
            if (userContext?.role.auroraAccessLevel === "all" && clientID) {
                url += `?clientID=${clientID}`
            }
            const response = await apiV2.get<Dashboard[]>(url)
            setDashboards(response.data)

            //  http://127.0.0.1:8888/v2/reporting/get-dashboard-list



            if (response.data.length === 0) {
                console.warn("No dashboards available.");
                setSelectedTitle("Dashboard");
                return;
            }

            const savedDashboardId = dashboardID || localStorage.getItem(`selectedDashboardId_${clientID}`);
            const matchedDashboard = response.data.find((d) => d.id === savedDashboardId);

            console.log("Checking default selection:", savedDashboardId, matchedDashboard);

            if (matchedDashboard) {
                localStorage.setItem(`selectedDashboardId_${clientID}`, matchedDashboard.id);
                goToDashboard(matchedDashboard.id)
                return;
            }

            if (response.data.length > 0) {
                const firstDashboard = response.data[0].id;
                console.log("No stored dashboard, selecting first available:", firstDashboard);
                localStorage.setItem(`selectedDashboardId_${clientID}`, firstDashboard);
                goToDashboard(firstDashboard)
            }

        } catch (error) {
            console.error("Error fetching dashboards:", error);
        } finally {
            setIsLoading(false);
        }
    }



    useEffect(() => {
        fetchDashboards();
    }, []);


    


    const myDashboards = dashboards.filter((dashboard) => dashboard.createdByUserID === userID);
    const storedDashboardId = localStorage.getItem(`selectedDashboardId_${clientID}`);

    const goToDashboard = useCallback((dashboardID: string) => {
        if (clientID) {
            navigate(`/reporting/summary/${dashboardID}?clientID=${clientID}`)
            return;
        }
        navigate(`/reporting/summary/${dashboardID}`)
    }, [clientID])

    useEffect(() => {
        if (dashboardID) {
            console.log("selected", dashboardID)
            localStorage.setItem(`selectedDashboardId_${clientID}`, dashboardID);

            goToDashboard(dashboardID);
        }
    }, [dashboardID, clientID])

    const { accessToken } = useOidcAccessToken();


    useEffect(() => {
        if (!dashboardID) return;

        const baseUrl = `${process.env.REACT_APP_API_V2_URL}/ws/get-dashboard-data/${dashboardID}`;
        const url = new URL(baseUrl.replace("http", "ws"));

        console.log("AccessToken:", accessToken);
        console.log("Selected Dashboard:", dashboardID);

        url.searchParams.append("timezone", timezone);
        url.searchParams.append("token", accessToken);

        if (clientID) {
            url.searchParams.append("clientID", clientID);
            console.log("Client ID:", clientID);
        }

        const ws = new WebSocket(url.toString());

        ws.onopen = () => {
            console.log("WebSocket connection established.");
            setSseConnected(true);
        };

        ws.onmessage = (event) => {
            console.log("WebSocket message received:", event.data);

            try {
                const data: WebSocketMessage = JSON.parse(event.data);
                const { customReportID, data: reportData, error } = data;

                if (error) {
                    console.error("WebSocket message error:", error);
                    console.log("Setting Unauthorized", customReportID)
                    setUnauthorized(prev => ({
                        ...prev,
                        [customReportID]: true,
                    }))
                    return;
                }

                setLoadingReports((prev) => prev.filter((id) => id !== customReportID));

                if (reportData) {
                    const { type: reportType, title: reportTitle, data: reportDetailsArray, timeframe: incomingTimeframe } = reportData;

                    const reportDetails = Array.isArray(reportDetailsArray) && reportDetailsArray.length > 0
                        ? { title: reportTitle, response: reportDetailsArray }
                        : null;

                    setReportsData((prevData) => ({
                        ...prevData,
                        [customReportID]: {
                            type: reportType,
                            title: reportTitle,
                            reportDetails,
                        },
                    }));

                    // Determine and update timeframeStates
                    if (reportData.timeframe === "custom") {
                        const startDate = reportData.startDate;
                        const endDate = reportData.endDate;

                        setTimeframeStates((prev) => ({
                            ...prev,
                            [customReportID]: {
                                ...prev[customReportID],
                                timeframe: `${formatDateReverse(startDate)} - ${formatDateReverse(endDate)}`,
                                customStartDate: startDate,
                                customEndDate: endDate,
                                isOpen: false,
                                showCustomDates: false,
                            },
                        }));
                    } else {
                        const matchedKey = Object.entries(timeframeMapping).find(([label, val]) => val === incomingTimeframe);
                        const timeframe = matchedKey ? matchedKey[0] : incomingTimeframe || "unknown";

                        setTimeframeStates((prev) => ({
                            ...prev,
                            [customReportID]: {
                                ...prev[customReportID],
                                isOpen: false,
                                timeframe,
                                showCustomDates: false,
                            },
                        }));
                    }
                }
            } catch (e) {
                console.error("Error parsing WebSocket message:", e);
            }
        };

        ws.onerror = (event) => {
            console.error("WebSocket error observed:", event);
            ws.close();
        };

        ws.onclose = (event) => {
            console.log("WebSocket connection closed:", event.code, event.reason);
            setSseConnected(false);
        };

        setSocket(ws);

        return () => {
            console.log("Cleaning up WebSocket connection.");
            if (ws.readyState === WebSocket.OPEN) {
                console.log("Closing WebSocket connection.");
                ws.close();
            }
            setSocket(null);
        };
    }, [dashboardID, reloadWebsocketSymbol]);

    const prevSelectedDashboard = useRef<string>()

    useEffect(() => {
        prevSelectedDashboard.current = dashboardID
    }, [dashboardID])


    function formatDateReverse(dateString: string | undefined) {
        if (!dateString) return "";
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
    }

    const [createDashboardError, setCreateDashboardError] = useState("")


    const handleCreateDashboardClick = async () => {
        const departmentIDs = selectedDepartments.map((dept) => dept.id)

        if(departmentIDs.length == 0 && !auth.hasAccessLevelClient){
            setCreateDashboardError("No departments selected");
            return
        }

        const payload = {
            dashboardName,
            clientID,
            departmentIDs,
            clientAdminsOnly,
        };


        try {
            const response = await apiPOST<CreateDashboardResponse>(
                `${process.env.REACT_APP_API_V2_URL}/reporting/create-dashboard`,
                payload
            );
    
            if (response.dashboard?.id) {
                const newDashboard: Dashboard = {
                    ...response.dashboard,
                    clientName: clientName || "Unknown Client", 
                };
    
                const newDashboardId = response.dashboard.id;
    
                localStorage.setItem(`selectedDashboardId_${clientID}`, newDashboardId);
    
                setDashboards((prev) => [...prev, newDashboard]);
    
                setIsCreateDashboardOpen(false);
                goToDashboard(newDashboardId); 
    
                return;
            }
    
            refetch();
            setIsCreateDashboardOpen(false);
            window.location.reload(); // Ensure it reflects the new dashboard
        } catch (error) {
            console.error("Error creating dashboard:", error);
        }
    };


    const handleOpenCreateDashboardClick = () => {
        setIsCreateDashboardOpen(true)
    }

    const rowsPerPage = 10

    const openOverlay = () => {
        setIsOverlayOpen(true)
    }

    const closeOverlay = () => {
        setIsOverlayOpen(false)
    }

    const handleNextPage = () => {
        if (overlayData) {
            setCurrentPage((prevPage) =>
                prevPage < Math.ceil(overlayData.length / rowsPerPage) - 1 ? prevPage + 1 : prevPage
            )
        }
    }

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : prevPage))
    }

    const closeCreateDashboard = () => {
        setIsCreateDashboardOpen(false)
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const useOutsideClick = (ref: React.RefObject<HTMLElement>, setState: React.Dispatch<React.SetStateAction<boolean>>) => {
        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (ref.current && !ref.current.contains(event.target as Node)) {
                    setState(false);  // Close the dropdown/menu when clicked outside
                }
            };
    
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, setState]);
    };

    const settingsDropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick(settingsDropdownRef, setSettingsDropdownOpen);



    const dropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick(dropdownRef, setDropdownOpen);
    const timeframeDropdownRef = useRef<HTMLDivElement>(null);

    useOutsideClick(timeframeDropdownRef, (isOpen) => {
        setTimeframeStates((prev) => {
            const newState = { ...prev };
            Object.keys(newState).forEach((key) => {
                newState[key].isOpen = false; 
            });
            return newState;
        });
    });

    const toggleReportDropdown = () => {
        if (reportDropdownOpen) {
            setReportDropdownOpen(false);
            return  // Close the dropdown if it's already open
        }
        setReportDropdownOpen(true);
        // No need to toggle, because we don't want it to reopen automatically
    };

    const reportDropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick(reportDropdownRef, setReportDropdownOpen);


    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    const handleLayoutChange = async (newLayout: LayoutItem[]) => {
        if (!dashboardID) return;


        const dashboard = dashboards.find(d => d.id == dashboardID);

        if(dashboard && dashboard.createdByUserID != userID){
            return
        }

        const updatedDashboard = {
            id: dashboardID,
            layout: newLayout.map((item) => {
                const existingItem = layout.find((l) => l.i === item.i);
                return {
                    ...item,
                    reportID: existingItem?.reportID || "",
                    reportName: existingItem?.reportName || "",
                    tableView: existingItem?.tableView || false,
                    owner: existingItem?.owner ?? false,
                    departments: existingItem?.departments || [""],
                };
            }),
        };

        setLayout(updatedDashboard.layout)

        try {
            await apiV2.patch(`/reporting/update-dashboard-layout/${dashboardID}`, updatedDashboard);
        } catch (error) {
            console.error("Error updating dashboard layout:", error);
        }
    };


    const handleSelectDashboard = (dashboardId: string) => {
        const selected = dashboards.find((dashboard) => dashboard.id === dashboardId)
        if (selected) {
            setDropdownOpen(false);
        
            setSelectedTitle(selected.dashboardName)
            localStorage.setItem(`selectedDashboardId_${clientID}`, selected.id);
            localStorage.setItem(`selectedDashboardTitle_${clientID}`, selected.dashboardName);
            goToDashboard(selected.id)

            setDropdownOpen(false);
            return
        }
        setDropdownOpen(!dropdownOpen)
    }

    const handleDragStart = () => {
        setDragging(true)
    }

    const handleDragStop = () => {
        setDragging(false)
    }

    const handleValueClick = async (reportID: string) => {

        setSelectedReportID(reportID);
        setIsOverlayOpen(true)
        setOverlayData(null)
        setOverlayDataTitle("")
    
        try {
            const { data } = await apiV2.get(`/reporting/get-report-rows/${reportID}`, {
                params: { dashboardID }
            })
    
            const documents = data?.data || []
            const title = data?.title || "Data"
            setOverlayData(documents)
            setOverlayDataTitle(title)
        } catch {
            setOverlayData([])
            setOverlayDataTitle("Error fetching data")
        }
    }
    



    const handleToggle = async (reportID: string) => {
        const layoutItem = layout.find((item) => item.reportID === reportID);
        if (!layoutItem) return;

        const updatedTableView = !layoutItem.tableView;

        setLoadingReports((prev) => [...prev, reportID]); // Add reportID to loadingReports

        try {
            const payload = {
                layoutID: layoutItem.i,
                tableView: updatedTableView,
            };

            await apiV2.patch(`/reporting/update-layout-tableview/${dashboardID}`, payload);

            const updatedLayout = layout.map((item) =>
                item.i === layoutItem.i ? { ...item, tableView: updatedTableView } : item
            );
            setLayout(updatedLayout);

            const message = JSON.stringify({
                action: "reloadReport",
                customReportID: reportID,
                tableView: updatedTableView,
            });
            socket?.send(message);
        } catch (error) {
            console.error("Error updating tableView:", error);
            setLoadingReports((prev) => prev.filter((id) => id !== reportID)); // Remove reportID from loadingReports if an error occurs
        }
    };


    useEffect(() => {
        if (!socket) return;

        const handleWebSocketMessage = (event: MessageEvent) => {
            const data: WebSocketMessage = JSON.parse(event.data);
            const { customReportID } = data;

            if (customReportID) {
                setLoadingReports((prev) => prev.filter((id) => id !== customReportID));
            }
        };

        socket.addEventListener("message", handleWebSocketMessage);

        return () => {
            socket.removeEventListener("message", handleWebSocketMessage);
        };
    }, [socket]);


    const renderChartContent = (reportID: string | undefined, tableView: boolean, width = 300, height = 200) => {
        if (!reportID) {
            return (
                <div className="flex items-center justify-center h-full">
                    <p className="text-md text-gray-700">No data available</p>
                </div>
            )
        }
        const data = reportsData[reportID]

        if(unauthorized[reportID]) {
            return (
                <div className="flex items-center justify-center h-full">
                    <p className="text-xl mt-6 font-semibold text-gray-700">Insufficient Access</p>
                </div>
            )
        }

        if (!data) {
            return (
                <div className="flex items-center justify-center h-full">
                    <p className="text-xl mt-6 font-semibold text-gray-700">Loading...</p>
                </div>
            )
        }

        const { reportDetails, type } = data
        if (!reportDetails) {
            return (
                <div className="flex items-center justify-center h-full">
                    <p className="text-md text-gray-700">No report details available</p>
                </div>
            )
        }
        const { response } = reportDetails
        const isOwner = userID === dashboards.find(d => d.id === dashboardID)?.createdByUserID;

        const fetchedCardData = cardData[reportID];
        const rowsPerPage = 6;

        const displayedRows =
            fetchedCardData && fetchedCardData.length > 0
                ? fetchedCardData.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage)
                : [];

                if (tableView && type === "value-percent" && Array.isArray(response) && response.length > 0) {
                    const record = response[0] as Record<string, any>;
                    const isLoading = loadingReports.includes(reportID);
                    const keys = Object.keys(record);
                    let key1 = keys[0];
                    let key2 = keys[1];
                    let val1 = record[key1] ?? "N/A";
                    let val2 = record[key2] ?? "N/A";
                
                    if (typeof val1 === "number" && typeof val2 === "number" && val1 > val2) {
                        [val1, val2] = [val2, val1];
                        [key1, key2] = [key2, key1];
                    }
                
                    return (
                        <div className="text-center mt-4 p-2 group">
                            <h3 className="text-lg font-semibold">{data.title}</h3>
                            <div className="flex justify-center mt-2 text-sm text-gray-600">
                                <span className="w-1/2 text-right pr-2">{isLoading ? "" : key1}</span>
                                <span className="w-1/2 text-left pl-2">{isLoading ? "" : key2}</span>
                            </div>
                            <div className="flex justify-center items-center text-3xl text-gray-700 mt-4">
                                <div className="w-1/2 text-right pr-4">
                                    {isLoading ? <CircularProgress size={24} sx={{ color: "#716F6F" }} /> : val1}
                                </div>
                                <div className="border-l-2 border-gray-400 h-8"></div>
                                <div className="w-1/2 text-left pl-4">
                                    {isLoading ? <CircularProgress size={24} sx={{ color: "#716F6F" }} /> : val2}
                                </div>
                            </div>
                        </div>
                    );
                }
                

        if (tableView && type != "value-percent" && Array.isArray(response)) {
            const rowsPerPage = 6;
            const displayedRows = response.slice(
                currentPage * rowsPerPage,
                (currentPage + 1) * rowsPerPage
            );
            const isLoading = loadingReports.includes(reportID);

            return (
                <div>
                    {isLoading ? (
                        <div className="flex justify-center items-center mt-28 h-full">
                            <CircularProgress size={24} sx={{ color: "#716F6F" }} />
                        </div>
                    ) : (
                        <>
                            <div className="flex justify-end">
                                <div className="flex justify-between items-center mr-4 text-xs">
                                    <p className="text-gray-500 mx-auto">
                                        Page {currentPage + 1} of {Math.ceil(response.length / rowsPerPage)}
                                    </p>
                                </div>
                                <button
                                    className={`px-1 mr-2 ${currentPage === 0 ? "text-grey" : "text-blue font-semibold"}`}
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                                    disabled={currentPage === 0}
                                >
                                    &lt;
                                </button>
                                <button
                                    className={`px-1 ${currentPage >= Math.ceil(response.length / rowsPerPage) - 1
                                            ? "text-grey"
                                            : "text-blue font-semibold"
                                        }`}
                                    onClick={() =>
                                        setCurrentPage((prev) =>
                                            Math.min(prev + 1, Math.ceil(response.length / rowsPerPage) - 1)
                                        )
                                    }
                                    disabled={currentPage >= Math.ceil(response.length / rowsPerPage) - 1}
                                >
                                    &gt;
                                </button>
                            </div>
                            <div className="overflow-auto rounded border border-gray-300 shadow-md">
                                <table className="min-w-full text-xs">
                                    <thead>
                                        <tr className="bg-gray-100 text-left text-gray-600 font-semibold">
                                            {Object.keys(response[0]).map((key) => (
                                                <th key={key} className="px-3 py-1 border-b border-gray-300">
                                                    {key}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayedRows.map((row, rowIndex) => (
                                            <tr
                                                key={rowIndex}
                                                className={`border-b last:border-none transition-colors ${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                                                    }`}
                                            >
                                                {Object.keys(row).map((key) => (
                                                    <td key={key} className="px-3 py-1 text-gray-700 whitespace-nowrap">
                                                        {row[key]}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            );
        }

        if (
            (type === "value" ||
              type === "static" ||
              type === "value-percent") &&
            Array.isArray(response) &&
            response.length > 0
          ) {
            const record = response[0] || {};
            const firstKey = Object.keys(record)[0];
            let val = firstKey ? record[firstKey] : "N/A";
            if (type === "value-percent") {
                val = `${parseFloat(val).toFixed(1)}%`;
              }              
            const isLoading = loadingReports.includes(reportID);
          
            return (
              <div className="text-center mt-4 p-2 group">
                <h3 className="text-lg font-semibold">{data.title}</h3>
                <button
                  onClick={() => handleValueClick(reportID)}
                  className="text-3xl mt-12 text-gray-700 hover:text-blue cursor-pointer"
                >
                  <div className="text-blue group-hover:underline">
                    {isLoading ? (
                      <CircularProgress size={24} sx={{ color: "#716F6F" }} />
                    ) : (
                      val
                    )}
                  </div>
                </button>
              </div>
            );
          }


        const renderResponse = (resp: any) => {
            if (typeof resp === "string" || typeof resp === "number" || typeof resp === "boolean") {
                return (
                    <button className="text-3xl mt-12 text-gray-700 hover:text-dark-dark-grey cursor-pointer" onClick={openOverlay}>
                        {resp}
                    </button>
                )
            }
            return <p className="text-md text-gray-700">Unsupported data type</p>
        }

        return (
            <div className="text-center p-2">
                <h3 className="text-lg font-semibold">{data.title}</h3>
                {renderResponse(response)}
            </div>
        )
    }

    const renderOverlayContent = (reportID: string) => {


        
        const data = reportsData[reportID]
        if (!data) {
            return (
                <div className="flex items-center justify-center h-full">
                    <p className="text-xl mt-6 font-semibold text-gray-700">Loading...</p>
                </div>
            )
        }

        console.log("overlay data", overlayData);
        console.log("data type", data.type);
        console.log("data", data);
    
        if (!data) return <div className="text-center text-gray-500"><LinearProgress /></div>;

        if (data.type === "value-percent" && Array.isArray(overlayData) && overlayData.length > 0) {
            const record = overlayData[0] ?? {};
            const keys = Object.keys(record);
            let val1 = record[keys[0]] ?? "N/A";
            let val2 = record[keys[1]] ?? "N/A";
            const isLoading = loadingReports.includes(reportID);
            if (typeof val1 === "number" && typeof val2 === "number" && val1 > val2) {
                [val1, val2] = [val2, val1];
                [keys[0], keys[1]] = [keys[1], keys[0]];
            }
        
            return (
                <div className="text-center mt-4 p-2 group">
                    <div className="flex justify-center mt-2 text-sm text-gray-600">
                        <span className="w-1/2 text-right pr-2">{keys[0]}</span>
                        <span className="w-1/2 text-left pl-2">{keys[1]}</span>
                    </div>
                    <div className="flex justify-center items-center text-3xl text-gray-700 mt-4">
                        <div className="w-1/2 text-right pr-4">
                            {isLoading ? <CircularProgress size={24} sx={{ color: "#716F6F" }} /> : val1}
                        </div>
                        <div className="border-l-2 border-gray-400 h-8"></div>
                        <div className="w-1/2 text-left pl-4">
                            {isLoading ? <CircularProgress size={24} sx={{ color: "#716F6F" }} /> : val2}
                        </div>
                    </div>
                </div>
            );
        }
    
        console.log("overlayData:", overlayData)
        if (overlayData === null) {
            return <div className="text-center text-gray-500">
                <LinearProgress />
            </div>
        }
        if (overlayData.length > 0) {
            const startIndex = currentPage * rowsPerPage
            const endIndex = Math.min(startIndex + rowsPerPage, overlayData.length)

            return (
                <div>
                    <div className="overflow-auto rounded border border-gray-300 shadow-md">
                        <table className="min-w-full text-sm">
                            <thead>
                                <tr className="bg-gray-100 text-left text-gray-600 font-semibold">
                                    {Object.keys(overlayData[0] || {}).map((header) => (
                                        <th key={header} className="px-3 py-2.5 border-b border-gray-300">
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {overlayData.slice(startIndex, endIndex).map((row, index) => (
                                    <tr
                                        key={index}
                                        className={`border-b last:border-none transition-colors ${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                            }`}
                                    >
                                        {Object.values(row || {}).map((value, valueIndex) => (
                                            <td
                                                key={valueIndex}
                                                className="px-3 py-2 text-gray-700 whitespace-nowrap"
                                            >
                                                {typeof value === "object" ? JSON.stringify(value, null, 2) : value?.toString() || ""}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
                            onClick={handlePrevPage}
                            disabled={currentPage === 0}
                        >
                            Previous
                        </button>
                        <p className="text-gray-700">
                            Page {currentPage + 1} of {Math.ceil(overlayData.length / rowsPerPage)}
                        </p>
                        <button
                            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
                            onClick={handleNextPage}
                            disabled={currentPage === Math.ceil(overlayData.length / rowsPerPage) - 1}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )
        }
        return <p className="text-center text-gray-500">No data available.</p>
    }

    const [customDateStates, setCustomDateStates] = useState<{
        [key: string]: {
            isOpen: boolean;
            startDate: string;
            endDate: string;
        };
    }>({});


    const toggleCustomDatePopup = (reportID: string) => {
        setTimeframeStates((prev) => ({
            ...prev,
            [reportID]: {
                ...prev[reportID],
                isOpen: true, // Ensure the menu stays open
                showCustomDates: true, // Show custom date popup instead of the timeframe menu
            },
        }));
    };

    const updateCustomDateState = (reportID: string, key: "startDate" | "endDate", value: string) => {
        setCustomDateStates((prev) => ({
            ...prev,
            [reportID]: {
                ...prev[reportID],
                [key]: value,
            },
        }));
    };

    const formatDate = (dateString: string, separator: string): string => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "";
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}${separator}${month}${separator}${day}`;
    };



    const setCustomDate = (reportID: string, tableView: boolean, startDate: string, endDate: string) => {
        if (!startDate || !endDate) {
            setCustomDateErrors((prev) => ({
                ...prev,
                [reportID]: "Both start and end dates are required.",
            }));
            return;
        }

        const now = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);
        if (start > now) {
            setCustomDateErrors((prev) => ({
                ...prev,
                [reportID]: "Start date cannot be in the future.",
            }));
            return;
        }
        if (start > end) {
            setCustomDateErrors((prev) => ({
                ...prev,
                [reportID]: "Start date cannot be after end date.",
            }));
            return;
        }
        setCustomDateErrors((prev) => ({
            ...prev,
            [reportID]: null,
        }));

        const formattedDisplayStartDate = formatDate(startDate, "/");
        const formattedDisplayEndDate = formatDate(endDate, "/");

        setCustomDateStates((prev) => ({
            ...prev,
            [reportID]: {
                ...prev[reportID],
                isOpen: false,
            },
        }));

        selectTimeframe(reportID, "CUSTOM DATE", reportID, tableView, startDate, endDate);
    };



    function closeEditOverlay() {
        setIsEditOverlayOpen(false)
        setNewReportID(null)
        setNewReportName("")
      }
    

    const [isFullscreen, setIsFullscreen] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);


    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            gridContainerRef.current?.requestFullscreen().then(() => {
                setIsFullscreen(true);
            }).catch((err) => {
                console.error("Error attempting to enable full-screen mode:", err);
            });
        } else {
            document.exitFullscreen().then(() => {
                setIsFullscreen(false);
            }).catch((err) => {
                console.error("Error attempting to exit full-screen mode:", err);
            });
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
        };
    }, []);

    const dashboardTitle = useMemo(() => {
        const clientMatch = dashboardQueryData?.dashboard.clientID;
        const userClientID = userContext?.role.clientID;
        if (
            (userContext?.role.auroraAccessLevel === "all" && clientMatch === clientID) || 
            (userContext?.role.auroraAccessLevel !== "all" )
        ) {
            return selectedTitle;
        }
    
        return "";
    }, [dashboardQueryData?.dashboard.clientID, selectedTitle, clientID, userContext?.role]);
    

    const handleZoomIn = () => {
        setZoomLevel((prev) => Math.min(prev + 0.1, 2));
    };

    const handleZoomOut = () => {
        setZoomLevel((prev) => Math.max(prev - 0.1, 0.5));
    };


    const toggleTimeframeMenu = (reportID: string) => {
        setTimeframeStates((prev) => {
            const isCurrentlyOpen = prev[reportID]?.isOpen || false;
            return {
                ...prev,
                [reportID]: {
                    isOpen: !isCurrentlyOpen,
                    timeframe: prev[reportID]?.timeframe,
                    showCustomDates: false,
                },
            };
        });
    };

    const isOwner = userID === dashboards.find(d => d.id === dashboardID)?.createdByUserID;

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setTimeframeStates((prev) => {
                    const newState = { ...prev };
                    Object.keys(newState).forEach((key) => {
                        newState[key].isOpen = false;  // Close the menu
                    });
                    return newState;
                });
            }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const selectTimeframe = async (
        reportID: string,
        timeframe: string,
        cID?: string,
        tableView?: boolean,
        startDate?: string,
        endDate?: string
    ) => {

        const formattedDisplayStartDate = startDate ? formatDate(startDate, "/") : "";
        const formattedDisplayEndDate = endDate ? formatDate(endDate, "/") : "";

        const formattedSendStartDate = startDate ? formatDate(startDate, "-") : "";
        const formattedSendEndDate = endDate ? formatDate(endDate, "-") : "";

        setTimeframeStates((prev) => ({
            ...prev,
            [reportID]: {
                ...prev[reportID],
                timeframe:
                    timeframe === "CUSTOM DATE"
                        ? `${formattedDisplayStartDate} - ${formattedDisplayEndDate}`
                        : timeframe,
                isOpen: false,
                showCustomDates: timeframe === "CUSTOM DATE",
            },
        }));

        if (!cID) return;

        setLoadingReports((prev) => [...prev, cID]);

        const payload =
            timeframe === "CUSTOM DATE"
                ? {
                    customReportID: cID,
                    timeframe: "custom",
                    startDate: formattedSendStartDate,
                    endDate: formattedSendEndDate,
                }
                : { customReportID: cID, timeframe: timeframeMapping[timeframe] || "unknown" };

        try {
            await apiV2.patch("/reporting/update-custom-report", payload);
            const message = JSON.stringify({ action: "reloadReport", customReportID: cID, tableView: tableView });
            console.log("WebSocket Payload:", message);
            socket?.send(message);
        } catch (error) {
            console.error("Error updating report timeframe:", error);
        } finally {
            setLoadingReports((prev) => prev.filter((id) => id !== cID));
        }
    };

    const viewableDashboards = dashboards.filter((dashboard) => {
        if (dashboard.createdByUserID === userID) return false;

        return true
    });


    const filteredMyDashboards = dashboards.filter(
        (dashboard) =>
            dashboard.createdByUserID === userID &&
            dashboard.dashboardName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const filteredViewableDashboards = viewableDashboards.filter((dashboard) =>
        dashboard.dashboardName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const [isExporting, setIsExporting] = useState<"idle" | "loading" | "success">("idle");

    const handleExport = async (reportName: string, reportID: string) => {
        try {
          const currentDate = new Date().toISOString().split("T")[0];
          const formattedReportName = reportName.toLowerCase().replace(/\s+/g, "_");
          const fileName = `${formattedReportName}_${currentDate}.xlsx`;
      
          const response = await apiV2.get(
            `/reports/download/${reportID}`, 
            {
              params: {
                timezone, 
              },
              responseType: "blob",
            }
          );
      
          if (response.status !== 200) throw new Error("Failed to export report");
      
          const contentDisposition = response.headers["content-disposition"];
          const match = contentDisposition?.match(/filename="?(.+)"?/);
          const downloadFileName = match?.[1] || fileName;
      
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
      
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", downloadFileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        } catch (error: any) {
          alert(error.response?.data?.message || "Failed to export report.");
          console.error("Export Error:", error);
          throw error;
        }
      };
      

      const [isScheduleOverlayOpen, setIsScheduleOverlayOpen] = useState(false)
        const [scheduleType, setScheduleType] = useState<string>("file")
        const [scheduleTimezone, setScheduleTimezone] = useState<TimeZoneConfig>({
        countryISO: tzCtx.countryISO,
        timezone: tzCtx.timeZone || "",
        })
        const [dataSourceTimezone, setDataSourceTimezone] = useState<TimeZoneConfig>({
        countryISO: tzCtx.countryISO,
        timezone: tzCtx.timeZone || "",
        })
        const [time, setTime] = useState("")
        const [recurrence, setRecurrence] = useState<Recurrence>({
        startDate: "",
        selectedDays: [],
        frequency: "custom",
        repeatEvery: 1,
        repeatUnit: "week",
        monthlyYearlyDay: "",
        })


    return (
        <div className="relative bg-white h-full pb-[4.25rem]">
            <div className="bg-white-blue p-4 border-b border-light-grey flex items-center">
                <div className="flex">
                    <h1 className="text-xl font-semibold text-grey">{dashboardTitle}</h1>
                    <button 
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (dropdownOpen) {
                          setDropdownOpen(false);
                        } else {
                          setDropdownOpen(true);
                        }
                      }}
                    className="text-grey ml-2" aria-haspopup="listbox" aria-expanded={dropdownOpen}>
                        <svg
                            className={`w-5 h-5 transform transition-transform duration-300 ${dropdownOpen ? "rotate-180" : "rotate-0"}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                        </svg>
                    </button>
                </div>
                <div className="ml-auto mr-4 flex text-sm rounded">
                    {userContext?.role.auroraAccessLevel === "all" && (
                        <ArrowBack
                            className="cursor-pointer pb-1  mr-2 hover:text-blue active:text-dark-blue"
                            onClick={() => {
                                clearClient();
                            }}
                        />
                    )}
                    {clientName}
                </div>
                <div className="rounded-sm border-2 border-blue">
                    <button onClick={handleOpenCreateDashboardClick} className="text-blue text-xs p-2">
                        Create Dashboard
                    </button>
                </div>

                <div className="ml-2 rounded-sm bg-blue">
                    <button 
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (reportDropdownOpen) {
                          setReportDropdownOpen(false);
                        } else {
                          setReportDropdownOpen(true);
                        }
                      }}
                    className="text-white text-xs p-2.5">
                        Add Report
                        <svg
                            className={`w-3 h-3 inline-block ml-1 transform ${reportDropdownOpen ? "rotate-180" : "rotate-0"}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                        </svg>
                    </button>
                </div>
                <div className="ml-2 rounded-sm relative">
                    <button onClick={toggleFullscreen} className="text-blue cursor-pointer hover:text-dark-dark-grey">
                        {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
                    </button>
                </div>
                <div className="ml-2 rounded-sm relative">
                    <Settings
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleSettingsDropdown();
                          }}
                        className="text-blue cursor-pointer hover:text-dark-dark-grey"
                    />
                    {settingsDropdownOpen && (
                        <div 
                        ref={settingsDropdownRef}
                        className="absolute right-0 mt-2 w-40 bg-white border border-grey rounded shadow-lg z-10">
                            <h1 className="text-sm p-2">Refresh reports every:</h1>
                            <ul className="text-sm text-grey">
                                {["5 minutes", "15 minutes", "1 hour", "4 hours", "Never"].map((option) => (
                                    <li
                                        key={option}
                                        className={`cursor-pointer p-2 hover:bg-light-grey ${selectedInterval === option ? "bg-light-grey font-semibold" : ""
                                            }`}
                                        onClick={() => handleIntervalSelect(option)}
                                    >
                                        <input
                                            type="radio"
                                            id={option}
                                            name="interval"
                                            checked={selectedInterval === option}
                                            className="mr-2"
                                            readOnly
                                        />
                                        {option}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                {reportDropdownOpen && (
                    <div 
                        ref={reportDropdownRef}
                        className="right-3 mt-32 border-1 border-blue text-blue shadow-md absolute rounded-lg z-20">
                        <div className="px-1.5 bg-white text-xs rounded-lg py-2">
                            <ul>
                                <li onClick={() => navigate("/reporting/reports")} className="cursor-pointer p-2 bg-white hover:bg-light-blue">
                                    From My Reports
                                </li>
                                <li onClick={() => navigate("/reporting/create-new-report", { state: { from: location.pathname, clientID: clientID } })} className="cursor-pointer p-2 bg-white rounded hover:bg-light-blue">
                                    Create Report
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>

            <Modal open={isDeleteConfirmOpen} setOpen={setIsDeleteConfirmOpen}>

                <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                    <h2 className="text-xl font-semibold text-gray-700 mb-4">
                        Are you sure you want to delete this dashboard?
                    </h2>
                    <div className="flex justify-end">
                        <button
                            onClick={handleCloseDeleteConfirm}
                            className="px-4 py-2 text-gray-600 hover:text-gray-800 rounded mr-4"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleDeleteDashboard}
                            className="px-4 py-2 bg-red text-white hover:bg-dark-red rounded"
                        >
                            Delete
                        </button>
                    </div>
                </div>

            </Modal>

            {dropdownOpen && (
                <div
                    ref={dropdownRef}
                    className="ml-4 shadow-md absolute rounded-lg z-20 mt-2 w-1/3">
                    <div className="p-3 bg-light-light-grey rounded-t-lg">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="border border-grey rounded p-2 w-full"
                            placeholder="Search dashboards"
                            aria-label="Search Dashboards"
                        />
                    </div>
                    <div className="px-4 bg-white rounded-b-lg py-2 max-h-60 overflow-y-auto">
                        {/* My Dashboards */}
                        <p className="font-bold text-grey">My Dashboards</p>
                        <ul className="mt-2">
                            {filteredMyDashboards.length > 0 ? (
                                filteredMyDashboards.map((dashboard) => (
                                    <li
                                        key={`${dashboard.id}-${dashboard.dashboardName}`}
                                        className="relative group cursor-pointer text-sm p-2 pl-8 bg-white rounded hover:bg-light-blue flex justify-between items-center"
                                        role="option"
                                        onClick={() => handleSelectDashboard(dashboard.id)}
                                    >
                                        {dashboard.dashboardName}
                                        <Edit
                                            fontSize="small"
                                            className="text-light-grey opacity-0 group-hover:opacity-100 hover:text-dark-dark-grey transition-all cursor-pointer"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDashboardEditClick(dashboard);
                                            }}
                                        />
                                    </li>
                                ))
                            ) : (
                                <p className="text-sm text-gray-500 ml-8">No matching dashboards</p>
                            )}
                        </ul>


                        <p className="font-bold text-grey mt-4">Viewable Dashboards</p>
                        <ul className="mt-2">
                            {filteredViewableDashboards.length > 0 ? (
                                filteredViewableDashboards.map((dashboard) => (
                                    <li
                                        key={`${dashboard.id}-${dashboard.dashboardName}`}
                                        onClick={() => handleSelectDashboard(dashboard.id)}
                                        className="cursor-pointer relative group text-sm p-2 pl-8 bg-white rounded flex justify-between hover:bg-light-blue"
                                        role="option"
                                    >
                                        {dashboard.dashboardName}
                                        <InfoOutlined
                                            fontSize="small"
                                            className="text-light-grey opacity-0 group-hover:opacity-100 hover:text-dark-dark-grey transition-all cursor-pointer"
                                            onClick={(e) => {
                                                handleDashboardInfoClick(dashboard);
                                                e.stopPropagation();
                                            }}
                                        />
                                    </li>
                                ))
                            ) : (
                                <p className="text-sm text-gray-500 ml-8">No matching dashboards</p>
                            )}
                        </ul>
                    </div>
                </div>
            )}


            <div ref={gridContainerRef} className="p-4 px-4 bg-white-blue h-full overflow-y-auto relative"
                style={{
                    transform: `scale(${zoomLevel})`,
                    transformOrigin: "top left",
                    transition: "transform 0.2s ease-in-out",
                }}
            >

                <style>
                    {`
            .react-grid-item.react-grid-placeholder {
              background-color: #BCE0FD;
              border: 2px dashed #0096fa;
              transition: background-color 0.2s ease-in-out;
            }
          `}
                </style>

                {isLoading ? (
                    <div className="flex flex-col items-center mt-32 justify-center">
                        <CircularProgress />
                    </div>
                ) : ((myDashboards.length === 0 && viewableDashboards.length === 0) || (myDashboards.length === 0 && !storedDashboardId)) ? (
                    <div className="flex flex-col items-center mt-32 justify-center">
                        <DashboardCustomizeRounded sx={{ fontSize: 60, color: "#2699FB" }} />
                        <h2 className="text-xl font-semibold mt-4 text-gray-700">
                            Create your first dashboard
                        </h2>
                        <p className="text-gray-500 mb-4">
                            Start by adding a dashboard to track your reports.
                        </p>
                        <button
                            className="bg-blue text-white px-4 py-2 rounded-md shadow-md hover:bg-dark-blue focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-1 transition"
                            onClick={handleOpenCreateDashboardClick}
                        >
                            <div className="flex items-center">
                                <AddCircleOutline className="mr-2" />
                                Create Dashboard
                            </div>
                        </button>
                    </div>
                ) : (

                    (layout.length === 0 && !isLoadingQuery) ? (
                        <div className="relative flex flex-col items-center mt-32 justify-center">
                            <h2 className="text-xl font-semibold mt-4 text-gray-700">
                                No Reports Yet
                            </h2>
                            <p className="text-gray-500 mb-4 text-center max-w-md">
                                There aren’t any reports on this dashboard. Add your first report to start tracking your data.
                            </p>
                        </div>
                    ) :

                        <GridLayout
                            className="layout"
                            layout={layout}
                            cols={6}
                            rowHeight={150}
                            width={1100}
                            isDraggable={userID === dashboards.find(d => d.id === dashboardID)?.createdByUserID}
                            isResizable={userID === dashboards.find(d => d.id === dashboardID)?.createdByUserID}
                            onDragStart={handleDragStart}
                            onDragStop={handleDragStop}
                            onLayoutChange={handleLayoutChange}
                        >
                            {layout.length > 0 ? (
                                layout.map((item) => {
                                    const reportData = reportsData[item.reportID]
                                    const reportType = reportData?.type
                                    const isValueOrTable = reportType === "value" || reportType == "value-percent" || reportType === "table"
                                    const itemState = timeframeStates[item.reportID] || {
                                        isOpen: false,
                                        timeframe: "PAST DAY",
                                        showCustomDates: false,
                                    }
                                    return (
                                        <div
                                            key={item.i}
                                            id={`container-${item.i}`}
                                            className="relative bg-white shadow-md border-transparent hover:outline-solid hover:outline outline-black hover:outline-light-blue rounded transition-all group duration-200"
                                        >
                                            <div className="absolute top-2 right-2 p-2 flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                                <MoreVert
                                                    onClick={(event) => handleMoreVertClick(event, item.i)}
                                                    className="text-grey hover:text-black cursor-pointer z-20" />
                                                <Menu
                                                    anchorEl={menuAnchorEl}
                                                    open={Boolean(menuAnchorEl)}
                                                    onClose={handleMenuClose}
                                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                                                >

                                                    {userID === dashboards.find((d) => d.id === dashboardID)?.createdByUserID && (
                                                   
                                                            <MenuItem
                                                                onClick={() => {
                                                                    const layoutItem = layout.find((item) => item.i === selectedItem);
                                                                    if (layoutItem) {
                                                                        setSelectedReportID(layoutItem.reportID);
                                                                        setSelectedReportName(layoutItem.reportName);
                                                                        const displayTimeframe = timeframeStates[layoutItem.reportID]?.timeframe || "PAST DAY";
                                                                        const initialTimeframe = timeframeMapping[displayTimeframe] || "past1";
                                                                        setInitialTimeframe(initialTimeframe);

                                                                        setIsEditOverlayOpen(true);
                                                                        handleMenuClose();
                                                                    }
                                                                }}
                                                                className="text-sm"
                                                            >
                                                                <Edit fontSize="small" className="mr-1" />
                                                                <span className="text-sm">Edit</span>
                                                            </MenuItem>
                                                            )}
                                                            {userID === dashboards.find((d) => d.id === dashboardID)?.createdByUserID && (

                                                            <MenuItem className="text-sm flex items-center">
                                                                {(() => {
                                                                    const layoutItem = layout.find((item) => item.i === selectedItem);
                                                                    if (!layoutItem) return null;

                                                                    return (
                                                                        <label className="inline-flex items-center cursor-pointer">
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={layoutItem.tableView}
                                                                                onChange={() => handleToggle(layoutItem.reportID)}
                                                                                className="sr-only peer"
                                                                            />
                                                                            <div className="relative w-7 h-4 bg-light-blue peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer 
                          dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[1.83px] after:start-[1.8px]
                           after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue dark:peer-checked:bg-blue-600"></div>
                                                                        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                                            {reportsData[layoutItem.reportID]?.type === "value-percent" 
                                                                                ? (layoutItem.tableView ? "Value" : "Percentage") 
                                                                                : (layoutItem.tableView ? "Table" : "Value")}
                                                                        </span>
                                                                        </label>
                                                                    );
                                                                })()}
                                                            </MenuItem>
                                                            )}
                                                {userID === dashboards.find((d) => d.id !== dashboardID)?.createdByUserID && (
                                                            <MenuItem
                                                            onClick={() => {
                                                                const layoutItem = layout.find((item) => item.i === selectedItem);
                                                                if (layoutItem) {
                                                                    setSelectedReportID(layoutItem.reportID);
                                                                    setSelectedReportName(layoutItem.reportName);
                                                                    const displayTimeframe = timeframeStates[layoutItem.reportID]?.timeframe || "PAST DAY";
                                                                    const initialTimeframe = timeframeMapping[displayTimeframe] || "past1";
                                                                    setInitialTimeframe(initialTimeframe);

                                                                    setIsCloneOverlayOpen(true);
                                                                    handleMenuClose();
                                                                }
                                                            }}
                                                            className="text-sm"
                                                        >
                                                            <ContentCopy fontSize="small" className="mr-1" />
                                                            <span className="text-sm">Clone</span>
                                                        </MenuItem>
                                                )}
                                            <MenuItem onClick={() => { 
                                                const layoutItem = layout.find((item) => item.i === selectedItem);
                                                if (layoutItem) {

                                                 setSelectedReportID(layoutItem.reportID);
                                                 setSelectedReportName(layoutItem.reportName);
                                                setIsScheduleOverlayOpen(true); handleMenuClose() 
                                            }
                                            }}>
                                                    <Schedule fontSize="small" className="mr-1" />
                                                    <span className="text-sm">Schedule</span>
                                                </MenuItem>
                                                <MenuItem
                                                onClick={async () => {
                                                    const layoutItem = layout.find((item) => item.i === selectedItem);
                                                    if (!layoutItem) return;

                                                    setIsExporting("loading");

                                                    try {
                                                    await handleExport(layoutItem.reportName, layoutItem.reportID);
                                                    setIsExporting("success");
                                                    setTimeout(() => setIsExporting("idle"), 500);
                                                    handleMenuClose();
                                                    } catch (error) {
                                                    setIsExporting("idle");
                                                    }
                                                }}
                                                >
                                                {isExporting === "loading" ? (
                                                    <CircularProgress size={14} className="mr-2" />
                                                ) : isExporting === "success" ? (
                                                    <Icon path={mdiCheckCircleOutline} size={0.8} className="mr-1 text-green-500" />
                                                ) : (
                                                    <FileDownload fontSize="small" className="mr-2" />
                                                )}
                                                <span className="text-sm">Export</span>
                                                </MenuItem>
                                                {userID === dashboards.find((d) => d.id === dashboardID)?.createdByUserID && (

                                                <MenuItem
                                                    onClick={() => {
                                                        const layoutItem = layout.find((item) => item.i === selectedItem);
                                                        if (layoutItem) openConfirmPopup(dashboardID || "", layoutItem.reportID);
                                                    }}
                                                    className="text-sm"
                                                >
                                                    <Close fontSize="small" className="mr-1" />
                                                    <span className="text-sm">Remove from Dashboard</span>
                                                </MenuItem>

                                                )}
                                                </Menu>
                                            </div>

                                            <div className="flex pr-4">
                                                <span className="pl-6 block font-semibold text-lg text-dark-dark-grey p-4">
                                                    {item.reportName}
                                                </span>
                                                {isValueOrTable && (
                                                    <div className="relative mr-6 mt-4 group">
                                                        <div
                                                          onMouseDown={(e) => e.stopPropagation()}
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                toggleTimeframeMenu(item.reportID);
                                                              }}
                                                            className="cursor-pointer bg-light-grey mt-0.5 rounded px-1"
                                                        >
                                                            <span className="text-dark-dark-grey font-semibold uppercase text-xs">
                                                                {itemState.timeframe}
                                                            </span>
                                                            {!item.owner && isOwner && (
                                                                <div className="absolute left-1/2 transform -translate-x-1/2 w-64 bg-white text-grey text-xs rounded-md shadow-lg z-20 opacity-0 group-hover:opacity-100 transition-opacity">
                                                                    You do not own this report. You may clone it if you want to change its filters.
                                                                </div>
                                                            )}
                                                        </div>

                                                        {item.owner && isOwner && itemState.isOpen && (
                                                            <div 
                                                            ref={timeframeDropdownRef}
                                                            className="absolute right-0 mt-2 w-32 bg-white border border-grey rounded shadow-lg z-10">
                                                                {!itemState.showCustomDates ? (
                                                                    <ul className="text-sm text-grey">
                                                                        <li
                                                                            className="cursor-pointer p-2 hover:bg-light-grey"
                                                                            onClick={() => selectTimeframe(item.reportID, "PAST DAY", item.reportID, item.tableView)}
                                                                        >
                                                                            Past Day
                                                                        </li>
                                                                        <li
                                                                            className="cursor-pointer p-2 hover:bg-light-grey"
                                                                            onClick={() => selectTimeframe(item.reportID, "PAST WEEK", item.reportID, item.tableView)}
                                                                        >
                                                                            Past Week
                                                                        </li>
                                                                        <li
                                                                            className="cursor-pointer p-2 hover:bg-light-grey"
                                                                            onClick={() => selectTimeframe(item.reportID, "PAST 30 DAYS", item.reportID, item.tableView)}
                                                                        >
                                                                            Past 30 Days
                                                                        </li>
                                                                        <li
                                                                            className="cursor-pointer p-2 hover:bg-light-grey"
                                                                            onClick={() => selectTimeframe(item.reportID, "MONTH TO DATE", item.reportID, item.tableView)}
                                                                        >
                                                                            Month To Date
                                                                        </li>
                                                                        <li
                                                                            className="cursor-pointer p-2 hover:bg-light-grey"
                                                                            onClick={() => selectTimeframe(item.reportID, "YEAR TO DATE", item.reportID, item.tableView)}
                                                                        >
                                                                            Year To Date
                                                                        </li>
                                                                        <li
                                                                            className="cursor-pointer p-2 hover:bg-light-grey"
                                                                            onClick={() => toggleCustomDatePopup(item.reportID)}
                                                                        >
                                                                            Custom Date
                                                                        </li>
                                                                    </ul>
                                                                ) : (
                                                                    <div className="p-2">
                                                                        <label className="block text-xs text-dark-grey mb-2">
                                                                            Start Date
                                                                            <input
                                                                                type="date"
                                                                                value={customDateStates[item.reportID]?.startDate || ""}
                                                                                onChange={(e) =>
                                                                                    updateCustomDateState(item.reportID, "startDate", e.target.value)
                                                                                }
                                                                                className="block w-full border border-grey rounded p-1 mt-1"
                                                                            />
                                                                        </label>
                                                                        <label className="block text-xs text-dark-grey mb-2">
                                                                            End Date
                                                                            <input
                                                                                type="date"
                                                                                value={customDateStates[item.reportID]?.endDate || ""}
                                                                                onChange={(e) =>
                                                                                    updateCustomDateState(item.reportID, "endDate", e.target.value)
                                                                                }
                                                                                className="block w-full border border-grey rounded p-1 mt-1"
                                                                            />
                                                                        </label>
                                                                        {customDateErrors[item.reportID] && (
                                                                            <p className="text-xs text-red mt-1">
                                                                                {customDateErrors[item.reportID]}
                                                                            </p>
                                                                        )}
                                                                        <button
                                                                            className="block bg-blue text-white relative right-0 px-2 py-1 text-xs rounded mt-2"
                                                                            onClick={() =>
                                                                                setCustomDate(
                                                                                    item.reportID,
                                                                                    item.tableView,
                                                                                    customDateStates[item.reportID]?.startDate,
                                                                                    customDateStates[item.reportID]?.endDate
                                                                                )
                                                                            }
                                                                        >
                                                                            Set Date Range
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {item.departments && item.departments.length > 0 && !unauthorized[item.reportID] && (
                                                <div className="relative text-xs ml-6 text-gray">
                                                    <span className="cursor-pointer group/departments">
                                                        Departments: {item.departments.length}
                                                        <div
                                                            className="absolute left-0 top-full mt-1 max-w-[30vh] bg-white text-gray text-xs rounded-md shadow-lg z-20 opacity-0 group-hover/departments:opacity-100 transition-opacity p-2 pointer-events-none"
                                                        >
                                                            {item.departments.join(", ")}
                                                        </div>
                                                    </span>
                                                </div>
                                            )}

                                            <div className="p-2">{renderChartContent(item.reportID, item.tableView)}</div>
                                        </div>
                                    )
                                })
                            ) : (
                                <p>No layout data available</p>
                            )}
                        </GridLayout>
                )}
            </div>

        {isNewEditOverlayOpen && newReportID && newReportName && (
        <NewReportEditOverlay
          closeEditOverlay={closeEditOverlay}
          reportName={newReportName}
          setReportName={setNewReportName}
          customRangeStart={customRangeStart}
          setCustomRangeStart={setCustomRangeStart}
          customRangeEnd={customRangeEnd}
          setCustomRangeEnd={setCustomRangeEnd}
          initialTimeframe={initialTimeframe}
          clientName={newClientName}
          clientID={newClientID}
          customReportID={newReportID}
          dataSourceDepartments={newDataSourceDepartments}
          allUsers={location.state?.allUsers}
          onSave={() => {
            refetch();
          }}
        />
      )}


            {isEditOverlayOpen && !newReportID && !newReportName && (
                <EditOverlay
                    closeEditOverlay={() => {
                        reloadWebsocket()
                        setIsEditOverlayOpen(false)
                    }}
                    reportName={selectedReportName}
                    setReportName={setSelectedReportName}
                    customRangeStart={customRangeStart}
                    setCustomRangeStart={setCustomRangeStart}
                    customRangeEnd={customRangeEnd}
                    setCustomRangeEnd={setCustomRangeEnd}
                    initialTimeframe={initialTimeframe}
                    clientName={clientName || ""}
                    clientID={clientID || ""}
                    customReportID={selectedReportID}
                    onSave={() => {
                        refetch();
                    }}
                />
            )}

                {isCloneOverlayOpen && !newReportID && !newReportName && (
                <EditOverlay
                    closeEditOverlay={() => setIsCloneOverlayOpen(false)}
                    reportName={selectedReportName}
                    setReportName={setSelectedReportName}
                    customRangeStart={customRangeStart}
                    setCustomRangeStart={setCustomRangeStart}
                    customRangeEnd={customRangeEnd}
                    setCustomRangeEnd={setCustomRangeEnd}
                    initialTimeframe={initialTimeframe}
                    clientName={clientName || ""}
                    clientID={clientID || ""}
                    customReportID={selectedReportID}
                    onSave={() => {
                        refetch();
                    }}
                    isClone={true}
                />
            )}


                {isScheduleOverlayOpen && (
                <ScheduleOverlay
                    onClose={() => setIsScheduleOverlayOpen(false)}
                    selectedReportName={selectedReportName}
                    selectedReportID={selectedReportID}
                />
                )}


            {isDashboardEditOpen && dashboardData && (
                <DashboardEditOverlay
                    onClose={() => {
                        setIsDashboardEditOpen(false)
                    }}
                    dashboardID={dashboardData.id}
                    onSave={() => {
                        toggleDropdown();
                        window.location.reload();
                    }}
                    onDelete={() => {
                        handleOpenDeleteConfirm(dashboardData.id);
                    }}
                    isOwner={true}
                />
            )}

            {isDashboardInfoOpen && dashboardData && (
                <DashboardEditOverlay
                    onClose={() => setIsDashboardInfoOpen(false)}
                    dashboardID={dashboardData.id}
                    onSave={() => {
                        toggleDropdown();
                        window.location.reload();
                    }}
                    onDelete={() => {
                        handleOpenDeleteConfirm(dashboardData.id);
                    }}
                    isOwner={false}
                />
            )}


            {isConfirmPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div
                        className="absolute inset-0 bg-black bg-opacity-50"
                        onClick={closeConfirmPopup}
                    ></div>
                    <div className="relative bg-white w-1/3 p-6 rounded shadow-lg z-10">
                        <h2 className="text-xl font-semibold text-gray-700 mb-4">
                            Are you sure you want to remove this report from the dashboard?
                        </h2>
                        <div className="flex justify-end">
                            <button
                                onClick={closeConfirmPopup}
                                className="px-4 py-2 text-gray-600 hover:text-gray-800 rounded mr-4"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleRemoveClick}
                                className="px-4 py-2 bg-red text-white hover:bg-red rounded"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {isCreateDashboardOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div
                        className="absolute inset-0 backdrop-blur-sm backdrop-brightness-50"
                        onClick={closeCreateDashboard}
                    ></div>
                    <div className="relative bg-white-blue m-20 mt-40 pb-8 min-w-xl w-[768px] max-w-full p-0 rounded-lg shadow-lg z-100 overflow-visible animate-[zoom-in_0.2s_ease-in-out_forwards]">
                        <div className="bg-blue w-full flex items-center justify-between p-4">
                            <h2 className="text-lg font-semibold text-white">New Dashboard</h2>
                            <button
                                className="text-white hover:text-white-blue"
                                onClick={closeCreateDashboard}
                                aria-label="Close Create Dashboard Modal"
                            >
                                <Close />
                            </button>
                        </div>
                        <div className="px-8 flex flex-col gap-8">
                            <div className={"flex flex-col gap-2"}>
                                <h3 className="font-semibold pt-6 text-dark-dark-grey">Dashboard Name</h3>
                                <div className="w-full">
                                    <BasicText value={dashboardName} onChange={setDashboardName} placeholder={"Enter dashboard name"} />
                                </div>
                            </div>
                            {auth.hasAccessLevelClient && (
                                <div className="w-full flex justify-center">
                                    <ToggleButton text="Client Admins Only" initialValue={false} onChange={setClientAdminsOnly}/>
                                </div>
                            )}
                            { !clientAdminsOnly && (
                                <div className="">
                                    <div className="bg-white rounded">
                                        <DepartmentSelect
                                            clientID={userContext?.role.auroraAccessLevel === "all" ? clientID : userContext?.metadata.clientID}
                                            departments={selectedDepartments}
                                            onSelectionChange={(departments) => {
                                                setSelectedDepartments(departments);
                                            }}
                                            multiple={true}
                                            allowSelectAll={true}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="flex flex-col justify-center items-center gap-8">
                                <button
                                    onClick={handleCreateDashboardClick}
                                    className="border-2 text-blue text-sm rounded p-2"
                                >
                                    Create Dashboard
                                </button>
                                {createDashboardError && (
                                    <p className={"text-red text-sm"}>
                                        {createDashboardError}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isOverlayOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div
                        className="absolute inset-0 bg-black bg-opacity-50"
                        onClick={closeOverlay}
                    ></div>

                    <div className="relative bg-white w-3/4 p-8 rounded shadow-lg z-10 animate-[zoom-in_0.2s_ease-in-out_forwards]">
                        <h2 className="text-2xl font-semibold mb-4">
                            {overlayDataTitle || ""}
                        </h2>
                        {renderOverlayContent(selectedReportID)}
                        <button
                            className="mt-4 px-4 py-2 bg-blue text-white rounded"
                            onClick={closeOverlay}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
            <style>
                {`
          @keyframes zoom-in {
            0% {
              opacity: 0;
              transform: scale3d(0.7, 0.7, 0.7);
            }
            100% {
              opacity: 1;
              transform: scale3d(1, 1, 1);
            }
          }
        `}
            </style>
        </div>
    )
}
