import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import BasicText from "../basic-text";
import {ButtonV2} from "../../ui/ButtonsV2/ButtonV2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import {useDebugCounter} from "../../../lib/debug-counter/useDebugCounter";
import {PhoneInput, PhoneInputHandle} from "../phone-input/PhoneInput";
import {parsePhoneNumberWithError} from "libphonenumber-js";
import {countries} from "../../../lib/countries";

type InputType = "email" | "phone"

type MultiInputSingleFieldProps = {
    label?: string;
    initialValues?: string[];
    onChange?: (values: string[]) => void;
    placeholder?: string;
    type?: InputType
}


const MultiInputSingleField: React.FC<MultiInputSingleFieldProps> = (props) => {

    const {label, initialValues, onChange, placeholder} = props as Required<MultiInputSingleFieldProps>;
    const [debug, incrementDebug] = useDebugCounter()

    const phoneRef = useRef<PhoneInputHandle>(null);

    const [inputValue, setInputValue] = useState<string>("");


    const disableAdd = useMemo(() => {
        return inputValue.trim() == ""
    }, [inputValue]);


    const clean = useCallback((value: string) => {
        if (props.type == "email") {
            return value.toLowerCase().trim()
        }
        return value.trim()
    }, [props.type])

    const [values, setValues] = useState<string[]>(initialValues.map(clean));


    const add = useCallback(() => {
        if(disableAdd){
            return
        }

        const newValues = [...values, clean(inputValue)]
        setValues(newValues)
        setInputValue("")

        if (phoneRef.current) {
            phoneRef.current.reset();
        }

    }, [inputValue, setInputValue, values, setValues, disableAdd]);

    const edit = useCallback((value: string, index: number) => {
        setValues((prevValues) => {
            const newValues = [...prevValues];
            newValues[index] = clean(value);
            return newValues;
        });
    }, [setValues]);

    const remove = useCallback((index: number) => {
        setValues((prevValues) => {
            const newValues = [...prevValues];
            newValues.splice(index, 1)
            return newValues;
        });
    }, [setValues]);

    useEffect(() => {
        onChange(values.map(clean))
    }, [values]);


    const inputField = useMemo(() => {
        switch (props.type) {
            case "phone":
                return <PhoneInput onChange={setInputValue} ref={phoneRef}/>
        }
        return <BasicText value={inputValue} onChange={setInputValue} placeholder={placeholder} onEnter={add}/>
    }, [props.type, inputValue, setInputValue, phoneRef, props.placeholder])

    return (
        <div className={"flex flex-col gap-4"}>
            {label && <label onClick={incrementDebug} className="text-md text-left pb-2 font-semibold">{label}</label>}

            {debug && <p>{JSON.stringify(values)}</p>}


            {values.map((v, i) => (
                <>
                    <Row key={i} value={v} index={i} onChange={edit} onRemove={remove} type={props.type} placeholder={placeholder}/>
                    <hr className="border-light-grey"/>
                </>
            ))}


            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {inputField}
                <div className={"grid grid-cols-2 grid-cols-subgrid gap-8 col-span-1"}>

                    <ButtonV2 label={<AddIcon/>} onClick={add} disabled={disableAdd}/>
                </div>
            </div>

        </div>
    );
};

MultiInputSingleField.displayName = "MultiInputSingleField";

MultiInputSingleField.defaultProps = {
    label: "",
    initialValues: [],
    onChange: (values) => {
        console.log(`${MultiInputSingleField.displayName}:onChange`, values);
    },
    placeholder: "",
}

type RowProps = {
    value: string;
    index: number;
    onChange: (value: string, index: number) => void;
    onRemove: (index: number) => void;
    type?: InputType
    placeholder: string
}

const Row: React.FC<RowProps> = (props) => {

    const [editMode, setEditMode] = useState(false)

    const [editValue, setEditValue] = useState<string>(props.value);


    const enableEdit = useCallback(() => {
        setEditMode(true)
    }, [setEditMode])


    const remove = useCallback(() => {
        props.onRemove(props.index)
    }, [editValue, setEditMode, props.index])

    const disableSave = useMemo(() => {
        return editValue.trim() == ""
    }, [editValue])

    const update = useCallback(() => {
        if(disableSave) return
        props.onChange(editValue, props.index)
        setEditMode(false)
    }, [editValue, setEditMode, props.index, disableSave])



    const inputField = useMemo(() => {
        switch (props.type) {
            case "phone":
                return <PhoneInput  initialValue={editValue} onChange={setEditValue} placeholder={props.placeholder}/>
        }
        return <BasicText value={editValue} onChange={setEditValue} placeholder={props.placeholder} onEnter={update}/>
    }, [props.type, editValue, setEditValue])


    const display = useMemo(() => {
        if (props.type == "phone") {
            try {
                const parsed = parsePhoneNumberWithError(props.value)

                if (parsed) {

                    const country = countries.find(c => c.countryCode == parsed.country)

                    if (country) {
                        return `${country.flag} ${parsed.format("INTERNATIONAL")}`
                    }

                    return parsed.format("INTERNATIONAL")
                }
            } catch (e) {
            }
        }

        return props.value
    }, [props.value, props.type])


    return <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {editMode ? (
            <>
                {inputField}
                <div className={"grid grid-cols-2 gap-8 col-span-1"}>
                    <ButtonV2 label={<SaveIcon/>} onClick={update} color={"green"} disabled={disableSave}/>
                </div>


            </>

        ) : (
            <>
                <div className={"flex items-center"}>
                    <div className={"truncate max-w-full"}>{display}</div>
                </div>
                <div className={"grid grid-cols-2 gap-8 col-span-1"}>
                    <ButtonV2 label={<EditIcon/>} onClick={enableEdit}/>
                    <ButtonV2 label={<DeleteIcon/>} onClick={remove} color={"orange"}/>
                </div>

                {/* <BlueButton text={"Edit"} onClick={enableEdit}/>*/}
            </>

        )}
    </div>
}


export default MultiInputSingleField;