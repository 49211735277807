import { useMemo } from "react";

export const useChunkedArray = <T,>(arr: T[], size: number) => {

    return useMemo(() => {
        const result = [];
        for (let i = 0; i < arr.length; i += size) {
            result.push(arr.slice(i, i + size));
        }
        return result;
    }, [arr, size]);

}

