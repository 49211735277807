import {apiV2} from "../../api/axiosConfig";
import {PaginatedResponse} from "../../types/PaginatedResponse";
import {Department} from "../../types/userManagement";
import {useQuery} from "react-query"

export const fetchDepartment = async (id: string) => {
    const res = await apiV2.get<Department>(`/departments/${id}`)
    return res.data
}

export const useLoadDepartment = (id: string | null) => {
    return useQuery(["department", id], () => fetchDepartment(id || ""), {
        enabled: !!id,  // Only enable the query if `id` is not null
        initialData: null,  // Set the initial data to null
        placeholderData: null,
    });
}