import React, {useCallback, useMemo, useState} from "react";

type ProgressBarProps = {
    current: number;
    total: number;
}


export const ProgressBar: React.FC<ProgressBarProps> = ({current, total}) => {


    const [showPercentage, setShowPercentage] = useState(true);
    const togglePercentage = useCallback(() => {
        setShowPercentage(prev => !prev);
    },[])

    const progressWidth = useMemo(() => {

        const val = ((current / total) * 100).toFixed(0);
        return `${val}%`

    }, [current, total])



    return (
        <div className={"cursor-pointer select-none"} onClick={togglePercentage}>
            <div className="w-full bg-light-grey rounded-full h-2.5">
                <div className="bg-blue h-2.5 rounded-full" style={{width: progressWidth}}></div>
            </div>
            {showPercentage ? (<div className={"text-sm text-center "}>{progressWidth}</div>) : (
                <div className={"text-sm text-center"}>{current}/{total}</div>

            )}
        </div>
    )
}