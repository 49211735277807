import React, { useContext, useEffect, useMemo, useState } from "react"
import {
  IconButton,
  Tooltip,
  Popover,
  TextField,
  InputAdornment
} from "@mui/material"
import Fuse from "fuse.js"
import {
  MoreVert,
  FilterList as FilterListIcon,
  ArrowBack,
  ArrowForward,
  Search
} from "@mui/icons-material"
import ClientSelectSinglePopup from "../../../../../components/form-v2/searchable-select/ClientSelectPopup"
import { UserContext } from "../../../../../context/user"
import { useClientDashboard } from "./ClientDashboardProvider"
import DepartmentSelect from "../../../../../components/form-v2/searchable-select/DepartmentSelect"
import UserSelect from "../../../../../components/form-v2/searchable-select/UserSelect"
import { Report, ScheduledReport } from "./reportTypes"
import { Department } from "../../../../../types/userManagement"
import { UserDataTypes } from "../../../../../types/user-data-types"
import {ReportRow} from "./ReportsRow";


interface CommonProps {
  handleMenuOpen: (e: React.MouseEvent<HTMLElement>, report: Report | ScheduledReport) => void
  handleNameClick: (e: React.MouseEvent<HTMLElement>, report: Report | ScheduledReport) => void
}

export interface ReportsTableAllProps extends CommonProps {
  data: Report[]
  filterAnchorEl: HTMLElement | null
  handleFilterOpen: (event: React.MouseEvent<HTMLElement>) => void
  handleFilterClose: () => void
  FILTER_TYPES: string[]
  selectedFilterTypes: string[]
  handleFilterChange: (type: string) => void
}

export function ReportsTableAll({
  data,
  handleMenuOpen,
  handleNameClick,
  selectedFilterTypes,
}: ReportsTableAllProps) {
  const userContext = useContext(UserContext)!
  const accessLevel = userContext.role.auroraAccessLevel
  const { setClient: setContextClient, client: contextClient } = useClientDashboard()
  const [clientFilterAnchor, setClientFilterAnchor] = useState<HTMLElement | null>(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedDepartments, setSelectedDepartments] = useState<Department[]>([]);
  const [selectedOwners, setSelectedOwners] = useState<UserDataTypes[]>([]);

  const [deptFilterAnchor, setDeptFilterAnchor] = useState<HTMLElement | null>(null)
  const [ownerFilterAnchor, setOwnerFilterAnchor] = useState<HTMLElement | null>(null)
  const [activeDepartments, setActiveDepartments] = useState<{
    id: string
    departments: string[]
    anchorEl: HTMLElement | null
  } | null>(null)



  const fuse = useMemo(
    () =>
      new Fuse(data, {
        keys: ["reportName", "clientName", "ownedByUserName", "timeframe", "departmentNames"],
        threshold: 0.3
      }),
    [data]
  )
  const filteredBySearch = useMemo(() => {
    if (searchTerm) return fuse.search(searchTerm).map((res) => res.item)
    return data
  }, [searchTerm, data, fuse])
  const filteredData = useMemo(() => {
    let result = filteredBySearch
    if (selectedDepartments.length > 0) {
      result = result.filter((report) => {
        const depts = report.dataSourceDepartmentNames || []
        if (depts.includes("all") || depts.includes("All Departments")) return true
        return depts.some((dept) =>
          selectedDepartments.some((sel) => sel.name === dept)
        )
      })
    }
    if (selectedOwners.length > 0) {
      result = result.filter((report) =>
        selectedOwners.some(
          (owner) => `${owner.profile.firstName} ${owner.profile.lastName}` === report.ownedByUserName
        )
      )
    }
    if (selectedFilterTypes.length > 0) {
      result = result.filter((report) => selectedFilterTypes.includes(report.filterType))
    }
    return result
  }, [filteredBySearch, selectedDepartments, selectedOwners, selectedFilterTypes])

  const [currentPage, setCurrentPage] = useState(1)
  const REPORTS_PER_PAGE = 7
  const totalPages = Math.ceil(filteredData.length / REPORTS_PER_PAGE)
  const paginatedData = filteredData.slice(
    (currentPage - 1) * REPORTS_PER_PAGE,
    currentPage * REPORTS_PER_PAGE
  )
  const indexOfFirst = (currentPage - 1) * REPORTS_PER_PAGE
  const indexOfLast = currentPage * REPORTS_PER_PAGE

  return (
    <div className="rounded-lg mt-4">
      <div className="mb-4 flex justify-between items-center">
        <TextField
          variant="outlined"
          label="Search Reports"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setCurrentPage(1)
          }}
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
          className="bg-white rounded-lg shadow-md"
        />
      </div>
      <table className="bg-white w-full">
        <thead>
          <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-1 px-4 text-left">Report Name</th>
            {accessLevel === "all" && (
            <th className="py-1 px-4 text-left">
              <span className="inline-flex items-center relative">
                Client
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    setClientFilterAnchor(
                      clientFilterAnchor ? null : e.currentTarget
                    )
                  }}
                  className={`ml-1 ${contextClient ? "bg-blue-500 text-white" : ""}`}
                >
                  <FilterListIcon fontSize="small" />
                  {contextClient && (
                    <span className="absolute top-0 right-0 w-2 h-2 bg-grey rounded-full" />
                  )}
                </IconButton>
              </span>
              <ClientSelectSinglePopup
                client={contextClient}
                onChange={(selected) => setContextClient(selected)}
                disabled={false}
                open={Boolean(clientFilterAnchor)}
                anchorEl={clientFilterAnchor}
                onClose={() => setClientFilterAnchor(null)}
              />
            </th>
            )}
            <th className="py-1 px-4 text-left">
              <span className="inline-flex items-center">
                Department(s)
                <IconButton
                  size="small"
                  onClick={(e) => {
                    console.log("Departments filter icon clicked")
                    setDeptFilterAnchor(deptFilterAnchor ? null : e.currentTarget)
                  }}
                  className="ml-1"
                >
                  <FilterListIcon fontSize="small" />
                </IconButton>
              </span>
              <Popover
                open={Boolean(deptFilterAnchor)}
                anchorEl={deptFilterAnchor}
                onClose={() => setDeptFilterAnchor(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                PaperProps={{
                  sx: { width: 350, height: 250, p: 2 },
                  className: "shadow-lg rounded-lg bg-transparent"
                }}
              >
                <DepartmentSelect
                  clientID={contextClient?.id || null}
                  multiple={true}
                  departments={selectedDepartments}
                  onSelectionChange={(depts) => setSelectedDepartments(depts)}
                  disabled={false}
                  allowSelectAll={true}
                  noClientOnChange={true}
                />
              </Popover>
            </th>
            <th className="py-1 px-4 text-left">
              <span className="inline-flex items-center">
                Owned By
                <IconButton
                  size="small"
                  onClick={(e) =>
                    setOwnerFilterAnchor(ownerFilterAnchor ? null : e.currentTarget)
                  }
                  className="ml-1"
                >
                  <FilterListIcon fontSize="small" />
                </IconButton>
              </span>
              <Popover
                open={Boolean(ownerFilterAnchor)}
                anchorEl={ownerFilterAnchor}
                onClose={() => setOwnerFilterAnchor(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                PaperProps={{
                  sx: { width: 350, height: 250, p: 2 },
                  className: "shadow-lg rounded-lg bg-transparent"
                }}
              >
                <UserSelect
                  clientID={contextClient?.id || null}
                  departmentID={null}
                  multiple={true}
                  onSelectionChange={(users) => setSelectedOwners(users)}
                  users={selectedOwners}
                  disabled={false}
                  noClientOnChange={true}
                />
              </Popover>
            </th>
            <th className="py-1 px-4 text-left">Timeframe</th>
            <th className="py-1 px-4 text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {paginatedData.length === 0 ? (
            <tr>
              <td colSpan={7} className="py-2 text-center">
                No reports found.
              </td>
            </tr>
          ) : (
            paginatedData.map((report, idx) => (
                <ReportRow key={report.id} report={report} index={idx} handleMenuOpen={handleMenuOpen} handleNameClick={handleNameClick} />
            ))
          )}
        </tbody>
      </table>
      {totalPages > 1 && (
        <div className="flex justify-between items-center mt-3 px-6 bg-gray-100 rounded-b-lg">
          <div className="text-sm text-gray-700">
            Showing {indexOfFirst + 1} to {Math.min(indexOfLast, filteredData.length)} of {filteredData.length} reports
          </div>
          <div className="flex items-center space-x-2">
            <Tooltip title="Previous Page">
              <IconButton
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <span className="text-sm text-gray-700">
              Page {currentPage} of {totalPages}
            </span>
            <Tooltip title="Next Page">
              <IconButton
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
              >
                <ArrowForward />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  )
}

export interface ReportsTableScheduledProps extends CommonProps {
  data: ScheduledReport[]
}

export function ReportsTableScheduled({
  data,
  handleMenuOpen,
  handleNameClick
}: ReportsTableScheduledProps) {
  const [searchTerm, setSearchTerm] = useState("")
  const fuse = useMemo(
    () =>
      new Fuse(data, {
        keys: ["reportName", "recipientName", "schedule.frequency", "schedule.time"],
        threshold: 0.3
      }),
    [data]
  )
  const filtered = useMemo(
    () => (searchTerm ? fuse.search(searchTerm).map((res) => res.item) : data),
    [searchTerm, data, fuse]
  )
  const [currentPage, setCurrentPage] = useState(1)
  const REPORTS_PER_PAGE = 7
  const totalPages = Math.ceil(filtered.length / REPORTS_PER_PAGE)
  const paginated = filtered.slice(
    (currentPage - 1) * REPORTS_PER_PAGE,
    currentPage * REPORTS_PER_PAGE
  )
  return (
    <div className="overflow-auto mt-2 rounded-lg">
      <div className="mb-2 flex mt-4 justify-between items-center">
        <TextField
          variant="outlined"
          label="Search Scheduled Reports"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setCurrentPage(1)
          }}
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
          className="bg-white rounded-lg shadow-md"
        />
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-3 px-6 text-left">Report Name</th>
            <th className="py-3 px-6 text-left">Recipient</th>
            <th className="py-3 px-6 text-left">Frequency</th>
            <th className="py-3 px-6 text-left">Time</th>
            <th className="py-3 px-6 text-left">Start Date</th>
            <th className="py-3 px-6 text-left">End Date</th>
            <th className="py-3 px-6 text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {paginated.length === 0 ? (
            <tr>
              <td colSpan={7} className="py-4 text-center">
                No scheduled reports found.
              </td>
            </tr>
          ) : (
            paginated.map((sr, idx) => (
              <tr
                key={sr.id}
                className={`border-b border-gray-200 hover:bg-gray-50 ${
                  idx % 2 === 0 ? "bg-white" : "bg-gray-50"
                }`}
              >
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  <div className="flex items-center">
                    <span className="font-medium">{sr.reportName}</span>
                  </div>
                </td>
                <td className="py-3 px-6 text-left">
                  <span className="font-medium">{sr.recipientName}</span>
                </td>
                <td className="py-3 px-6 text-left text-xs capitalize">
                  {sr.schedule.frequency === "year"
                    ? `Yearly, on the ${sr.schedule.monthlyYearlyDay}`
                    : sr.schedule.frequency === "month"
                    ? `Monthly${sr.schedule.monthlyYearlyDay ? `, on the ${sr.schedule.monthlyYearlyDay}` : ""}`
                    : sr.schedule.frequency === "week"
                    ? `Weekly, on ${sr.schedule.daysOfWeek?.join(", ")}`
                    : "Daily"}
                </td>
                <td className="py-3 px-6 text-left">{sr.schedule.time}</td>
                <td className="py-3 px-6 text-left">
                  {new Date(sr.schedule.startDate).toLocaleDateString()}
                </td>
                <td className="py-3 px-6 text-left">
                  {sr.schedule.endDate
                    ? new Date(sr.schedule.endDate).toLocaleDateString()
                    : "Continuous"}
                </td>
                <td className="py-3 px-6 text-center">
                  <Tooltip title="Actions">
                    <IconButton onClick={(e) => handleMenuOpen(e, sr)}>
                      <MoreVert />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {totalPages > 1 && (
        <div className="flex justify-between items-center mt-4 px-6 py-3 bg-gray-100 rounded-b-lg">
          <div className="flex items-center space-x-2">
            <Tooltip title="Previous Page">
              <IconButton
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <span className="text-sm text-gray-700">
              Page {currentPage} of {totalPages}
            </span>
            <Tooltip title="Next Page">
              <IconButton
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
              >
                <ArrowForward />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  )
}
