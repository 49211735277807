import React, {useMemo} from "react";
import {Layout} from "./Layout";
import {useUserActivation} from "./UserActivationContext";
import {PasswordForm} from "./PasswordForm";
import {UserForm} from "./UserForm";
import {CompletePage} from "./CompletePage";

export const UserActivationPage: React.FC = () => {
    const ctx = useUserActivation()


    const page = useMemo(() => {

        switch (ctx.step) {
            case 1:
                return <PasswordForm/>
            case 2:
                return <UserForm/>
            case 3:
                return <CompletePage/>
        }

        return null
    }, [ctx.step])


    return <Layout>
        {page}
    </Layout>

}