export const routesPath = [
  {
    title: "Operational Map",
    path: "/operational-map",
    slug: "operational_map",
  },
  {
    title: "World Search",
    path: "/world-search",
    slug: "world_search",
  },
  {
    title: "Trips",
    path: "/trips",
    slug: "trips",
  },
  {
    title: "Sites",
    path: "/sites",
    slug: "sites",
  },
  {
    title: "Risk",
    path: "/risk/country-profiles",
    slug: "risk",
  },
  {
    title: "Reporting",
    path: "/reporting/summary",
    slug: "reports",
  },
  {
    title: "eLearning",
    path: "https://ngstravelsafety.elearning247.com/",
    slug: "e_learning",
  },
];
