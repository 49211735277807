import React from "react";


export const Panel: React.FC<React.PropsWithChildren> = ({ children }) => {

    return (
        <div className="bg-white shadow-md flex flex-col gap-8 items-center p-8">
            {children}
        </div>
    )
}