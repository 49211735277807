import React from "react";

export default function PaginationNoneFloating({
  currentPage,
  itemsPerPage,
  togglePageSelect,
  dataLength,
}: {
  currentPage: number;
  itemsPerPage: number;
  dataLength: number;
  togglePageSelect: (page: number) => void;
}) {
  // calculate the total number of pages
  const totalPages = Math.ceil(dataLength / itemsPerPage);

  // define the number of pages to show before and after the current page
  const pagesToShow = 3;

  // calculate the range of pages to show before and after the current page
  const pageRangeStart = Math.max(currentPage - pagesToShow, 1);
  const pageRangeEnd = Math.min(currentPage + pagesToShow, totalPages);

  // add dots if the page range does not start at 1 or end at the last page
  const shouldShowLeftDots = pageRangeStart > 1;
  const shouldShowRightDots = pageRangeEnd < totalPages;

  return (
    <div className="">
      <div className="flex text-light-grey">
        <span className="mr-2 text-grey">Page</span>
        <div className="flex items-center">
          {shouldShowLeftDots && (
            <>
              <button
                onClick={() => togglePageSelect(1)}
                className={`mr-3 ${currentPage === 1 ? "text-grey" : ""}`}
              >
                1
              </button>
              <span className="mr-3">...</span>
            </>
          )}

          {Array.from(
            { length: pageRangeEnd - pageRangeStart + 1 },
            (v, i) => i + pageRangeStart
          ).map((page) => (
            <button
              key={page}
              onClick={() => togglePageSelect(page)}
              className={`mr-3 ${page === currentPage ? "text-grey" : ""}`}
            >
              {page}
            </button>
          ))}

          {shouldShowRightDots && (
            <>
              <span className="mr-3">...</span>
              <button
                onClick={() => togglePageSelect(totalPages)}
                className={`mr-3 ${
                  currentPage === totalPages ? "text-grey" : ""
                }`}
              >
                {totalPages}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
