import {RefObject, useEffect, useMemo, useState} from "react";
import useUserActivity from "../../../services/useUserActivity";
import { UserActivityDataTypes } from "../../../types/user-activity-data-types";
import {useSearchParams} from "react-router-dom";

const emergencyTypes = [
  "alerts",
  "geofences",
  "check_in_missed",
  "safety_zone",
  "message",
  "silent_alarm",
  "emergency_alarm",
];

export default function useAlarm(audioRef: RefObject<HTMLAudioElement>) {
  const [alarm, setAlarm] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const { data, activeArr } = useUserActivity();

  const [alwaysMuteAlarm, setAlwaysMuteAlarm] = useState<boolean>(!!localStorage.getItem("alwaysMuteAlarm"));

  const [searchParams] = useSearchParams();


  const muteAlarmParam = useMemo(() => {
    const val = searchParams.get("mute");
    switch (val) {
      case "1":
      case "true":
        return true
      case "0":
      case "false":
        return false;
    }

    return undefined;

  }, [searchParams])

  useEffect(() => {
    switch(muteAlarmParam){
      case true:
        localStorage.setItem("alwaysMuteAlarm", "true");
        setAlwaysMuteAlarm(true);
        return
      case false:
        localStorage.removeItem("alwaysMuteAlarm");
        setAlwaysMuteAlarm(false);
    }
  }, [muteAlarmParam]);

  /**
   *
   * @param userActivity NEW USER ACTIVITY
   * if there is a new emergency then it sets the alarm to true
   */
  const checkAlarm = (userActivity: UserActivityDataTypes[]) => {
    const emergency = userActivity?.filter((activity: UserActivityDataTypes) =>
      emergencyTypes.includes(activity.type)
    );
    if (emergency.length > 0) {
      setAlarm(true);
    }
  };

  useEffect(() => {
    const active = data?.active;
    if (!active) return;
    const emergency = active?.filter((activity: UserActivityDataTypes) =>
      emergencyTypes.includes(activity.type)
    );
    if (emergency.length === 0) {
      setAlarm(false);
      return;
    }
    if (active) {
      checkAlarm(active);
    }
  }, [activeArr, data]);

  useEffect(() => {
    const activeUserActivity = data?.active;
    if (activeUserActivity) {
      checkAlarm(activeUserActivity);
    }
  }, []);

  const play = () => {
    if(alwaysMuteAlarm){
      return
    }
    audioRef.current?.play().then(() => setPlaying(true)).catch(() => setPlaying(false));
  };

  const pause = () => {
    setPlaying(false);
    audioRef.current?.pause();
  };

  useEffect(() => {
    if (alarm) {
      try {
        play();
      } catch(e){
        console.error(e);
      }
    } else {
      pause();
    }
  }, [alarm]);

  return { alarm, setAlarm, alwaysMuteAlarm };
}
