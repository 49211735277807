// Import necessary libraries
import React, {createContext, useContext, useState, ReactNode, useEffect, useCallback, useMemo} from "react";
import {useQuery} from "react-query";
import {apiV2} from "../../../api/axiosConfig";
import {Client, Department, Users} from "../../../types/userManagement";
import {PaginatedResponse} from "../../../types/PaginatedResponse";
import client from "../../../pages/user-management/Client";
import {UserDataTypes} from "../../../types/user-data-types";
import {useDebounce} from "use-debounce";
import {useAuth} from "../../../context/user";
import {isAxiosError} from "axios";
import {useUserManagementBrowsing} from "../../../lib/user-management/UserManagementBrowsingContext";
import {fetchUsers} from "./useUserExport";

// Define the shape of the context value
interface UsersManagementContextType {
    client?: Client;
    setClient: (client?: Client) => void;
    departments?: Department[];
    setDepartments: (departments: Department[]) => void;
    users?: PaginatedResponse<UserDataTypes>;
    isLoading: boolean;
    error: any;
    selected: Set<string>;
    addSelected: (id: string) => void;
    removeSelected: (id: string) => void;
    selectAll: () => void;
    deselectAll: () => void;
    page: number;
    setPage: (page: number) => void;
    limit: number;
    refetch: () => void;
    actionError: string;
    setActionError: (error: string) => void;
    archived: boolean;
    setArchived: (archived: boolean) => void;
    search: string;
    setSearch: (search: string) => void;
    selectAllPages: () => void;
    archiveMultiple: () => Promise<void>;
    unarchiveMultiple: () => Promise<void>;

    submitting: boolean;
    setSubmitting: (submitting: boolean) => void;
}

const UsersManagementContext = createContext<UsersManagementContextType | undefined>(undefined);



// Create a provider component
interface UsersManagementProviderProps {
    children: ReactNode;
}

export const UsersManagementProvider: React.FC<UsersManagementProviderProps> = ({children}) => {
    const browsingCtx = useUserManagementBrowsing()

    const {searchParams, setSearchParams} = browsingCtx;
    const [client, setClient] = useState<Client>();
    const [departments, setDepartments] = useState<Department[]>([]);

    const [selected, setSelected] = useState(new Set<string>)
    const [page, setPage] = useState<number>(Number(searchParams.get("page")) || 1);
    const [limit, setLimit] = useState<number>(10);
    const [archived, setArchived] = useState(false)
    const [search, setSearch] = useState(searchParams.get("search") || "")
    const [debouncedSearch] = useDebounce(search, 300)

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setPage(1)
    }, [debouncedSearch]);


    useEffect(() => {
        browsingCtx.setClientID(client?.id || "");
    }, [client]);



    const [actionError, setActionError] = useState("");


    useEffect(() => {
        if (actionError != "") {
            setTimeout(() => {
                setActionError("");
            }, 10 * 1000)
        }


    }, [actionError]);


    const addSelected = (id: string) => {
        const newSet = new Set(selected);
        newSet.add(id);
        setSelected(newSet);
    }

    const removeSelected = (id: string) => {
        const newSet = new Set(selected);
        newSet.delete(id);
        setSelected(newSet);

    }

    const selectAll = () => {
        if (users) {
            const newSet = new Set(selected);
            users.items.forEach(d => d.id ? newSet.add(d.id) : null)
            setSelected(newSet)
        }
    }

    const selectAllPages = async () => {
        let page = 1

        const ids: string[] = []
        while(true){
            const users = await fetchUsers(client?.id || "", page, 50, archived, departments.map(d => d.id), debouncedSearch)
            if(users?.items.length == 0){
                break
            }
            page += 1
            users?.items.forEach(u => ids.push(u.id))
        }

        setSelected(new Set(ids))
    }


    const deselectAll = () => {
        setSelected(new Set())
    }

    const auth = useAuth()

    const enableQuery = useMemo(() => {
        if(auth.isSuperAdmin){
            return !!client
        }

        return true

    }, [auth.isSuperAdmin, auth.hasAccessLevel, client])


    const {data: users, isLoading, error, refetch} = useQuery(
        ["users", client, departments, archived, page, limit, debouncedSearch],
        () => fetchUsers(client?.id || "", page, limit, archived, departments.map(d => d.id), debouncedSearch),
        {
            enabled: enableQuery, // Only fetch when clientID is set
        }
    );



    useEffect(() => {

        setSearchParams((prev) => {
            const newParams = new URLSearchParams(prev.toString());
            newParams.set("page", `${page}`);
            newParams.set("search", `${debouncedSearch}`);
            return newParams;
        })

    }, [debouncedSearch, page]);



    const archiveMultiple = useCallback(async () => {
        await  apiV2.patch("users/archive-multiple", {
            ids: Array.from(selected)
        })
        deselectAll()
        return

    }, [selected])

    const unarchiveMultiple = useCallback(async () => {
        await  apiV2.patch("users/unarchive-multiple", {
            ids: Array.from(selected)
        })
        deselectAll()
        return

    }, [selected])


    return (
        <UsersManagementContext.Provider value={{
            client,
            setClient,
            departments,
            setDepartments,
            users,
            isLoading,
            error,
            addSelected,
            removeSelected,
            selectAll,
            deselectAll,
            selected,
            page,
            limit,
            setPage,
            refetch,
            actionError,
            setActionError,
            archived,
            setArchived,
            search,
            setSearch,
            selectAllPages,
            archiveMultiple,
            unarchiveMultiple,
            submitting,
            setSubmitting,
        }}>
            {children}
        </UsersManagementContext.Provider>
    );
};

// Create a custom hook for consuming the context
export const useUsersManagement = (): UsersManagementContextType => {
    const context = useContext(UsersManagementContext);
    if (!context) {
        throw new Error("useUsersManagement must be used within a UsersManagementProvider");
    }
    return context;
};
