import React, {
    createContext,
    useContext,
    ReactNode,
    Ref, useState, useEffect, useCallback,
} from "react";
import {useFileSelect} from "./useFileSelect";
import {ImportRow, useImportParser} from "./useImportParser";
import {useVerifyImport, VerificationResponseItem, VerifyResponse} from "./useVerifyImport";
import {useUserManagementBrowsing} from "../../../lib/user-management/UserManagementBrowsingContext";
import {useToggle} from "./useToggle";

type Stage = "select_file" | "verify" | "verify_results" | "submit"

// Define context types using `type`
type ImportContextType = {
    currentStage: string
    gotoStage: (stage: Stage) => void

    fileInputRef: Ref<HTMLInputElement>
    selectedFile: File | null
    activateFileSelect: () => void
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    selectedFileRows: number | null
    clear: () => void
    departmentsCreated: string[]

    headers: ImportRow
    rows: ImportRow[]

    submitForVerification: () => Promise<void>
    verificationProgress: number
    totalRows: number
    verifying: boolean
    verificationCompleted: boolean
    verificationResults: VerificationResponseItem[],
    clientID: string | undefined,

    sendInvitationEmails: boolean,
    setSendInvitationEmails: (value: boolean) => void

    matchOnEmail: boolean,
    setMatchOnEmail: (value: boolean) => void
    archiveMissing: boolean,
    setArchiveMissing: (value: boolean) => void

    invalidFile: boolean,

    name: string,
    setName: (value: string) => void
};

// Create context with an empty initial value
const ImportContext = createContext<ImportContextType | undefined>(undefined);

// Define provider component
type ImportContextProviderProps = {
    children: ReactNode;
};

const useCurrentStage = (initialStage: Stage = "select_file") => {
    const [currentStage, setCurrentStage] = useState<Stage>(initialStage);
    return {currentStage, gotoStage: setCurrentStage};
}


export const ImportContextProvider: React.FC<ImportContextProviderProps> = ({children}) => {

    const [name, setName] = useState("");
    const {clientID} = useUserManagementBrowsing()
    const {currentStage, gotoStage} = useCurrentStage()
    const {fileInputRef, activateFileSelect, selectedFile, handleFileChange, selectedFileRows, clear: clearFile, invalidFile} = useFileSelect();
    const {headers, rows} = useImportParser(selectedFile, currentStage == "select_file");
    const [sendInvitationEmails, , setSendInvitationEmails] = useToggle(false)
    const [matchOnEmail, , setMatchOnEmail] = useToggle(false)
    const [archiveMissing, , setArchiveMissing] = useToggle(false)

    const {
        submitForVerification,
        verificationProgress,
        totalRows,
        verifying,
        verificationCompleted,
        verificationResults,
        clear: clearVerification,
        departmentsCreated,
    } = useVerifyImport(headers, rows, clientID, matchOnEmail)



    const clear = useCallback(() => {
        clearFile()
        clearVerification()
    }, [clearFile])

    const value: ImportContextType = {
        currentStage,
        gotoStage,
        fileInputRef,
        activateFileSelect,
        selectedFile,
        handleFileChange,
        selectedFileRows,
        clear,
        headers,
        rows,
        submitForVerification,
        verificationProgress,
        totalRows,
        verifying,
        verificationCompleted,
        verificationResults,
        clientID,
        sendInvitationEmails,
        setSendInvitationEmails,
        matchOnEmail,
        setMatchOnEmail,
        invalidFile,
        departmentsCreated,
        name,
        setName,
        archiveMissing,
        setArchiveMissing,
    };

    return (
        <ImportContext.Provider value={value}>
            {children}
        </ImportContext.Provider>
    );
};

// Custom hook to access the context
export const useImportContext = (): ImportContextType => {
    const context = useContext(ImportContext);
    if (!context) {
        throw new Error("useImportContext must be used within an ImportContextProvider");
    }
    return context;
};


