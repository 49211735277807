import {InputType} from "./InputType";
import React, {forwardRef, useImperativeHandle, useMemo, useRef} from "react";
import {PhoneInput, PhoneInputHandle} from "../phone-input/PhoneInput";
import {CountrySelect, CountrySelectHandle} from "../country-select/CountrySelect";
import BasicText from "../basic-text";
import moment from "moment/moment";

export type MultiInputMultiFieldInputProps = {
    value: string
    onChange: (value: string) => void
    placeholder: string
    type: InputType
    onEnter?: () => void
}

export type MultiInputMultiFieldInputHandle = {
    reset: () => void
}


export const MultiInputMultiFieldInput = forwardRef<MultiInputMultiFieldInputHandle, MultiInputMultiFieldInputProps>(
    (props, ref) => {
        const {
            value,
            onChange,
            placeholder,
            type,
            onEnter,
        } = props;

        const phoneRef = useRef<PhoneInputHandle>(null);
        const countryRef = useRef<CountrySelectHandle>(null);

        useImperativeHandle(ref, () => ({
            reset: () => {
                phoneRef.current?.reset();
                countryRef.current?.reset();
            },
        }));

        const inputType = useMemo<InputType>(
            () => (["phone", "country", "date"].includes(type) ? type : "text"),
            [type]
        );

        if (inputType === "text" || inputType === "date") {
            return (
                <BasicText
                    type={type}
                    value={inputType === "date" && value ? moment.tz(value, "UTC").format("YYYY-MM-DD") : value}
                    onChange={onChange}
                    placeholder={placeholder}
                    onEnter={onEnter}
                />
            );
        }

        if (inputType === "phone") {
            return (
                <PhoneInput
                    ref={phoneRef}
                    placeholder={placeholder}
                    onChange={onChange}
                    initialValue={value}
                    onEnter={onEnter}
                />
            );
        }

        if (inputType === "country") {
            return <CountrySelect ref={countryRef} onChange={onChange} initialValue={value} />;
        }

        return null;
    }
);

MultiInputMultiFieldInput.displayName = "MultiInputMultiFieldInput"