import React, {useMemo} from "react";
import WhiteLogo from "../../components/ui/WhiteLogo";

import WhiteLogoImage from "../../assets/icons/ngs-white-blue.svg"
import {usePasswordReset} from "./PasswordResetContext";

export const Layout: React.FC<React.PropsWithChildren> = (props) => {

    const ctx = usePasswordReset()


    return <div className={"flex flex-col items-center justify-start gap-8 text-grey bg-desaturated-grey min-h-screen"}>
        <Header/>

        {!ctx.loading && !ctx.loadError && <> <Title text={"Reset Your Password"}/>
            <Progress/>
            <div className="w-full p-8 flex flex-col items-center gap-8">
                {props.children}
            </div>
        </>
        }

        {ctx.loadError  &&  <Title text={ctx.loadError}/>

        }
    </div>

}

const Header: React.FC = () => {


    return <div
        className="bg-white bg-gradient-to-r from-[#2D166F_50%] to-[#007584] h-28 w-full px-6 flex justify-center">
        <img
            src={WhiteLogoImage}
            alt="NGS Logo"
            className="h-fill w-auto max-h-30 max-w-[200px] object-contain"
        />
    </div>

}

type TitleProps = {
    text: string
}

const Title: React.FC<TitleProps> = (props) => {
    return <div>
        <h1 className={"font-semibold text-2xl capitalize"}>{props.text}</h1>
    </div>
}

const Progress: React.FC = () => {

    const ctx = usePasswordReset()


    const steps = [
        {id: 1, label: "Set New Password"},
        {id: 2, label: "Complete"},
    ];


    return (
        <div className="grid grid-cols-2 gap-8">
            {steps.map((step, index) => (
                <React.Fragment key={step.id}>
                    {/* Step */}
                    <div className="flex flex-col items-center">
                        <div
                            className={`flex items-center justify-center w-10 h-10 rounded-full ${
                                ctx.step >= step.id
                                    ? "bg-blue text-white"
                                    : "bg-grey text-white"
                            }`}
                        >
                            {step.id}
                        </div>
                        <span
                            className={`mt-2 font-semibold ${
                                ctx.step >= step.id ? "text-blue-500" : "text-gray-500"
                            }`}
                        >
              {step.label}
            </span>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};