import React, {useCallback, useMemo, useState} from "react";
import Layout from "../layout/Layout";
import {ClientSelectSingle} from "../../../components/form-v2/searchable-select/ClientSelect";
import {ClientsManagementProvider} from "./ClientsManagementContext";
import {Client, Clients} from "../../../types/userManagement";
import {apiV2} from "../../../api/axiosConfig";
import BasicText from "../../../components/form-v2/basic-text";
import {BlueButton} from "../../../components/ui/Buttons";
import {useNavigate, useSearchParams} from "react-router-dom";
import {isAxiosError} from "axios";



const ClientCreate: React.FC = () => {

    const [name, setName] = useState("");
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("")
    const [client, setClient] = useState<Client>();

    const [searchParams] = useSearchParams();

    const clientID = useMemo(() => {
        return searchParams.get("clientID")
    }, [searchParams])

    const navigate = useNavigate();

    const save = useCallback(async () => {
        setSaving(true);

        try {
            await apiV2.post("clients", {
                name
            })

        }  catch (e){
            if (isAxiosError(e) && e?.response?.data?.error) {
                setError(e.response.data.error);
            } else {
                setError("An error has occurred")
            }
            setSaving(false);
            return


        }

        setSaving(false);

        const params = new URLSearchParams();
        params.set("message", "client has been created")
        navigate(`/management/clients?${params.toString()}`);
        return
    }, [name])

    return <Layout
        active={"clients"}
    >
            <div className="flex flex-col gap-8">
                <div className="bg-white shadow-md p-4 w-1/2 flex flex-col gap-8">
                    <p className="text-md text-left pb-2 font-semibold">Create Client</p>
                    <BasicText value={name} onChange={setName} label="Name" />
                    <BlueButton text={"Create Client"} disabled={name == ""} onClick={save} loading={saving}/>
                    {error && <p className={"text-center text-red"}>{error}</p>}
                </div>
            </div>

    </Layout>

}

ClientCreate.displayName = "ClientCreate";

export default ClientCreate;