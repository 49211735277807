import {useCallback, useMemo} from "react";
import {useQuery} from "react-query";
import {apiV2} from "../../api/axiosConfig";
import {Roles} from "../../types/userManagement";


export const useRoles = () => {

    const fetchRoles = useCallback(async() => {
        const {data} = await apiV2.get<Roles[]>("roles");
        return data
    }, [])




    const {data: roles} = useQuery(
        ["roles"],
        fetchRoles,
        {
            placeholderData: [],
            refetchInterval: 1000 * 60,
        }
    )

    const canUseRole = useCallback((roleID: string) => {
        return !!roles?.find(r => r.id === roleID)
    }, [roles])

    const loaded = useMemo(() => {
        if(!roles) return false
        return roles?.length > 0
    }, [roles])

    return {roles: roles ?? [], loaded, canUseRole}

}