
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import React, {createContext, ReactNode, useCallback, useContext, useEffect, useMemo} from "react";

export type AccessTokenPayloadType = {
  iss: string;
  sub: string;
  profile: {
    firstName: string;
    middleNames: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    genderAssignedAtBirth: null;
    countryOfResidence: string;
    nationality: string;
    ethnicity: null;
    metadata: null;
  };
  email: string;
  role: {
    id: string;
    name: string;
    description: string;
    auroraModules: string[];
    auroraAccessLevel: "all" | "client" | "department" | "user";
    appPermissions: string[];
    clientID: null;
    clientName: null;
  };
  metadata: {
    clientName: string;
    departmentName: string;
    clientID: string;
    departmentID: string;
  };
  modules: string[];
  iat: number;
  nbf: number;
  exp: number;
};



export const UserContext = createContext<AccessTokenPayloadType | null>(null);




export default function UserContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { accessTokenPayload } = useOidcAccessToken();

  const data = accessTokenPayload;

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(data));
  }, []);
  return <UserContext.Provider value={data}>
    <AuthProvider>
    {children}
    </AuthProvider>
  </UserContext.Provider>;
}

export type AuroraAccessLevel = "all" | "client" | "department" | "user"

type AuthContextType = {
  isSuperAdmin:  boolean;
  isUser: boolean;
  clientName: string;
  clientID: string;
  userID: string;
  hasAccessLevel: (accessLevel: AuroraAccessLevel) => boolean;
  hasAccessLevelClient: boolean;
  hasAccessLevelDepartment: boolean;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// eslint-disable-next-line @typescript-eslint/ban-types
type AuthProviderProps = {}

export const AuthProvider: React.FC<React.PropsWithChildren<AuthProviderProps>> = ({children}) => {

  const userContext = useContext(UserContext);
  const {isAuthenticated} = useOidc();
  const isSuperAdmin = useMemo(() => {
    return userContext?.role.auroraAccessLevel == "all"
  }, [userContext?.role.auroraAccessLevel]);

  const isUser = useMemo(() => {
    return userContext?.role.auroraAccessLevel == "user"
  }, [userContext?.role.auroraAccessLevel]);

  const clientName = useMemo(() => {
    return userContext?.metadata.clientName || "";
  }, [userContext?.metadata.clientName]);

  const userID = useMemo(() => {
    return userContext?.sub || "";
  }, [userContext?.sub]);

  const clientID = useMemo(() => {
    return userContext?.metadata.clientID || "";
  }, [userContext?.metadata.clientID]);

  const hasAccessLevel = useCallback((accessLevel: AuroraAccessLevel) => {
    if(!userContext?.role) return false;

    const userAccessLevel = userContext?.role.auroraAccessLevel;

    if(userAccessLevel == "all") return true;

    if(userAccessLevel == "client" && ["client", "department", "user"].includes(accessLevel)) return true
    if(userAccessLevel == "department" && ["department", "user"].includes(accessLevel)) return true
    if(userAccessLevel == "user" && ["user"].includes(accessLevel)) return true

    return false;
  }, [userContext?.role.auroraAccessLevel]);


  const hasAccessLevelClient = useMemo(() => {
      return hasAccessLevel("client");
  }, [hasAccessLevel]);

  const hasAccessLevelDepartment = useMemo(() => {
    return hasAccessLevel("department");
  }, [hasAccessLevel]);

  return <AuthContext.Provider value={{
    isSuperAdmin,
    isUser,
    userID,
    clientID,
    clientName,
    hasAccessLevel,
    isAuthenticated,
    hasAccessLevelClient,
    hasAccessLevelDepartment,
  }}>
    {children}
  </AuthContext.Provider>
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
