import {apiV2} from "../../api/axiosConfig";
import {PaginatedResponse} from "../../types/PaginatedResponse";
import {Department} from "../../types/userManagement";
import {useQuery} from "react-query"

export const fetchSearchDepartment = async (search: string, rpp = 20, clientID : string | null = null) => {
    try{

        const params: Record<string, string>  = {
            search: search,
            rpp: `${rpp}`
        }

        if (clientID) {
            params["clientID"] = clientID
        }

        const res = await apiV2.get<PaginatedResponse<Department>>("/departments", { params  })
        return res.data

    } catch(e){
        console.error(e)
    }

}

export const useDepartmentSearch = (search: string, rpp = 20, clientID : string | null = null) => {
    return useQuery(["departments", search], () => fetchSearchDepartment(search, rpp, clientID), {});
}