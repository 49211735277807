import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {usePrettyFileSize} from "../../../lib/files/usePrettyFileSize";
import * as XLSX from "xlsx";

export const useFileSelect = () => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const activateFileSelect = useCallback(() => {
        if(!fileInputRef.current) return;
        fileInputRef.current.click()

    }, [fileInputRef.current])

    const [selectedFile, setSelectedFile] = useState<File|null>(null)


    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        setSelectedFile(file);
    }, []);


    const [selectedFileRows, setSelectedFileRows] = useState(0);

    const invalidFile = useMemo(() => {
        if(!selectedFile) return false;

        const fileName = selectedFile.name.toLowerCase();

        return !(fileName.endsWith(".csv") || fileName.endsWith(".xlsx"))

    }, [selectedFile])

    useEffect(() => {
        if (!selectedFile) {
            setSelectedFileRows(0);
            return;
        }

        const fileName = selectedFile.name.toLowerCase();
        const reader = new FileReader();

        if (fileName.endsWith(".csv")) {
            reader.onload = (event) => {
                const fileContent = event.target?.result as string;
                const lines = fileContent.split("\n").filter((line) => line.trim());
                setSelectedFileRows(lines.length - 1);
            };
            reader.readAsText(selectedFile);
        } else if (fileName.endsWith(".xlsx")) {
            reader.onload = (event) => {
                const data = new Uint8Array(event.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: "array" });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData: string[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false });


                setSelectedFileRows(jsonData.length - 1);
            };
            reader.readAsArrayBuffer(selectedFile);
        }
    }, [selectedFile]);


    const prettyFileSize = usePrettyFileSize(selectedFile);

    const clear = useCallback(() => {
        setSelectedFileRows(0);
        setSelectedFile(null)
    }, [])
    return {fileInputRef, activateFileSelect, selectedFile, handleFileChange, selectedFileRows, prettyFileSize, clear, invalidFile}
}

useFileSelect.displayName = "useFileSelect"