import {Route, Routes} from "react-router-dom";
import React from "react";
import {ProtectedRoute} from "../auth";
import {ClientsManagementProvider} from "../features/management-v2/clients/ClientsManagementContext";
import ClientList from "../features/management-v2/clients/ClientList";
import ClientCreate from "../features/management-v2/clients/ClientCreate";
import ClientEdit from "../features/management-v2/clients/ClientEdit";
import {DepartmentsManagementProvider} from "../features/management-v2/departments/DepartmentsManagementContext";
import DepartmentList from "../features/management-v2/departments/DepartmentList";
import DepartmentCreate from "../features/management-v2/departments/DepartmentCreate";
import DepartmentEdit from "../features/management-v2/departments/DepartmentEdit";
import DepartmentGuardians from "../features/management-v2/departments/DepartmentGuardians";
import {InvitationsManagementProvider} from "../features/management-v2/invitations/InvitationsManagementContext";
import InvitationList from "../features/management-v2/invitations/InvitationList";
import {ImportContextProvider} from "../features/management-v2/import/ImportContextProvider";
import {ImportPage} from "../features/management-v2/import/ImportPage";
import {UsersManagementProvider} from "../features/management-v2/users/UsersManagementContext";
import UserList from "../features/management-v2/users/UserList";
import UserCreate from "../features/management-v2/users/UserCreate";
import UserEdit from "../features/management-v2/users/UserEdit";
import {ClientCustomisation} from "../features/management-v2/ClientCustomisation";
import { UserManagementBrowsingProvider } from "../lib/user-management/UserManagementBrowsingContext";

export const ManagementRoutes = () => {
    return (
        <UserManagementBrowsingProvider>
            <Routes>
                <Route
                    path="clients"
                    element={
                        <ProtectedRoute>
                            <ClientsManagementProvider>
                                <ClientList/>
                            </ClientsManagementProvider>

                        </ProtectedRoute>
                    }
                />
                <Route
                    path="clients/create"
                    element={
                        <ProtectedRoute>
                            <ClientCreate/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="clients/edit/:id"
                    element={
                        <ProtectedRoute>
                            <ClientEdit/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="departments"
                    element={
                        <ProtectedRoute accessLevel={"department"}>
                            <DepartmentsManagementProvider>
                                <DepartmentList/>
                            </DepartmentsManagementProvider>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="departments/create"
                    element={
                        <ProtectedRoute accessLevel={"department"}>
                            <DepartmentCreate/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="departments/edit/:id"
                    element={
                        <ProtectedRoute accessLevel={"department"}>
                            <DepartmentEdit/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="departments/:id/guardians"
                    element={
                        <ProtectedRoute>
                            <DepartmentGuardians/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="invitations"
                    element={
                        <ProtectedRoute accessLevel={"department"}>
                            <InvitationsManagementProvider>
                                <InvitationList/>
                            </InvitationsManagementProvider>
                        </ProtectedRoute>
                    }
                />


                <Route
                    path="import"
                    element={
                        <ProtectedRoute accessLevel={"all"}>
                            <ImportContextProvider>
                                <ImportPage/>
                            </ImportContextProvider>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="users"
                    element={
                        <ProtectedRoute accessLevel={"department"}>
                            <UsersManagementProvider>
                                <UserList/>
                            </UsersManagementProvider>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="users/create"
                    element={
                        <ProtectedRoute accessLevel={"department"}>
                            {/* eslint-disable-next-line react/jsx-no-undef */}
                            <UserCreate/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="users/edit/:id"
                    element={
                        <ProtectedRoute accessLevel={"user"}>
                            <UserEdit/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="customisation"
                    element={
                        <ProtectedRoute accessLevel={"all"}>
                            <ClientCustomisation/>
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </UserManagementBrowsingProvider>)
}