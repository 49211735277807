import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import { Close, Delete } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import ClientSelect from "../../../../../components/form-v2/searchable-select/ClientSelect";
import DepartmentSelect, {
  DepartmentSelect1Way
} from "../../../../../components/form-v2/searchable-select/DepartmentSelect";
import { apiV2 } from "../../../../../api/axiosConfig";
import { Client, Department } from "../../../../../types/userManagement";
import {useAuth, UserContext} from "../../../../../context/user";
import {DepartmentSelectV2Multi} from "../../../../../lib/departments/DepartmentSelectV2Multi";
import {ToggleButton} from "../../../../../features/management-v2/import/inputs";

interface DashboardEditOverlayProps {
  onClose: () => void;
  dashboardID: string;
  onSave: (updatedDashboard: { name: string; clientID: string; departmentIDs: string[] }) => void;
  onDelete: () => void;
  isOwner: boolean;
}

const DashboardEditOverlay: React.FC<DashboardEditOverlayProps> = ({
  onClose,
  dashboardID,
  onSave,
  onDelete,
  isOwner,
}) => {
  const auth = useAuth()
  const [name, setName] = useState<string>("");
  const [ownerName, setOwnerName] = useState<string>("");
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedDepartments, setSelectedDepartments] = useState<Department[]>([]);
  const [departmentNames, setDepartmentNames] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const userContext = useContext(UserContext)!;
  const accessLevel = userContext.role.auroraAccessLevel;

  const [clientAdminsOnly, setClientAdminsOnly] = useState<boolean>(false);


  const DepartmentsList = useCallback(() => {
    if(clientAdminsOnly) return null

    return <p className="text-gray-800 text-sm">
      {departmentNames.length ? departmentNames.join(", ") : ""}
    </p>
  }, [departmentNames, clientAdminsOnly])

  const [initialDepartments, setInitialDepartments] = useState<Department[]>([]);

  useEffect(() => {
    setSelectedDepartments(initialDepartments)

  }, [initialDepartments]);

  const initialDepartmentIDs = useMemo(() => {
    return initialDepartments.map(d => d.id)
  }, [initialDepartments]);

  useEffect(() => {
    const fetchDashboardDetails = async () => {
      setLoading(true);
      try {
        const { data } = await apiV2.get(`/reporting/get-dashboard/${dashboardID}`);

        setName(data.dashboard.dashboardName || "");
        setOwnerName(data.dashboardOwner || "");
        setSelectedClient({
          id: data.dashboard.clientID,
          name: data.dashboard.clientName,
        } as Client);

        setClientAdminsOnly(data.dashboard.clientAdminsOnly)

        const departments =  data.dashboard.departmentIDs.map((id: string, index: number) => ({
          id,
          name: data.dashboard.departmentNames[index] || "",
        }))

        setInitialDepartments(departments)


        setDepartmentNames(data.dashboard.departmentNames || []);
      } catch (error) {
        console.error("Error fetching dashboard details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardDetails();
  }, [dashboardID]);

  

  const handleSave = async () => {
    if(!clientAdminsOnly) {
      if (!selectedDepartments) return;
      if (selectedDepartments.length == 0) return
    }

    setIsSaving(true);
    try {
      const updatedDashboard = {
        dashboardID,
        dashboardName: name,
        clientID: selectedClient?.id || "",
        departmentIDs: selectedDepartments.map((dept) => dept.id),
        clientAdminsOnly,
      };
      await apiV2.patch("/reporting/update-dashboard", updatedDashboard);
      onSave({
        name: updatedDashboard.dashboardName,
        clientID: updatedDashboard.clientID,
        departmentIDs: updatedDashboard.departmentIDs,
      });

      onClose();
    } catch (error) {
      console.error("Error saving dashboard:", error);
    } finally {
      setIsSaving(false);
    }
  };

  if(loading){
    return null
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
      <div className="relative bg-white w-1/2 rounded-lg shadow-lg z-10 animate-[zoom-in_0.2s_ease-in-out_forwards]">
        <div className={"bg-blue w-full flex items-center justify-between p-4 rounded-t"}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={`w-2/5 text-xl font-semibold ${
              isOwner
                ? "text-white border-2 border-light-grey bg-blue hover:border-light-grey focus:border-white focus:ring-0"
                : "text-white bg-blue"
            } rounded p-2 outline-none`}
            placeholder=""
            disabled={!isOwner}
          />
          <button onClick={onClose} className="text-white right-0 hover:text-gray-200">
            <Close />
          </button>
        </div>
        {loading ? (
          <div className="flex items-center justify-center py-20">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="mt-4 py-3 px-6">
                <label className="block mb-8 text-sm text-gray-700">Owner: {ownerName}</label>

              {auth.isSuperAdmin && <>
                <label className="block mb-2 text-lg font-medium text-gray-700">Client:</label>
                <p className="text-gray-800 text-sm">{selectedClient?.name || ""}</p>
              </>}

            </div>
            <div className="mt-4 py-2 pb-10 px-6">
              <label className="block mb-2 font-medium text-gray-700">Departments with access:</label>


              {isOwner && auth.hasAccessLevelClient && (
                  <div className={"mb-2"}>
                    <ToggleButton text={"Client Admins Only"} initialValue={clientAdminsOnly} onChange={setClientAdminsOnly}/>
                  </div>
              )}

              {(!clientAdminsOnly && isOwner && initialDepartments != undefined ) ? (
                  <DepartmentSelectV2Multi clientID={selectedClient?.id} onChange={setSelectedDepartments} initialIDs={initialDepartmentIDs} />
              ) : (
                <DepartmentsList/>
              )}
            </div>
            {isOwner && (
              <div className="ml-2 p-6 flex justify-between">
                <button
                  onClick={() => {
                    onClose();
                    onDelete();
                  }}
                  className="flex items-center text-red-600 hover:text-red-800"
                >
                  <Delete className="mr-1" />
                </button>
                <button
                  onClick={handleSave}
                  disabled={isSaving}
                  className={`px-4 py-2 text-white rounded ${
                    isSaving ? "bg-gray-400" : "bg-blue hover:bg-blue-800"
                  }`}
                >
                  {isSaving ? "Saving..." : "Save"}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardEditOverlay;
