import React, { useEffect, useMemo, useRef } from "react";
import Select, {ClassNamesConfig, GroupBase, SelectInstance, SingleValue, StylesConfig} from "react-select";
import { reactSelectClassNames, reactSelectStyles } from "../react-select/styling";
import { noop } from "../misc/noop";
import { getTimezonesForCountryWithOverride } from "../../util/tz";

type SelectTimezoneProps = {
    countryISO?: string;
    value?: string;
    onChange?: (value: string) => void;
};

type Option = {
    label: string;
    value: string;
};

export const SelectTimezone: React.FC<SelectTimezoneProps> = ({
                                                                  countryISO = "",
                                                                  value = "",
                                                                  onChange = noop,
                                                              }) => {
    const ref = useRef<SelectInstance<Option>>(null);

    const handleOnChange = (option: SingleValue<Option> | null) => {
        if (onChange) {
            onChange(option?.value ?? "");
        }
    };

    const options = useMemo<Option[]>(() => {
        if (!countryISO) return [];
        return getTimezonesForCountryWithOverride(countryISO).map((tz) => ({
            label: tz.name,
            value: tz.name,
        }));
    }, [countryISO]);

    const selectedOption = useMemo(() => {
        return options.find((option) => option.value === value) || null;
    }, [options, value]);

    useEffect(() => {
        if (ref.current) {
            ref.current.clearValue();
        }
    }, [countryISO]);

    return (
        <Select<Option>
            ref={ref}
            classNames={reactSelectClassNames as ClassNamesConfig<Option>}
            styles={reactSelectStyles as StylesConfig<Option>}
            placeholder="Select Timezone"
            value={selectedOption}
            onChange={handleOnChange}
            options={options}
            isClearable
        />
    );
};
