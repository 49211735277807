import React, {useCallback, useEffect, useMemo, useState} from "react";
import Layout from "../layout/Layout";
import {ClientSelectSingle} from "../../../components/form-v2/searchable-select/ClientSelect";
import {DepartmentsManagementProvider} from "./DepartmentsManagementContext";
import {Client, Department, Departments} from "../../../types/userManagement";
import {apiV2} from "../../../api/axiosConfig";
import BasicText from "../../../components/form-v2/basic-text";
import {BlueButton} from "../../../components/ui/Buttons";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {isAxiosError} from "axios";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import {UserSelectSingle} from "../../../components/form-v2/searchable-select/UserSelect";
import {UserDataTypes} from "../../../types/user-data-types";
import {useQuery} from "react-query";


const DepartmentGuardians: React.FC = () => {


    const {id} = useParams();


    const [loading, setLoading] = useState(true);

    const [department, setDepartment] = useState<Department>();
    const [name, setName] = useState("");
    const [clientID, setClientID] = useState("");
    const [error, setError] = useState("")

    const [user, setUser] = useState<UserDataTypes>()



    const navigate = useNavigate();

    const loadDepartment = async() => {
        try {
            const res = await apiV2.get<Department>(`/departments/${id}`);

            setName(res.data?.name);
            setDepartment(res.data)
            setClientID(res.data.clientID)


        } catch (e){
            setError("error loading department")
        }

        setLoading(false);
    }

    const loadGuardians = async () => {
            const res = await apiV2.get<UserDataTypes[]>(`departments/${id}/guardians`);
            return res.data
    }

    const guardiansQuery = useQuery<UserDataTypes[]>(
        ["guardians", department?.id],
        loadGuardians,
        {
        enabled: !!department?.id,
    })

    useEffect(() => {
        if(!id){
            return
        }

        loadDepartment()
    }, [id]);


    const addGuardian = async() => {
        if(!user || !user?.id){
            return
        }
        try {
            await apiV2.post(`departments/${id}/add-guardian`, {
                userID: user?.id
            })
            guardiansQuery.refetch()
        } catch (e){

        }

        setUser(undefined)
    }


    const removeGuardian = async(userID: string|undefined) => {
        if(!userID){
            return
        }

        try {
            await apiV2.post(`departments/${id}/remove-guardian`, {
                userID: userID
            })
            guardiansQuery.refetch()
        } catch (e){

        }

        setUser(undefined)
    }

    return <Layout
        active={"departments"}
    >

        <div className="flex flex-col gap-8">
            {loading && <div className="bg-white shadow-md p-4 w-1/2 flex flex-col gap-8 items-center">
                <LoadingSpinner/>
            </div>}
            {!loading &&
                <div className={"flex flex-row gap-8"}>

                    <div className="bg-white shadow-md p-4 flex flex-col gap-8 flex-1">
                        <p className="text-md text-left pb-2 font-semibold">Manage Guardians ({department?.name})</p>
                        <div className="grid grid-cols-[30%_50%_20%]">
                            <div>Name</div>
                            <div>Email</div>
                            <div>Action</div>
                        </div>
                        <hr/>

                        {guardiansQuery.data && guardiansQuery.data.map((g)  =>
                            <div className="grid grid-cols-[30%_50%_20%]">
                                <div className={"overflow-hidden overflow-ellipsis pr-4"}>
                                    <Link to={`/management/users/edit/${g.id}`}>
                                        {g.profile.firstName} {g.profile.lastName}
                                    </Link>
                                </div>
                                <div className={"overflow-hidden overflow-ellipsis pr-4"}>
                                    <Link to={`/management/users/edit/${g.id}`}>{g.email}</Link>
                                </div>
                                <div><BlueButton text={"Remove"} onClick={() => {
                                    console.log("TEST")
                                    removeGuardian(g.id)
                                }}/></div>
                            </div>
                        )}


                    </div>

                    <div className="bg-white shadow-md p-4 flex flex-col gap-8 w-72">
                        <p className="text-md text-left pb-2 font-semibold">Add Guardian</p>
                        <UserSelectSingle clientID={clientID} user={user} onChange={setUser}/>
                        <BlueButton text={"Add Guardian"} disabled={!user} onClick={addGuardian}/>
                    </div>


                </div>}
        </div>

    </Layout>

}

DepartmentGuardians.displayName = "DepartmentGuardians";

export default DepartmentGuardians;