import React, {useCallback, useEffect, useState} from "react";
import {Report} from "./reportTypes";
import {IconButton, Popover, Tooltip} from "@mui/material";
import {MoreVert} from "@mui/icons-material";
import {capitalize} from "lodash";
import {apiV2} from "../../../../../api/axiosConfig";
import {PaginatedResponse} from "../../../../../types/PaginatedResponse";
import {DepartmentDataType} from "../../../../Trips/types/department-data-types";
import {Departments} from "../../../../../types/userManagement";

type ReportRowProps = {
    report: Report,
    index: number,
    handleMenuOpen: (event: React.MouseEvent<HTMLElement>, report: Report) => void,
    handleNameClick: (event: React.MouseEvent<HTMLElement>, report: Report) => void
}

export const ReportRow: React.FC<ReportRowProps> = ({report, index, handleMenuOpen, handleNameClick}) => {
    const [activeDepartments, setActiveDepartments] = useState<{
        id: string;
        departments: string[];
        anchorEl: HTMLElement | null;
    } | null>(null);




    return <tr
        key={report.id}
        className={`border-b border-gray-200 hover:bg-gray-50 ${
            index % 2 === 0 ? "bg-white" : "bg-gray-50"
        }`}
    >
        <td
            style={{
                maxWidth: "250px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }}
            className="py-2 px-4 text-left font-medium cursor-pointer hover:underline"
            onClick={(e) => handleNameClick(e, report)}
        >
            {report.reportName}
        </td>
        <td
            style={{
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }}
            className="py-2 px-4 text-left font-medium"
        >
            {report.clientName || "Unknown Client"}
        </td>
        <td className="py-2 px-4 text-left">
            {report.dataSourceDepartmentNames && report.dataSourceDepartmentNames .length > 0 ? (
                <div className="relative">
                    {report.dataSourceDepartmentNames .length > 1 ? (
                        <button
                            className="px-2 py-1 bg-light-grey text-white text-sm font-medium rounded-md hover:bg-blue-700 transition"
                            onClick={(e) => {
                                e.stopPropagation()
                                setActiveDepartments({
                                    id: report.id,
                                    departments: report.dataSourceDepartmentNames ,
                                    anchorEl: e.currentTarget
                                })
                            }}
                        >
                            {`${report.dataSourceDepartmentNames .length} selected`}
                        </button>
                    ) : (
                        <span className="px-2 py-1 bg-blue-100 text-blue-800 text-sm font-normal rounded-md">
                          {report.dataSourceDepartmentNames [0]}
                        </span>
                    )}
                </div>
            ) : (
                <span className="font-normal text-gray-500">
                      All departments
                    </span>
            )}
        </td>
        <td className="py-2 px-4 text-left whitespace-nowrap">
            {report.ownedByUserName}
        </td>
        <td
            style={{
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }}
            className="py-2 px-4 text-left"
        >
            {formatTimeframe(report.timeframe, report.startDate, report.endDate)}
        </td>
        <td
            style={{
                maxWidth: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }}
            className="py-2 px-4 text-center"
        >
            <Tooltip title="Actions">
                <IconButton onClick={(e) => handleMenuOpen(e, report)}>
                    <MoreVert/>
                </IconButton>
            </Tooltip>
        </td>
    </tr>

}

function formatTimeframe(timeframe: string, startDate?: string, endDate?: string): string {
    switch (timeframe) {
        case "past1":
            return "Past Day"
        case "past7":
            return "Past Week"
        case "past30":
            return "Past 30 Days"
        case "mtd":
            return "Month To Date"
        case "ytd":
            return "Year To Date"
        case "custom":
        case "":
            if (startDate && endDate) {
                const formattedStart = new Date(startDate).toLocaleDateString()
                const formattedEnd = new Date(endDate).toLocaleDateString()
                return `${formattedStart} - ${formattedEnd}`
            }
            return "Custom Timeframe"
        default:
            return capitalize(timeframe)
    }
}