import { useCallback, useEffect, useState } from "react";
import Papa, { ParseResult } from "papaparse";
import * as XLSX from "xlsx";

export type ImportRow = string[];

export const useImportParser = (file: File | null, run: boolean) => {
    const [headers, setHeaders] = useState<ImportRow>([]);
    const [rows, setRows] = useState<ImportRow[]>([]);

    const clear = useCallback(() => {
        setHeaders([]);
        setRows([]);
    }, []);

    useEffect(() => {
        if (!run) return;
        if (!file) return clear();

        const fileName = file.name.toLowerCase();
        const reader = new FileReader();

        if (fileName.endsWith(".csv")) {
            Papa.parse(file, {
                complete(results: ParseResult<string[]>) {
                    setHeaders(results.data[0] || []);
                    setRows(results.data.slice(1).filter((r) => r.length > 1));
                },
            });
        } else if (fileName.endsWith(".xlsx")) {
            reader.onload = (event) => {
                const data = new Uint8Array(event.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json<string[]>(worksheet, { header: 1, defval: "", raw: false, blankrows: false});

                setHeaders(jsonData[0] || []);
                setRows(jsonData.slice(1).filter((r) => r.length > 1));
            };
            reader.readAsArrayBuffer(file);
        }
    }, [file, run, clear]);

    return { headers, rows };
};