import WhiteLogo from "../../components/ui/WhiteLogo";
import React, {useContext, useMemo, useRef, useState} from "react";
import { routesPath } from "./routesPath";
import { Link } from "react-router-dom";
import SettingIcon from "../../assets/icons/icon-settings.svg";
import PublicIcon from "../../assets/icons/icon-public.svg";
import ClockIcon from "../../assets/icons/clock-dark.svg";
import { useOidc } from "@axa-fr/react-oidc";
import LogoutIcon from "@mui/icons-material/Logout";
import { UserContext } from "../../context/user";
import {
  useRestrictedSettings,
  useRestrictedUserManagement,
} from "../../restrictions";
import {useTimeZoneSelectContext} from "../../providers/TimeZoneSelectProvider";
import CustomAlert from "./BannerAlert";
import SearchableSelect, {SearchableSelectOption} from "../../components/form-v2/searchable-select";
import {BlueButton} from "../../components/ui/Buttons";
import {getTimezonesForCountry} from "countries-and-timezones";
import {countries} from "../../constants/countries";
import {CountrySelectSingle} from "../../components/form-v2/searchable-select/CountrySelectSingle";
import BasicSelect from "../../components/form-v2/basic-select";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {getTimezonesForCountryWithOverride} from "../../util/tz";

function Header() {
  const userContext = useContext(UserContext)!;
  const auroraModules = userContext!.role.auroraModules;
  const { logout, isAuthenticated } = useOidc();
  const accessUserManagement = useRestrictedUserManagement();
  const firstSettingsLink = useRestrictedSettings()[0];
  const handleLogout = async () => {
    isAuthenticated && (await logout());
  };
  const userFirstNameInitial = userContext.profile.firstName.split("")[0];
  const userLastNameInitial = userContext.profile.lastName.split("")[0];

  const timeZoneSelect = useTimeZoneSelectContext()

  const [alertVisible, setAlertVisible] = useState(true);
  const closeAlert = () => setAlertVisible(false);

  const getTimezoneOptions = (countryISO: string) => {
      return getTimezonesForCountryWithOverride(countryISO)?.map((tz) => ({
          label: tz.name,
          value: tz.name
      })) || []
  }


  const [tzCountry, setTZCountry] = useState<undefined|string>()

  const tzDropdownRef = useRef(null);
  useOnClickOutside(tzDropdownRef, () => setShowTZSelect(false));
  const [showTZSelect, setShowTZSelect] = useState(false)

    const managementLink = useMemo(() => {
        if(userContext.role.auroraAccessLevel == "all") return "/management/clients"
        if(userContext.role.auroraAccessLevel == "client") return "/management/departments"
        if(userContext.role.auroraAccessLevel == "department") return "/management/departments"

        return "#"
    }, [userContext.role.auroraAccessLevel]);


  return (
    <header className="fixed top-0 z-[100] w-screen">
      <CustomAlert />
      <div className="bg-white bg-gradient-to-r from-[#2D166F_50%] to-[#007584] h-28 w-full px-6 flex">
        <div className="h-full">
          <WhiteLogo />
        </div>

      <nav className="flex items-end w-full ml-16">
        <ul className="flex flex-row text-white justify-start w-full">
          {routesPath.map((route, i) => {
            if (
              auroraModules?.includes("all") ||
              auroraModules?.includes(route.slug)
            ) {
              if (route.slug === "e_learning") {
                return (
                  <li key={route.slug}>
                    <a
                      href={route.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-4"
                    >
                      {route.title}
                    </a>
                  </li>
                );
              } else {
                return (
                  <li className="" key={route.slug} id={`route-${route.slug}`}>
                    <Link className={"px-4 border-r"} to={route.path}>
                      <span className="inline-block h-12 hover:border-b-4">
                        {route.title}
                      </span>
                    </Link>
                  </li>
                );
              }
            }
          })}
        </ul>
      </nav>

      {/**
       * USER MENU
       */}

      <div className="flex text-white gap-4 justify-center items-center">
            {/* <select className={"text-white min-w-32 pr-2"}*/}
            {/*        style={{"background": "none", "textAlignLast": "right"}}*/}
            {/*        value={timeZoneSelect.timeZone}*/}
            {/*        onChange={(e) => {*/}
            {/*          timeZoneSelect.setTimeZone(e.target.value);*/}
            {/*        }}*/}

            {/* >*/}
            {/*  {timeZoneSelect.options.map((option) => (*/}
            {/*      <option key={option} value={option} className={"text-grey p-2"}>{option}</option>*/}
            {/*  ))}*/}

            {/* </select>*/}

          <div className={"relative"}>
              <button
                onClick={() => setShowTZSelect(true)}
                  className="w-10 h-10 rounded-md flex items-center justify-center bg-white"
              >
                  <img src={ClockIcon} alt=""/>

              </button>
            { showTZSelect && <div ref={tzDropdownRef} className={"absolute bg-white z-[1000] left-[-7em] top-[3em] w-[16em] p-4 rounded shadow text-grey flex flex-col gap-4"}>
                <CountrySelectSingle value={timeZoneSelect.countryISO} setValue={(v)=> {
                  if(v){
                    timeZoneSelect.setCountryISO(v);
                }}} label={"Country"}/>
                {timeZoneSelect.countryISO && <BasicSelect label={"Timezone"} options={getTimezoneOptions(timeZoneSelect.countryISO)} value={timeZoneSelect.timeZone}
                                            onChange={(v) => {
                                              if(v){
                                                timeZoneSelect.setTimeZone(v)
                                              }
                                            }}/>}
              </div>}
          </div>

          {!firstSettingsLink ? null : (
              <Link
                  className="w-10 h-10 rounded-md flex items-center justify-center bg-white"
            to={firstSettingsLink.path}
          >
            <img src={SettingIcon} alt="" />
          </Link>
        )}

        {accessUserManagement && (
          <Link
            to={managementLink}
            className="w-10 h-10 rounded-md flex items-center justify-center bg-white"
          >
            <img src={PublicIcon} alt="" />
          </Link>
        )}
        <Link
          className="w-10 h-10 rounded-md flex items-center justify-center bg-blue font-semibold text-md"
          to={`management/users/edit/${userContext.sub}`}
        >
          {`${userFirstNameInitial}${userLastNameInitial}`}
        </Link>

        <button
          className="w-10 h-10 rounded-md flex items-center justify-center bg-white font-semibold text-xl"
          onClick={handleLogout}
        >
          <LogoutIcon style={{ color: "grey" }} />
        </button>
      </div>
      </div>
    </header>
  );
}

export default Header;
