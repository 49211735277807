import {useCallback, useState} from "react";

type useIncrementHook = [value: number, increment: (amount?: number) => void, reset: () => void];

export const useIncrement = (defaultVal = 0): useIncrementHook => {
    const [value, setValue] = useState(defaultVal);

    const increment = useCallback((amount = 1) => {
        setValue(prev => prev + amount);
    }, [])

    const reset = useCallback(() => {
        setValue(defaultVal)
    }, [])

    return [value, increment, reset]
}
