import {useMemo} from "react";
import {VerificationResponseItem, VerifyResponse} from "./useVerifyImport";

const errorTypesWithLabel: Record<string, string> = {
    "id_not_found": "User ID Not Found",
    "server_error": "Server Error",
    "missing_first_name": "Missing First Name",
    "missing_last_name": "Missing Last Name",
    "missing_email": "Missing Email",
    "email_exists": "Email Already In Use",
    "missing_phone_number": "Missing Phone Number",
    "invalid_phone_number": "Invalid Phone Number",
    "invalid_gender": "Invalid Gender",
    "invalid_gender_at_birth": "Invalid Gender At Birth",
    "invalid_date_of_birth": "Invalid Date Of Birth",
}

type VerifyErrorStat = {
    key: string,
    message: string,
    count: number,
}

function formatString(str: string) {
    return str
        .replace(/_/g, " ")
        .replace(/\b\w/g, char => char.toUpperCase());
}


export const useVerificationResults = (results: VerificationResponseItem[] ) =>{


    const errorTypes = useMemo(() => {
        const types = new Set<string>()

        results.forEach((r) => {
            r.errors.forEach((e) => types.add(e))
        })

        return Array.from(types)

    },[results])


    const warningTypes = useMemo(() => {
        const types = new Set<string>()

        results.forEach((r) => {
            r.warnings.forEach((e) => types.add(e))
        })

        return Array.from(types)

    },[results])


    const verificationErrors = useMemo<VerifyErrorStat[]>(() => {

        return errorTypes.map((key: string) => {

            return {
                key,
                message: errorTypesWithLabel[key] || formatString(key),
                count: results.filter(r => r.errors.includes(key)).length,
            }

        }).filter(ve => ve.count)



    },[results, errorTypes])

    const verificationWarnings = useMemo<VerifyErrorStat[]>(() => {

        return warningTypes.map((key: string) => {

            return {
                key,
                message: errorTypesWithLabel[key] || formatString(key),
                count: results.filter(r => r.warnings.includes(key)).length,
            }

        }).filter(ve => ve.count)



    },[results, warningTypes])


    const validCount = useMemo(() => {
        return results.filter(r => r.valid).length
    }, results)

    const invalidCount = useMemo(() => {
        return results.filter(r => !r.valid).length
    }, [results])


    const createCount = useMemo(() => {
        return results.filter(r => r.action == "create").length
    }, [results])

    const updateCount = useMemo(() => {
        return results.filter(r => r.action == "update").length
    }, [results])

    const archiveCount = useMemo(() => {
        return results.filter(r => r.action == "archive").length
    }, [results])

    const unarchiveCount = useMemo(() => {
        return results.filter(r => r.action == "unarchive").length
    }, [results])



    return {
        validCount,
        invalidCount,
        createCount,
        updateCount,
        archiveCount,
        unarchiveCount,
        verificationErrors,
        verificationWarnings,
    }

}