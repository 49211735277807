import React, { useContext, useEffect, useState } from "react";
import ClientSelect from "../../../../../components/form-v2/searchable-select/ClientSelect";
import { Client } from "../../../../../types/userManagement";
import { useClientDashboard } from "./ClientDashboardProvider";



export default function DashboardPageClientSelect() {
    const {setClient: setContextClient, client: contextClient} = useClientDashboard()
    const [client, setClient] = useState<Client | undefined>(contextClient);

    useEffect(() => {
        setContextClient(client);
        
    },[client, setClient])

    return (

    //   <ClientSelectV2
    //   initialID={client ? client.id : null}
    //   onChange={(selected) => setClient(selected)}
    // />


    <div className="flex flex-col h-full bg-white-blue">
      <div className="bg-white shadow-sm py-4 px-6 flex justify-center items-center border-gray-300">
        <h2 className="text-xl font-semibold text-gray-700">Select a Client</h2>
      </div>
      <div className="flex flex-grow justify-center items-start mt-16">
        <div className="bg-white p-6 rounded-lg shadow-md w-1/3">
          <h3 className="text-lg font-medium text-gray-700 mb-3 text-center">
            Choose a Client to Continue
          </h3>
          <ClientSelect
            multiple={false}
            clients={client ? [client] : []}
            onSelectionChange={(clients) => {
              setClient(clients[0]);
            }}
          />
        </div>
      </div>
    </div>

      );

}
