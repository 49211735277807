import React, { useState, useEffect, useContext, useRef } from "react"
import api, { apiV2 } from "../../../../../api/axiosConfig"
import {
  Cancel,
  CheckCircle,
  Close,
  Edit,
  Flight,
  GpsFixed,
  Message,
  NotificationsActive,
  Person
} from "@mui/icons-material"
import BasicText from "../../../../../components/form-v2/basic-text"
import { useNavigate, useLocation } from "react-router-dom"
import { UserContext } from "../../../../../context/user"
import ClientSelect, { ClientSelectSingle } from "../../../../../components/form-v2/searchable-select/ClientSelect"
import DepartmentSelect from "../../../../../components/form-v2/searchable-select/DepartmentSelect"
import { Client, Department } from "../../../../../types/userManagement"
import { CircularProgress } from "@mui/material"
import SecurityUpdateGoodIcon from "@mui/icons-material/SecurityUpdateGood";
import {ToggleButton} from "../../../../../features/management-v2/import/inputs";


interface Report {
  id: number
  title: string
  type: string
  icon: string
}

const iconMap: { [key: string]: JSX.Element } = {
  person: <Person />,
  plane: <Flight />,
  check: <CheckCircle />,
  cross: <Cancel />,
  phone: <SecurityUpdateGoodIcon />,
  emergency: <NotificationsActive />,
  location: <GpsFixed />,
  message: <Message />
}
export default function CreateNewReportDoc() {
  const [templates, setTemplates] = useState<Report[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<Report | null>(null)
  const [reportTitle, setReportTitle] = useState("")
  const [timeframe, setTimeframe] = useState("past30")
  const [customRangeStart, setCustomRangeStart] = useState("")
  const [customRangeEnd, setCustomRangeEnd] = useState("")
  const [reportData, setReportData] = useState<any>(null)
  const [client, setClient] = useState<Client | undefined>(undefined)
  const [departments, setDepartments] = useState<Department[]>([]);
  const [dataSourceDepartments, setDataSourceDepartments] = useState<Department[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]); 
  const [loadingReportData, setLoadingReportData] = useState(false);
  const [startDateError, setStartDateError] = useState<string | null>(null);
  const [endDateError, setEndDateError] = useState<string | null>(null);
  const [selectedDepartmentIDs, setSelectedDepartmentIDs] = useState<string[]>([]);
  const [authData, setAuthData] = useState({
    accessLevel: "",
    clientName: "",
    clientID: "",
    departmentID: "",
  });
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState<string | null>(null);
  const [initialClient, setInitialClient] = useState<Client>();

  const [allUsers, setAllUsers] = useState(false);

  const onDepartmentsChange = (departments: Department[]) => {
    setDepartments(departments);
    console.log(departments);
  };
  
  const navigate = useNavigate()

  const accessLevel = authData.accessLevel
  const userContext = useContext(UserContext)!
  console.log(userContext.metadata)

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const res = await apiV2.get<Report[]>("/reporting/get-report-filters")
        setTemplates(res.data)
      } catch (err: any) {
        setError(err.message || "Unknown error")
      } finally {
        setLoading(false)
      }
    }
    fetchTemplates()
  }, [])

  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        const res = await apiV2.get<{
          accessLevel: string;
          clientName: string;
          clientID: string;
          departmentID: string;
        }>("/reporting/get-user-auth-data");

        setAuthData({
          accessLevel: res.data.accessLevel,
          clientName: res.data.clientName,
          clientID: res.data.clientID,
          departmentID: res.data.departmentID,
        });
      } catch (err: any) {
      } finally {
        setLoading(false);
      }
    };

    fetchAuthData();
  }, []);

  const handleTemplateClick = (tmpl: Report) => {
    setSelectedTemplate(tmpl)
    setReportTitle(tmpl.title)
    setIsOverlayOpen(true)
    setTimeframe("past30")
  }

  const closeOverlay = () => {
    setIsOverlayOpen(false)
    setSelectedTemplate(null)
    setReportData(null)
    setTimeframe("past30")
    setCustomRangeStart("")
    setCustomRangeEnd("")
  }

  const handleTimeframeChange = (val: string) => {
    setTimeframe(val);
  };

  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleEditClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    console.log("useEffect triggered. Location state:", location.state);
  
    if (location.state?.from) {
      console.log("From location:", location.state.from);
      setPreviousPath(location.state.from);
  
      if (location.state.clientID) {
        console.log("Client ID found:", location.state.clientID);
  
        const fetchClientData = async () => {
          try {
            console.log("Fetching client data...");
            const { data } = await api.get<Client>(`/clients/${location.state.clientID}`);
            console.log("Client data received:", data);
  
            setInitialClient(data);
            setClient(data); 
          } catch (error) {
            console.error("Error fetching client data:", error);
          }
        };
  
        fetchClientData();
      } else {
        console.warn("No clientID found in location.state.");
      }
    } else {
      console.warn("No 'from' location found.");
    }
  }, [location]);

  
  
  const handleCreateReport = async () => {


    const errors: string[] = [];

    if (accessLevel === "all") {
      if (!client) errors.push("Client cannot be empty.");
    } else if (accessLevel === "department") {
      if (dataSourceDepartments.length === 0) errors.push("Data source departments cannot be empty.");
    }

    if (errors.length > 0) {
      setErrorMessages(errors);
      return;
    }

    setErrorMessages([]);

    if (!selectedTemplate) return;
    const finalTitle = reportTitle.trim() || selectedTemplate.title;

    const payload: any = {
      filterID: String(selectedTemplate.id),
      reportName: finalTitle,
      timeframe: timeframe,
      dataSourceDepartmentIDs: dataSourceDepartments.length > 0 ? dataSourceDepartments.map((d) => d.id) : [],
      allUsers
    };

    if (timeframe === "custom") {
      payload.StartDate = customRangeStart;
      payload.EndDate = customRangeEnd;
    }

    if (accessLevel === "all") {
      payload.ClientID = client?.id;
    } else if (accessLevel === "client" || accessLevel === "department") {
      payload.ClientID = userContext.metadata.clientID;
    }

    console.log("create", dataSourceDepartments)

    try {
      const response = await apiV2.post("/reporting/create-new-report", payload);
      const newReport = response.data.report;
      console.log(previousPath, "previousPath")
      navigate(previousPath || "/reporting/reports", {
        state: {
          openEditOverlay: true,
          newReportID: newReport.id,
          newReportName: newReport.reportName,
          newClientName: newReport.clientName,
          newClientID: newReport.clientID,
          newStartDate: newReport.startDate || "",
          newEndDate: newReport.endDate || "",
          newTimeframe: newReport.timeframe,
          newDataSourceDepartments: dataSourceDepartments,
          allUsers,
        },
      });

    } catch (error) {
      console.error("Error creating report:", error);
    }
  };
  
  
  
  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <p>Loading...</p>
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <p>Error: {error}</p>
      </div>
    )
  }


  return (
    <div className="flex flex-col justify-between h-full">
      <div className="bg-white p-5 border-b border-light-grey">
        <h1 className="text-xl font-semibold text-grey">Create New Report</h1>
      </div>

      <div className="p-6 overflow-auto h-full">
        <div className="ml-2 mb-4">
          <h2 className="text-dark-dark-grey text-lg font-semibold">
            Create report from template
          </h2>
        </div>
        {templates.length === 0 ? (
          <div className="flex items-center justify-center h-full">
            <p>No templates available.</p>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-8 mx-16 mt-6">
            {templates.map((t) => (
              <div
                key={t.id}
                className="bg-white flex rounded-md shadow-md border-2 border-light-blue overflow-hidden cursor-pointer p-4"
                onClick={() => handleTemplateClick(t)}
              >
                <span className="mx-2 ">{iconMap[t.icon] || null}</span>
                <h3 className="text-lg ml-1 font-medium text-gray-700 text-center">{t.title}</h3>
              </div>
            ))}
          </div>
        )}
      </div>

      {isOverlayOpen && selectedTemplate && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div
      className="absolute inset-0 backdrop-blur-sm backdrop-brightness-50"
      onClick={closeOverlay}
    />
    <div className="relative bg-white-blue m-20 mt-40 h-4/5 rounded-lg shadow-lg z-100 w-3/5 flex flex-col animate-[zoom-in_0.2s_ease-in-out_forwards]">
      <div className="bg-blue w-full flex items-center justify-between px-4">
        <div className="w-3/5 py-2">
        <input
          ref={inputRef}
          type="text"
          value={reportTitle}
          onChange={(e) => setReportTitle(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="h-1/5 w-4/5 text-xl font-semibold bg-blue border-2 border-light-grey rounded-lg p-2 text-white hover:border-light-grey focus:border-white focus:ring-0 outline-none"
        />
          <Edit 
            fontSize="small" 
            className={`ml-2 mb-2 cursor-pointer ${
              isFocused ? "text-white" : "text-light-grey hover:text-white"
            }`}
            onClick={handleEditClick}
          />
          </div>
        <button className="text-white" onClick={closeOverlay}>
          <Close />
        </button>
      </div>
      <div className="flex-grow overflow-y-auto p-4">
        <div className="shadow-md rounded bg-white p-3">
          <h2 className="text-lg font-semibold mb-4">Filters</h2>
          <div className="mb-4">
            <select
              value={timeframe}
              onChange={(e) => handleTimeframeChange(e.target.value)}
              className="h-10 w-full shadow-md rounded-md px-4 outline-none bg-white"
            >
              <option value="past1">Past Day</option>
              <option value="past7">Past Week</option>
              <option value="past30">Past 30 Days</option>
              <option value="mtd">Month To Date</option>
              <option value="ytd">Year To Date</option>
              <option value="custom">Custom Date</option>
            </select>
          </div>
          {timeframe === "custom" && (
            <div className="mb-2">
              <BasicText
                label="Start Date:"
                value={customRangeStart}
                onChange={(val) => {
                  setCustomRangeStart(val);
                  const now = new Date();
                  const startDate = new Date(val);
                  if (!val) {
                    setStartDateError("Start date is required.");
                  } else if (startDate >= now) {
                    setStartDateError("Start date must be in the past.");
                  } else {
                    setStartDateError(null);
                  }
                  if (customRangeEnd && new Date(customRangeEnd) <= startDate) {
                    setEndDateError("End date must be after the start date.");
                  } else {
                    setEndDateError(null);
                  }
                }}
                type="date"
                className="h-13 mb-1"
              />
              {startDateError && (
                <p className="text-red text-sm mt-2">{startDateError}</p>
              )}
              <div className="flex">
                <BasicText
                  label="End Date:"
                  value={customRangeEnd}
                  onChange={(val) => {
                    setCustomRangeEnd(val);
                    const startDate = new Date(customRangeStart);
                    const endDate = new Date(val);
                    if (!val) {
                      setEndDateError("End date is required.");
                    } else if (startDate && endDate <= startDate) {
                      setEndDateError("End date must be after the start date.");
                    } else {
                      setEndDateError(null);
                    }
                  }}
                  type="date"
                  className="h-13 w-full mb-2"
                />
              </div>
              {endDateError && (
                <p className="text-red text-sm mt-2">{endDateError}</p>
              )}
            </div>
          )}
          <div className="mb-4">
            {accessLevel === "all" && (
              <ClientSelectSingle
                client={client}
                onChange={(selectedClient) => setClient(selectedClient)}
              />
            )}
            {(accessLevel === "client" || accessLevel === "department") && (
              <h2 className="text-medium mt-8 font-medium">
                Client: {authData.clientName}
              </h2>
            )}
            {accessLevel === "all" && (
              <div className="mt-2 flex flex-col gap-4">
                <h3 className="text-md font-semibold">
                  Data Source:
                </h3>
                <ToggleButton text={"All Users"} initialValue={false} onChange={setAllUsers}/>
                {!allUsers && <DepartmentSelect
                  departments={dataSourceDepartments}
                  multiple={true}
                  clientID={client?.id}
                  onSelectionChange={(departments) => {
                    const departmentIDs = (departments || []).map((d) => d.id);
                    setSelectedDepartmentIDs(departmentIDs);
                    setDataSourceDepartments(departments);
                  }}
                  allowSelectAll={true}
                />}
              </div>
            )}
            {(accessLevel === "client" || accessLevel === "department") && (
              <div className="mt-2 flex flex-col gap-4">
                <h3 className="text-md font-semibold">
                  Data Source:
                </h3>
                {accessLevel === "client" &&
                  <ToggleButton text={"All Users"} initialValue={false} onChange={setAllUsers}/>}

                  {!allUsers && <DepartmentSelect
                      departments={dataSourceDepartments}
                      multiple={true}
                      clientID={authData.clientID}
                      onSelectionChange={(departments) => {
                        const departmentIDs = (departments || []).map((d) => d.id);
                        setSelectedDepartmentIDs(departmentIDs);
                        setDataSourceDepartments(departments);
                      }}
                      allowSelectAll={true}
                  />}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="p-2">
        <button
          type="button"
          onClick={handleCreateReport}
          className="w-full h-10 text-white text-sm rounded-md shadow-md bg-blue"
        >
          Create and Save Report
        </button>
        {errorMessages.length > 0 && (
          <div className="mt-2 text-red text-sm">
            {errorMessages.map((msg, idx) => (
              <p key={idx}>{msg}</p>
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
)}


      <style>
        {`
          @keyframes zoom-in {
            0% {
              opacity: 0;
              transform: scale3d(0.7, 0.7, 0.7);
            }
            100% {
              opacity: 1;
              transform: scale3d(1, 1, 1);
            }
          }
        `}
      </style>
    </div>
  )
}
