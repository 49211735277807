import React, {useCallback, useEffect, useMemo, useState} from "react";
import Layout from "../layout/Layout";
import {ClientSelectSingle} from "../../../components/form-v2/searchable-select/ClientSelect";
import {DepartmentsManagementProvider, useDepartmentsManagement} from "./DepartmentsManagementContext";
import {Departments} from "../../../types/userManagement";
import {useQuery} from "react-query";
import {apiV2} from "../../../api/axiosConfig";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import {Action, ActionSelect} from "../../../components/ui/ActionSelect";
import PaginationNoneFloating from "../../../components/ui/Content/PaginationNoneFloating";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {isAxiosError} from "axios";
import {BlueButton} from "../../../components/ui/Buttons";
import Modal from "../../../components/portal/Modal";
import BasicText from "../../../components/form-v2/basic-text";
import {useAuth} from "../../../context/user";
import {useFetchUser} from "../../../lib/users/useFetchUser";
import {useUserManagementBrowsing} from "../../../lib/user-management/UserManagementBrowsingContext";

const DepartmentFilterArchiveStatus: React.FC = () => {

    const ctx = useDepartmentsManagement()
    const auth = useAuth();

    return <div className="bg-white shadow-md p-4 flex flex-row gap-8 items-center">
        <a className={`cursor-pointer ${ctx.archived ? "opacity-50" : ""}`} onClick={() => {
            ctx.setPage(1);
            ctx.setArchived(false)
        }}>Departments ({ctx?.departments?.total})</a>
        <a className={`cursor-pointer ${ctx.archived ? "" : "opacity-50"}`} onClick={() => {
            ctx.setPage(1);
            ctx.setArchived(true)
        }}>Archived</a>
        <div className={"flex-1"}/>
        <div>
            {auth.isSuperAdmin && ctx.client &&
                <a href={"/management/departments/create"}>Add Department</a>}
            {!auth.isSuperAdmin && auth.hasAccessLevelClient && <a href={"/management/departments/create"}>Add Department</a>}
        </div>
    </div>
}

const DepartmentFilterSearch: React.FC = () => {

    const ctx = useDepartmentsManagement()
    const auth = useAuth()

    const browsing = useUserManagementBrowsing()

    return <div className="bg-white shadow-md p-4 flex flex-row gap-8 items-center">
        {auth.isSuperAdmin && <div className="w-64">
            <ClientSelectSingle initialClientID={browsing.clientID} client={ctx.client} onChange={ctx.setClient}/>
        </div>
        }

        <div className="w-64">
            <BasicText value={ctx.search} onChange={ctx.setSearch} placeholder={"Search"}/>
        </div>
    </div>
}


const DepartmentTable: React.FC = () => {

    const ctx = useDepartmentsManagement()

    return <div className="bg-white shadow-md p-4">
        <DepartmentTableHeader/>
        <hr className={"my-4"}/>

        <div className={"flex flex-col gap-4"}>
            {ctx.departments?.items && ctx.departments.items.map((d) => (
                <DepartmentRow department={d} key={d.id}/>
            ))}
            <div className={"flex justify-center"}>
                <PaginationNoneFloating
                    currentPage={ctx.page}
                    itemsPerPage={ctx.limit}
                    dataLength={ctx.departments?.total || 0}
                    togglePageSelect={(page) => ctx.setPage(page)}
                />
            </div>


        </div>


    </div>
}


type DepartmentRowProps = {
    department: Departments
}

const fetchDepartmentUserCount = async (departmentID?: string) => {
    const response = await apiV2.get(`departments/${departmentID}/user-count`);
    return response.data
}


const DepartmentRow: React.FC<DepartmentRowProps> = (props) => {

    const ctx = useDepartmentsManagement()
    const auth = useAuth()

    const [showArchive, setShowArchive] = useState(false)
    const [showUnassign, setShowUnassign] = useState(false)

    const navigate = useNavigate()

    const {data, isLoading, error} = useQuery(
        ["department-user-count", props.department.id],
        () => fetchDepartmentUserCount(props.department.id),
        {
            enabled: !!props.department,
        }
    );

    const archiveToggle = useCallback(async () => {

        const url = archived ? `departments/${props.department.id}/unarchive` : `departments/${props.department.id}/archive`
        try {
            await apiV2.patch(url, {})
            ctx.refetch()
        } catch (e) {
            console.error(e)
            if (isAxiosError(e) && e?.response?.data?.error) {
                ctx.setActionError(e.response.data.error)
            }
        }
    }, [props.department.id])

    const unassignUsers = useCallback(async () => {
        try {
            await apiV2.patch(`departments/${props.department.id}/unassign-users`, {})
            ctx.refetch()
        } catch (e) {
            console.error(e)
            if (isAxiosError(e) && e?.response?.data?.error) {
                ctx.setActionError(e.response.data.error)
            }
        }
    }, [props.department.id])


    const archived = useMemo(() => {
        return props.department.archived
    }, [props.department])

    const [owner, errorLoadingOwner, isLoadingOwner] = useFetchUser(props.department.createdByUserID)

    const ownerName = useMemo(() => {
        if(isLoadingOwner){
            return "Loading..."
        }

        if(errorLoadingOwner){
            return "Error Loading Owner"
        }

        if(owner){
            return `${owner.profile.firstName} ${owner.profile.lastName}`
        }

    }, [owner])

    return <>
        <Modal open={showArchive} setOpen={setShowArchive}>
            {archived &&
                <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                    <p className={"font-semibold"}>Unarchive Department</p>
                    <p>Please click to confirm unarchiving the department ({props.department.name})</p>
                    <BlueButton text={"Unarchive Department"} onClick={() => {
                        archiveToggle()
                        setShowArchive(false)
                    }}/>
                </div>}

            {!archived &&
                <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                    <p className={"font-semibold"}>Archive Department</p>
                    <p>Please click to confirm archival of the department ({props.department.name})</p>
                    <BlueButton text={"Archive Department"} onClick={() => {
                        archiveToggle()
                        setShowArchive(false)
                    }}/>
                </div>
            }
        </Modal>

        <Modal open={showUnassign} setOpen={setShowUnassign}>
            <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                <p className={"font-semibold"}>Unassign Users From Department</p>
                <p>Please click to confirm unassigning all {data?.total} users from the department
                    ({props.department.name})</p>
                <BlueButton text={`Unassign ${data?.total} Users`} onClick={() => {
                    unassignUsers()
                    setShowUnassign(false)
                }}/>
            </div>


        </Modal>
        <div className="grid grid-cols-[5%_19%_19%_19%_19%_19%]">
            <div className={"flex justify-center"}>
                <input type={"checkbox"}
                       checked={!!(props.department.id && ctx.selected.has(props.department.id))}
                       onChange={(e) => {
                           if (!props.department.id) {
                               return
                           }
                           if (e.target.checked) {
                               ctx.addSelected(props.department.id);
                           } else {
                               ctx.removeSelected(props.department.id);
                           }
                       }}
                />


            </div>
            <div className={"flex items-center"}>
                <Link to={`/management/departments/edit/${props.department.id}`}>
                    {props.department.name}
                </Link>
            </div>
            <div className={"flex items-center"}>{ownerName}</div>
            <div className={"flex items-center"}>
                {isLoading ? <LoadingSpinner width={16} height={16}/> :

                    <Link to={`/management/users?clientID=${props.department.clientID}&departmentIDs=${props.department.id}`}
                       target="_blank" rel="noreferrer">
                        {data.total}
                    </Link>

                }

            </div>

            <div className={"flex items-center"}>
                <Link to={`/management/departments/${props.department.id}/guardians`}>
                    {props.department.guardianUserIDs.length}
                </Link>
            </div>
            <div className={""}>
                {archived &&
                    <ActionSelect label={"Select Action"}>
                        <Action label={"Unarchive"} onSelect={() => setShowArchive(true)}/>
                    </ActionSelect>
                }

                {!archived &&
                    <ActionSelect label={"Select Action"}>
                        <Action label={"Edit"} onSelect={() => {
                            window.location.href = `/management/departments/edit/${props.department.id}`;
                        }}/>
                        {auth.hasAccessLevelClient && <Action label={"Manage Guardians"} onSelect={() => {
                            navigate(`/management/departments/${props.department.id}/guardians`)
                        }}/>}
                        <Action label={"Unassign All Users"} onSelect={() => setShowUnassign(true)}/>
                        <Action label={"Archive"} onSelect={() => setShowArchive(true)}/>

                    </ActionSelect>
                }
            </div>
        </div>
    </>
}

const DepartmentTableHeader: React.FC = () => {
    const ctx = useDepartmentsManagement()


    return <div className="grid grid-cols-[5%_19%_19%_19%_19%_19%]">
        <div className={"flex justify-center"}>
            <input type={"checkbox"}
                   onChange={(e) => {
                       if (e.target.checked) {
                           ctx.selectAll()
                       } else {
                           ctx.deselectAll()
                       }
                   }}
            />
        </div>
        <div>Name</div>
        <div>Created By</div>
        <div>Users</div>
        <div>Guardians</div>
        <div>Actions</div>
    </div>
}

const MultiSelectActions: React.FC = () => {
    const ctx = useDepartmentsManagement()


    const count = useMemo(() => {
        return ctx.selected.size
    }, [ctx.selected])

    if (count == 0) {
        return null
    }




    return <div className="bg-white shadow-md p-4 mt-8">
        <div className="grid grid-cols-[15%_13%_24%_24%_24%]">
            <div className={"flex items-center"}>{count} Selected</div>
            <div></div>
            <div></div>
            <div className="w-64">
                <BlueButton text={`Archive (${count})`}/>
            </div>
            <div className="w-64">
                <BlueButton text={`Unassign Users (${count})`}/>
            </div>
        </div>
    </div>
}


export const DepartmentList: React.FC = () => {
    const ctx = useDepartmentsManagement()

    const [searchParams, setSearchParams] = useSearchParams()

    const [message, setMessage] = useState(searchParams.get("message"));

    useEffect(() => {
        if (message) {
            setTimeout(() => {
                setMessage(null)
                const newParams = new URLSearchParams(searchParams.toString());
                newParams.delete("message")
                setSearchParams(newParams);
            }, 5000)
        }
    }, [message]);


    return <Layout
        active={"departments"}
    >
        <div className="flex flex-col gap-8">
            {ctx.actionError && <div className="text-white bg-red rounded-md shadow-md p-4 flex flex-row gap-8 items-center">
                {ctx.actionError}
            </div>}

            {message && <div className="text-white bg-green shadow-md p-4 flex flex-row gap-8 items-center justify-center rounded-md">
                {message}
            </div>}

            <DepartmentFilterArchiveStatus/>
            <DepartmentFilterSearch/>
            <DepartmentTable/>
        </div>
        <MultiSelectActions/>


    </Layout>

}

DepartmentList.displayName = "DepartmentList";

export default DepartmentList;