import React, {useCallback, useEffect, useRef, useState} from "react";
import api from "../../../../api/axiosConfig";
import {BlueButton} from "../../../../components/ui/Buttons";

type UserImageFormProps = {
    id: string
}

export const UserImageForm: React.FC<UserImageFormProps> = (props) => {


    const [saving, setSaving] = useState(false);
    const [preview, setPreview] = useState<string | null>(null);
    const [imageBlob, setImageBlob] = useState<Blob | null>(null);
    const [noImage, setNoImage] = useState(false)
    const [loading, setLoading] = useState(true)

    const inputRef = useRef<HTMLInputElement | null>(null)

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    };


    const fetchImage = async () => {
        await fetch(
            `${process.env.REACT_APP_API_URL}files/profile-image/${props.id}`
        )
            .then(async (response) => {
                if (response.ok) {
                    const blob = await response.blob();
                    setImageBlob(blob);
                }
            })
            .catch((err) => {
                console.error("error fetching image", err)
                setImageBlob(null);

            });
        setLoading(false);
    };

    useEffect(() => {
        fetchImage();
    }, []);


    const save = useCallback(async () => {
        if (!inputRef?.current?.files) {
            return
        }

        const file = inputRef?.current?.files[0]


        const formData = new FormData();
        formData.append("file", file);

        api
            .post(`/files/profile-image/${props.id}`, formData, {
                headers: {"content-type": "multipart/form-data"},
            })
            .then((res) => {
                console.log("image uploaded");
                if (inputRef.current) {
                    inputRef.current.value = "";
                    setPreview(null)
                }
                fetchImage();
            })
            .catch((err) => {
                console.error(err);
            });
    }, [])


    return <div className="bg-white shadow-md p-4 flex flex-col gap-8">
        <p className="text-md text-left pb-2 font-semibold">Upload Image</p>

        <div>
            <p>Current Image:</p>
            <div className="flex flex-row justify-center">

                {!imageBlob && !loading && <p>No image available</p>}

                {imageBlob && !loading && (
                    <img
                        src={URL.createObjectURL(imageBlob)}
                        alt="Fetched Image"
                        className="rounded-md max-h-64"
                    />
                )}
            </div>
        </div>

        <div>
            <p>Preview:</p>
            <div className="flex flex-row justify-center">
                {preview ? <img src={preview} alt="Selected" className={"max-h-64 rounded-md"}/>
                    : <p>No image selected</p>
                }
            </div>
        </div>

        <input ref={inputRef} type="file" accept="image/*" onChange={handleImageChange} className={"hidden"}/>

        <BlueButton text={"Select Image"} onClick={() => {
            if (inputRef.current) {
                inputRef.current?.click()
            }
        }} loading={saving}/>
        <BlueButton text={"Update Image"} disabled={!preview} onClick={save} loading={saving}/>
    </div>

}
