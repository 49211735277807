import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function LocationWatcher() {
  const location = useLocation();
  
  useEffect(() => {
    if (!location.pathname.startsWith("/reporting")) {
      console.log("removing selected client");
      localStorage.removeItem("selectedClient");
    }
  }, [location.pathname]);

  return null;
}
