import React, {useCallback, useEffect, useMemo, useState} from "react";
import Layout from "../layout/Layout";
import {ClientSelectSingle} from "../../../components/form-v2/searchable-select/ClientSelect";
import {UsersManagementProvider, useUsersManagement} from "./UsersManagementContext";
import {Department, Users} from "../../../types/userManagement";
import {useQuery} from "react-query";
import {apiV2} from "../../../api/axiosConfig";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import {Action, ActionSelect} from "../../../components/ui/ActionSelect";
import PaginationNoneFloating from "../../../components/ui/Content/PaginationNoneFloating";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {isAxiosError} from "axios";
import {BlueButton} from "../../../components/ui/Buttons";
import Modal from "../../../components/portal/Modal";
import {UserDataTypes} from "../../../types/user-data-types";
import {useDepartmentsManagement} from "../departments/DepartmentsManagementContext";
import BasicText from "../../../components/form-v2/basic-text";
import {DepartmentSelectSingle} from "../../../components/form-v2/searchable-select/DepartmentSelect";
import {useAuth} from "../../../context/user";
import {DepartmentSelectV2} from "../../../lib/departments/DepartmentSelectV2";
import {DepartmentSelectV2Multi} from "../../../lib/departments/DepartmentSelectV2Multi";
import {useSendResetLink} from "../../../lib/users/useSendResetLink";
import {useUserManagementBrowsing} from "../../../lib/user-management/UserManagementBrowsingContext";
import {IconButton} from "../import/inputs";
import {Download} from "@mui/icons-material";
import {useUserExport} from "./useUserExport";

const UserFilterArchiveStatus: React.FC = () => {

    const ctx = useUsersManagement()
    const auth = useAuth()

    return <div className="bg-white shadow-md p-4 flex flex-row gap-8 items-center">
        <a className={`cursor-pointer ${ctx.archived ? "opacity-50" : ""}`} onClick={() => {
            ctx.setPage(1);
            ctx.setArchived(false)
        }}>Users ({ctx?.users?.total})</a>
        <a className={`cursor-pointer ${ctx.archived ? "" : "opacity-50"}`} onClick={() => {
            ctx.setPage(1);
            ctx.setArchived(true)
        }}>Archived</a>
        <div className={"flex-1"}/>
        <div>
            {auth.isSuperAdmin && ctx.client &&
                <Link to={"/management/users/create"}>
                    <div>Add User</div>
                </Link>}
            {!auth.isSuperAdmin && <a href={"/management/users/create"}>Add User</a>}
        </div>
    </div>
}

const UserFilterSearch: React.FC = () => {

    const ctx = useUsersManagement()
    const browsing = useUserManagementBrowsing()
    const auth = useAuth()


    const [initialDepartmentIDs, setInitialDepartmentIDs] = useState<string[]>(browsing.departmentIDs);
    const [initialClientID, setInitialClientID] = useState<string>(browsing.clientID || "");


    useEffect(() => {
        if(initialClientID != browsing.clientID && initialClientID && browsing.clientID){
            setInitialDepartmentIDs([])
        }
    }, [initialClientID, browsing.clientID]);

    const clientID = useMemo(() => { return ctx.client?.id ?? ""},[ctx.client])
    const departmentIDs = useMemo(() => { return ctx.departments?.map(d => d.id) || [] },[ctx.departments])


    const {download, downloading} = useUserExport(clientID, departmentIDs, ctx.search)


    return <div className="bg-white shadow-md p-4 flex flex-row flex-wrap gap-8">
        {auth.isSuperAdmin && <div className="w-64">
            <ClientSelectSingle initialClientID={browsing.clientID} client={ctx.client} onChange={(c) => {
                ctx.setClient(c)
            }}/>

        </div>}
        {(browsing.clientID || !auth.isSuperAdmin) && <div className="flex-1">
             <DepartmentSelectV2Multi
                initialIDs={initialDepartmentIDs}
                clientID={auth.isSuperAdmin ? browsing.clientID : undefined}
                onChange={(items) => {
                    browsing.setDepartmentIDs(items.map(d => d.id))
                    ctx.setDepartments(items)
                }}
             />
        </div>}

        <div className="w-64">
            <BasicText value={ctx.search} onChange={ctx.setSearch} placeholder={"Search"}/>
        </div>
        {!ctx.archived && <div className="w-64">
            <IconButton text={"Export"} icon={downloading  ? <LoadingSpinner color={"#fff"} width={20} height={20}/> : <Download/>} onClick={download}/>
        </div>}
    </div>

}

const UserTable: React.FC = () => {

    const ctx = useUsersManagement()

    return <div className="bg-white shadow-md p-4">
        <UserTableHeader/>
        <hr className={"my-4"}/>

        <div className={"flex flex-col gap-4"}>
            {ctx.users?.items && ctx.users.items.map((d) => (
                <UserRow user={d} key={d.id}/>
            ))}
            <div className={"flex justify-center"}>
                <PaginationNoneFloating
                    currentPage={ctx.page}
                    itemsPerPage={ctx.limit}
                    dataLength={ctx.users?.total || 0}
                    togglePageSelect={(page) => ctx.setPage(page)}
                />
            </div>


        </div>


    </div>
}


type UserRowProps = {
    user: UserDataTypes
}


const UserRow: React.FC<UserRowProps> = (props) => {

    const ctx = useUsersManagement()

    const [showArchive, setShowArchive] = useState(false)
    const [showSendPasswordReset, setShowSendPasswordReset] = useState(false)

    const navigate = useNavigate()


    const archiveToggle = useCallback(async () => {

        const url = archived ? `users/${props.user.id}/unarchive` : `users/${props.user.id}/archive`
        try {
            await apiV2.patch(url, {})
            ctx.refetch()
        } catch (e) {
            console.error(e)
            if (isAxiosError(e) && e?.response?.data?.error) {
                ctx.setActionError(e.response.data.error)
            }
        }
    }, [props.user.id])

    const unassignUsers = useCallback(async () => {
        try {
            await apiV2.patch(`users/${props.user.id}/unassign-users`, {})
            ctx.refetch()
        } catch (e) {
            console.error(e)
            if (isAxiosError(e) && e?.response?.data?.error) {
                ctx.setActionError(e.response.data.error)
            }
        }
    }, [props.user.id])


    const archived = useMemo(() => {
        return props.user.archived
    }, [props.user])

    const name = useMemo(() => {
        return `${props.user.profile.firstName} ${props.user.profile.lastName}`
    }, [props.user])


    const [sendResetLink, sendingResetLink, errorSendingResetLink] = useSendResetLink(
        props.user.id,
        () => setShowSendPasswordReset(false)
    )

    return <>
        <Modal open={showArchive} setOpen={setShowArchive}>
            {archived &&
                <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                    <p className={"font-semibold"}>Unarchive User</p>
                    <p>Please click to confirm unarchiving the user ({name})</p>
                    <BlueButton text={"Unarchive User"} onClick={() => {
                        archiveToggle()
                        setShowArchive(false)
                    }}/>
                </div>}

            {!archived &&
                <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                    <p className={"font-semibold"}>Archive User</p>
                    <p>Please click to confirm archival of the user ({name})</p>
                    <BlueButton text={"Archive User"} onClick={() => {
                        archiveToggle()
                        setShowArchive(false)
                    }}/>

                </div>
            }
        </Modal>

        <Modal open={showSendPasswordReset} setOpen={setShowSendPasswordReset}>
            <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                <p className={"font-semibold"}>Send Password Reset Link</p>
                <p>Please click to confirm sending a password reset link to {props.user.email}</p>
                <BlueButton text={"Send Reset Link"} loading={sendingResetLink} onClick={async () => {
                    await sendResetLink()
                }}/>
                {errorSendingResetLink && (
                    <p className={"text-red text-center"}>Error sending reset link</p>
                )}
            </div>
        </Modal>

        <div className="grid grid-cols-[5%_23%_24%_24%_24%]">
            <div className={"flex justify-center"}>
                <input type={"checkbox"}
                       checked={!!(props.user.id && ctx.selected.has(props.user.id))}
                       onChange={(e) => {
                           if (!props.user.id) {
                               return
                           }
                           if (e.target.checked) {
                               ctx.addSelected(props.user.id);
                           } else {
                               ctx.removeSelected(props.user.id);
                           }
                       }}
                />


            </div>
            <div className={"flex items-center"}>
                <Link to={`/management/users/edit/${props.user.id}`} className={"truncate pr-4"}>
                    {name}
                </Link>
            </div>
            <div className={"flex items-center"}>
                <Link to={`/management/departments/edit/${props.user.departmentID}`} target={"_blank"} className={"truncate  pr-4"}>
                    {props.user.departmentName}
                </Link>
            </div>
            <div className={"flex items-center"}>{props.user.roleName}</div>
            <div className={""}>
                {archived &&
                    <ActionSelect label={"Select Action"}>
                        <Action label={"Unarchive"} onSelect={() => setShowArchive(true)}/>
                    </ActionSelect>
                }

                {!archived &&
                    <ActionSelect label={"Select Action"}>
                        <Action label={"Edit"} onSelect={() => {
                            window.location.href = `/management/users/edit/${props.user.id}`;
                        }}/>
                        <Action label={"Send Password Reset"} onSelect={() => setShowSendPasswordReset(true)}/>
                        <Action label={"Archive"} onSelect={() => setShowArchive(true)}/>


                    </ActionSelect>
                }
            </div>
        </div>
    </>
}

const UserTableHeader: React.FC = () => {
    const ctx = useUsersManagement()


    return <div className="grid grid-cols-[5%_23%_24%_24%_24%]">
        <div className={"flex justify-center"}>
            <input type={"checkbox"}
                   onChange={(e) => {
                       if (e.target.checked) {
                           ctx.selectAll()
                       } else {
                           ctx.deselectAll()
                       }
                   }}
            />
        </div>
        <div>Name</div>
        <div>Department</div>
        <div>Role</div>
        <div>Actions</div>
    </div>
}

const MultiSelectActions: React.FC = () => {
    const ctx = useUsersManagement()

    const [showArchive, setShowArchive] = useState(false)
    const [showUnarchive, setShowUnarchive] = useState(false)

    const count = useMemo(() => {
        return ctx.selected.size
    }, [ctx.selected])

    if (count == 0) {
        return null
    }


    return <>

        <Modal open={showArchive} setOpen={setShowArchive}>
            <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                <p className={"font-semibold"}>Archive Users</p>
                <p>Please click to confirm archiving {count} users </p>
                <BlueButton text={`Archive ${count} Users`} loading={ctx.submitting} onClick={() => {
                    ctx.setSubmitting(true)
                    ctx.archiveMultiple().then(() => {
                        setShowArchive(false)
                        ctx.refetch()
                    }).finally(() => {
                        ctx.setSubmitting(false)
                    })
                }}/>
            </div>

        </Modal>

        <Modal open={showUnarchive} setOpen={setShowUnarchive}>
            <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                <p className={"font-semibold"}>Archive Users</p>
                <p>Please click to confirm unarchiving {count} users </p>
                <BlueButton text={`Unarchive ${count} Users`} loading={ctx.submitting} onClick={() => {
                    ctx.setSubmitting(true)
                    ctx.unarchiveMultiple().then(() => {
                        setShowArchive(false)
                        ctx.refetch()
                    }).finally(() => {
                        ctx.setSubmitting(false)
                    })
                }}/>
            </div>

        </Modal>

        <div className="bg-white shadow-md p-4 mt-8">
            <div className="grid grid-cols-[10%_30%_30%_30%]">
                <div className={"flex items-center"}>{count} Selected</div>
                <div></div>
                <div className={"pr-4"}>
                    <BlueButton text={"Select All"} onClick={ctx.selectAllPages}/>
                </div>

                {!ctx.archived && (
                    <div className={"pl-4"}>
                        <BlueButton text={`Archive (${count})`} onClick={() => setShowArchive(true)}/>
                    </div>
                )}

                {ctx.archived && (
                    <div className={"pl-4"}>
                        <BlueButton text={`Unarchive (${count})`} onClick={() => setShowUnarchive(true)}/>
                    </div>
                )}
            </div>
        </div>
    </>
}


const UserList: React.FC = () => {
    const ctx = useUsersManagement()

    const [searchParams, setSearchParams] = useSearchParams()

    const [message, setMessage] = useState(searchParams.get("message"));

    useEffect(() => {
        if (message) {
            setTimeout(() => {
                setMessage(null)
                const newParams = new URLSearchParams(searchParams.toString());
                newParams.delete("message")
                setSearchParams(newParams);
            }, 5000)
        }
    }, [message]);

    return <Layout
        active={"users"}
    >
        <div className="flex flex-col gap-8">
            {ctx.actionError && <div
                className="text-white bg-red shadow-md p-4 flex flex-row gap-8 items-center justify-center rounded-md">
                {ctx.actionError}
            </div>}

            {message && <div
                className="text-white bg-green shadow-md p-4 flex flex-row gap-8 items-center justify-center rounded-md">
                {message}
            </div>}
            <UserFilterArchiveStatus/>
            <UserFilterSearch/>
            <UserTable/>
        </div>
        <MultiSelectActions/>


    </Layout>

}

UserList.displayName = "UserList";

export default UserList;