import React, {createContext, useContext, ReactNode, useMemo, useEffect, useState} from "react";
import moment from "moment";
import {UserContext} from "../context/user";
import {getTimezonesForCountry} from "countries-and-timezones";
import {getTimezonesForCountryWithOverride} from "../util/tz";

// Define the shape of the context data
export type TimeZoneSelectContextType = {
    timeZone: string;
    setTimeZone: (newValue: string) => void;
    countryISO: string;
    setCountryISO: (newCountryISO: string) => void;
    options: string[]
}

// Create the context with a default value
const TimeZoneSelectContext = createContext<TimeZoneSelectContextType | undefined>(undefined);

// Define the provider component
interface TimeZoneSelectProviderProps {
    children: ReactNode;
}

const getTimezoneOptions = (countryISO: string) => {
    return getTimezonesForCountryWithOverride(countryISO)?.map((tz) => ({
        label: tz.name,
        value: tz.name
    })) || []
}

export const TimeZoneSelectProvider: React.FC<TimeZoneSelectProviderProps> = ({ children }) => {

    const assumedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const savedTimeZone = localStorage.getItem("auroraTimeZone");
    const savedCountry = localStorage.getItem("auroraCountry");

    const userContext = useContext(UserContext);

    if(!savedTimeZone){
        localStorage.setItem("auroraTimeZone", assumedTimeZone);
    }

    if(!savedCountry){
        if(userContext?.profile.countryOfResidence){
            localStorage.setItem("auroraCountry", userContext?.profile.countryOfResidence);
        }
    }

    const [timeZone, setTimeZone] = useState<string>(savedTimeZone || assumedTimeZone);
    const [countryISO, setCountryISO] = useState<string>(savedCountry || userContext?.profile.countryOfResidence || "GB");

    const setAndSaveTimezone = (value: string) => {
        localStorage.setItem("auroraTimeZone", value);
        setTimeZone(value)
    }

    const setAndSaveCountry = (value: string)=>{
        localStorage.setItem("auroraCountry", value);

        const tzOptions = getTimezoneOptions(value);
        if(tzOptions.length != 0){
            setAndSaveTimezone(tzOptions[0].value)
        }

        setCountryISO(value)
    }

    const timeZoneOptions = moment.tz.names()

    return (
        <TimeZoneSelectContext.Provider value={{
            timeZone,
            setTimeZone: setAndSaveTimezone,
            options: timeZoneOptions,
            countryISO,
            setCountryISO: setAndSaveCountry,
        }}>
            {children}
        </TimeZoneSelectContext.Provider>
    );
};

// Create a custom hook to use the context
export const useTimeZoneSelectContext = (): TimeZoneSelectContextType => {
    const context = useContext(TimeZoneSelectContext);
    if (!context) {
        throw new Error("useTimeZoneSelectContext must be used within a TimeZoneSelectProvider");
    }
    return context;
};