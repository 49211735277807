// @ts-nocheck

import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import AsyncSelect from "react-select/async";
import {apiV2} from "../../api/axiosConfig";
import {PaginatedResponse} from "../../types/PaginatedResponse";
import {Department} from "../../types/userManagement";
import {fetchDepartment, useLoadDepartment} from "./useLoadDepartment";
import {reactSelectClassNames, reactSelectStyles} from "../react-select/styling";
import {fetchSearchDepartment} from "./useDepartmentSearch";
import {noop} from "../misc/noop";
import {
    ClassNamesConfig,
    CommonProps,
    FormatOptionLabelMeta,
    GroupBase,
    MultiValueProps,
    SelectInstance,
    StylesConfig
} from "react-select";


type Option = {
    id: string;
    label: string;
    department: Department;
}


type DepartmentSelectV2Props = {
    rpp?: number
    clientID?: string | null | undefined
    initialIDs?: string[] | null | undefined
    onChange?: (selected: Department[]) => void
}




export const DepartmentSelectV2Multi = ({
                                       rpp = 20,
                                       clientID = null,
                                       initialIDs = null,
                                       onChange = noop,
                                   }: DepartmentSelectV2Props) => {


    const [selected, setSelected] = useState<Option[]>([])
    const [loading, setLoading] = useState(true)

    const [initialOptions, setInitialOptions] = useState<Option[]|null>(null)

    const ref = useRef<SelectInstance<Option[]>>(null)

    const prevClientID = useRef<string|null>(null)

    useEffect(() => {
        prevClientID.current = clientID
    }, [clientID]);

    useEffect(() => {

        if(loading) return
        if(!clientID) return

        const options = selected.map(s => s.department).map(d => ({
            id: d.id,
            label: d.name,
            department: d,
            value: d.id,
        }))

        if(prevClientID.current && clientID != prevClientID.current){
            ref.current?.clearValue()

            options.forEach(o => ref.current?.selectOption(o))

            setSelected(options)
        }
    }, [clientID, loading, prevClientID.current, ref.current]);


    const loadDepartments = useCallback(async () => {

        console.log("loadDepartments", clientID, initialIDs)
        if(!initialIDs) return

        const promises = initialIDs.filter(id => id.trim()).map((id) => {
            return fetchDepartment(id)
        })

        const departments = await Promise.all(promises)

        const options = departments.filter(d => d.clientID == clientID).map(d => ({
            id: d.id,
            label: d.name,
            department: d,
            value: d.id,
        }))

        setInitialOptions(options)
        setSelected(options)


    }, [initialIDs, setSelected, clientID]);



    useEffect(() => {

        console.log("initialIDs", initialIDs)

        loadDepartments().finally(() => setLoading(false))
    }, [loadDepartments]);

    useEffect(() => {
        if(loading) return
        onChange(selected.map(o => o.department))
    }, [selected, loading]);




    const onSelect = useCallback((options?: Option[]) => {
        console.log("onSelect", options)

        if(loading){
            return
        }
        if (options) {
            setSelected(options)
            return
        }

        setSelected([])
    }, [onChange, selected]);



    const promiseOptions = useCallback(async (search: string) => {
        const results = await fetchSearchDepartment(search, rpp, clientID);
        if (!results) {
            return []
        }
        return results.items.map((d) => ({
            id: d.id,
            label: d.name,
            value: d.id,
            department: d,
        }))
    }, [rpp, clientID]);

    if (loading) {
        return null
    }

    const formatOptionLabel = (option: Option, meta: FormatOptionLabelMeta<Option>) => {
        return option.label
    }

    const MultiValue = (props: MultiValueProps<Option>) => {

        if(selected.length == 1) {
            return <div>{selected[0].label}</div>
        }
        return (
            <div className="[&:not(:first-child)]:hidden">{selected.length} Departments Selected</div>
        );
    };

    if(initialOptions == null){
        return null
    }

    if(loading){
        return null
    }



    return (
        <>
            {/* <p>{JSON.stringify(selected.map(s => s.id))}</p>*/}
        <AsyncSelect<Option[]>
            ref={ref}
            key={clientID}
            classNames={reactSelectClassNames as ClassNamesConfig<Option[]>}
                     styles={reactSelectStyles as StylesConfig<Option[]>}
                     placeholder="Select Departments"
                     defaultValue={initialOptions}
                     value={selected}
                     onChange={onSelect}
                     isMulti
                     // cacheOptions
                     loadOptions={promiseOptions}
                     defaultOptions
                     formatOptionLabel={formatOptionLabel}
                     // components={{MultiValue}}
                     isClearable/>
        </>
    )
};

