import React, {
    createContext,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo, useRef,
    useState
} from "react";
import {Client} from "../types/userManagement";
import api, {apiV2} from "../api/axiosConfig";
import {
    UsersLocation,
    UsersLocationUser
} from "../features/operational-map/components/Map/hooks/useUserLocationMarkers";
import {useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult} from "react-query";
import moment from "moment";
import {IncidentDataTypes} from "../types/incident-data-types";
import {PaginatedResponse} from "../types/PaginatedResponse";
import {WorldSearchRecord} from "../features/operational-map/components/Map/hooks/useWorldSearchMarkers";
import {FacilityDataTypes} from "../types/facilitiy-data-types";
import {Geofences, SafetyZones} from "../features/sites/types";
import {UserDataTypes} from "../types/user-data-types";
import {UserLocationHistory} from "../features/operational-map/components/Map/services/useLocationHistory";
import generateRandomToken from "../util/generateToken";
import {Overlay} from "../types/overlay";
import {useMap, useMapsLibrary} from "@vis.gl/react-google-maps";
import {BlueButton} from "../components/ui/Buttons";
import LoadingSpinner from "../components/ui/LoadingSpinner";
import Modal from "../components/portal/Modal";
import {saveAs} from "file-saver";
import * as XLSX from "xlsx";


const availableUserLocationTypes = ["app", "trip", "facility", "satellite"] as const;
export type UserLocationType = typeof availableUserLocationTypes[number];

const availableIncidentLevels = ["major", "extreme"] as const;
export type IncidentLevel = typeof availableIncidentLevels[number];

const availableTripTypes = ["travel", "accommodation"] as const;
export type TripType = typeof availableTripTypes[number];

const accommodationSubTypes = [
    "accommodation:hotel",
    "accommodation:private_property",
    "accommodation:home",
    "accommodation:other",
    "accommodation:undefined"
]

const travelSubTypes = [
    "travel:flight",
    "travel:taxi",
    "travel:ferry",
    "travel:train",
    "travel:rental",
    "travel:private_car",
    "travel:bus",
    "travel:other",
    "travel:undefined",
]

const emptyPaginatedResult = {
    hasMore: false,
    items: [],
    total: 0,
    page: 1
}

const MapTypeIDs = [
    "roadmap",
    "terrain",
    "hybrid",
    "satellite",
]

export type MapTypeID = typeof MapTypeIDs[number];


type OperationalMapContextType = {
    showFilters: boolean;
    setShowFilters: (showFilters: boolean) => void;

    showTrackingMenu: boolean;
    setShowTrackingMenu: (showTracking: boolean) => void;


    showSelectionMenu: boolean;
    setShowSelectionMenu: (showTracking: boolean) => void;

    client?: Client | undefined;
    setClient: (client: Client | undefined) => void;

    userQuery: UseQueryResult<UserLocation[]>;
    tripQuery: UseQueryResult<WorldSearchRecord[]>;
    incidentQuery: UseInfiniteQueryResult<PaginatedResponse<IncidentDataTypes>>;
    facilityQuery: UseInfiniteQueryResult<PaginatedResponse<FacilityDataTypes>>
    geofenceQuery: UseInfiniteQueryResult<PaginatedResponse<Geofences>>
    safetyZoneQuery: UseInfiniteQueryResult<PaginatedResponse<SafetyZones>>

    visibleInfoWindow: string | null;
    setVisibleInfoWindow: (key: string | null) => void;

    selectedUserLocationTypes: UserLocationType[];
    setSelectedUserLocationTypes: (userLocationTypes: UserLocationType[]) => void;
    availableUserLocationTypes: readonly UserLocationType[];

    selectedIncidentLevels: IncidentLevel[];
    setSelectedIncidentLevels: (incidentLevels: IncidentLevel[]) => void;

    showIncidentRadius: boolean;
    setShowIncidentRadius: (showIncidentRadius: boolean) => void;

    selectedTripTypes: TripType[];
    setSelectedTripTypes: (tripTypes: TripType[]) => void;

    showFacilities: boolean;
    setShowFacilities: (showFacilities: boolean) => void;

    showGeofences: boolean;
    setShowGeofences: (showGeofences: boolean) => void;

    showSafetyZones: boolean;
    setShowSafetyZones: (showSafetyZones: boolean) => void;

    trackingUser: UserDataTypes | undefined;
    setTrackingUser: (user: UserDataTypes | undefined) => void;

    trackingPointsQuery: UseQueryResult<UserLocationHistory[]>;

    zoom: number;
    setZoom: (zoom: number) => void;

    center: google.maps.LatLngLiteral;
    setCenter: (center: google.maps.LatLngLiteral) => void;

    bounds: google.maps.LatLngBoundsLiteral | undefined;
    setBounds: (bounds: google.maps.LatLngBoundsLiteral) => void;

    overlayQuery: UseInfiniteQueryResult<PaginatedResponse<Overlay>>;
    selectedOverlays: Overlay[];
    setSelectedOverlays: (selectedOverlays: Overlay[]) => void;

    mapTypeID: MapTypeID | null;
    setMapTypeID: (mapTypeID: MapTypeID | null) => void;


    drawingMode: null | "circle" | "polygon";
    setDrawingMode: (value: null | "circle" | "polygon") => void;

    drawingManager: google.maps.drawing.DrawingManager | null

    drawCircle: () => void
    drawPolygon: () => void


}

const OperationalMapContext = createContext<OperationalMapContextType | undefined>(undefined)

type OperationalMapProviderProps = {
    children: ReactNode
}

export type UserLocation = {
    key: string;
    lat: number;
    lng: number;
    users: UsersLocationUser[];
    multiple: boolean;
}



export const OperationalMapProvider: React.FC<OperationalMapProviderProps> = (props) => {




    // const [circle, setCircle] = useState<google.maps.Circle | null>(null);
    // const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);

    const [drawingMode, setDrawingMode] = useState<null | "circle" | "polygon">(null)


    const [showFilters, setShowFilters] = useState(false);
    const [showTrackingMenu, setShowTrackingMenu] = useState(false);
    const [showSelectionMenu, setShowSelectionMenu] = useState(false);

    const [client, setClient] = useState<Client | undefined>();
    const [visibleInfoWindow, setVisibleInfoWindow] = useState<string | null>(null);

    const storedUserLocationTypes = localStorage.getItem("operationalMap:user");
    const [selectedUserLocationTypes, setSelectedUserLocationTypes] = useState<UserLocationType[]>(storedUserLocationTypes ? JSON.parse(storedUserLocationTypes) : availableUserLocationTypes);

    const storedIncidentLevels = localStorage.getItem("operationalMap:incidentLevel");
    const [selectedIncidentLevels, setSelectedIncidentLevels] = useState<IncidentLevel[]>(storedIncidentLevels ? JSON.parse(storedIncidentLevels) : ["extreme"]);

    const storedShowIncidentRadius = localStorage.getItem("operationalMap:showIncidentRadius");
    const [showIncidentRadius, setShowIncidentRadius] = useState<boolean>(storedShowIncidentRadius == null ? true : JSON.parse(storedShowIncidentRadius));

    const storedTripTypes = localStorage.getItem("operationalMap:trip");
    const [selectedTripTypes, setSelectedTripTypes] = useState<TripType[]>(storedTripTypes ? JSON.parse(storedTripTypes) : []);

    const storedShowFacilities = localStorage.getItem("operationalMap:facilities");
    const [showFacilities, setShowFacilities] = useState(storedShowFacilities ? JSON.parse(storedShowFacilities) : false);

    const storedShowGeofences = localStorage.getItem("operationalMap:geofences");
    const [showGeofences, setShowGeofences] = useState(storedShowGeofences ? JSON.parse(storedShowGeofences) : false);

    const storedShowSafetyZones = localStorage.getItem("operationalMap:safetyZones");
    const [showSafetyZones, setShowSafetyZones] = useState(storedShowSafetyZones ? JSON.parse(storedShowSafetyZones) : false);

    const [trackingUser, setTrackingUser] = useState<UserDataTypes | undefined>();
    const [trackingUserToken, setTrackingUserToken] = useState<string | null>(null)

    const [trackingPoints, setTrackingPoints] = useState<UserLocationHistory[]>([])

    const [center, setCenter] = useState<google.maps.LatLngLiteral>({lat: 0, lng: 0})
    const [bounds, setBounds] = useState<google.maps.LatLngBoundsLiteral>()
    const [zoom, setZoom] = useState(3)
    const [mapTypeID, setMapTypeID] = useState<MapTypeID | null>(null)

    const [selectedOverlays, setSelectedOverlays] = useState<Overlay[]>([]);

    const map = useMap("operational-map");
    const drawing = useMapsLibrary("drawing");

    const [downloadUsers, setDownloadUsers] = useState<UserDataTypes[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [showDownload, setShowDownload] = useState<boolean>(false);

    const [currentShape, setCurrentShape] = useState<null | google.maps.Circle | google.maps.Polygon>(null);

    const drawingShapeOptions = useMemo(() => {
      return {
        editable: false,
        strokeColor: "#000",
        strokeOpacity: 0.55,
        strokeWeight: 2,
        fillColor: "#000",
        fillOpacity: 0.2,
      }
    }, [])



    const drawingManager = useMemo<google.maps.drawing.DrawingManager | null>(() => {
        if (!drawing) {
            return null
        }

        if (!map) {
            return null
        }


        const dm = new drawing.DrawingManager({
            map,
            drawingMode: null,
            drawingControl: false,
            circleOptions: drawingShapeOptions,
            polygonOptions: drawingShapeOptions
        });

        google.maps.event.addListener(dm, "circlecomplete", (circle: google.maps.Circle) => {


            dm.setDrawingMode(null)

            if (circle) {
                setCurrentShape(circle)
                downloadCircleRef.current(circle)
            }
        });

        google.maps.event.addListener(dm, "polygoncomplete", (polygon: google.maps.Polygon) => {

            dm.setDrawingMode(null)

            if (polygon) {
                setCurrentShape(polygon)
                downloadPolygonRef.current(polygon)
            }

        })


        return dm
    }, [drawing, map])

    const handleShowDownload = useCallback((show: boolean) => {
        if (map && !show) {
            map.setOptions({gestureHandling: "auto"})
        }


        if (!show && currentShape) {
            currentShape.setMap(null)
        }

        setShowDownload(show)
    }, [currentShape])


    const downloadCSV = useCallback(() => {


        const headers = [
            "First Name",
            "MiddleNames",
            "Last Name",
            "Client",
            "Department",
            "Latitude",
            "Longitude",
            "Time",
            "Home Facility",
            "Stationed Facility",
            "Email"
        ]

      const phoneNumberCount = Math.max(...downloadUsers.map(u => u.phoneNumbers.length), 1)

      if(phoneNumberCount == 1){
        headers.push("Phone Number")
      } else {
        for(let i = 0; i < phoneNumberCount; i++){
          headers.push(`Phone Number ${i + 1}`)
        }
      }

        downloadUsers.forEach((u) => {
            u.metadata.forEach(({label}) => {
                if (!headers.includes(label)) {
                    headers.push(label)
                }
            })

        })

      const phoneNumbers = (u: UserDataTypes) => {
        if (!u.phoneNumbers) return "";
        if (u.phoneNumbers.length == 0) return "";

        return u.phoneNumbers.map(p => p.number)
      }

        const getUserRow = (u: UserDataTypes) => {
            const data = [
                u.profile.firstName || "",
                u.profile.middleNames || "",
                u.profile.lastName || "",
                u.clientName,
                u.departmentName,
                u.lastKnownLocation.position.coordinates[1],
                u.lastKnownLocation.position.coordinates[0],
                moment(u.lastKnownLocation.receivedAt).format("YYYY-MM-DD HH:mm:ss Z"),
                u.homeFacilityName || "",
                u.stationedFacilityName || "",
                u.email,
                ...phoneNumbers(u),

            ]

            u.metadata.forEach(({label, value}) => {
                const index = headers.indexOf(label)
                data[index] = value
            })

            return data
        }



        const rows = downloadUsers.map(getUserRow);

        const data = [
            headers,
            ...rows
        ]

        const ws = XLSX.utils.aoa_to_sheet(data);


        ws["!cols"] = data[0].map((_, colIndex) => ({
            wch: Math.max(...data.map((row) => (row[colIndex] ? row[colIndex].toString().length : 10))) + 2,
        }));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Users");


        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});

        const fileBlob = new Blob([excelBuffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        saveAs(fileBlob, `map-users-${moment().unix()}.xlsx`);

        handleShowDownload(false)
        if(currentShape){
          currentShape.setMap(null)
        }

    }, [handleShowDownload, currentShape, downloadUsers])


    const drawCircle = useCallback(() => {

        if (!drawingManager) return
        setShowSelectionMenu(false)
        drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE)

        if (!map) return
        map.setOptions({gestureHandling: "none"})

    }, [drawingManager, setShowSelectionMenu])

    const downloadPolygon = useCallback(async (polygon: google.maps.Polygon) => {

        setLoading(true)
        setShowDownload(true)

        if (!polygon) return

        const path = polygon.getPath();
        const points = path.getArray().map((latLng: google.maps.LatLng) => {
            return `${latLng.lat()},${latLng.lng()}`;
        }).join(";");



        const res = await apiV2.get<UserDataTypes[]>("map/users/get-polygon", {
            params: {
                clientID: client?.id,
                polygon: points,
                sources: selectedUserLocationTypes.join(",")
            }
        })

        setLoading(false)

        if (!res.data) return

        setDownloadUsers(res.data)
    }, [client])



    const downloadCircle = useCallback(async (circle: google.maps.Circle) => {


        console.log("CIRCLE",{client})
        setLoading(true)
        setShowDownload(true)

        if (!circle) return

        const pos = circle.getCenter()

        if (!pos) return

        const {lat, lng} = pos
        const radius = circle.getRadius()

        const res = await apiV2.get<UserDataTypes[]>("map/users/get-radius", {
            params: {
                clientID: client?.id,
                lat: lat(),
                lng: lng(),
                radius,
                sources: selectedUserLocationTypes.join(",")
            }
        })


        setLoading(false)

        if (!res.data) return

        setDownloadUsers(res.data)


    }, [client])

    const downloadCircleRef = useRef(downloadCircle);
    const downloadPolygonRef = useRef(downloadPolygon);

    useEffect(() => {
        downloadCircleRef.current = downloadCircle;
    }, [downloadCircle]);

    useEffect(() => {
        downloadPolygonRef.current = downloadPolygon;
    }, [downloadPolygon]);




    const drawPolygon = useCallback(() => {
        if (!drawingManager) return
        setShowSelectionMenu(false)
        drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON)
        if (!map) return
    }, [drawingManager, setDrawingMode])


    const fetchOverlays = async (page = 1): Promise<PaginatedResponse<Overlay>> => {
        const params = {
            clientID: client ? client.id : null,
            page,
            rpp: 200
        }
        const result = await api.get<PaginatedResponse<Overlay>>("map-overlays", {params})

        return result.data
    }

    const overlayQuery = useInfiniteQuery(
        ["operational-map-overlays", client],
        ({pageParam = 1}) => fetchOverlays(pageParam),
        {
            getNextPageParam: (lastPage, allPages) => lastPage.hasMore ? allPages.length + 1 : undefined,
        }
    )

    useEffect(() => {
        if (overlayQuery.hasNextPage && !overlayQuery.isFetchingNextPage) {
            overlayQuery.fetchNextPage();
        }
    }, [overlayQuery.hasNextPage, overlayQuery.isFetchingNextPage, overlayQuery.fetchNextPage]);


    const fetchUsersLocations = async () => {
        // console.log("fetching user locations")
        const locations = await api.get<UsersLocation[]>(
            "ops/user-locations-clustered",
            {
                params: {
                    sources: selectedUserLocationTypes.join(","),
                    clientID: client ? client.id : null,
                },
            })

        const users: UserLocation[] = []

        locations.data.forEach((l) => {
            const key = l.users.map(u => u._id).join("_")
            users.push({
                key,
                lat: l.id[1],
                lng: l.id[0],
                multiple: l.users.length > 1,
                users: l.users,
            })
        })

        return users

    };

    const userQuery = useQuery(
        [
            "operational-map-users",
            client,
            selectedUserLocationTypes,
        ],
        fetchUsersLocations,
        {
            refetchInterval: 60000,
        }
    )

    const fetchIncidents = async (page: number): Promise<PaginatedResponse<IncidentDataTypes>> => {
        console.log("fetching incidents", page)

        if (selectedIncidentLevels.length == 0) {
            return {
                hasMore: false,
                items: [],
                page: 1,
                total: 0,
            }
        }

        const rpp = 200
        const startTime = moment().subtract(1, "week").toISOString()
        const endTime = moment().add(1, "week").toISOString()
        const severity = selectedIncidentLevels.join(",")

        const params = {
            rpp,
            startTime,
            endTime,
            severity
        }

        const response = await api.get<PaginatedResponse<IncidentDataTypes>>(`/incidents?page=${page}`, {params})
        return response.data;
    }

    const incidentQuery = useInfiniteQuery(
        ["operational-map-incidents", selectedIncidentLevels],
        ({pageParam = 1}) => fetchIncidents(pageParam),
        {
            getNextPageParam: (lastPage, allPages) => lastPage.hasMore ? allPages.length + 1 : undefined,
        }
    )

    useEffect(() => {
        if (incidentQuery.hasNextPage && !incidentQuery.isFetchingNextPage) {
            incidentQuery.fetchNextPage();
        }
    }, [incidentQuery.hasNextPage, incidentQuery.isFetchingNextPage, incidentQuery.fetchNextPage]);


    const fetchFacilities = async (page: number): Promise<PaginatedResponse<FacilityDataTypes>> => {

        console.log("fetching facilities", page)

        if (!showFacilities) {
            return emptyPaginatedResult;
        }

        const params = {
            clientID: client ? client.id : null,
            rpp: 200
        }

        const response = await api.get<PaginatedResponse<FacilityDataTypes>>(`/facilities?page=${page}`, {params})
        return response.data
    };

    const facilityQuery = useInfiniteQuery(
        ["operational-map-facilities", showFacilities],
        ({pageParam = 1}) => fetchFacilities(pageParam),
        {
            getNextPageParam: (lastPage, allPages) => lastPage.hasMore ? allPages.length + 1 : undefined,
        }
    )

    useEffect(() => {
        if (facilityQuery.hasNextPage && !facilityQuery.isFetchingNextPage) {
            facilityQuery.fetchNextPage();
        }
    }, [facilityQuery.hasNextPage, facilityQuery.isFetchingNextPage, facilityQuery.fetchNextPage]);

    const fetchGeofences = async (page: number): Promise<PaginatedResponse<Geofences>> => {

        console.log("fetching geofences", page)

        if (!showGeofences) {
            return emptyPaginatedResult;
        }

        const params = {
            clientID: client ? client.id : null,
            rpp: 200
        }

        const response = await api.get<PaginatedResponse<Geofences>>(`/geofences?page=${page}`, {params})
        return response.data
    };

    const geofenceQuery = useInfiniteQuery(
        ["operational-map-geofences", showGeofences],
        ({pageParam = 1}) => fetchGeofences(pageParam),
        {
            getNextPageParam: (lastPage, allPages) => lastPage.hasMore ? allPages.length + 1 : undefined,
        }
    )

    useEffect(() => {
        if (geofenceQuery.hasNextPage && !geofenceQuery.isFetchingNextPage) {
            geofenceQuery.fetchNextPage();
        }
    }, [geofenceQuery.hasNextPage, geofenceQuery.isFetchingNextPage, geofenceQuery.fetchNextPage]);

    const fetchSafetyZones = async (page: number): Promise<PaginatedResponse<SafetyZones>> => {

        console.log("fetching safetyZones", page)

        if (!showSafetyZones) {
            return emptyPaginatedResult;
        }
        const params = {
            clientID: client ? client.id : null,
            rpp: 200
        }

        const response = await api.get<PaginatedResponse<SafetyZones>>(`/safety-zones?page=${page}`, {params})
        return response.data
    };

    const safetyZoneQuery = useInfiniteQuery(
        ["operational-map-safetyZones", showSafetyZones],
        ({pageParam = 1}) => fetchSafetyZones(pageParam),
        {
            getNextPageParam: (lastPage, allPages) => lastPage.hasMore ? allPages.length + 1 : undefined,
        }
    )

    useEffect(() => {
        if (safetyZoneQuery.hasNextPage && !safetyZoneQuery.isFetchingNextPage) {
            safetyZoneQuery.fetchNextPage();
        }
    }, [safetyZoneQuery.hasNextPage, safetyZoneQuery.isFetchingNextPage, safetyZoneQuery.fetchNextPage]);


    const fetchTrips = async (): Promise<WorldSearchRecord[]> => {
        console.log("fetching trips");
        const subtypes = []
        if (selectedTripTypes.includes("travel")) {
            subtypes.push(...travelSubTypes)
        }

        if (selectedTripTypes.includes("accommodation")) {
            subtypes.push(...accommodationSubTypes)
        }

        if (subtypes.length == 0) {
            return []
        }

        const params = {
            clientID: client ? client.id : null,
            subtypes: subtypes.join(","),
        }

        const response = await apiV2.get<WorldSearchRecord[]>("/map/world-search", {params})

        return response.data
    }

    const tripQuery = useQuery(
        ["operational-map-trips", client, selectedTripTypes],
        fetchTrips,
    )


    const handleSetUserLocationTypes = (v: UserLocationType[]) => {
        localStorage.setItem("operationalMap:user", JSON.stringify(v))
        setSelectedUserLocationTypes(v);
    }

    const handleSetIncidentLevels = (v: IncidentLevel[]) => {
        localStorage.setItem("operationalMap:incidentLevel", JSON.stringify(v))
        setSelectedIncidentLevels(v);
    }

    const handleSetTripTypes = (v: TripType[]) => {
        localStorage.setItem("operationalMap:trip", JSON.stringify(v))
        setSelectedTripTypes(v);
    }


    const handleSetShowFacilities = (v: boolean) => {
        localStorage.setItem("operationalMap:facilities", JSON.stringify(v))
        setShowFacilities(v);
    }

    const handleSetShowGeofences = (v: boolean) => {
        localStorage.setItem("operationalMap:geofences", JSON.stringify(v))
        setShowGeofences(v);
    }
    const handleSetShowSafetyZones = (v: boolean) => {
        localStorage.setItem("operationalMap:safetyZones", JSON.stringify(v))
        setShowSafetyZones(v);
    }

    const handleSetTrackingUser = (u: UserDataTypes | undefined) => {
        setTrackingPoints([])
        if (u) {
            setTrackingUserToken(`${u.id}_${generateRandomToken(12)}`)
        }
        setTrackingUser(u)
    }

    const handleSetShowIncidentRadius = (showIncidentRadius: boolean) => {
        localStorage.setItem("operationalMap:showIncidentRadius", JSON.stringify(showIncidentRadius))
        setShowIncidentRadius(showIncidentRadius)
    }

    const fetchUserTracking = async (): Promise<UserLocationHistory[]> => {
        // console.log("fetching user tracking");

        if (!trackingUser) {
            // console.log("no user selected to track")
            return []
        }

        const params = {
            token: trackingUserToken
        }

        const response = await api.get<UserLocationHistory[]>(`ops/user-location-feed/${trackingUser.id}`, {params})

        const result = [...trackingPoints, ...response.data]

        if (response.data.length) {
            setTrackingPoints(result)
            const last = result[result.length - 1]

            if (last) {
                setCenter({
                    lat: last.coordinates[1],
                    lng: last.coordinates[0],
                })

                setZoom(18)
            }
        }
        return result

    }

    const trackingPointsQuery = useQuery(
        ["operational-map-tracking", trackingUser],
        fetchUserTracking,
        {
            refetchInterval: 5000,
        }
    )



    return <OperationalMapContext.Provider value={{
        showFilters,
        setShowFilters,
        showTrackingMenu,
        setShowTrackingMenu,
        client,
        setClient,
        userQuery,
        tripQuery,
        incidentQuery,
        facilityQuery,
        safetyZoneQuery,
        geofenceQuery,
        visibleInfoWindow,
        setVisibleInfoWindow,
        availableUserLocationTypes,
        selectedUserLocationTypes,
        setSelectedUserLocationTypes: handleSetUserLocationTypes,
        selectedIncidentLevels,
        setSelectedIncidentLevels: handleSetIncidentLevels,
        selectedTripTypes,
        setSelectedTripTypes: handleSetTripTypes,
        showFacilities,
        setShowFacilities: handleSetShowFacilities,
        showGeofences,
        setShowGeofences: handleSetShowGeofences,
        showSafetyZones,
        setShowSafetyZones: handleSetShowSafetyZones,
        trackingUser,
        setTrackingUser: handleSetTrackingUser,
        trackingPointsQuery,
        zoom,
        setZoom,
        center,
        setCenter,
        bounds,
        setBounds,
        overlayQuery,
        selectedOverlays,
        setSelectedOverlays,
        mapTypeID,
        setMapTypeID,
        showIncidentRadius: showIncidentRadius,
        setShowIncidentRadius: handleSetShowIncidentRadius,
        drawingMode,
        setDrawingMode,
        showSelectionMenu,
        setShowSelectionMenu,
        drawingManager,
        drawCircle,
        drawPolygon
    }}
    >
        <>
            <DownloadModal open={showDownload} setOpen={handleShowDownload} loading={loading} users={downloadUsers}
                           downloadFunc={downloadCSV}/>
            {props.children}

        </>
    </OperationalMapContext.Provider>
}

export const useOperationalMapContext = (): OperationalMapContextType => {
    const context = useContext(OperationalMapContext);
    if (!context) {
        throw new Error("useTimeZoneSelectContext must be used within a TimeZoneSelectProvider");
    }
    return context;
};


type DownloadModalProps = {
    users: UserDataTypes[]
    downloadFunc: () => void,
    open: boolean,
    setOpen: (open: boolean) => void,
    loading: boolean,
}

const DownloadModal: React.FC<DownloadModalProps> = (props) => {


    const userCount = useMemo(() => {
        return props.users.length
    }, [props.users])

    if (userCount == 0) {
        return (
            <Modal open={props.open} setOpen={props.setOpen}>
                <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                    <p className={"font-semibold"}>No Users Selected</p>
                    <BlueButton text={"Close"} onClick={() => props.setOpen(false)}/>
                </div>
            </Modal>
        )
    }

    if (props.loading) {
        return (
            <Modal open={props.open} setOpen={props.setOpen}>
                <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                    <LoadingSpinner height={32} width={32}/>
                </div>
            </Modal>
        )
    }


    return (

        <Modal open={props.open} setOpen={props.setOpen}>
            <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">

                <p className={"font-semibold"}>{userCount} Users Selected</p>
                <p>Please click to download </p>
                <BlueButton text={"Download"} onClick={props.downloadFunc}/>

            </div>

        </Modal>
    )

    return null


}
