import {useEffect, useState} from "react";
import useDebouncer from "../../../hooks/debouncer";
import {checkPassword} from "../lib";

export type PasswordFields = {
    password: string;
    setPassword: (password: string) => void;

    passwordVerification: string;
    setPasswordVerification: (password: string) => void;

    passwordIsValid: boolean;
    passwordErrorMessages: string[];
}


export const usePasswordState = (): PasswordFields => {
    const [password, setPassword] = useState(process.env.REACT_APP_DEBUG ? "Secret123!" : "");
    const [passwordVerification, setPasswordVerification] = useState(process.env.REACT_APP_DEBUG ? "Secret123!" : "");
    const [passwordErrorMessages, setPasswordErrorMessages] = useState<string[]>([]);

    const debouncedPassword = useDebouncer(password, 500);
    const debouncedPasswordVerification = useDebouncer(passwordVerification, 500);

    const [passwordIsValid, setPasswordIsValid] = useState(false);

    useEffect(() => {
        const [valid, messages] = checkPassword(debouncedPassword, debouncedPasswordVerification);
        setPasswordIsValid(valid);
        setPasswordErrorMessages(messages);
    }, [debouncedPassword, debouncedPasswordVerification]);


    return {
        password,
        setPassword,
        passwordVerification,
        setPasswordVerification,
        passwordErrorMessages,
        passwordIsValid

    };
}