import {useMemo} from "react";

export const usePrettyFileSize = (file: File | null) => {
    return useMemo(() => {

        if(!file) return ""

        const size = file.size
        if (size === 0) return "0 B";
        const i = Math.floor(Math.log(size) / Math.log(1024));
        const sizes = ["B", "KB", "MB", "GB", "TB"];
        return `${(size / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
    }, [file]);
};