import React, { useContext } from "react";
import { useClientDashboard } from "./ClientDashboardProvider";
import { UserContext, useAuth } from "../../../../../context/user";
import DashboardPageClientSelect from "./DashboardPageClientSelect";
import DashboardPageTwo from "./DashboardPageTwo";


export default function DashboardPageNew() {
    const {clientID} = useClientDashboard()
    const {isSuperAdmin} = useAuth();
     
    if (!clientID && isSuperAdmin) {
        return <DashboardPageClientSelect/>
    }

    return (

        <DashboardPageTwo/>
      );

}
