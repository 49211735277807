import React, {useContext} from "react";
import ManagementNavButton from "./ManagementNavButton";
import {useAuth, UserContext} from "../../../context/user";
import {
    UserManagementBrowsingProvider,
    useUserManagementBrowsing
} from "../../../lib/user-management/UserManagementBrowsingContext";

type LayoutProps = {
    active?: string;
    children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = (props) => {
    const userContext = useContext(UserContext);
    const auth = useAuth();
    const browsing = useUserManagementBrowsing()

    return (
            <div className="pt-36 px-7">
                <div className="flex flex-row gap-8 mb-8">
                    {auth.hasAccessLevel("department") &&
                        <div className="w-64 bg-white shadow-md p-4 gap-4 flex flex-col">
                            <p className="text-md text-center pb-2 font-semibold">Management</p>

                            {auth.isSuperAdmin &&
                                <ManagementNavButton active={props.active == "customisation"} text="Customisation"
                                                     href={"/management/customisation"}/>}

                            {auth.isSuperAdmin && <ManagementNavButton active={props.active == "clients"} text="Clients"
                                                                       href={"/management/clients"}/>}
                            <ManagementNavButton active={props.active == "departments"} text="Departments"
                                                 href={"/management/departments"}/>
                            <ManagementNavButton active={props.active == "users"} text="Users"
                                                 href={"/management/users"}/>
                            {/* {auth.isSuperAdmin && <ManagementNavButton active={props.active == "add-ons"} text="Add-ons" href={"/management/add-ons"}/>}*/}
                            {auth.hasAccessLevel("department") &&
                                <ManagementNavButton active={props.active == "invitations"} text="Invitations"
                                                     href={"/management/invitations"}/>}


                            {auth.isSuperAdmin &&
                                <ManagementNavButton active={props.active == "import"} text="Import"
                                                     href={"/management/import"}/>}
                        </div>}
                    <div className="flex-1">
                        {browsing.loaded && props.children}
                    </div>

                </div>
            </div>
    )

}

export default Layout;