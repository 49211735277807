import React, { ChangeEvent, useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { AutorenewRounded } from "@mui/icons-material";

const fullWeekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const shortWeekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const uiWeekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

type Recurrence = {
  startDate: string;
  selectedDays: string[];
  frequency: string;
  repeatEvery: number;
  repeatUnit: "day" | "week" | "month" | "year";
  endDate?: string;
  pattern?: string;
  monthlyYearlyDay: string
};

type RecurrencePickerProps = {
  recurrence: Recurrence;
  onChange: (updatedValues: Partial<Recurrence>) => void;
};

function getWeekdayOccurrence(dateStr: string): number {
  const date = new Date(dateStr);
  return Math.floor((date.getDate() - 1) / 7) + 1;
}

function countWeekdayInMonth(dateStr: string): number {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = date.getMonth();
  const weekday = date.getDay();
  let count = 0;
  const d = new Date(year, month, 1);
  while (d.getMonth() === month) {
    if (d.getDay() === weekday) count++;
    d.setDate(d.getDate() + 1);
  }
  return count;
}

export default function RecurrencePicker({ recurrence, onChange }: RecurrencePickerProps) {
  const { startDate, selectedDays, repeatEvery, repeatUnit, endDate, pattern } = recurrence;
  const [initialWeekday, setInitialWeekday] = useState<string | null>(null);

  useEffect(() => {
    if (startDate) {
      const dayIndex = new Date(startDate).getDay();
      setInitialWeekday(fullWeekdays[dayIndex]);
    }
  }, [startDate]);

  const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    onChange({ startDate: newStartDate });
    const dayIndex = new Date(newStartDate).getDay();
    onChange({ selectedDays: [fullWeekdays[dayIndex]] });
  };

  const handleDaySelection = (_: React.MouseEvent<HTMLElement>, newDays: string[]) => {
    if (!newDays.length) return;
    if (repeatUnit === "day" && repeatEvery > 1) return;
    const selectedFullNames = newDays.map(day => fullWeekdays[shortWeekdays.indexOf(day)]);
    onChange({ selectedDays: selectedFullNames });
    if (repeatUnit === "day" && selectedFullNames.length < 7) {
      onChange({ repeatUnit: "week", frequency: "custom" });
    }
  };

  const handleRepeatUnitChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as "day" | "week" | "month" | "year";
    onChange({ repeatUnit: value });
    if (value === "day") {
      onChange({ selectedDays: [...fullWeekdays], pattern: undefined });
    } else if (value === "week" && initialWeekday) {
      onChange({ selectedDays: [initialWeekday], pattern: undefined });
    } else if (value === "month" || value === "year") {
      onChange({ pattern: "byDate" });
    }
  };

  

  const renderMonthlyYearlyOptions = () => {
    if (!(repeatUnit === "month" || repeatUnit === "year") || !startDate) return null;
    
    const start = new Date(startDate);
    const day = start.getDate();
    const weekday = fullWeekdays[start.getDay()];
    const monthName = start.toLocaleString("default", { month: "long" });
    const occurrence = getWeekdayOccurrence(startDate);
    const totalOccurrences = countWeekdayInMonth(startDate);
    const ordinalMap = ["first", "second", "third", "fourth", "last"];
    let ordinal = ordinalMap[occurrence - 1];
    if (occurrence === totalOccurrences) {
      ordinal = "last";
    }
    const showFourthOption = totalOccurrences === 5 && occurrence === 4;
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPattern = e.target.value as "byDate" | "byOrdinal" | "byLast";
      let monthlyYearlyDay = "";
      if (newPattern === "byDate") {
        monthlyYearlyDay = `${day}`;
      } else if (newPattern === "byOrdinal") {
        monthlyYearlyDay = `${ordinal}${weekday}`;
      } else if (newPattern === "byLast") {
        monthlyYearlyDay = `last${weekday}`;
      }
      
      onChange({ pattern: newPattern, monthlyYearlyDay });
    };
  
    return (
      <RadioGroup row value={pattern || "byDate"} onChange={handleChange} sx={{ fontSize: "0.8rem" }}>
      <FormControlLabel
        value="byDate"
        control={<Radio size="small" sx={{ transform: "scale(0.8)" }} />}
        label={
          <span style={{ fontSize: "0.8rem" }}>
            {repeatUnit === "month" ? `On day ${day}` : `On ${monthName} ${day}`}
          </span>
        }
      />
        <FormControlLabel
          value="byOrdinal"
          control={<Radio size="small" sx={{ transform: "scale(0.8)" }} />}
          label={
            <span style={{ fontSize: "0.8rem" }}>
              {repeatUnit === "month"
                ? `On the ${ordinal} ${weekday}`
                : `On the ${ordinal} ${weekday} of ${monthName}`}
            </span>
          }
        />
        {showFourthOption && (
          <FormControlLabel
            value="byLast"
            control={<Radio size="small" sx={{ transform: "scale(0.8)" }} />}
            label={
              <span style={{ fontSize: "0.8rem" }}>
                {repeatUnit === "month" ? `On the last ${weekday}` : `On the last ${weekday} of ${monthName}`}
              </span>
            }
          />
        )}
      </RadioGroup>
    );
  };
  
  

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">Start Date</label>
        <input
          type="date"
          className="w-full border border-gray-300 p-1 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none text-sm"
          value={startDate}
          onChange={handleStartDateChange}
        />
      </div>
      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">Repeat</label>
        <div className="flex items-center gap-1 text-xs">
          <AutorenewRounded sx={{ fontSize: 18, color: "gray.500" }} />
          <span>Every</span>
          <select
            className="border border-gray-300 p-1 rounded-md focus:ring-2 focus:ring-blue-500 text-sm"
            value={repeatEvery}
            onChange={(e) => onChange({ repeatEvery: parseInt(e.target.value) })}
          >
            {Array.from({ length: 99 }, (_, i) => i + 1).map(num => (
              <option key={num} value={num}>{num}</option>
            ))}
          </select>
          <select
            className="border border-gray-300 p-1 rounded-md focus:ring-2 focus:ring-blue-500 text-sm"
            value={repeatUnit}
            onChange={handleRepeatUnitChange}
          >
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select>
        </div>
      </div>
      {(repeatUnit === "week" || (repeatUnit === "day" && repeatEvery === 1)) && (
        <ToggleButtonGroup
          value={selectedDays.map(day => shortWeekdays[fullWeekdays.indexOf(day)])}
          onChange={handleDaySelection}
          exclusive={false}
          className="flex flex-wrap justify-center gap-2"
          sx={{
            "& .MuiToggleButtonGroup-grouped": {
              border: "1px solid #d1d5db !important",
              margin: "0 !important",
              padding: "0 !important",
            },
            "& .MuiToggleButton-root": {
              minWidth: "30px !important",
              minHeight: "30px !important",
              fontSize: "0.7rem !important",
              width: "35px !important",
              height: "35px !important",
              borderRadius: "50% !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              lineHeight: "1 !important",
              transition: "all 0.2s ease-in-out",
              color: "#374151",
              backgroundColor: "white",
              "&:hover": { backgroundColor: "#e5e7eb" },
              "&.Mui-selected": { backgroundColor: "#3b82f6 !important", color: "white !important" },
              "&.Mui-selected:hover": { backgroundColor: "#2563eb !important" },
            },
          }}
        >
          {uiWeekdays.map(day => (
            <ToggleButton key={day} value={day}>
              {day}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
      {(repeatUnit === "month" || repeatUnit === "year") && renderMonthlyYearlyOptions()}
      <div>
        <div className="mt-4 text-sm p-2 bg-gray-100 rounded-md">
          <p className="text-sm">
            <strong>Frequency:</strong>{" "}
            {repeatEvery === 1
              ? `Every ${repeatUnit}`
              : `Every ${repeatEvery} ${repeatUnit}s`}
          </p>
          {repeatUnit === "week" && (
            <p className="text-sm"><strong>Days:</strong> {selectedDays.length ? selectedDays.join(", ") : "None"}</p>
          )}
        <p className="text-normal mt-1">
          <span
            className="text-blue underline cursor-pointer"
            onClick={() =>
              onChange({ endDate: endDate !== undefined ? undefined : "" })
            }
          >
            {endDate !== undefined ? "Remove End Date" : "Choose an End Date"}
          </span>
        </p>
        </div>
        {endDate !== undefined && (
          <div>
            <label className="block text-sm text-gray-700 mb-2">End Date</label>
            <input
              type="date"
              className="w-full border border-gray-300 p-1.5 text-xs rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
              value={endDate || ""}
              onChange={(e) => onChange({ endDate: e.target.value })}
            />
          </div>
        )}
      </div>
    </div>
  );
}
