import React, {createContext, useContext, useState, ReactNode, useCallback, useEffect} from "react";
import {PasswordFields, usePasswordState} from "./states/password";
import {setUserFields, UserFields, useUserState} from "./states/user";
import {useParams} from "react-router-dom";
import {apiV2} from "../../api/axiosConfig";
import {isAxiosError} from "axios";
import {isValidDate} from "rxjs/internal/util/isDate";




type UserActivationContextType = {
    step: number;
    setStep: (step: number) => void;
    passwordNextStep: () => void;
    loading: boolean;
    loadError: string;
    saving: boolean;
    savingError: string;
    submit: () => void;

} & PasswordFields & UserFields;

const UserActivationContext = createContext<UserActivationContextType | undefined>(undefined);




export const UserActivationProvider = ({ children }: { children: ReactNode }) => {


    const {token} = useParams();

    const [step, setStep] = useState(1);

    const passwordState = usePasswordState();
    const userState = useUserState();

    const [loadError, setLoadError] = useState<string>("");
    const[loading, setLoading] = useState(true)

    const [saving, setSaving] = useState(false)
    const [savingError, setSavingError] = useState("")

    const passwordNextStep = useCallback(() => {
        setStep(2)
    }, [passwordState.passwordIsValid]);

    const loadToken = useCallback(async () => {
        try {
            const res = await apiV2.get(`user-activation/${token}`)
            setUserFields(res.data, userState)
        } catch (e) {
            if(isAxiosError(e) && e.response?.data?.error) {
                setLoadError(e.response?.data?.error)
            } else {
                setLoadError("error loading token");
            }
        }

        setLoading(false);


    }, [token])


    const submit = async () => {

        setSaving(true)
        setSavingError("")

        try {

            await apiV2.post(`user-activation/${token}`, {
                password: passwordState.password,
                email: userState.email,
                firstName: userState.firstName,
                middleName : userState.middleName,
                lastName: userState.lastName,
                dateOfBirth: isValidDate(userState.dateOfBirth) ? userState.dateOfBirth : null,
                gender: userState.gender,
                genderAtBirth: userState.genderAtBirth,
                mainLanguage: userState.mainLanguage,
                secondaryLanguages: userState.secondaryLanguages,
                nationality: userState.nationality,
                countryISO: userState.countryISO,
                passports: userState.passports,
                emergencyContacts: userState.emergencyContacts,
                phone: userState.phone,
                additionalPhones: userState.secondaryPhones,
                additionalEmails: userState.secondaryEmails
            })

            setStep(3)



        } catch(e) {
            if(isAxiosError(e) && e.response?.data?.error) {
                setSavingError(e.response?.data?.error)
            } else {
                setSavingError("error activating account");
            }
        }

        setSaving(false)
    }

    useEffect(() => {
        loadToken();
    }, [token]);




    return (
        <UserActivationContext.Provider value={{
            step, setStep,
            ...passwordState,
            passwordNextStep,
            ...userState,
            loadError,
            loading,
            savingError,
            saving,
            submit

        }}>
            {children}
        </UserActivationContext.Provider>
    );
};

export const useUserActivation = () => {
    const context = useContext(UserActivationContext);
    if (!context) {
        throw new Error("useUserActivation must be used within a UserActivationProvider");
    }
    return context;
};