import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import App from "./App";
import "./index.css";

import { Auth, Login, Logout, ProtectedRoute } from "./auth";

import OperationalMap from "./pages/OperationalMap/index";

import WorldSearch from "./pages/WorldSearch";


import {
  AdviceGuides,
  AirlineRiskMatrix,
  AllCountryProfiles,
  CountryProfile,
  CountryProfiles,
  GlobalRiskMap,
  Home as RiskHome,
  SecurityAssessments,
} from "./pages/risk";


import {
  AddNewClient,
  Client,
  ClientUsers,
  RolesList,
  Home as UserManagementHome,
} from "./pages/user-management";

import UserProfile from "./pages/UserProfile";
// Pages --- Trips
import AllTripsPage from "./pages/Trips/AllTripsPage";
import IndividualTripPage from "./pages/Trips/IndividualTrip";
import UpcomingCheckInsPage from "./pages/Trips/UpcomingCheckIns";

// Pages ––- Sites
import {
  SitesAddCustomOverlayPage,
  SitesAddZone,
  SitesCustomOverlayPage,
  SitesEditCustomOverlay,
  SitesEditZone,
  SitesFacilitiesPage,
  SitesHomePage,
  SitesOutlet,
  SitesZonePage,
} from "./pages/Sites";

// Pages --- Settings
import Settings from "./pages/Settings";

import { OidcConfiguration, OidcProvider } from "@axa-fr/react-oidc";

// Pages --- Reports
import ClientsContextProvider from "./context/clients";
import AdviceGuidesEmergencySection from "./features/risk/pages/advice-guides/sections/emergency";
import AdviceGuidesMedicalSection from "./features/risk/pages/advice-guides/sections/medical";
import AdviceGuidesNaturalHazardsSection from "./features/risk/pages/advice-guides/sections/natural-hazards";
import AdviceGuidesTravelSection from "./features/risk/pages/advice-guides/sections/travel";
import CountryProfilesContextProvider from "./features/risk/pages/country-profiles/context/country-profiles";
import CountryProfileSection from "./features/risk/pages/country-profiles/sections/country-profille";
import CountryProfileCrimeSection from "./features/risk/pages/country-profiles/sections/crime";
import CountryProfileCulturalConsiderationSection from "./features/risk/pages/country-profiles/sections/cultural-consideration";
import CountryProfileMapSection from "./features/risk/pages/country-profiles/sections/map";
import CountryProfileMedicalSection from "./features/risk/pages/country-profiles/sections/medical";
import CountryProfileNaturalHazardsSection from "./features/risk/pages/country-profiles/sections/natural-hazards";
import CountryProfileOperationalSection from "./features/risk/pages/country-profiles/sections/operational";
import CountryProfileOverviewSection from "./features/risk/pages/country-profiles/sections/overview";
import CountryProfilePoliticalSection from "./features/risk/pages/country-profiles/sections/political";
import CountryProfileRegionalInfoSection from "./features/risk/pages/country-profiles/sections/regional-information";
import CountryProfileSecurityAdviceSection from "./features/risk/pages/country-profiles/sections/security-advice";
import CountryProfileTerrorismSection from "./features/risk/pages/country-profiles/sections/terrorism";
import CountryProfileTravelSection from "./features/risk/pages/country-profiles/sections/travel";
import ClientUsersContextProvider from "./features/user-management/context/client-users";
import UserProfileContextProvider from "./features/user-management/pages/user-profile/context/user-profile";
import Reports from "./pages/Reports";
import ReportsDataProvider from "./pages/Reports/ReportsDataProvider";
// import { ErrorBoundary } from "react-error-boundary";
import Authenticating from "./auth/authenticating";
import Error from "./auth/error";
import Loading from "./auth/loading";
import AdviceGuidesContextProvider from "./features/risk/pages/advice-guides/context/advice-guides";
import ClientContextProvider from "./features/user-management/context/client";
import PaginatedClientsContextProvider from "./features/user-management/context/clients";
import AddClient from "./features/user-management/pages/add-client/form/add-client";
import AddDepartments from "./features/user-management/pages/add-client/form/add-departments";
import AddUsers from "./features/user-management/pages/add-client/form/add-users";
import ViewClient from "./features/user-management/pages/client-details/form/view-client";
import ViewDepartments from "./features/user-management/pages/client-details/form/view-departments";
import { CheckInConfiguration } from "./pages/CheckInConfiguration";
import MessageLog from "./pages/Reports/messageProvider";
import CreateNewReportDoc from "./pages/Reports/pages/Summary/components/CreateNewReportDoc";
import DashboardPageNew from "./pages/Reports/pages/Summary/components/DashboardPageNew";
import ReportFileDownload from "./pages/Reports/pages/Summary/components/ReportFileDownload";
import ReportsPage from "./pages/Reports/pages/Summary/components/ReportsPage";
import SummaryPage from "./pages/Reports/pages/Summary/components/SummaryPage";
import IncidentLog from "./pages/RiskV2/IncidentLog";
import AirlineRiskUpload from "./pages/Settings/components/airline-risk-matrix";
import { AppCompliance } from "./pages/Settings/components/app-compliance";
import SetBannerAlert from "./pages/Settings/components/banner-alert";
import CustomEmailsRecipients from "./pages/Settings/components/custom-emails-recipients";
import CustomProfiles from "./pages/Settings/pages/CustomProfiles";
import TripCreate from "./pages/TripsV2/create";
import TripEdit from "./pages/TripsV2/edit";
import LocationWatcher from "./util/LocationWatcher";
import ProtectedRouteClient from "./util/ProtectedRouteClient";
import { ClientDashboardProvider } from "./pages/Reports/pages/Summary/components/ClientDashboardProvider";
import ClientList from "./features/management-v2/clients/ClientList";
import UserList from "./features/management-v2/users/UserList";
import DepartmentList from "./features/management-v2/departments/DepartmentList";
import DepartmentCreate from "./features/management-v2/departments/DepartmentCreate";
import DepartmentEdit from "./features/management-v2/departments/DepartmentEdit";
import {DepartmentsManagementProvider} from "./features/management-v2/departments/DepartmentsManagementContext";
import DepartmentGuardians from "./features/management-v2/departments/DepartmentGuardians";
import {ClientsManagementProvider} from "./features/management-v2/clients/ClientsManagementContext";
import ClientCreate from "./features/management-v2/clients/ClientCreate";
import ClientEdit from "./features/management-v2/clients/ClientEdit";
import {UsersManagementProvider} from "./features/management-v2/users/UsersManagementContext";
import UserCreate from "./features/management-v2/users/UserCreate";
import UserEdit from "./features/management-v2/users/UserEdit";
import {UserActivationPage} from "./pages/user-activation/UserActivationPage";
import {UserActivationProvider} from "./pages/user-activation/UserActivationContext";
import InvitationList from "./features/management-v2/invitations/InvitationList";
import {InvitationsManagementProvider} from "./features/management-v2/invitations/InvitationsManagementContext";
import {ClientCustomisation} from "./features/management-v2/ClientCustomisation";
import {PasswordResetProvider} from "./pages/reset-password/PasswordResetContext";
import {ResetPasswordPage} from "./pages/reset-password/ResetPasswordPage";
import {UserManagementBrowsingProvider} from "./lib/user-management/UserManagementBrowsingContext";
import {ImportPage} from "./features/management-v2/import/ImportPage";
import {ImportContextProvider} from "./features/management-v2/import/ImportContextProvider";
import {ManagementRoutes} from "./routes/ManagementRoutes";


const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


const configuration: OidcConfiguration = {
  client_id: process.env.REACT_APP_OAUTH2_CLIENT_ID || "",
  redirect_uri: window.location.origin + "/authentication/callback",
  silent_redirect_uri:
    window.location.origin + "/authentication/silent-callback",
  scope: "openid profile email offline_access", // offline_access scope allow your client to retrieve the refresh_token
  service_worker_relative_url: "/OidcServiceWorker.js",
  authority: process.env.REACT_APP_OAUTH2_AUTHORITY || "",
  service_worker_only: false,
};

// Inside DashboardClientProviderWrapper

root.render(
  <React.StrictMode>
    <OidcProvider
      configuration={configuration}
      authenticatingComponent={Loading}
      loadingComponent={Authenticating}
      authenticatingErrorComponent={Error}
    >
      <QueryClientProvider client={queryClient}>


        <Router>
          <LocationWatcher />

          <Routes>
            <Route path="" element={<Auth />}>
              <Route path="" element={<Login />} />
            </Route>
            <Route
              path="logout"
              element={
                <ProtectedRoute>
                  <Logout />
                </ProtectedRoute>
              }
            />

            <Route path="/" element={<App />}>
              <Route
                path="operational-map"
                element={
                  <ProtectedRoute>
                    <ClientsContextProvider>
                      <OperationalMap />
                    </ClientsContextProvider>
                  </ProtectedRoute>
                }
              />
              {
                // temp
              }
              <Route
                path="world-search"
                element={
                  <ProtectedRoute>
                    <WorldSearch />
                  </ProtectedRoute>
                }
              />
              <Route
                path="risk"
                element={
                  <ProtectedRoute>
                    <RiskHome />
                  </ProtectedRoute>
                }
              >
                <Route path="global-incident-map" element={<GlobalRiskMap />} />

                <Route
                  path="country-profiles"
                  element={
                    <CountryProfilesContextProvider>
                      <CountryProfiles />
                    </CountryProfilesContextProvider>
                  }
                >
                  <Route path="" element={<AllCountryProfiles />} />

                  <Route path=":country_iso" element={<CountryProfile />}>
                    <Route path="" element={<CountryProfileSection />}>
                      <Route
                        path="overview"
                        element={<CountryProfileOverviewSection />}
                      />
                      <Route
                        path="political"
                        element={<CountryProfilePoliticalSection />}
                      />
                      <Route
                        path="operational"
                        element={<CountryProfileOperationalSection />}
                      />
                      <Route
                        path="personal-security"
                        element={<CountryProfileCrimeSection />}
                      />
                      <Route
                        path="terrorism"
                        element={<CountryProfileTerrorismSection />}
                      />
                      <Route
                        path="travel"
                        element={<CountryProfileTravelSection />}
                      />
                      <Route
                        path="medical"
                        element={<CountryProfileMedicalSection />}
                      />
                      <Route
                        path="regional-information"
                        element={<CountryProfileRegionalInfoSection />}
                      />
                      <Route
                        path="cultural-considerations"
                        element={<CountryProfileCulturalConsiderationSection />}
                      />
                      <Route
                        path="natural-hazards"
                        element={<CountryProfileNaturalHazardsSection />}
                      />
                      <Route
                        path="security-advice"
                        element={<CountryProfileSecurityAdviceSection />}
                      />
                      <Route
                        path="map"
                        element={<CountryProfileMapSection />}
                      />
                    </Route>
                  </Route>
                </Route>

                <Route
                  path="advice-guides"
                  element={
                    <AdviceGuidesContextProvider>
                      <AdviceGuides />
                    </AdviceGuidesContextProvider>
                  }
                >
                  <Route
                    path="travel"
                    element={<AdviceGuidesTravelSection />}
                  />
                  <Route
                    path="natural-hazards"
                    element={<AdviceGuidesNaturalHazardsSection />}
                  />
                  <Route
                    path="medical"
                    element={<AdviceGuidesMedicalSection />}
                  />
                  <Route
                    path="emergency"
                    element={<AdviceGuidesEmergencySection />}
                  />
                </Route>

                <Route
                  path="incident-log"
                  element={
                    <AdviceGuidesContextProvider>
                      <IncidentLog />
                    </AdviceGuidesContextProvider>
                  }
                />

                <Route
                  path="airline-risk-matrix"
                  element={
                  <ProtectedRouteClient>
                  <AirlineRiskMatrix />
                  </ProtectedRouteClient> }
                />

                <Route
                  path="security-assessments"
                  element={<SecurityAssessments />}
                />
              </Route>
              <Route
                path="settings"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              >
                <Route path="country-profiles" element={<CustomProfiles />} />
                <Route
                  path="custom-emails-recipients"
                  element={<CustomEmailsRecipients />}
                />
                <Route
                  path="airline-risk-matrix"
                  element={<AirlineRiskUpload/>}
                 />
                <Route
                  path="banner-alert"
                  element={<SetBannerAlert/>}
                 />
                <Route
                  path="app-compliance"
                  element={<AppCompliance/>}
                />
                {/* <Route*/}
                {/*  path="emergency-response"*/}
                {/*  element={*/}
                {/*    <UsersDataProvider>*/}
                {/*      <EmergencyResponse />*/}
                {/*    </UsersDataProvider>*/}
                {/*  }*/}
                {/* >*/}
                {/*  <Route*/}
                {/*    path="view-plans"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <ViewPlans />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/* </Route>*/}
                {/* <Route*/}
                {/*  path="mass-warning"*/}
                {/*  element={*/}
                {/*    <UsersDataProvider>*/}
                {/*      <MassWarning />*/}
                {/*    </UsersDataProvider>*/}
                {/*  }*/}
                {/* >*/}
                {/*  <Route*/}
                {/*    path=""*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <SettingsMassWarningHome />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path="groups"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <AllGroups />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  >*/}
                {/*    <Route*/}
                {/*      path=""*/}
                {/*      element={*/}
                {/*        <UsersDataProvider>*/}
                {/*          <Dashboard />*/}
                {/*        </UsersDataProvider>*/}
                {/*      }*/}
                {/*    />*/}
                {/*    <Route*/}
                {/*      path="edit-group"*/}
                {/*      element={*/}
                {/*        <UsersDataProvider>*/}
                {/*          <EditGroup />*/}
                {/*        </UsersDataProvider>*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </Route>*/}
                {/*  <Route*/}
                {/*    path="all-email-templates"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <SeeAllEmailTemplates />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path="all-audio-files"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <SeeAllAudioFiles />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/* </Route>*/}
                {/* <Route*/}
                {/*  path="custom-emails"*/}
                {/*  element={*/}
                {/*    <UsersDataProvider>*/}
                {/*      <CustomEmails />*/}
                {/*    </UsersDataProvider>*/}
                {/*  }*/}
                {/* >*/}
                {/*  <Route*/}
                {/*    path="invitation-email"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <InvitationEmail />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path="pre-trip-email"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <PreTripEmail />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path="new-trip-email"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <NewTripEmail />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path="real-time-travellers-affected"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <RealTimeTravellersAffected />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path="compliance-report-email"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <ComplianceReportEmail />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/* </Route>*/}
                {/* <Route*/}
                {/*  path="facilities"*/}
                {/*  element={*/}
                {/*    <UsersDataProvider>*/}
                {/*      <Facilities />*/}
                {/*    </UsersDataProvider>*/}
                {/*  }*/}
                {/* />*/}
              </Route>
              <Route
                path="sites"
                element={
                  <ProtectedRoute>
                    <SitesOutlet />
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":client_id?/:facility_id?"
                  element={<SitesHomePage />}
                />
                <Route
                  path="custom-overlay"
                  element={<SitesCustomOverlayPage />}
                />
                <Route
                  path="add-custom-overlay"
                  element={<SitesAddCustomOverlayPage />}
                />
                <Route
                  path="edit-custom-overlay/:id"
                  element={<SitesEditCustomOverlay />}
                />
                <Route path="zones" element={<SitesZonePage />} />
                <Route path="facilities" element={<SitesFacilitiesPage />} />
                <Route path="add-zone" element={<SitesAddZone />} />
                <Route
                  path="edit-zone/:type/:zoneID"
                  element={<SitesEditZone />}
                />
              </Route>
              <Route
                path="trips"
                element={
                  <ProtectedRoute>
                    <AllTripsPage />
                  </ProtectedRoute>
                }
              />

              <Route
                  path="trips/check-in-configuration"
                  element={
                    <ProtectedRoute>
                      <CheckInConfiguration/>

                    </ProtectedRoute>
                  }
              />

              <Route
                path="trips/:id"
                element={
                  <ProtectedRoute>
                    <IndividualTripPage />
                  </ProtectedRoute>
                }
              />


              <Route
                  path="trips/create"
                  element={
                    <ProtectedRoute>
                      <TripCreate />
                    </ProtectedRoute>
                  }
              />

              <Route
                  path="trips/:id/edit"
                  element={
                    <ProtectedRoute>
                      <TripEdit/>
                    </ProtectedRoute>
                  }
              />

              <Route
                path="trips/upcoming-check-ins"
                element={
                  <ProtectedRoute>
                    <UpcomingCheckInsPage />
                  </ProtectedRoute>
                }
              />


            <Route path="management/*" element={<ManagementRoutes/>}/>


              <Route
                  path="reporting"
                  element={
                    <ProtectedRoute>
                      {/* <DashboardClientProviderWrapper> */}
                      <ReportsDataProvider>
                        <Reports />
                      </ReportsDataProvider>
                      {/* </DashboardClientProviderWrapper> */}
                    </ProtectedRoute>
                  }
              >

                {/* <Route path="summary" element={<DashboardClientProviderPage />}>
                      <Route index element={<DashboardPage />} />
                      <Route path=":dashboardID" element={<DashboardPage />} />
                    </Route> */}

                <Route
                    path="summary/:dashboardID?"
                    element={
                      <DashboardPageNew />
                    }

                />


                <Route path="create-new-report" element={<CreateNewReportDoc />} />

                <Route path="reports" element={<ReportsPage />}>
                  <Route path="summary" element={<SummaryPage />} />
                  <Route path="saved-reports" element={<CreateNewReportDoc />} />
                </Route>

                <Route path="messages" element={<MessageLog />} />
              </Route>

            </Route>
            <Route path="reports/download" element={<ReportFileDownload />} />





            <Route path="messages" element={<MessageLog />} />
              <Route
                path="activate-account/:token"
                element={<UserActivationProvider>
                    <UserActivationPage/>
              </UserActivationProvider>}
              />


              <Route
                  path="reset-password/:token"
                  element={<PasswordResetProvider><ResetPasswordPage/></PasswordResetProvider>}
              />
          </Routes>




        </Router>
        <ReactQueryDevtools initialIsOpen={false} />

      </QueryClientProvider>
    </OidcProvider>
  </React.StrictMode>
);
