import React, { useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import { CountrySelectSingle } from "../../../../../components/form-v2/searchable-select/CountrySelectSingle";
import BasicSelect from "../../../../../components/form-v2/basic-select";
import { UserSelectSingle } from "../../../../../components/form-v2/searchable-select/UserSelect";
import RecurrencePicker from "../../../../../lib/reports/RecurringSelect";
import { apiV2 } from "../../../../../api/axiosConfig";
import { getTimezonesForCountry } from "countries-and-timezones";
import { useTimeZoneSelectContext } from "../../../../../providers/TimeZoneSelectProvider";

export interface TimeZoneConfig {
  countryISO: string;
  timezone: string;
}

export interface Recurrence {
  startDate: string;
  selectedDays: string[];
  frequency: string;
  repeatEvery: number;
  repeatUnit: "day" | "week" | "month" | "year";
  endDate?: string;
  pattern?: string;
  monthlyYearlyDay: string;
}

interface ScheduleOverlayProps {
  onClose: () => void;
  selectedReportName: string;
  selectedReportID: string;
}

const ScheduleOverlay: React.FC<ScheduleOverlayProps> = ({
  onClose,
  selectedReportName,
  selectedReportID,
}) => {

  const tzContext = useTimeZoneSelectContext()

  const [scheduleType, setScheduleType] = useState<string>("file");
  const [scheduleTimezone, setScheduleTimezone] = useState<TimeZoneConfig>({
    countryISO: tzContext.countryISO,
    timezone: tzContext.timeZone || "", 
  });
  const [dataSourceTimezone, setDataSourceTimezone] = useState<TimeZoneConfig>({
    countryISO: tzContext.countryISO,
    timezone: tzContext.timeZone || "", 
  });
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [time, setTime] = useState<string>("");
  const [recurrence, setRecurrence] = useState<Recurrence>({
    startDate: "",
    selectedDays: [],
    frequency: "custom",
    repeatEvery: 1,
    repeatUnit: "week",
    monthlyYearlyDay: "",
  });
  const [validationError, setValidationError] = useState<string>("");
  const [endDateError, setEndDateError] = useState<string>("");

  const getTimezoneOptions = (countryISO: string) => {
    const data = getTimezonesForCountry(countryISO);
    return data ? data.map((tz) => ({ label: tz.name, value: tz.name })) : [];
  };

  const [authData, setAuthData] = useState<{
    userID: string;
    accessLevel: string;
    clientName: string;
    clientID: string;
    departmentID: string;
  } | null>(null);

  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        const res = await apiV2.get<{
          userID: string;
          accessLevel: string;
          clientName: string;
          clientID: string;
          departmentID: string;
        }>("/reporting/get-user-auth-data");

        setAuthData({
          userID: res.data.userID,
          accessLevel: res.data.accessLevel,
          clientName: res.data.clientName,
          clientID: res.data.clientID,
          departmentID: res.data.departmentID,
        });
      } catch (err) {
        console.error("Failed to fetch auth data:", err);
      }
    };

    fetchAuthData();
  }, []);

  // When country changes for scheduleTimezone, update timezone if needed
  useEffect(() => {
    const options = getTimezoneOptions(scheduleTimezone.countryISO);
    if (options.length > 0 && !scheduleTimezone.timezone) {
      setScheduleTimezone((prev) => ({
        ...prev,
        timezone: options[0].value,
      }));
    }
  }, [scheduleTimezone.countryISO]);

  // When country changes for dataSourceTimezone, update timezone if needed
  useEffect(() => {
    const options = getTimezoneOptions(dataSourceTimezone.countryISO);
    if (options.length > 0 && !dataSourceTimezone.timezone) {
      setDataSourceTimezone((prev) => ({
        ...prev,
        timezone: options[0].value,
      }));
    }
  }, [dataSourceTimezone.countryISO]);

  // API call to schedule the report
  const scheduleReport = async (): Promise<boolean> => {
    setValidationError("");
    setEndDateError("");

    if (!recurrence.startDate || !time) {
      setValidationError("Start Date and Time are required");
      return false;
    }
    const selectedStart = new Date(`${recurrence.startDate}T${time}`);
    if (selectedStart < new Date()) {
      setValidationError("Start Time must not be in the past");
      return false;
    }
    let endDateFormatted = "";
    if (recurrence.endDate) {
      const selectedEnd = new Date(recurrence.endDate);
      if (selectedEnd <= selectedStart) {
        setEndDateError("End Date must be after Start Date");
        return false;
      }
      endDateFormatted = selectedEnd.toISOString().split("T")[0];
    }
    const [hours, minutes] = time.split(":").map(Number);
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    const startDateFormatted = selectedStart.toISOString().split("T")[0];

    const payload = {
      customReportID: selectedReportID,
      reportName: selectedReportName,
      schedule: {
        frequency: recurrence.repeatUnit,
        repeatEvery: recurrence.repeatEvery,
        time: formattedTime,
        scheduleTimezone: scheduleTimezone.timezone,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
        daysOfWeek: recurrence.selectedDays,
        pattern: recurrence.pattern,
        monthlyYearlyDay: recurrence.monthlyYearlyDay,
      },
      scheduleType,
      dataSourceTimezone: dataSourceTimezone.timezone,
      recipientID: selectedUser ? selectedUser.id : null,
    };

    try {
      await apiV2.post("/reporting/schedule-report-new", payload);
      onClose();
      return true;
    } catch (error) {
      console.error("Error scheduling report:", error);
      return false;
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose} />
      <div className="relative bg-white h-4/5 overflow-y-auto mt-20 p-6 md:p-4 rounded-lg shadow-xl w-11/12 max-w-5xl">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <Close className="w-6 h-6" />
        </button>
        <div className="text-center mb-4">
          <h2 className="font-semibold text-gray-800">
            {selectedReportName ? `Schedule Report Email: ${selectedReportName}` : "Schedule Report Email"}
          </h2>
        </div>
        <div>
                <label className="block text-sm flex justify-center font-medium text-gray-700">
                  Attachment Type
                </label>
                <div className="flex items-center justify-between bg-light-light-grey p-3 mx-60 px-4 rounded-md">
                  <label className="flex items-center space-x-3 cursor-pointer">
                    <input
                      type="radio"
                      name="attachmentType"
                      value="file"
                      checked={scheduleType === "file"}
                      onChange={() => setScheduleType("file")}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <span className="text-gray-700 text-sm">File Attachment</span>
                  </label>
                  <label className="flex items-center space-x-3 cursor-pointer">
                    <input
                      type="radio"
                      name="attachmentType"
                      value="link"
                      checked={scheduleType === "link"}
                      onChange={() => setScheduleType("link")}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <span className="text-gray-700 text-sm">Email Download Link</span>
                  </label>
                </div>
              </div>

        {/* Two-Column Layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
          {/* Left Column: Timezones & Recipient */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Scheduled Email Timezone
              </label>
              <div className="bg-white p-2 rounded shadow flex flex-col gap-3">
                <CountrySelectSingle
                  value={scheduleTimezone.countryISO}
                  setValue={(newCountryISO: string | undefined) => {
                    if (newCountryISO) {
                      const timezoneOptions = getTimezoneOptions(newCountryISO);
                      setScheduleTimezone((prev) => ({
                        ...prev,
                        countryISO: newCountryISO,
                        timezone: timezoneOptions.length > 0 ? timezoneOptions[0].value : "",
                      }));
                    }
                  }}
                  label="Country"
                />
                <BasicSelect
                  label="Timezone"
                  options={getTimezoneOptions(scheduleTimezone.countryISO)}
                  value={scheduleTimezone.timezone}
                  onChange={(newTimezone: string | undefined) => {
                    if (newTimezone) {
                      setScheduleTimezone((prev) => ({ ...prev, timezone: newTimezone }));
                    }
                  }}
                  useEmptyOption={false}
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Data Source Timezone
              </label>
              <div className="bg-white p-2 rounded shadow flex flex-col gap-3">
                <CountrySelectSingle
                  value={dataSourceTimezone.countryISO}
                  setValue={(newCountryISO: string | undefined) => {
                    if (newCountryISO) {
                      const timezoneOptions = getTimezoneOptions(newCountryISO);
                      setDataSourceTimezone((prev) => ({
                        ...prev,
                        countryISO: newCountryISO,
                        timezone: timezoneOptions.length > 0 ? timezoneOptions[0].value : "",
                      }));
                    }
                  }}
                  label="Country"
                />
                <BasicSelect
                  label="Timezone"
                  options={getTimezoneOptions(dataSourceTimezone.countryISO)}
                  value={dataSourceTimezone.timezone}
                  onChange={(newTimezone: string | undefined) => {
                    if (newTimezone) {
                      setDataSourceTimezone((prev) => ({ ...prev, timezone: newTimezone }));
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Recipient</label>
              <UserSelectSingle
                user={selectedUser}
                onChange={(user: any) => setSelectedUser(user)}
                clientID={authData?.accessLevel === "client" ? authData?.clientID : null}
                departmentID={authData?.accessLevel === "department" ? authData?.departmentID : null}
                disabled={false}
              />
            </div>
          </div>

          {/* Right Column: Time & Recurrence */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Time <span className="text-xs text-gray-500">({scheduleTimezone.timezone})</span>
              </label>
              <input
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                className="w-full border border-gray-300 p-1.5 text-xs rounded-md focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <RecurrencePicker
              recurrence={recurrence}
              onChange={(updates) => setRecurrence((prev) => ({ ...prev, ...updates }))}
            />
          </div>
        </div>

        {validationError && <p className="mt-2 text-sm text-red-500">{validationError}</p>}
        {endDateError && <p className="mt-1 text-sm text-red-500">{endDateError}</p>}

        <div className="absolute bottom-4 right-4 flex justify-end mt-4 space-x-3">
          <button onClick={onClose} className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md">
            Cancel
          </button>
          <button onClick={scheduleReport} className="px-4 py-2 bg-blue text-white rounded-md">
            Schedule Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleOverlay;
