import React, {createContext, ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {SetURLSearchParams, useSearchParams} from "react-router-dom";

type UserManagementBrowsingContextType = {
    clientID?: string;
    setClientID: (id: string) => void;
    departmentIDs: string[];
    setDepartmentIDs: (departmentIDs: string[]) => void;
    searchParams: URLSearchParams;
    setSearchParams: SetURLSearchParams;
    loaded: boolean;
};

const UserManagementBrowsingContext = createContext<UserManagementBrowsingContextType | undefined>(undefined);

type UserManagementBrowsingProviderProps = {
    children: ReactNode;
};

const updateClientParamsOn = [
    "/management/users",
    "/management/departments",
    "/management/invitations",
    "/management/import",
]

const updateDepartmentParamsOn = [
    "/management/users",
]

export const UserManagementBrowsingProvider: React.FC<UserManagementBrowsingProviderProps> = ({children}) => {


    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        localStorage.setItem("UserManagementBrowsingProvider.clientID", searchParams.get("clientID") ?? "" )
    }, [searchParams]);

    const [loaded, setLoaded] = useState<boolean>(false)

    const [clientID, setClientID] = useState<string>(searchParams.get("clientID") ||  localStorage.getItem("UserManagementBrowsingProvider.clientID") || "")
    const [departmentIDs, setDepartmentIDs] = useState<string[]>(searchParams.get("departmentIDs")?.split(",") || localStorage.getItem("UserManagementBrowsingProvider.departmentIDs")?.split(",") || [])



    const pathInClientWhiteList = useMemo(() => {
        return updateClientParamsOn.includes(window.location.pathname)

    }, [window.location.pathname])


    const pathInDepartmentWhiteList = useMemo(() => {
        return updateDepartmentParamsOn.includes(window.location.pathname)
    }, [window.location.pathname])

    useEffect(() => {
        if (loaded) return;
        const cid = searchParams.get("clientID") || localStorage.getItem("UserManagementBrowsingProvider.clientID")
        // console.log("CID", cid)

        if (cid) {
            console.log("UserManagementBrowsingProvider.setClientID", cid)
            setClientID(cid)
        }


        const did = searchParams.get("departmentIDs") || localStorage.getItem("UserManagementBrowsingProvider.departmentIDs")
        // console.log("DID", did)
        if (did) {
            console.log("UserManagementBrowsingProvider.setDepartmentIDs", did)
            setDepartmentIDs(did.split(","))
        }


        setLoaded(true)
    }, [loaded]);


    useEffect(() => {
        if(!pathInClientWhiteList) return



        if (clientID && loaded) {

            setSearchParams((prev) => {
                const newParams = new URLSearchParams(prev.toString());

                if (newParams.get("clientID") !== clientID) {
                    newParams.set("clientID", clientID);
                }

                console.log("Updated params:", newParams.toString());
                return newParams;
            });

            console.log("UserManagementBrowsingProvider updating clientID", clientID)
            localStorage.setItem("UserManagementBrowsingProvider.clientID", clientID)
        }

    }, [clientID, loaded, setSearchParams]);


    useEffect(() => {
        if(!pathInDepartmentWhiteList) return


        if (!loaded) return

        // console.log("DID2", departmentIDs)
        setSearchParams((prev) => {
            const newParams = new URLSearchParams(prev.toString())
            newParams.set("departmentIDs", departmentIDs.join(","))

            if (prev.toString() == newParams.toString()) {
                return prev
            }

            return newParams
        })

        localStorage.setItem("UserManagementBrowsingProvider.departmentIDs", departmentIDs.join(","))
    }, [departmentIDs, loaded]);

    const departmentIDsMemo = useMemo(() => {
        return departmentIDs
    }, [departmentIDs.join(",")])


    return (
        <UserManagementBrowsingContext.Provider
            value={{clientID, departmentIDs: departmentIDsMemo, setClientID, setDepartmentIDs, searchParams, setSearchParams, loaded}}>
            {loaded && children}
        </UserManagementBrowsingContext.Provider>
    );
};

export const useUserManagementBrowsing = (): UserManagementBrowsingContextType => {
    const context = useContext(UserManagementBrowsingContext);
    if (!context) {
        throw new Error("useUserManagementBrowsing must be used within a UserManagementBrowsingProvider");
    }
    return context;
};