import {useCallback} from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";


export const generateAndSaveXLSX = (filename: string, headers: string[], data: string[][]) => {
    const rows = [headers, ...data];

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    Object.keys(worksheet).forEach((cell) => {
        if (cell.startsWith("!")) return; // Ignore metadata keys
        worksheet[cell] = {
            ...worksheet[cell],
            t: "s",
            z: "@",
        };
    });


    worksheet["!cols"] = rows[0].map((_, colIndex) => ({
        wch: Math.max(...rows.map((row) => (row[colIndex] ? row[colIndex].toString().length : 10))) + 2,
    }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    saveAs(blob, filename);
};


export type Sheet = {
    name: string;
    headers: string[];
    rows: string[][]
}


export const generateAndSaveXLSXMultiSheet = (sheets: Sheet[], filename: string) => {


    const workbook = XLSX.utils.book_new();

    sheets.forEach((sheet: Sheet) => {
        const rows = [sheet.headers, ...sheet.rows];
        const worksheet = XLSX.utils.aoa_to_sheet(rows);
        Object.keys(worksheet).forEach((cell) => {
            if (cell.startsWith("!")) return; // Ignore metadata keys
            worksheet[cell] = {
                ...worksheet[cell],
                t: "s",
                z: "@",
            };
        });

        worksheet["!cols"] = rows[0].map((_, colIndex) => ({
            wch: Math.max(...rows.map((row) => (row[colIndex] ? row[colIndex].toString().length : 10))) + 2,
        }));

        XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);

    })


    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    saveAs(blob, filename);
};



export const useDownloadXLSX = (filename: string, headers: string[], data: string[][] = []) => {



    const generateAndSave = useCallback(() => {
        generateAndSaveXLSX(filename, headers, data)
    }, [headers, filename])



    return {generateAndSave}
}