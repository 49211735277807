import React, {createContext, useContext, useState, ReactNode, useCallback, useEffect} from "react";
import {PasswordFields, usePasswordState} from "../user-activation/states/password";
import {useParams} from "react-router-dom";
import {apiV2} from "../../api/axiosConfig";
import {isAxiosError} from "axios";




type PasswordResetContextType = {
    step: number;
    setStep: (step: number) => void;
    loading: boolean;
    loadError: string;
    saving: boolean;
    savingError: string;
    submit: () => void;

} & PasswordFields;

const PasswordResetContext = createContext<PasswordResetContextType | undefined>(undefined);




export const PasswordResetProvider = ({ children }: { children: ReactNode }) => {


    const {token} = useParams();

    const [step, setStep] = useState(1);

    const passwordState = usePasswordState();

    const [loadError, setLoadError] = useState<string>("");
    const[loading, setLoading] = useState(true)

    const [saving, setSaving] = useState(false)
    const [savingError, setSavingError] = useState("")


    useEffect(() => {
        setSavingError("")
    }, [passwordState.password, passwordState.passwordVerification]);


    const loadToken = useCallback(async () => {
        try {
            await apiV2.get(`password-reset/${token}`)
        } catch (e) {
            if(isAxiosError(e) && e.response?.data?.error) {
                setLoadError(e.response?.data?.error)
            } else {
                setLoadError("error loading");
            }
        }

        setLoading(false);


    }, [token])


    const submit = async () => {

        setSaving(true)
        setSavingError("")

        try {

            await apiV2.post(`password-reset/${token}`, {
                password: passwordState.password,
            })

            setStep(2)



        } catch(e) {
            if(isAxiosError(e) && e.response?.data?.error) {
                setSavingError(e.response?.data?.error)
            } else {
                setSavingError("error resetting password");
            }
        }

        setSaving(false)
    }


    useEffect(() => {
        loadToken();
    }, [token]);




    return (
        <PasswordResetContext.Provider value={{
            step, setStep,
            ...passwordState,
            loadError,
            loading,
            savingError,
            saving,
            submit

        }}>
            {children}
        </PasswordResetContext.Provider>
    );
};

export const usePasswordReset = () => {
    const context = useContext(PasswordResetContext);
    if (!context) {
        throw new Error("usePasswordReset must be used within a PasswordResetProvider");
    }
    return context;
};