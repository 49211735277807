import React from "react"


type BasicSelectProps = {
  label: string
  value?: string
  options: { value: string, label: string }[]
  onChange?: (value: string | undefined) => void
  useEmptyOption?: boolean
  disabled?: boolean
  required?: boolean
}

const BasicSelect: React.FC<BasicSelectProps> = ({label, value, options, onChange, useEmptyOption, disabled, required}) => {
  return <div className={"flex flex-col"}>
    <label className={"mb-1"}>{label}{required && <span className="text-dark-red"> *</span>}</label>
    <select
        disabled={disabled}
        className={"focus:outline focus:outline-blue h-10 w-full shadow-md rounded-md max-h-20 px-4 outline-none bg-white"}
        onChange={(e) => onChange && onChange(e.target.value)}
        value={value}
    >
      {useEmptyOption && <option value={""}>
          Select Option
      </option>}
      {options.map((o) => <option key={o.value} value={o.value}>{o.label}</option>)}
    </select>
  </div>
}

export default BasicSelect