import {useCallback, useMemo, useState} from "react";

export const useDebugCounter = (count = 10): [boolean, () => void] => {

    const [counter, setCounter] = useState(0);

    const enabled = useMemo(() => {
        return counter > count
    }, [counter, count]);


    const increment = useCallback(() => {
        setCounter(counter + 1);
    },[counter, setCounter]);

    return [enabled, increment];

}