import {noop} from "rxjs";
import {useImportContext} from "./ImportContextProvider";
import {usePrettyFileSize} from "../../../lib/files/usePrettyFileSize";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Check, Close, FileUploadRounded} from "@mui/icons-material";
import clsx from "clsx";
import {useToggle} from "./useToggle";

type IconButtonProps = {
    onClick?: () => void;
    text?: string;
    icon?: JSX.Element,
    color?: "blue" | "green" | "red" | "grey" | "purple" | "orange"
    disabled?: boolean;
}

export const IconButton: React.FC<IconButtonProps> = (
    {
        onClick = noop,
        text = "",
        icon,
        color = "blue",
        disabled = false,
    }) => {


    const handleOnClick = useCallback(() => {
        if(disabled) return
        onClick()

    }, [onClick, disabled]);

    const className = useMemo(() => {
        return clsx(
            "w-full h-10 rounded-md shadow-lg flex items-center px-4 py-2 text-sm",
            {
                "bg-blue text-white": color == "blue",
                "bg-green text-white": color == "green",
                "bg-red text-white": color == "red",
                "bg-desaturated-grey": color == "grey",
                "bg-dark-orange text-white": color == "orange",
                "bg-global-purple text-white": color == "purple",
                "opacity-50": disabled,
            },
        )
    }, [color, disabled]);

    return (
        <button
            type="button"
            className={className}
            onClick={handleOnClick}
        >
            <div>
                {text}
            </div>
            <div className="ml-auto">
                {icon}
            </div>
        </button>
    )
}

type ToggleButtonProps = {
    text?: string;
    initialValue?: boolean;
    onChange?: (value: boolean) => void;
    value?: boolean;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
    text = "Button",
    initialValue = false,
    onChange = noop,
    value: extValue,
                                                          }) => {

    const [value, toggle, setValue] = useToggle(extValue || initialValue)

    useEffect(() => {
        if(extValue != undefined){
            setValue(value)
        }
    }, [extValue]);

    useEffect(() => {
        onChange(value)
    }, [value]);

    const icon = useMemo(() => {
        if(value){
            return <Check/>
        }
        return <Close/>
    }, [value])

    const color = useMemo(() => {
        return value ? "purple" : "grey"
    }, [value])


    return <>
        <IconButton text={text} onClick={toggle} icon={icon} color={color} />
    </>

}

export const FileSelect: React.FC = () => {
    const {activateFileSelect, fileInputRef, handleFileChange, selectedFile, invalidFile} = useImportContext()

    const fileSize = usePrettyFileSize(selectedFile)


    const text = useMemo(() => {

        if(invalidFile){
            return "Invalid File"
        }

        return selectedFile ? `${selectedFile.name} (${fileSize})` : "Select CSV or XLSX file"
    }, [selectedFile])

    const icon = useMemo(() => {
        return selectedFile ? <Check/> : <FileUploadRounded/>
    }, [selectedFile])

    return (
        <div className={"flex flex-col gap-4"}>
            <input ref={fileInputRef} type="file"     accept=".csv, .xlsx"  onChange={handleFileChange}
                   className={"hidden"}/>


            <IconButton onClick={activateFileSelect} text={text} icon={icon}/>

        </div>
    )
}



export const ErrorToggle: React.FC<{
    error: { key: string; message: string; count: number },
    isHidden: boolean,
    toggle: (key: string) => void
}> = ({error, isHidden, toggle}) => (
    <div onClick={() => toggle(error.key)}
         className={clsx("shadow-md text-sm cursor-pointer grid grid-cols-[1fr_50px] gap-4 bg-darker-red text-white w-full rounded-md px-4 py-2", {"opacity-30": isHidden})}>
        <div>{error.message}</div>
        <div className="text-right">{error.count}</div>
    </div>
);


export const WarningToggle: React.FC<{
    warning: { key: string; message: string; count: number },
    isHidden: boolean,
    toggle: (key: string) => void
}> = ({warning, isHidden, toggle}) => (
    <div onClick={() => toggle(warning.key)}
         className={clsx("shadow-md text-sm cursor-pointer grid grid-cols-[1fr_50px] gap-4 bg-dark-orange text-white w-full rounded-md px-4 py-2", {"opacity-30": isHidden})}>
        <div>{warning.message}</div>
        <div className="text-right">{warning.count}</div>
    </div>
);

type ValidToggleButtonProps = {
    enabled: boolean;
    toggle: () => void;
    count: number;
    label: string;
}

export const ValidToggleButton: React.FC<ValidToggleButtonProps> = ({toggle, count, label, enabled}) => (
    <div
        className={clsx(" shadow-md text-sm grid grid-cols-[1fr_50px] gap-4 bg-green text-white w-full rounded-md px-4 py-2 cursor-pointer", {"opacity-30": !enabled})}
        onClick={toggle}>
        <div>{label}</div>
        <div className="text-right">{count}</div>
    </div>
);

export const NeutralToggleButton: React.FC<ValidToggleButtonProps> = ({toggle, count, label, enabled}) => (
    <div
        className={clsx(" shadow-md text-sm grid grid-cols-[1fr_50px] gap-4 bg-blue text-white w-full rounded-md px-4 py-2 cursor-pointer", {"opacity-30": !enabled})}
        onClick={toggle}>
        <div>{label}</div>
        <div className="text-right">{count}</div>
    </div>
);