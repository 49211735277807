import React, {useCallback, useEffect, useMemo, useState} from "react";
import Layout from "../layout/Layout";
import {ClientSelectSingle} from "../../../components/form-v2/searchable-select/ClientSelect";
import {DepartmentsManagementProvider} from "./DepartmentsManagementContext";
import {Client, Departments} from "../../../types/userManagement";
import {apiV2} from "../../../api/axiosConfig";
import BasicText from "../../../components/form-v2/basic-text";
import {BlueButton} from "../../../components/ui/Buttons";
import {useNavigate, useSearchParams} from "react-router-dom";
import {isAxiosError} from "axios";
import {useUserManagementBrowsing} from "../../../lib/user-management/UserManagementBrowsingContext";
import {PhoneInput} from "../../../components/form-v2/phone-input/PhoneInput";
import {ToggleButton} from "../import/inputs";



const DepartmentCreate: React.FC = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [receiveEmailNotifications, setReceiveEmailNotifications] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("")
    const [client, setClient] = useState<Client>();


    const ctx = useUserManagementBrowsing()

    const [clientID, setClientID] = useState(ctx.clientID);

    const navigate = useNavigate();

    const save = useCallback(async () => {
        setSaving(true);

        try {
            await apiV2.post("departments", {
                name,
                email,
                phone,
                receiveEmailNotifications,
                clientID: ctx.clientID,
            })

        }  catch (e){
            if (isAxiosError(e) && e?.response?.data?.error) {
                setError(e.response.data.error);
            } else {
                setError("An error has occurred")
            }
            setSaving(false);
            return


        }




        setSaving(false);

        const params = new URLSearchParams();
        params.set("message", "department has been created")
        navigate(`/management/departments?${params.toString()}`);
        return
    }, [name, email, phone])

    return <Layout
        active={"departments"}
    >
            <div className="flex flex-col gap-8">
                <div className="bg-white shadow-md p-4 w-1/2 flex flex-col gap-8">
                    <p className="text-md text-left pb-2 font-semibold">Create Department</p>
                    {clientID && <ClientSelectSingle disabled={true} initialClientID={clientID} client={client} onChange={setClient}/>}
                    <BasicText value={name} onChange={setName} label="Name" />
                    <BasicText value={email} onChange={setEmail} label="Email" />
                    <ToggleButton text={"Receive Email Notifications"} initialValue={false} onChange={setReceiveEmailNotifications} />
                    <PhoneInput initialValue={phone} onChange={setPhone} label="Phone Number" />
                    <BlueButton text={"Create Department"} disabled={name == ""} onClick={save} loading={saving}/>
                    {error && <p className={"text-center text-red"}>{error}</p>}
                </div>
            </div>

    </Layout>

}

DepartmentCreate.displayName = "DepartmentCreate";

export default DepartmentCreate;