import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useClientDashboard } from "./ClientDashboardProvider";
import { UserContext } from "../../../../../context/user";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { clientID } = useClientDashboard();
  const user = useContext(UserContext)
  const accessLevel = user?.role.auroraAccessLevel

  const handleSectionClick = (section: string) => {
    switch (section) {
      case "Dashboard":
        if (!location.pathname.startsWith("/reporting/summary")) {
          navigate("/reporting/summary")
        }
        break
        case "Reports":
          navigate(clientID && accessLevel === "all" 
            ? `/reporting/reports?clientID=${clientID}` 
            : "/reporting/reports");
          break;
        
      case "Settings":
        navigate("/settings")
        break
      default:
        break
    }
  }
  

  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="w-1/6 bg-white h-full border-r border-light-grey p-4">
      <h1 className="text-center font-semibold text-lg text-dark-dark-grey mt-8">
        Reporting
      </h1>
      <hr className="border-b-1 border-grey mt-2 mx-8" />
      <ul className="space-y-4 pt-14">
        <li
          onClick={() => handleSectionClick("Dashboard")}
          className={`cursor-pointer p-2 rounded-sm text-grey text-medium hover:bg-light-blue transition ${
            isActive("/reporting/summary") || window.location.pathname.startsWith("/reporting/summary")
              ? "bg-light-blue border-l-4 border-blue"
              : ""
          }`}
        >
          Dashboard
        </li>
        <li
          onClick={() => handleSectionClick("Reports")}
          className={`cursor-pointer p-2 rounded-sm text-grey text-medium hover:bg-light-blue transition ${
            isActive("/reporting/reports")
              ? "bg-light-blue border-l-4 border-blue"
              : ""
          }`}
        >
          Reports
        </li>
      </ul>
    </div>
  );
}
