import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {ButtonV2} from "../../ui/ButtonsV2/ButtonV2";
import AddIcon from "@mui/icons-material/Add";
import {useDebugCounter} from "../../../lib/debug-counter/useDebugCounter";
import {noop} from "rxjs";
import clsx from "clsx";
import BasicText from "../basic-text";
import {PhoneInput, PhoneInputHandle} from "../phone-input/PhoneInput";
import moment from "moment";
import {CountrySelect, CountrySelectHandle} from "../country-select/CountrySelect";
import {MultiInputMultiFieldRow} from "./MultiInputMultiFieldRow";
import {InputType} from "./InputType";
import {MultiInputMultiFieldInput, MultiInputMultiFieldInputHandle} from "./MultiInputMultiFieldInput";


type MultiInputMultiFieldProps<T> = {
    label?: string;
    initialItems?: T[];
    placeholders?: Partial<Record<keyof T, string>>;
    types?: Partial<Record<keyof T, InputType>>;
    onChange?: (items: T[]) => void;
    columns?: number;
    showLabels?: boolean;
    requireAll?: boolean;
    required?: Array<keyof T>;
    unboldLabel?: boolean;
}


export function MultiInputMultiField<T>(props: MultiInputMultiFieldProps<T>): JSX.Element {

    const {
        label,
        initialItems,
        onChange,
        placeholders,
        columns,
        types,
        required,
    } = props as Required<MultiInputMultiFieldProps<T>>;
    const [items, setItems] = useState(initialItems)
//
    const [debug, incrementDebug] = useDebugCounter()

    const inputRefs = useRef<MultiInputMultiFieldInputHandle[]>([])
    const addInputRef = (element: MultiInputMultiFieldInputHandle | null) => {
        if (element && !inputRefs.current.includes(element)) {
            inputRefs.current.push(element);
        }
    };

    const gridClass = clsx(
        `grid grid-cols-1 md:grid-cols-${columns} gap-8`
    )

    const keys = useMemo(() => {
        return Object.keys(placeholders) as Array<keyof T>;
    }, [placeholders]);

    const add = () => {

        if (disabled) {
            return
        }

        setItems((prev) => {
            const newItem = {} as T;
            keys.forEach((key) => {
                newItem[key] = inputItem[key];
            });
            return [...prev, newItem]
        })

        setInputItem((prev) => {
            const newItem = {...prev}
            keys.forEach((key) => {
                newItem[key] = "" as T[keyof T];
            });
            return newItem
        })

        inputRefs.current.forEach((ref) => {
            ref.reset();
        });
    }

    const [inputItem, setInputItem] = useState<T>({} as T);

    const updateInput = (key: keyof T) => {
        return (value: string) => {
            console.log(key, value)
            setInputItem((prev) => {
                const newInputItem = {...prev}
                newInputItem[key] = value as T[keyof T];
                return newInputItem
            });
        }
    }

    useEffect(() => {
        onChange(items)
    }, [items]);

    const remove = useCallback((index: number) => {
        setItems((prev) => {
            const newValues = [...prev];
            newValues.splice(index, 1)
            return newValues;
        });
    }, [setItems]);

    const edit = useCallback((item: T, index: number) => {
        setItems((prev) => {
            const newItems = [...prev];
            newItems[index] = item;
            return newItems;
        });
    }, [setItems, items]);


    const disabled = useMemo(() => {
        if (props.requireAll) {
            return keys.some((key) => !inputItem[key]);
        }

        const allEmpty = keys.every((key) => {
            const value = ((inputItem[key] || "") as string).trim();
            return !value && !required.includes(key);
        });

        const anyRequiredMissing = keys.some((key) => {
            const value = ((inputItem[key] || "") as string).trim();
            return required.includes(key) && !value;
        });

        return allEmpty || anyRequiredMissing;
    }, [inputItem, props.requireAll, required, keys]);

    const labelClass = useMemo(() => `text-md text-left pb-2 ${props.unboldLabel ? "" : "font-semibold"}`, [props.unboldLabel])


    return (
        <div className={"flex flex-col gap-4"}>
            {label && <label onClick={incrementDebug} className={labelClass}>{label}</label>}

            {debug && <p>{JSON.stringify(items)}</p>}

            {items.map((v, i) => (
                <React.Fragment key={i}>
                    <MultiInputMultiFieldRow item={v} index={i} columns={columns} placeholders={placeholders}
                                             types={types} showLabels={!!props.showLabels} onRemove={remove}
                                             onChange={edit} required={required} requireAll={!!props.requireAll}/>

                    <hr className="border-light-grey"/>

                </React.Fragment>
            ))}

            <div className={gridClass}>

                {keys.map(k => (
                    <MultiInputMultiFieldInput onEnter={add} ref={addInputRef} value={inputItem[k] as string}
                                               onChange={updateInput(k)}
                                               key={k as string}
                                               placeholder={placeholders[k] as string} type={types[k] || "text"}/>
                ))}

                <div className={"grid grid-cols-2 grid-cols-subgrid gap-8 col-span-1"}>
                    <ButtonV2 label={<AddIcon/>} onClick={add} disabled={disabled}/>
                </div>
            </div>

        </div>
    );
}


MultiInputMultiField.displayName = "MultiInputMultiField";
MultiInputMultiField.defaultProps = {
    label: "",
    initialItems: [],
    placeholders: {},
    types: {},
    onChange: noop,
    columns: 3,
    type: "text",
    required: [],

}

