import React, {useCallback, useMemo} from "react";
import LoadingSpinner from "../LoadingSpinner";
import clsx from "clsx";


type ButtonV2Props = {
    type?: "submit" | "reset" | "button";
    label: string | React.ReactNode;
    color?: "blue" | "orange" | "gray" | "green"
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
}


export const ButtonV2: React.FC<ButtonV2Props> = (props) => {

    const type = useMemo(() => {
        return props.type || "button";
    }, [props.type])

    const color = useMemo(() => {
        if(!props.color) return "blue";
        return props.color
    }, [props.color])


    const buttonClass = useMemo(() =>
            clsx(
                "w-full min-w-fit h-10  text-sm rounded-md shadow-lg flex items-center justify-center text-center hover:bg-opacity-80 focus:bg-opacity-80 transition-opacity ",
                {
                    "cursor-not-allowed cursor-not-allowed bg-light-grey opacity-30": props.disabled,
                    "bg-blue text-white": color == "blue" && !props.disabled,
                    "bg-dark-orange text-white": color == "orange" && !props.disabled,
                    "bg-green text-white": color == "green" && !props.disabled,
                    "0": props.disabled
                }
            ),
        [props.disabled, color]
    );

    const content = useMemo(() => {
        if (props.loading) return <LoadingSpinner width={16} height={16} color={"white"}/>;
        return props.label
    }, [props.loading, props.label])

    const onClick = useCallback(() => {
        if(!props.onClick) return
        if(props.loading) return
        if(props.disabled) return
        props.onClick()
    }, [props.loading, props.disabled, props.onClick])

    return <button
        type={type}
        className={buttonClass}
        onClick={onClick}
        disabled={props.disabled}
    >
        {content}
    </button>

}