import {Layout} from "./Layout";
import React, {useMemo} from "react";
import {CompletePage} from "../user-activation/CompletePage";
import {usePasswordReset} from "./PasswordResetContext";
import {PasswordForm} from "./PasswordForm";

export const ResetPasswordPage: React.FC = () => {

    const ctx = usePasswordReset()

    const page = useMemo(() => {

        switch (ctx.step) {
            case 1:
                return <PasswordForm/>
            case 2:
                return <CompletePage/>
        }

        return null
    }, [ctx.step])



    return (
        <Layout>
            {page}
        </Layout>
    )
}