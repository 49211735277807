import React, {useMemo} from "react";
import BasicText from "../../components/form-v2/basic-text";
import {GenderSelect, RealGenderSelect} from "../../components/form-v2/searchable-select/GenderSelect";
import {useUserActivation} from "./UserActivationContext";
import {LanguageSelect, LanguageSelectMultiple} from "../../components/form-v2/searchable-select/LanguageSelect";
import {NationalitySelect} from "../../components/form-v2/searchable-select/NationalitySelect";
import {CountrySelectSingle} from "../../components/form-v2/searchable-select/CountrySelectSingle";
import {BlueButton, OrangeButton} from "../../components/ui/Buttons";
import EmergencyContact from "../../features/user-management/pages/user-profile/components/emergency-contact";
import {PassportDetailsNoTitle} from "../../features/user-management/pages/user-profile/components/passport-details";
import {PhoneInput} from "../../components/form-v2/phone-input/PhoneInput";
import MultiInputSingleField from "../../components/form-v2/multi-input/MultiInputSingleField";
import {MultiInputMultiField} from "../../components/form-v2/multi-input/MultiInputMultiField";

export const UserForm: React.FC = () => {
    const ctx = useUserActivation()


    return <>
        <div className="flex flex-col lg:flex-row gap-8 rounded-md w-full lg:max-w-7xl">
            <div className="flex flex-col gap-8 flex-1">
                <PersonalDetails/>
                <Demographic/>



            </div>

            <div className="flex flex-col gap-8 flex-1">
                <EmergencyContactForm/>
                <PassportForm/>
                <AdditionalPhoneNumbers/>
                <AdditionalEmailAddresses/>
            </div>
        </div>

        <SavingStatus/>

        <div className="flex flex-row gap-8 rounded-md w-full md:w-1/3">
            <div className="flex-1 flex flex-col gap-8">
                <BlueButton text={"Complete Activation"} onClick={ctx.submit} loading={ctx.saving}/>
            </div>
        </div>

    </>
}

const SavingStatus: React.FC = () => {
    const ctx = useUserActivation()

    if (ctx.savingError == "") {
        return null
    }

    return <div className={"bg-red text-white text-center rounded-md py-2 px-4 w-full md:w-2/3"}>
            {ctx.savingError}
    </div>

}


const AdditionalPhoneNumbers : React.FC = () => {
    const ctx = useUserActivation()



    return <div className="bg-white shadow-md p-4 flex flex-col gap-8">


        <MultiInputSingleField placeholder={"Enter Phone Number"} label={"Additional Phone Numbers"}
                               initialValues={ctx.secondaryPhones}
                               onChange={ctx.setSecondaryPhones} type={"phone"}/>

    </div>
}




const AdditionalEmailAddresses : React.FC = () => {
    const ctx = useUserActivation()



    return <div className="bg-white shadow-md p-4 flex flex-col gap-8">

        <MultiInputSingleField placeholder={"Enter Email Address"} label={"Additional Email Addresses"}
                               initialValues={ctx.secondaryEmails}
                               type={"email"}
                               onChange={ctx.setSecondaryEmails}/>
    </div>

}


const PassportForm: React.FC = () => {
    const ctx = useUserActivation()

    return <div className="bg-white shadow-md p-4 flex flex-col gap-8">
        <MultiInputMultiField label={"Passport Details"}
                              initialItems={ctx.passports}
                              columns={2}
                              onChange={ctx.setPassports}
                              showLabels
                              placeholders={{
                                  number: "Passport Number",
                                  expiresAt: "Expires On",
                                  countryISO: "Country",
                              }}
                              types={{
                                  number: "text",
                                  expiresAt: "date",
                                  countryISO: "country",
                              }}
                              required={[
                                  "number",
                                  "countryISO"
                              ]}
        />
    </div>

}


const EmergencyContactForm: React.FC = () => {
    const ctx = useUserActivation()

    return <div className="bg-white shadow-md p-4 flex flex-col gap-8">
        <MultiInputMultiField label={"Emergency Contacts"} initialItems={ctx.emergencyContacts} columns={2}
                              showLabels
                              onChange={ctx.setEmergencyContacts}
                              placeholders={{
                                  firstName: "First Name",
                                  lastName: "Last Name",
                                  relationship: "Relationship",
                                  email: "Email",
                                  phoneNumber: "Phone Number"
                              }}
                              types={{
                                  phoneNumber: "phone",
                              }}
                              required={[
                                  "firstName",
                                  "lastName"
                              ]}
        />
    </div>

}


const PersonalDetails: React.FC = () => {
    const ctx = useUserActivation()

    return <div className="bg-white shadow-md p-4 flex flex-col gap-8">
        <p className="text-md text-left pb-2 font-semibold">Personal Details</p>
        <BasicText type={"email"} value={ctx.email} onChange={(e) => ctx.setEmail(e.toLowerCase())}
                   label="Email Address" disabled/>
        <BasicText value={ctx.firstName} onChange={ctx.setFirstName} label="First Name" required/>
        <BasicText value={ctx.middleName} onChange={ctx.setMiddleName} label="Middle Name/s"/>
        <BasicText value={ctx.lastName} onChange={ctx.setLastName} label="Last Name" required/>
        <BasicText type="date" value={ctx.dateOfBirth} onChange={ctx.setDateOfBirth} label="Date of Birth"/>
        <GenderSelect gender={ctx.gender} onChange={ctx.setGender} required/>
        {/* <RealGenderSelect gender={ctx.genderAtBirth} onChange={ctx.setGenderAtBirth}/>*/}
        <PhoneInput required initialValue={ctx.phone} onChange={ctx.setPhone} label="Phone Number"/>


    </div>
}

const Demographic: React.FC = () => {
    const ctx = useUserActivation()

    return <div className="bg-white shadow-md p-4 flex flex-col gap-8">
        <p className="text-md text-left pb-2 font-semibold">Demographic Details</p>
        <div>
            <label className="mb-1">Main Language</label>
            <LanguageSelect value={ctx.mainLanguage} setValue={ctx.setMainLanguage}/>
        </div>

        <div>
            <label className="mb-1">Secondary Languages</label>
            <LanguageSelectMultiple value={ctx.secondaryLanguages} setValue={ctx.setSecondaryLanguages}/>
        </div>

        <div>
            <label className="mb-1">Nationality</label>
            <NationalitySelect value={ctx.nationality} setValue={ctx.setNationality}/>
        </div>

        <CountrySelectSingle value={ctx.countryISO} setValue={ctx.setCountryISO} label={"Country of Residence"}/>


    </div>

}