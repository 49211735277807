import React, { useEffect, useState } from "react"
import SearchableSelect, { SearchableSelectOption } from "."
import api from "../../../api/axiosConfig"
import { PaginatedResponse } from "../../../types/PaginatedResponse"
import SuitcaseIcon from "../../../assets/icons/suitcase.svg";
import {FacilityDataTypes} from "../../../types/facilitiy-data-types";
import {UserDataTypes} from "../../../types/user-data-types";

type FacilitySelectParams = {
  facilities: FacilityDataTypes[]
  multiple?: boolean;
  onSelectionChange?: (facilities: FacilityDataTypes[]) => void;
  disabled?: boolean;
  clientID?: string;
  initialFacilityIDs?: string[]
}

function FacilitySelect({
  multiple,
  onSelectionChange,
  facilities,
  disabled,
  clientID,
  initialFacilityIDs
}: FacilitySelectParams){

  const [loading, setLoading] = useState(false)
  const [options, setOptions]  = useState<SearchableSelectOption<FacilityDataTypes>[]>([])
  const [searchStr, setSearchStr] = useState("")


  const load = async () => {
    setLoading(true)
    if (initialFacilityIDs && initialFacilityIDs.length > 0) {
      const res = await api.get<{ items: FacilityDataTypes[] }>(`/facilities?ids=${initialFacilityIDs.join(",")}`);
      if (onSelectionChange) {
        onSelectionChange(res.data.items)
      }
    }
    setLoading(false)
  }

  const fetchFacilities = async () => {
    setLoading(true)
    try{
      const res = await api.get<PaginatedResponse<FacilityDataTypes>>("/facilities", { params:
            {
              search: searchStr,
              clientID: clientID,
              rpp: 20
            }
      })
      setOptions(res.data.items.map((c) => ({
        label: c.name,
        value: c,
        key: c.id!
      })))

    } catch(e){
      console.error(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchFacilities()
  }, [searchStr, clientID])

  useEffect(() => {
    load();
  }, []);


  const onChange = (selected: SearchableSelectOption<FacilityDataTypes>[]) => {
    if(onSelectionChange){
      onSelectionChange(selected.map(s => s.value))
    }
  }

  const onSearch = (value: string) => {
    setSearchStr(value);
  }

  return (<SearchableSelect
    icon={SuitcaseIcon}
    label={multiple ? "Facilities" : "Facility"}
    selected={facilities.map(c => ({
      label: c.name,
      value: c,
      key: c.id!
    }))}
    search={true}
    multiple={multiple ? true : false}
    options={options}
    localSearch={false}
    onChange={onChange}
    onSearch={onSearch}
    loading={loading}
    disabled={disabled}
  />)
}

export default FacilitySelect

type FacilitySelectSingleProps = {
  facility?: FacilityDataTypes,
  onChange?: (selected: FacilityDataTypes|undefined) => void,
  disabled?: boolean,
  clientID?: string;
  initialFacilityID?: string
}

export const FacilitySelectSingle: React.FC<FacilitySelectSingleProps> = (props) =>
{

  const onChange = (facilities: FacilityDataTypes[]) => {
    if (!props.onChange) return;


    if(facilities.length == 0){
      props.onChange(undefined)
      return
    }

    props.onChange(facilities[0])
  }


  return <FacilitySelect disabled={props.disabled} multiple={false} onSelectionChange={onChange} facilities={props.facility ? [props.facility] : []} clientID={props.clientID} initialFacilityIDs={props.initialFacilityID ? [props.initialFacilityID] : []}/>
}