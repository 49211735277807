import React, {forwardRef, KeyboardEventHandler, useCallback, useImperativeHandle, useMemo, useRef} from "react"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

type BasicTextProps = {
    label?: string
    value: string
    onChange?: (value: string) => void
    type?: React.HTMLInputTypeAttribute
    placeholder?: string
    center?: boolean
    disabled?: boolean
    required?: boolean
    onEnter?: () => void
    className?: string;
}

export type BasicTextHandle = {
    focus: () => void
}

const BasicText = forwardRef<BasicTextHandle, BasicTextProps>((props, ref) => {

    const inputRef = useRef<HTMLInputElement>(null);

    const focus = useCallback(() => {
        if(inputRef.current){
            inputRef.current.focus();
        }
    }, [ref])

    useImperativeHandle(ref, () => {
        return {
            focus,
        }
    })

    const [showPassword, setShowPassword] = React.useState(false)

    const type = useMemo(() => {
        if (props.type != "password") return props.type || "text"

        if (showPassword) return "text"
        return "password"
    }, [props.type, showPassword]);


    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (event.key != "Enter") {
            return
        }

        if (props.onEnter) {
            props.onEnter()
        }
    }



    const onChange = useCallback((value: string) => {
        if (!props.onChange) return;


        props.onChange(value)

    }, [props.onChange, props.type])


    const onBlur = useCallback(() => {
        if(props.type != "date") return
        if(!inputRef.current) return

        if(!inputRef.current.value){
            inputRef.current.value = ""
            if (props.onChange){
                props.onChange("")
            }
        }

    }, [props.type, inputRef.current, props.onChange])

    const value = useMemo(() => {
        if(props.type != "date") return props.value

        return moment(props.value).format("YYYY-MM-DD")

    }, [props.value, props.type])


    const className = useMemo(() => {

        const center = props.center ? "text-center" : ""
        const right = props.type == "date" && props.placeholder ? "text-right" : ""
        const disabled = props.disabled ? "opacity-70 cursor-not-allowed" : ""

        return `focus:outline focus:outline-blue h-10 w-full shadow-md rounded-md max-h-20 px-4 outline-none bg-white ${center} ${right} ${disabled}`
    }, [props.center, props.type, props.placeholder]);

    return <div className={`flex flex-col relative ${props.className}`}>
        {props.label != undefined && <label className={"mb-1"}>
            {props.label}
            {props.required && <span className="text-dark-red"> *</span>}
        </label>}
        {props.placeholder && props.type == "date" && (
            <div className={"absolute top-0 bottom-0 left-4 flex items-center opacity-80 z-10"} onClick={focus}>{props.placeholder}</div>
        )}
        <div className={"relative"}>
            {props.type == "password" && (
                <div className={"absolute top-0 bottom-0 right-4 flex items-center opacity-80 cursor-pointer"}
                     onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                </div>

            )}
            <input
                ref={inputRef}
                className={className}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                type={type}
                placeholder={props.placeholder}
                disabled={props.disabled}
                onKeyDown={handleKeyDown}
                onBlur={onBlur}
            />
        </div>


    </div>
});

BasicText.displayName = "BasicText";

export default BasicText

type BasicNumberProps = {
    label?: string
    onChange?: (value: number) => void
    type: "number"
    placeholder?: string
    value: number
    min: number
    max: number
    disabled?: boolean
}

export const BasicNumber: React.FC<BasicNumberProps> = (props) => {


    const handleChange = (val: number) => {
        if (props.onChange) {
            props.onChange(val)
        }
    }

    const handleOnInput = (e: any) => {
        if (e.target.value > props.max) {
            e.target.value = props.max
            handleChange(props.max)
        }

        if (e.target.value < props.min) {
            e.target.value = props.min
            handleChange(props.min)
        }
    }


    return <div className={"flex flex-col relative"}>
        {props.label != undefined && <label className={"mb-1"}>{props.label}</label>}
        <input
            min={props.min}
            max={props.max}
            onBlur={handleOnInput}
            className={"h-10 w-full shadow-md rounded-md max-h-20 px-4 outline-none bg-white text-center"}
            value={props.value}
            onChange={(e) => handleChange(Number(e.target.value))}
            type={"number"}
            placeholder={props.placeholder}
            disabled={props.disabled}
        />

    </div>
}

type BasicTextAreaProps = {
    label?: string
    value: string
    onChange?: (value: string) => void
    disabled?: boolean
    required?: boolean
    placeholder?: string

}

export const BasicTextArea: React.FC<BasicTextAreaProps> = (props) => {


    const className = useMemo(() => {

        const disabled = props.disabled ? "opacity-70 cursor-not-allowed" : ""

        return `w-full shadow-md rounded-md px-4 outline-none py-1 ${disabled}`
    }, [props.disabled]);

    return <div className={"flex flex-col relative"}>
        {props.label != undefined && <label className={"mb-1"}>
            {props.label}
            {props.required && <span className="text-dark-red"> *</span>}
        </label>}
        <textarea
            className={className}
            value={props.value}
            onChange={(e) => props.onChange && props.onChange(e.target.value)}
            placeholder={props.placeholder}
            disabled={props.disabled}
            rows={6}
        />

    </div>
}


