import React, { useEffect, useRef, useState, useCallback, memo } from "react";

type ActionSelectProps = {
  label: string;
  children: React.ReactNode;
};

export const ActionSelect: React.FC<ActionSelectProps> = memo(({ label, children }) => {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  // Flatten children, accounting for React Fragments
  const flattenChildren = useCallback((nodes: React.ReactNode): React.ReactElement[] => {
    const flat: React.ReactElement[] = [];
    React.Children.forEach(nodes, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === React.Fragment) {
          flat.push(...flattenChildren(child.props.children));
        } else {
          flat.push(child);
        }
      }
    });
    return flat;
  }, []);

  const items = flattenChildren(children);

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Action callback
  const action = useCallback(
      (callback: () => void) => {
        callback();
        setShow(false);
      },
      []
  );

  if (items.length === 0) {
    return null;
  }

  return (
      <div ref={ref} className="relative overflow-visible">
        <button
            type="button"
            className="w-full min-w-fit h-10 text-white text-sm rounded-md shadow-lg flex items-center gap-6 justify-center text-center hover:bg-opacity-80 transition-opacity bg-blue"
            onClick={() => setShow((prev) => !prev)}
        >
          {label}
        </button>

        {show && (
            <div className="absolute left-0 right-0 top-12 bg-white rounded-md shadow-2xl p-4 z-50 flex flex-col gap-4">
              {items.map((item, index) => (
                  <div key={index} className="">
                    <button
                        onClick={() => action(item.props.onSelect)}
                        className="text-center w-full"
                    >
                      {item.props.label}
                    </button>
                  </div>
              ))}
            </div>
        )}
      </div>
  );
});

ActionSelect.displayName = "ActionSelect"

type ActionProps = {
  label: string;
  onSelect: () => void;
};

// Memoized Action component
export const Action: React.FC<ActionProps> = memo(() => null);

Action.displayName = "Action"