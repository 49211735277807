import {apiV2} from "../../api/axiosConfig";
import {UserDataTypes} from "../../types/user-data-types";
import {useEffect, useState} from "react";
import {nilObjectID} from "../misc/nilObjectID";

type useFetchUserReturnType = [user: UserDataTypes|undefined, error: boolean, loading: boolean]

export const useFetchUser = (id: string): useFetchUserReturnType => {

    const [user, setUser] = useState<UserDataTypes>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(Boolean(id && id != nilObjectID));


    useEffect(() => {
        if(!id || id == nilObjectID){
            setLoading(false)
            setError(false);
            return
        }

        apiV2.get<UserDataTypes>(`/users/${id}`).then((res) => {
            setUser(res.data);
        })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, [id])

    return [user, error, loading]
}