import React, {forwardRef, useImperativeHandle, useMemo, useRef} from "react";
import Select, {ClassNamesConfig, CommonProps, FormatOptionLabelMeta, GroupBase, StylesConfig, SelectInstance} from "react-select";
import {countries} from "../../../lib/countries";

type CountrySelectProps = {
    initialValue?: string
    onChange?: (value: string) => void
}


export type CountrySelectHandle = {
    reset: () => void;
}

type CountryOption =  {
    value: string;
    countryISO: string;
    label: string;
}


export const CountrySelect = forwardRef<CountrySelectHandle, CountrySelectProps>((props, ref ) => {

    const selectInputRef = useRef<SelectInstance<CountryOption, false>>(null);

    useImperativeHandle(ref, () => ({
        reset: () => {

            if(selectInputRef.current) {
                selectInputRef.current.clearValue()
            }
        }
    }));

    const options = useMemo(() => {
        return countries.map((c) => ({
            value: c.countryCode,
            countryISO: c.countryCode,
            label: `<div class="grid grid-cols-[40px_1fr]">
                <div>${c.flag}</div>
                <div>${c.countryNameEn}</div>
            </div>`,
        }))
    }, [])

    const defaultValue = useMemo(() => {
        return options.find((o => o.countryISO === props.initialValue))
    }, [props.initialValue])

    const classNames: ClassNamesConfig<CountryOption, false> = {
        control: (state) => "h-10 w-full px-4",
    }

    const styles: StylesConfig<CountryOption> = {
        control: (def) => ({
            ...def,
            "border": "none",
        }),
        menu: (def) => ({
            ...def,
            "position": "absolute",
            "width": "500px",
            "max-width": "100vw"
        })
    }

    const formatOptionsLabel = (option: CountryOption, meta: FormatOptionLabelMeta<CountryOption>) => {
            return <div dangerouslySetInnerHTML={{__html: option.label}}/>;

    }

    const onChange = (option: CountryOption|null) => {
        if (!props.onChange) return
        props.onChange(option?.value || "")
    }

    return <div className="h-10 w-full shadow-md rounded-md max-h-20 outline-none bg-white">
        {defaultValue ?
            <Select ref={selectInputRef} onChange={onChange} options={options} classNames={classNames} styles={styles}
                    formatOptionLabel={formatOptionsLabel} defaultValue={defaultValue}/> :
            <Select ref={selectInputRef} onChange={onChange} options={options} classNames={classNames} styles={styles}
                    formatOptionLabel={formatOptionsLabel}/>
        }
    </div>
})

CountrySelect.displayName = "CountrySelect"