import React, {useCallback, useEffect, useMemo, useState} from "react";
import Layout from "../layout/Layout";
import {ClientSelectSingle} from "../../../components/form-v2/searchable-select/ClientSelect";
import {useClientsManagement} from "./ClientsManagementContext";
import {Client, Clients} from "../../../types/userManagement";
import {useQuery} from "react-query";
import {apiV2} from "../../../api/axiosConfig";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import {Action, ActionSelect} from "../../../components/ui/ActionSelect";
import PaginationNoneFloating from "../../../components/ui/Content/PaginationNoneFloating";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {isAxiosError} from "axios";
import {BlueButton} from "../../../components/ui/Buttons";
import Modal from "../../../components/portal/Modal";
import {useDepartmentsManagement} from "../departments/DepartmentsManagementContext";
import BasicText from "../../../components/form-v2/basic-text";

const ClientFilterArchiveStatus: React.FC = () => {

    const ctx = useClientsManagement()


    return <div className="bg-white shadow-md p-4 flex flex-row gap-8 items-center">

        <a className={`cursor-pointer ${ctx.archived ? "opacity-50" : ""}`} onClick={() => {
            ctx.setPage(1)
            ctx.setArchived(false)
        }}>Clients ({ctx?.clients?.total})</a>
        <a className={`cursor-pointer ${ctx.archived ? "" : "opacity-50"}`} onClick={() => {
            ctx.setPage(1)
            ctx.setArchived(true)}
        }>Archived</a>
        <div className={"flex-1"}/>
        <div>
            <a href={"/management/clients/create"}>Add Client</a>
        </div>
    </div>
}

const ClientFilterSearch: React.FC = () => {

    const ctx = useClientsManagement()


    return <div className="bg-white shadow-md p-4 flex flex-row gap-8 items-center">
        <div className="w-64">
            <BasicText value={ctx.search} onChange={ctx.setSearch} placeholder={"Search"}/>
        </div>
    </div>
}

const ClientTable: React.FC = () => {

    const ctx = useClientsManagement()

    return <div className="bg-white shadow-md p-4">
        <ClientTableHeader/>
        <hr className={"my-4"}/>

        <div className={"flex flex-col gap-4"}>
            {ctx.clients?.items && ctx.clients.items.map((d) => (
                <ClientRow client={d} key={d.id} />
            ))}
            <div className={"flex justify-center"}>
                <PaginationNoneFloating
                    currentPage={ctx.page}
                    itemsPerPage={ctx.limit}
                    dataLength={ctx.clients?.total || 0}
                    togglePageSelect={(page) => ctx.setPage(page)}
                />
            </div>


        </div>





    </div>
}


type ClientRowProps = {
    client: Client
}

const fetchClientUserCount = async (clientID?: string) => {
    const response = await apiV2.get(`clients/${clientID}/user-count`);
    return response.data
}


const fetchClientDepartmentCount = async (clientID?: string) => {
    const response = await apiV2.get(`clients/${clientID}/department-count`);
    return response.data
}

const ClientRow: React.FC<ClientRowProps> = (props) => {

    const ctx = useClientsManagement()

    const [showArchive, setShowArchive] = useState(false)
    const [showUnassign, setShowUnassign] = useState(false)

    const navigate = useNavigate()

    const userCountQuery = useQuery(
        ["client-user-count", props.client.id],
        () => fetchClientUserCount(props.client.id),
        {
            enabled: !!props.client,
        }
    );

    const departmentCountQuery = useQuery(
        ["client-department-count", props.client.id],
        () => fetchClientDepartmentCount(props.client.id),
        {
            enabled: !!props.client,
        }
    );

    const archiveToggle = useCallback(async () => {

        const url = archived ? `clients/${props.client.id}/unarchive` : `clients/${props.client.id}/archive`
        try {
            await apiV2.patch(url, {})
            ctx.refetch()
        } catch(e){
            console.error(e)
            if(isAxiosError(e) && e?.response?.data?.error){
                ctx.setActionError(e.response.data.error)
            }
        }
    }, [props.client.id])

    const unassignUsers = useCallback(async () => {
        try {
            await apiV2.patch(`clients/${props.client.id}/unassign-users`, {})
            ctx.refetch()
        } catch(e){
            console.error(e)
            if(isAxiosError(e) && e?.response?.data?.error){
                ctx.setActionError(e.response.data.error)
            }
        }
    }, [props.client.id])


    const archived = useMemo(() => {
        return props.client.archived
    }, [props.client])

    const archiveProcessing = useMemo(() => {
        if(!props.client.flags) return false
        return props.client.flags.includes("archive_processing")
    }, [props.client])


    return <>
        <Modal open={showArchive} setOpen={setShowArchive}>
            {archived &&
                <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                    <p className={"font-semibold"}>Unarchive Client</p>
                    <p>Please click to confirm unarchiving the client ({props.client.name})</p>
                    <BlueButton text={"Unarchive Client"} onClick={() => {
                        archiveToggle()
                        setShowArchive(false)
                    }} />
                </div>}

            {!archived &&
                <div className="bg-white shadow-md p-4 flex flex-col gap-4 rounded-md">
                    <p className={"font-semibold"}>Archive Client</p>
                    <p>Please click to confirm archival of the client ({props.client.name})</p>
                    <BlueButton text={"Archive Client"} onClick={() => {
                        archiveToggle()
                        setShowArchive(false)
                    }} />
                </div>
            }
        </Modal>


        <div className="grid grid-cols-[5%_37%_18%_18%_22%]">
            <div className={"flex justify-center"}>
                {!archiveProcessing && <input type={"checkbox"}
                       checked={!!(props.client.id && ctx.selected.has(props.client.id))}
                       onChange={(e) => {
                           if (!props.client.id) {
                               return
                           }
                           if (e.target.checked) {
                               ctx.addSelected(props.client.id);
                           } else {
                               ctx.removeSelected(props.client.id);
                           }
                       }}
                />}


            </div>
            <div className={"flex items-center"}>
                <Link to={`/management/clients/edit/${props.client.id}`}>
                    {props.client.name}
                </Link>
            </div>
            <div className={"flex items-center"}>
                {userCountQuery.isLoading ? <LoadingSpinner width={16} height={16}/> :

                    <a href={`/management/users?clientID=${props.client.id}`} target="_blank" rel="noreferrer">
                        {userCountQuery.data.total}
                    </a>

                }</div>

            <div className={"flex items-center"}>
                {departmentCountQuery.isLoading ? <LoadingSpinner width={16} height={16}/> :

                    <a href={`/management/departments?clientID=${props.client.id}`} target="_blank" rel="noreferrer">
                        {departmentCountQuery.data.total}
                    </a>

                }</div>
            <div className={""}>
                {archiveProcessing && archived && (
                    <p className={"text-center"}>Archiving in progress</p>
                )}

                {archiveProcessing && !archived && (
                    <p className={"text-center"}>Restoring in progress</p>
                )}

                {!archiveProcessing && archived &&
                    <ActionSelect label={"Select Action"}>
                        <Action label={"Unarchive"} onSelect={() => setShowArchive(true)}/>
                    </ActionSelect>
                }

                {!archiveProcessing && !archived &&
                    <ActionSelect label={"Select Action"}>
                        <Action label={"Edit"} onSelect={() => {
                            navigate(`/management/clients/edit/${props.client.id}`);
                        }}/>
                        <Action label={"Archive"} onSelect={() => setShowArchive(true)}/>
                    </ActionSelect>
                }
            </div>
        </div>
    </>
}

const ClientTableHeader: React.FC = () => {
    const ctx = useClientsManagement()


    return <div className="grid grid-cols-[5%_37%_18%_18%_22%]">
        <div className={"flex justify-center"}>
            <input type={"checkbox"}
                   onChange={(e) => {
                       if (e.target.checked) {
                           ctx.selectAll()
                       } else {
                           ctx.deselectAll()
                       }
                   }}
            />
        </div>
        <div>Name</div>
        <div>Users</div>
        <div>Departments</div>
        <div>Actions</div>
    </div>
}

const MultiSelectActions: React.FC = () => {
    const ctx = useClientsManagement()


    const count = useMemo(() => {
        return ctx.selected.size
    }, [ctx.selected])

    if(count == 0){
        return null
    }


    return <div className="bg-white shadow-md p-4 mt-8">
        <div className="grid grid-cols-[10%_68%_22%]">
            <div className={"flex items-center"}>{count} Selected</div>

            <div></div>
            <div className="">
                <BlueButton text={`Archive (${count})`}/>
            </div>
        </div>
    </div>
}


const ClientList: React.FC = () => {
    const ctx = useClientsManagement()
    const [searchParams, setSearchParams] = useSearchParams()

    const [message, setMessage] = useState(searchParams.get("message"));

    useEffect(() => {
        if(message){
            setTimeout(() => {
                setMessage(null)
                const newParams = new URLSearchParams(searchParams.toString());
                newParams.delete("message")
                setSearchParams(newParams);
            }, 5000)
        }
    }, [message]);

    return <Layout
        active={"clients"}
    >
        <div className="flex flex-col gap-8">
            {ctx.actionError && <div className="text-white bg-red rounded-md shadow-md p-4 flex flex-row gap-8 items-center">
                {ctx.actionError}
            </div>}
            {message && <div className="text-white bg-green shadow-md p-4 flex flex-row gap-8 items-center justify-center rounded-md">
                {message}
            </div>}
            <ClientFilterArchiveStatus/>
            <ClientFilterSearch/>
            <ClientTable/>
        </div>
        <MultiSelectActions/>


    </Layout>

}

ClientList.displayName = "ClientList";

export default ClientList;