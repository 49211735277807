import {useCallback, useState} from "react";
import {apiV2} from "../../api/axiosConfig";
import {noop} from "../misc/noop";

type useSendResetLinkReturnType = [
    func: () => Promise<void>,
    sending: boolean,
    error: boolean,
]

export const useSendResetLink = (id: string, onSuccess: () => void = noop): useSendResetLinkReturnType => {

    const [sending, setSending] = useState(false);
    const [error, setError] = useState(false);

    const func = useCallback(async () => {
        setSending(true);
        setError(false);

        await apiV2.post("send-password-reset", {userID: id})
            .then(onSuccess)
            .catch(() => setError(true))
            .finally(() => setSending(false))

    }, [id, sending, setSending]);

    return [func, sending, error]


}